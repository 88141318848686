/* You can add global styles to this file, and also import other style files */
/* TODO(Rusty): this should be merged in main.css*/

/***** REPORTS *****/
@import "@grapecity/activereports/styles/ar-js-ui.css";
@import "@grapecity/activereports/styles/ar-js-viewer.css";
@import "@grapecity/activereports/styles/ar-js-designer.css";
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/idea.css";
@import "~codemirror/theme/base16-light.css";
@import "~codemirror/addon/fold/foldgutter.css";
@import "~codemirror/addon/lint/lint.css";
@import 'ngx-toastr/toastr';
@charset "UTF-8";
/* Datex Icons v1.4 - Rusty Gillespie 2023 */

@font-face {
  font-family: 'datex-icons';
  src:  url('fonts/datex-icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-datex-"], [class*=" icon-datex-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'datex-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-datex-Accessorial:before {
  content: "\e900";
}
.icon-datex-AddAccessorial:before {
  content: "\e901";
}
.icon-datex-ASNOrder:before {
  content: "\e903";
}
.icon-datex-ASNReceiving:before {
  content: "\e902";
}
.icon-datex-BarcodePick:before {
  content: "\e910";
}
.icon-datex-Barcode-Receiving:before {
  content: "\e906";
}
.icon-datex-Billing:before {
  content: "\e904";
}
.icon-datex-Blind-Receiving:before {
  content: "\e909";
}
.icon-datex-Carrier:before {
  content: "\e905";
}
.icon-datex-ClusterPick:before {
  content: "\e90a";
}
.icon-datex-Count:before {
  content: "\e907";
}
.icon-datex-Flow:before {
  content: "\e908";
}
.icon-datex-Help:before {
  content: "\e911";
}
.icon-datex-Inbound:before {
  content: "\e912";
}
.icon-datex-InboundOrder:before {
  content: "\e913";
}
.icon-datex-Inventory:before {
  content: "\e914";
}
.icon-datex-LicensePlate:before {
  content: "\e916";
}
.icon-datex-LoadContainer:before {
  content: "\e918";
}
.icon-datex-Load:before {
  content: "\e90b";
}
.icon-datex-LocationInquiry:before {
  content: "\e915";
}
.icon-datex-Location:before {
  content: "\e91e";
}
.icon-datex-LotInquiry:before {
  content: "\e91f";
}
.icon-datex-Lot:before {
  content: "\e920";
}
.icon-datex-LPInquiry:before {
  content: "\e921";
}
.icon-datex-MoveLP:before {
  content: "\e90c";
}
.icon-datex-Manufacturing:before {
  content: "\e922";
}
.icon-datex-MaterialInquiry:before {
  content: "\e919";
}
.icon-datex-Material:before {
  content: "\e926";
}
.icon-datex-Move:before {
  content: "\e927";
}
.icon-datex-OrderGeneric:before {
  content: "\e928";
}
.icon-datex-Outbound:before {
  content: "\e929";
}
.icon-datex-OutboundOrder:before {
  content: "\e92a";
}
.icon-datex-Owner:before {
  content: "\e91a";
}
.icon-datex-Pack:before {
  content: "\e930";
}
.icon-datex-Packaging:before {
  content: "\e931";
}
.icon-datex-Pick:before {
  content: "\e90d";
}
.icon-datex-Putaway:before {
  content: "\e933";
}
.icon-datex-Query:before {
  content: "\e934";
}
.icon-datex-Receive:before {
  content: "\e90e";
}
.icon-datex-Replenishment:before {
  content: "\e936";
}
.icon-datex-Returns:before {
  content: "\e917";
}
.icon-datex-ScaleTicket:before {
  content: "\e937";
}
.icon-datex-SerialInquiry:before {
  content: "\e938";
}
.icon-datex-SerialValidation:before {
  content: "\e939";
}
.icon-datex-SerialNumber:before {
  content: "\e93a";
}
.icon-datex-Shipment:before {
  content: "\e93b";
}
.icon-datex-Tote:before {
  content: "\e93c";
}
.icon-datex-UPC:before {
  content: "\e90f";
}
.icon-datex-Warehouse:before {
  content: "\e93d";
}
.icon-datex-Wave:before {
  content: "\e93e";
}
.icon-datex-Footprint-2024 .path1:before {
  content: "\e91b";
  color: rgb(0, 0, 0);
  opacity: 0.55;
}
.icon-datex-Footprint-2024 .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.75;
}
.icon-datex-Footprint-2024 .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.icon-datex-Footprint-2024 .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

@font-face {
    font-family: "FluentSystemIcons-Resizable";
    src: url("fonts/FluentSystemIcons-Resizable.woff?ba58c662e0865949d9c88becdf65165a") format("woff");
}

i[class^="icon-ic"]:before, i[class*=" icon-ic"]:before {
    font-family: FluentSystemIcons-Resizable !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ic_fluent_access_time_20_filled:before {
    content: "\e000";
}
.icon-ic_fluent_access_time_20_regular:before {
    content: "\e001";
}
.icon-ic_fluent_accessibility_20_filled:before {
    content: "\e002";
}
.icon-ic_fluent_accessibility_20_regular:before {
    content: "\e003";
}
.icon-ic_fluent_accessibility_checkmark_20_filled:before {
    content: "\e004";
}
.icon-ic_fluent_accessibility_checkmark_20_regular:before {
    content: "\e005";
}
.icon-ic_fluent_add_20_filled:before {
    content: "\e006";
}
.icon-ic_fluent_add_20_regular:before {
    content: "\e007";
}
.icon-ic_fluent_add_circle_20_filled:before {
    content: "\e008";
}
.icon-ic_fluent_add_circle_20_regular:before {
    content: "\e009";
}
.icon-ic_fluent_add_square_20_filled:before {
    content: "\e00a";
}
.icon-ic_fluent_add_square_20_regular:before {
    content: "\e00b";
}
.icon-ic_fluent_add_square_multiple_20_filled:before {
    content: "\e00c";
}
.icon-ic_fluent_add_square_multiple_20_regular:before {
    content: "\e00d";
}
.icon-ic_fluent_add_subtract_circle_20_filled:before {
    content: "\e00e";
}
.icon-ic_fluent_add_subtract_circle_20_regular:before {
    content: "\e00f";
}
.icon-ic_fluent_airplane_20_filled:before {
    content: "\e010";
}
.icon-ic_fluent_airplane_20_regular:before {
    content: "\e011";
}
.icon-ic_fluent_airplane_landing_20_filled:before {
    content: "\e012";
}
.icon-ic_fluent_airplane_landing_20_regular:before {
    content: "\e013";
}
.icon-ic_fluent_airplane_take_off_20_filled:before {
    content: "\e014";
}
.icon-ic_fluent_airplane_take_off_20_regular:before {
    content: "\e015";
}
.icon-ic_fluent_album_20_filled:before {
    content: "\e016";
}
.icon-ic_fluent_album_20_regular:before {
    content: "\e017";
}
.icon-ic_fluent_album_add_20_filled:before {
    content: "\e018";
}
.icon-ic_fluent_album_add_20_regular:before {
    content: "\e019";
}
.icon-ic_fluent_alert_20_filled:before {
    content: "\e01a";
}
.icon-ic_fluent_alert_20_regular:before {
    content: "\e01b";
}
.icon-ic_fluent_alert_badge_20_filled:before {
    content: "\e01c";
}
.icon-ic_fluent_alert_badge_20_regular:before {
    content: "\e01d";
}
.icon-ic_fluent_alert_off_20_filled:before {
    content: "\e01e";
}
.icon-ic_fluent_alert_off_20_regular:before {
    content: "\e01f";
}
.icon-ic_fluent_alert_on_20_filled:before {
    content: "\e020";
}
.icon-ic_fluent_alert_on_20_regular:before {
    content: "\e021";
}
.icon-ic_fluent_alert_snooze_20_filled:before {
    content: "\e022";
}
.icon-ic_fluent_alert_snooze_20_regular:before {
    content: "\e023";
}
.icon-ic_fluent_alert_urgent_20_filled:before {
    content: "\e024";
}
.icon-ic_fluent_alert_urgent_20_regular:before {
    content: "\e025";
}
.icon-ic_fluent_align_bottom_20_filled:before {
    content: "\e026";
}
.icon-ic_fluent_align_bottom_20_regular:before {
    content: "\e027";
}
.icon-ic_fluent_align_center_horizontal_20_filled:before {
    content: "\e028";
}
.icon-ic_fluent_align_center_horizontal_20_regular:before {
    content: "\e029";
}
.icon-ic_fluent_align_center_vertical_20_filled:before {
    content: "\e02a";
}
.icon-ic_fluent_align_center_vertical_20_regular:before {
    content: "\e02b";
}
.icon-ic_fluent_align_end_horizontal_20_filled:before {
    content: "\e02c";
}
.icon-ic_fluent_align_end_horizontal_20_regular:before {
    content: "\e02d";
}
.icon-ic_fluent_align_end_vertical_20_filled:before {
    content: "\e02e";
}
.icon-ic_fluent_align_end_vertical_20_regular:before {
    content: "\e02f";
}
.icon-ic_fluent_align_left_20_filled:before {
    content: "\e030";
}
.icon-ic_fluent_align_left_20_regular:before {
    content: "\e031";
}
.icon-ic_fluent_align_right_20_filled:before {
    content: "\e032";
}
.icon-ic_fluent_align_right_20_regular:before {
    content: "\e033";
}
.icon-ic_fluent_align_space_around_horizontal_20_filled:before {
    content: "\e034";
}
.icon-ic_fluent_align_space_around_horizontal_20_regular:before {
    content: "\e035";
}
.icon-ic_fluent_align_space_around_vertical_20_filled:before {
    content: "\e036";
}
.icon-ic_fluent_align_space_around_vertical_20_regular:before {
    content: "\e037";
}
.icon-ic_fluent_align_space_between_horizontal_20_filled:before {
    content: "\e038";
}
.icon-ic_fluent_align_space_between_horizontal_20_regular:before {
    content: "\e039";
}
.icon-ic_fluent_align_space_between_vertical_20_filled:before {
    content: "\e03a";
}
.icon-ic_fluent_align_space_between_vertical_20_regular:before {
    content: "\e03b";
}
.icon-ic_fluent_align_space_evenly_horizontal_20_filled:before {
    content: "\e03c";
}
.icon-ic_fluent_align_space_evenly_horizontal_20_regular:before {
    content: "\e03d";
}
.icon-ic_fluent_align_space_evenly_vertical_20_filled:before {
    content: "\e03e";
}
.icon-ic_fluent_align_space_evenly_vertical_20_regular:before {
    content: "\e03f";
}
.icon-ic_fluent_align_space_fit_vertical_20_filled:before {
    content: "\e040";
}
.icon-ic_fluent_align_space_fit_vertical_20_regular:before {
    content: "\e041";
}
.icon-ic_fluent_align_start_horizontal_20_filled:before {
    content: "\e042";
}
.icon-ic_fluent_align_start_horizontal_20_regular:before {
    content: "\e043";
}
.icon-ic_fluent_align_start_vertical_20_filled:before {
    content: "\e044";
}
.icon-ic_fluent_align_start_vertical_20_regular:before {
    content: "\e045";
}
.icon-ic_fluent_align_straighten_20_filled:before {
    content: "\e046";
}
.icon-ic_fluent_align_straighten_20_regular:before {
    content: "\e047";
}
.icon-ic_fluent_align_stretch_horizontal_20_filled:before {
    content: "\e048";
}
.icon-ic_fluent_align_stretch_horizontal_20_regular:before {
    content: "\e049";
}
.icon-ic_fluent_align_stretch_vertical_20_filled:before {
    content: "\e04a";
}
.icon-ic_fluent_align_stretch_vertical_20_regular:before {
    content: "\e04b";
}
.icon-ic_fluent_align_top_20_filled:before {
    content: "\e04c";
}
.icon-ic_fluent_align_top_20_regular:before {
    content: "\e04d";
}
.icon-ic_fluent_animal_cat_20_filled:before {
    content: "\e04e";
}
.icon-ic_fluent_animal_cat_20_regular:before {
    content: "\e04f";
}
.icon-ic_fluent_animal_dog_20_filled:before {
    content: "\e050";
}
.icon-ic_fluent_animal_dog_20_regular:before {
    content: "\e051";
}
.icon-ic_fluent_animal_rabbit_20_filled:before {
    content: "\e052";
}
.icon-ic_fluent_animal_rabbit_20_regular:before {
    content: "\e053";
}
.icon-ic_fluent_animal_rabbit_off_20_filled:before {
    content: "\e054";
}
.icon-ic_fluent_animal_rabbit_off_20_regular:before {
    content: "\e055";
}
.icon-ic_fluent_animal_turtle_20_filled:before {
    content: "\e056";
}
.icon-ic_fluent_animal_turtle_20_regular:before {
    content: "\e057";
}
.icon-ic_fluent_app_folder_20_filled:before {
    content: "\e058";
}
.icon-ic_fluent_app_folder_20_regular:before {
    content: "\e059";
}
.icon-ic_fluent_app_generic_20_filled:before {
    content: "\e05a";
}
.icon-ic_fluent_app_generic_20_regular:before {
    content: "\e05b";
}
.icon-ic_fluent_app_recent_20_filled:before {
    content: "\e05c";
}
.icon-ic_fluent_app_recent_20_regular:before {
    content: "\e05d";
}
.icon-ic_fluent_app_title_20_filled:before {
    content: "\e05e";
}
.icon-ic_fluent_app_title_20_regular:before {
    content: "\e05f";
}
.icon-ic_fluent_approvals_app_20_filled:before {
    content: "\e060";
}
.icon-ic_fluent_approvals_app_20_regular:before {
    content: "\e061";
}
.icon-ic_fluent_apps_20_filled:before {
    content: "\e062";
}
.icon-ic_fluent_apps_20_regular:before {
    content: "\e063";
}
.icon-ic_fluent_apps_add_in_20_filled:before {
    content: "\e064";
}
.icon-ic_fluent_apps_add_in_20_regular:before {
    content: "\e065";
}
.icon-ic_fluent_apps_list_20_filled:before {
    content: "\e066";
}
.icon-ic_fluent_apps_list_20_regular:before {
    content: "\e067";
}
.icon-ic_fluent_apps_list_detail_20_filled:before {
    content: "\e068";
}
.icon-ic_fluent_apps_list_detail_20_regular:before {
    content: "\e069";
}
.icon-ic_fluent_archive_20_filled:before {
    content: "\e06a";
}
.icon-ic_fluent_archive_20_regular:before {
    content: "\e06b";
}
.icon-ic_fluent_archive_arrow_back_20_filled:before {
    content: "\e06c";
}
.icon-ic_fluent_archive_arrow_back_20_regular:before {
    content: "\e06d";
}
.icon-ic_fluent_archive_multiple_20_filled:before {
    content: "\e06e";
}
.icon-ic_fluent_archive_multiple_20_regular:before {
    content: "\e06f";
}
.icon-ic_fluent_archive_settings_20_filled:before {
    content: "\e070";
}
.icon-ic_fluent_archive_settings_20_regular:before {
    content: "\e071";
}
.icon-ic_fluent_arrow_autofit_content_20_filled:before {
    content: "\e072";
}
.icon-ic_fluent_arrow_autofit_content_20_regular:before {
    content: "\e073";
}
.icon-ic_fluent_arrow_autofit_down_20_filled:before {
    content: "\e074";
}
.icon-ic_fluent_arrow_autofit_down_20_regular:before {
    content: "\e075";
}
.icon-ic_fluent_arrow_autofit_height_20_filled:before {
    content: "\e076";
}
.icon-ic_fluent_arrow_autofit_height_20_regular:before {
    content: "\e077";
}
.icon-ic_fluent_arrow_autofit_height_dotted_20_filled:before {
    content: "\e078";
}
.icon-ic_fluent_arrow_autofit_height_dotted_20_regular:before {
    content: "\e079";
}
.icon-ic_fluent_arrow_autofit_height_in_20_filled:before {
    content: "\e07a";
}
.icon-ic_fluent_arrow_autofit_height_in_20_regular:before {
    content: "\e07b";
}
.icon-ic_fluent_arrow_autofit_up_20_filled:before {
    content: "\e07c";
}
.icon-ic_fluent_arrow_autofit_up_20_regular:before {
    content: "\e07d";
}
.icon-ic_fluent_arrow_autofit_width_20_filled:before {
    content: "\e07e";
}
.icon-ic_fluent_arrow_autofit_width_20_regular:before {
    content: "\e07f";
}
.icon-ic_fluent_arrow_autofit_width_dotted_20_filled:before {
    content: "\e080";
}
.icon-ic_fluent_arrow_autofit_width_dotted_20_regular:before {
    content: "\e081";
}
.icon-ic_fluent_arrow_between_down_20_filled:before {
    content: "\e082";
}
.icon-ic_fluent_arrow_between_down_20_regular:before {
    content: "\e083";
}
.icon-ic_fluent_arrow_between_up_20_filled:before {
    content: "\e084";
}
.icon-ic_fluent_arrow_between_up_20_regular:before {
    content: "\e085";
}
.icon-ic_fluent_arrow_bidirectional_left_right_20_filled:before {
    content: "\e086";
}
.icon-ic_fluent_arrow_bidirectional_left_right_20_regular:before {
    content: "\e087";
}
.icon-ic_fluent_arrow_bidirectional_up_down_20_filled:before {
    content: "\e088";
}
.icon-ic_fluent_arrow_bidirectional_up_down_20_regular:before {
    content: "\e089";
}
.icon-ic_fluent_arrow_bounce_20_filled:before {
    content: "\e08a";
}
.icon-ic_fluent_arrow_bounce_20_regular:before {
    content: "\e08b";
}
.icon-ic_fluent_arrow_circle_down_20_filled:before {
    content: "\e08c";
}
.icon-ic_fluent_arrow_circle_down_20_regular:before {
    content: "\e08d";
}
.icon-ic_fluent_arrow_circle_down_double_20_filled:before {
    content: "\e08e";
}
.icon-ic_fluent_arrow_circle_down_double_20_regular:before {
    content: "\e08f";
}
.icon-ic_fluent_arrow_circle_down_right_20_filled:before {
    content: "\e090";
}
.icon-ic_fluent_arrow_circle_down_right_20_regular:before {
    content: "\e091";
}
.icon-ic_fluent_arrow_circle_down_split_20_filled:before {
    content: "\e092";
}
.icon-ic_fluent_arrow_circle_down_split_20_regular:before {
    content: "\e093";
}
.icon-ic_fluent_arrow_circle_down_up_20_filled:before {
    content: "\e094";
}
.icon-ic_fluent_arrow_circle_down_up_20_regular:before {
    content: "\e095";
}
.icon-ic_fluent_arrow_circle_left_20_filled:before {
    content: "\e096";
}
.icon-ic_fluent_arrow_circle_left_20_regular:before {
    content: "\e097";
}
.icon-ic_fluent_arrow_circle_right_20_filled:before {
    content: "\e098";
}
.icon-ic_fluent_arrow_circle_right_20_regular:before {
    content: "\e099";
}
.icon-ic_fluent_arrow_circle_up_20_filled:before {
    content: "\e09a";
}
.icon-ic_fluent_arrow_circle_up_20_regular:before {
    content: "\e09b";
}
.icon-ic_fluent_arrow_circle_up_left_20_filled:before {
    content: "\e09c";
}
.icon-ic_fluent_arrow_circle_up_left_20_regular:before {
    content: "\e09d";
}
.icon-ic_fluent_arrow_circle_up_right_20_filled:before {
    content: "\e09e";
}
.icon-ic_fluent_arrow_circle_up_right_20_regular:before {
    content: "\e09f";
}
.icon-ic_fluent_arrow_clockwise_20_filled:before {
    content: "\e0a0";
}
.icon-ic_fluent_arrow_clockwise_20_regular:before {
    content: "\e0a1";
}
.icon-ic_fluent_arrow_clockwise_dashes_20_filled:before {
    content: "\e0a2";
}
.icon-ic_fluent_arrow_clockwise_dashes_20_regular:before {
    content: "\e0a3";
}
.icon-ic_fluent_arrow_collapse_all_20_filled:before {
    content: "\e0a4";
}
.icon-ic_fluent_arrow_collapse_all_20_regular:before {
    content: "\e0a5";
}
.icon-ic_fluent_arrow_counterclockwise_20_filled:before {
    content: "\e0a6";
}
.icon-ic_fluent_arrow_counterclockwise_20_regular:before {
    content: "\e0a7";
}
.icon-ic_fluent_arrow_counterclockwise_dashes_20_filled:before {
    content: "\e0a8";
}
.icon-ic_fluent_arrow_counterclockwise_dashes_20_regular:before {
    content: "\e0a9";
}
.icon-ic_fluent_arrow_curve_down_left_20_filled:before {
    content: "\e0aa";
}
.icon-ic_fluent_arrow_curve_down_left_20_regular:before {
    content: "\e0ab";
}
.icon-ic_fluent_arrow_curve_down_right_20_filled:before {
    content: "\e0ac";
}
.icon-ic_fluent_arrow_curve_down_right_20_regular:before {
    content: "\e0ad";
}
.icon-ic_fluent_arrow_curve_up_left_20_filled:before {
    content: "\e0ae";
}
.icon-ic_fluent_arrow_curve_up_left_20_regular:before {
    content: "\e0af";
}
.icon-ic_fluent_arrow_curve_up_right_20_filled:before {
    content: "\e0b0";
}
.icon-ic_fluent_arrow_curve_up_right_20_regular:before {
    content: "\e0b1";
}
.icon-ic_fluent_arrow_down_20_filled:before {
    content: "\e0b2";
}
.icon-ic_fluent_arrow_down_20_regular:before {
    content: "\e0b3";
}
.icon-ic_fluent_arrow_down_exclamation_20_filled:before {
    content: "\e0b4";
}
.icon-ic_fluent_arrow_down_exclamation_20_regular:before {
    content: "\e0b5";
}
.icon-ic_fluent_arrow_down_left_20_filled:before {
    content: "\e0b6";
}
.icon-ic_fluent_arrow_down_left_20_regular:before {
    content: "\e0b7";
}
.icon-ic_fluent_arrow_download_20_filled:before {
    content: "\e0b8";
}
.icon-ic_fluent_arrow_download_20_regular:before {
    content: "\e0b9";
}
.icon-ic_fluent_arrow_download_off_20_filled:before {
    content: "\e0ba";
}
.icon-ic_fluent_arrow_download_off_20_regular:before {
    content: "\e0bb";
}
.icon-ic_fluent_arrow_eject_20_filled:before {
    content: "\e0bc";
}
.icon-ic_fluent_arrow_eject_20_regular:before {
    content: "\e0bd";
}
.icon-ic_fluent_arrow_enter_20_filled:before {
    content: "\e0be";
}
.icon-ic_fluent_arrow_enter_20_regular:before {
    content: "\e0bf";
}
.icon-ic_fluent_arrow_enter_left_20_filled:before {
    content: "\e0c0";
}
.icon-ic_fluent_arrow_enter_left_20_regular:before {
    content: "\e0c1";
}
.icon-ic_fluent_arrow_enter_up_20_filled:before {
    content: "\e0c2";
}
.icon-ic_fluent_arrow_enter_up_20_regular:before {
    content: "\e0c3";
}
.icon-ic_fluent_arrow_exit_20_filled:before {
    content: "\e0c4";
}
.icon-ic_fluent_arrow_exit_20_regular:before {
    content: "\e0c5";
}
.icon-ic_fluent_arrow_expand_20_filled:before {
    content: "\e0c6";
}
.icon-ic_fluent_arrow_expand_20_regular:before {
    content: "\e0c7";
}
.icon-ic_fluent_arrow_export_20_filled:before {
    content: "\e0c8";
}
.icon-ic_fluent_arrow_export_20_regular:before {
    content: "\e0c9";
}
.icon-ic_fluent_arrow_export_ltr_20_filled:before {
    content: "\e0ca";
}
.icon-ic_fluent_arrow_export_ltr_20_regular:before {
    content: "\e0cb";
}
.icon-ic_fluent_arrow_export_rtl_20_filled:before {
    content: "\e0cc";
}
.icon-ic_fluent_arrow_export_rtl_20_regular:before {
    content: "\e0cd";
}
.icon-ic_fluent_arrow_export_up_20_filled:before {
    content: "\e0ce";
}
.icon-ic_fluent_arrow_export_up_20_regular:before {
    content: "\e0cf";
}
.icon-ic_fluent_arrow_fit_20_filled:before {
    content: "\e0d0";
}
.icon-ic_fluent_arrow_fit_20_regular:before {
    content: "\e0d1";
}
.icon-ic_fluent_arrow_fit_in_20_filled:before {
    content: "\e0d2";
}
.icon-ic_fluent_arrow_fit_in_20_regular:before {
    content: "\e0d3";
}
.icon-ic_fluent_arrow_flow_diagonal_up_right_20_filled:before {
    content: "\e0d4";
}
.icon-ic_fluent_arrow_flow_diagonal_up_right_20_regular:before {
    content: "\e0d5";
}
.icon-ic_fluent_arrow_flow_up_right_20_filled:before {
    content: "\e0d6";
}
.icon-ic_fluent_arrow_flow_up_right_20_regular:before {
    content: "\e0d7";
}
.icon-ic_fluent_arrow_flow_up_right_rectangle_multiple_20_filled:before {
    content: "\e0d8";
}
.icon-ic_fluent_arrow_flow_up_right_rectangle_multiple_20_regular:before {
    content: "\e0d9";
}
.icon-ic_fluent_arrow_forward_20_filled:before {
    content: "\e0da";
}
.icon-ic_fluent_arrow_forward_20_regular:before {
    content: "\e0db";
}
.icon-ic_fluent_arrow_forward_down_lightning_20_filled:before {
    content: "\e0dc";
}
.icon-ic_fluent_arrow_forward_down_lightning_20_regular:before {
    content: "\e0dd";
}
.icon-ic_fluent_arrow_forward_down_person_20_filled:before {
    content: "\e0de";
}
.icon-ic_fluent_arrow_forward_down_person_20_regular:before {
    content: "\e0df";
}
.icon-ic_fluent_arrow_hook_down_left_20_filled:before {
    content: "\e0e0";
}
.icon-ic_fluent_arrow_hook_down_left_20_regular:before {
    content: "\e0e1";
}
.icon-ic_fluent_arrow_hook_down_right_20_filled:before {
    content: "\e0e2";
}
.icon-ic_fluent_arrow_hook_down_right_20_regular:before {
    content: "\e0e3";
}
.icon-ic_fluent_arrow_hook_up_left_20_filled:before {
    content: "\e0e4";
}
.icon-ic_fluent_arrow_hook_up_left_20_regular:before {
    content: "\e0e5";
}
.icon-ic_fluent_arrow_hook_up_right_20_filled:before {
    content: "\e0e6";
}
.icon-ic_fluent_arrow_hook_up_right_20_regular:before {
    content: "\e0e7";
}
.icon-ic_fluent_arrow_import_20_filled:before {
    content: "\e0e8";
}
.icon-ic_fluent_arrow_import_20_regular:before {
    content: "\e0e9";
}
.icon-ic_fluent_arrow_join_20_filled:before {
    content: "\e0ea";
}
.icon-ic_fluent_arrow_join_20_regular:before {
    content: "\e0eb";
}
.icon-ic_fluent_arrow_left_20_filled:before {
    content: "\e0ec";
}
.icon-ic_fluent_arrow_left_20_regular:before {
    content: "\e0ed";
}
.icon-ic_fluent_arrow_maximize_20_filled:before {
    content: "\e0ee";
}
.icon-ic_fluent_arrow_maximize_20_regular:before {
    content: "\e0ef";
}
.icon-ic_fluent_arrow_maximize_vertical_20_filled:before {
    content: "\e0f0";
}
.icon-ic_fluent_arrow_maximize_vertical_20_regular:before {
    content: "\e0f1";
}
.icon-ic_fluent_arrow_minimize_20_filled:before {
    content: "\e0f2";
}
.icon-ic_fluent_arrow_minimize_20_regular:before {
    content: "\e0f3";
}
.icon-ic_fluent_arrow_minimize_vertical_20_filled:before {
    content: "\e0f4";
}
.icon-ic_fluent_arrow_minimize_vertical_20_regular:before {
    content: "\e0f5";
}
.icon-ic_fluent_arrow_move_20_filled:before {
    content: "\e0f6";
}
.icon-ic_fluent_arrow_move_20_regular:before {
    content: "\e0f7";
}
.icon-ic_fluent_arrow_move_inward_20_filled:before {
    content: "\e0f8";
}
.icon-ic_fluent_arrow_move_inward_20_regular:before {
    content: "\e0f9";
}
.icon-ic_fluent_arrow_next_20_filled:before {
    content: "\e0fa";
}
.icon-ic_fluent_arrow_next_20_regular:before {
    content: "\e0fb";
}
.icon-ic_fluent_arrow_outline_down_left_20_filled:before {
    content: "\e0fc";
}
.icon-ic_fluent_arrow_outline_down_left_20_regular:before {
    content: "\e0fd";
}
.icon-ic_fluent_arrow_outline_up_right_20_filled:before {
    content: "\e0fe";
}
.icon-ic_fluent_arrow_outline_up_right_20_regular:before {
    content: "\e0ff";
}
.icon-ic_fluent_arrow_paragraph_20_filled:before {
    content: "\e100";
}
.icon-ic_fluent_arrow_paragraph_20_regular:before {
    content: "\e101";
}
.icon-ic_fluent_arrow_previous_20_filled:before {
    content: "\e102";
}
.icon-ic_fluent_arrow_previous_20_regular:before {
    content: "\e103";
}
.icon-ic_fluent_arrow_redo_20_filled:before {
    content: "\e104";
}
.icon-ic_fluent_arrow_redo_20_regular:before {
    content: "\e105";
}
.icon-ic_fluent_arrow_repeat_1_20_filled:before {
    content: "\e106";
}
.icon-ic_fluent_arrow_repeat_1_20_regular:before {
    content: "\e107";
}
.icon-ic_fluent_arrow_repeat_all_20_filled:before {
    content: "\e108";
}
.icon-ic_fluent_arrow_repeat_all_20_regular:before {
    content: "\e109";
}
.icon-ic_fluent_arrow_repeat_all_off_20_filled:before {
    content: "\e10a";
}
.icon-ic_fluent_arrow_repeat_all_off_20_regular:before {
    content: "\e10b";
}
.icon-ic_fluent_arrow_reply_20_filled:before {
    content: "\e10c";
}
.icon-ic_fluent_arrow_reply_20_regular:before {
    content: "\e10d";
}
.icon-ic_fluent_arrow_reply_all_20_filled:before {
    content: "\e10e";
}
.icon-ic_fluent_arrow_reply_all_20_regular:before {
    content: "\e10f";
}
.icon-ic_fluent_arrow_reply_down_20_filled:before {
    content: "\e110";
}
.icon-ic_fluent_arrow_reply_down_20_regular:before {
    content: "\e111";
}
.icon-ic_fluent_arrow_reset_20_filled:before {
    content: "\e112";
}
.icon-ic_fluent_arrow_reset_20_regular:before {
    content: "\e113";
}
.icon-ic_fluent_arrow_right_20_filled:before {
    content: "\e114";
}
.icon-ic_fluent_arrow_right_20_regular:before {
    content: "\e115";
}
.icon-ic_fluent_arrow_rotate_clockwise_20_filled:before {
    content: "\e116";
}
.icon-ic_fluent_arrow_rotate_clockwise_20_regular:before {
    content: "\e117";
}
.icon-ic_fluent_arrow_rotate_counterclockwise_20_filled:before {
    content: "\e118";
}
.icon-ic_fluent_arrow_rotate_counterclockwise_20_regular:before {
    content: "\e119";
}
.icon-ic_fluent_arrow_routing_20_filled:before {
    content: "\e11a";
}
.icon-ic_fluent_arrow_routing_20_regular:before {
    content: "\e11b";
}
.icon-ic_fluent_arrow_routing_rectangle_multiple_20_filled:before {
    content: "\e11c";
}
.icon-ic_fluent_arrow_routing_rectangle_multiple_20_regular:before {
    content: "\e11d";
}
.icon-ic_fluent_arrow_shuffle_20_filled:before {
    content: "\e11e";
}
.icon-ic_fluent_arrow_shuffle_20_regular:before {
    content: "\e11f";
}
.icon-ic_fluent_arrow_shuffle_off_20_filled:before {
    content: "\e120";
}
.icon-ic_fluent_arrow_shuffle_off_20_regular:before {
    content: "\e121";
}
.icon-ic_fluent_arrow_sort_20_filled:before {
    content: "\e122";
}
.icon-ic_fluent_arrow_sort_20_regular:before {
    content: "\e123";
}
.icon-ic_fluent_arrow_sort_down_20_filled:before {
    content: "\e124";
}
.icon-ic_fluent_arrow_sort_down_20_regular:before {
    content: "\e125";
}
.icon-ic_fluent_arrow_sort_down_lines_20_filled:before {
    content: "\e126";
}
.icon-ic_fluent_arrow_sort_down_lines_20_regular:before {
    content: "\e127";
}
.icon-ic_fluent_arrow_sort_up_20_filled:before {
    content: "\e128";
}
.icon-ic_fluent_arrow_sort_up_20_regular:before {
    content: "\e129";
}
.icon-ic_fluent_arrow_split_20_filled:before {
    content: "\e12a";
}
.icon-ic_fluent_arrow_split_20_regular:before {
    content: "\e12b";
}
.icon-ic_fluent_arrow_sprint_20_filled:before {
    content: "\e12c";
}
.icon-ic_fluent_arrow_sprint_20_regular:before {
    content: "\e12d";
}
.icon-ic_fluent_arrow_square_down_20_filled:before {
    content: "\e12e";
}
.icon-ic_fluent_arrow_square_down_20_regular:before {
    content: "\e12f";
}
.icon-ic_fluent_arrow_square_up_right_20_filled:before {
    content: "\e130";
}
.icon-ic_fluent_arrow_square_up_right_20_regular:before {
    content: "\e131";
}
.icon-ic_fluent_arrow_step_back_20_filled:before {
    content: "\e132";
}
.icon-ic_fluent_arrow_step_back_20_regular:before {
    content: "\e133";
}
.icon-ic_fluent_arrow_step_in_20_filled:before {
    content: "\e134";
}
.icon-ic_fluent_arrow_step_in_20_regular:before {
    content: "\e135";
}
.icon-ic_fluent_arrow_step_in_diagonal_down_left_20_filled:before {
    content: "\e136";
}
.icon-ic_fluent_arrow_step_in_diagonal_down_left_20_regular:before {
    content: "\e137";
}
.icon-ic_fluent_arrow_step_in_left_20_filled:before {
    content: "\e138";
}
.icon-ic_fluent_arrow_step_in_left_20_regular:before {
    content: "\e139";
}
.icon-ic_fluent_arrow_step_in_right_20_filled:before {
    content: "\e13a";
}
.icon-ic_fluent_arrow_step_in_right_20_regular:before {
    content: "\e13b";
}
.icon-ic_fluent_arrow_step_out_20_filled:before {
    content: "\e13c";
}
.icon-ic_fluent_arrow_step_out_20_regular:before {
    content: "\e13d";
}
.icon-ic_fluent_arrow_step_over_20_filled:before {
    content: "\e13e";
}
.icon-ic_fluent_arrow_step_over_20_regular:before {
    content: "\e13f";
}
.icon-ic_fluent_arrow_swap_20_filled:before {
    content: "\e140";
}
.icon-ic_fluent_arrow_swap_20_regular:before {
    content: "\e141";
}
.icon-ic_fluent_arrow_sync_20_filled:before {
    content: "\e142";
}
.icon-ic_fluent_arrow_sync_20_regular:before {
    content: "\e143";
}
.icon-ic_fluent_arrow_sync_checkmark_20_filled:before {
    content: "\e144";
}
.icon-ic_fluent_arrow_sync_checkmark_20_regular:before {
    content: "\e145";
}
.icon-ic_fluent_arrow_sync_circle_20_filled:before {
    content: "\e146";
}
.icon-ic_fluent_arrow_sync_circle_20_regular:before {
    content: "\e147";
}
.icon-ic_fluent_arrow_sync_dismiss_20_filled:before {
    content: "\e148";
}
.icon-ic_fluent_arrow_sync_dismiss_20_regular:before {
    content: "\e149";
}
.icon-ic_fluent_arrow_sync_off_20_filled:before {
    content: "\e14a";
}
.icon-ic_fluent_arrow_sync_off_20_regular:before {
    content: "\e14b";
}
.icon-ic_fluent_arrow_trending_20_filled:before {
    content: "\e14c";
}
.icon-ic_fluent_arrow_trending_20_regular:before {
    content: "\e14d";
}
.icon-ic_fluent_arrow_trending_checkmark_20_filled:before {
    content: "\e14e";
}
.icon-ic_fluent_arrow_trending_checkmark_20_regular:before {
    content: "\e14f";
}
.icon-ic_fluent_arrow_trending_down_20_filled:before {
    content: "\e150";
}
.icon-ic_fluent_arrow_trending_down_20_regular:before {
    content: "\e151";
}
.icon-ic_fluent_arrow_trending_lines_20_filled:before {
    content: "\e152";
}
.icon-ic_fluent_arrow_trending_lines_20_regular:before {
    content: "\e153";
}
.icon-ic_fluent_arrow_trending_settings_20_filled:before {
    content: "\e154";
}
.icon-ic_fluent_arrow_trending_settings_20_regular:before {
    content: "\e155";
}
.icon-ic_fluent_arrow_trending_sparkle_20_filled:before {
    content: "\e156";
}
.icon-ic_fluent_arrow_trending_sparkle_20_regular:before {
    content: "\e157";
}
.icon-ic_fluent_arrow_trending_text_20_filled:before {
    content: "\e158";
}
.icon-ic_fluent_arrow_trending_text_20_regular:before {
    content: "\e159";
}
.icon-ic_fluent_arrow_trending_wrench_20_filled:before {
    content: "\e15a";
}
.icon-ic_fluent_arrow_trending_wrench_20_regular:before {
    content: "\e15b";
}
.icon-ic_fluent_arrow_turn_bidirectional_down_right_20_filled:before {
    content: "\e15c";
}
.icon-ic_fluent_arrow_turn_bidirectional_down_right_20_regular:before {
    content: "\e15d";
}
.icon-ic_fluent_arrow_turn_down_left_20_filled:before {
    content: "\e15e";
}
.icon-ic_fluent_arrow_turn_down_left_20_regular:before {
    content: "\e15f";
}
.icon-ic_fluent_arrow_turn_down_right_20_filled:before {
    content: "\e160";
}
.icon-ic_fluent_arrow_turn_down_right_20_regular:before {
    content: "\e161";
}
.icon-ic_fluent_arrow_turn_down_up_20_filled:before {
    content: "\e162";
}
.icon-ic_fluent_arrow_turn_down_up_20_regular:before {
    content: "\e163";
}
.icon-ic_fluent_arrow_turn_left_down_20_filled:before {
    content: "\e164";
}
.icon-ic_fluent_arrow_turn_left_down_20_regular:before {
    content: "\e165";
}
.icon-ic_fluent_arrow_turn_left_right_20_filled:before {
    content: "\e166";
}
.icon-ic_fluent_arrow_turn_left_right_20_regular:before {
    content: "\e167";
}
.icon-ic_fluent_arrow_turn_left_up_20_filled:before {
    content: "\e168";
}
.icon-ic_fluent_arrow_turn_left_up_20_regular:before {
    content: "\e169";
}
.icon-ic_fluent_arrow_turn_right_20_filled:before {
    content: "\e16a";
}
.icon-ic_fluent_arrow_turn_right_20_regular:before {
    content: "\e16b";
}
.icon-ic_fluent_arrow_turn_right_down_20_filled:before {
    content: "\e16c";
}
.icon-ic_fluent_arrow_turn_right_down_20_regular:before {
    content: "\e16d";
}
.icon-ic_fluent_arrow_turn_right_left_20_filled:before {
    content: "\e16e";
}
.icon-ic_fluent_arrow_turn_right_left_20_regular:before {
    content: "\e16f";
}
.icon-ic_fluent_arrow_turn_right_up_20_filled:before {
    content: "\e170";
}
.icon-ic_fluent_arrow_turn_right_up_20_regular:before {
    content: "\e171";
}
.icon-ic_fluent_arrow_turn_up_down_20_filled:before {
    content: "\e172";
}
.icon-ic_fluent_arrow_turn_up_down_20_regular:before {
    content: "\e173";
}
.icon-ic_fluent_arrow_turn_up_left_20_filled:before {
    content: "\e174";
}
.icon-ic_fluent_arrow_turn_up_left_20_regular:before {
    content: "\e175";
}
.icon-ic_fluent_arrow_undo_20_filled:before {
    content: "\e176";
}
.icon-ic_fluent_arrow_undo_20_regular:before {
    content: "\e177";
}
.icon-ic_fluent_arrow_up_20_filled:before {
    content: "\e178";
}
.icon-ic_fluent_arrow_up_20_regular:before {
    content: "\e179";
}
.icon-ic_fluent_arrow_up_left_20_filled:before {
    content: "\e17a";
}
.icon-ic_fluent_arrow_up_left_20_regular:before {
    content: "\e17b";
}
.icon-ic_fluent_arrow_up_right_20_filled:before {
    content: "\e17c";
}
.icon-ic_fluent_arrow_up_right_20_regular:before {
    content: "\e17d";
}
.icon-ic_fluent_arrow_upload_20_filled:before {
    content: "\e17e";
}
.icon-ic_fluent_arrow_upload_20_regular:before {
    content: "\e17f";
}
.icon-ic_fluent_arrow_wrap_20_filled:before {
    content: "\e180";
}
.icon-ic_fluent_arrow_wrap_20_regular:before {
    content: "\e181";
}
.icon-ic_fluent_arrow_wrap_off_20_filled:before {
    content: "\e182";
}
.icon-ic_fluent_arrow_wrap_off_20_regular:before {
    content: "\e183";
}
.icon-ic_fluent_arrows_bidirectional_20_filled:before {
    content: "\e184";
}
.icon-ic_fluent_arrows_bidirectional_20_regular:before {
    content: "\e185";
}
.icon-ic_fluent_attach_20_filled:before {
    content: "\e186";
}
.icon-ic_fluent_attach_20_regular:before {
    content: "\e187";
}
.icon-ic_fluent_attach_arrow_right_20_filled:before {
    content: "\e188";
}
.icon-ic_fluent_attach_arrow_right_20_regular:before {
    content: "\e189";
}
.icon-ic_fluent_attach_text_20_filled:before {
    content: "\e18a";
}
.icon-ic_fluent_attach_text_20_regular:before {
    content: "\e18b";
}
.icon-ic_fluent_auto_fit_height_20_filled:before {
    content: "\e18c";
}
.icon-ic_fluent_auto_fit_height_20_regular:before {
    content: "\e18d";
}
.icon-ic_fluent_auto_fit_width_20_filled:before {
    content: "\e18e";
}
.icon-ic_fluent_auto_fit_width_20_regular:before {
    content: "\e18f";
}
.icon-ic_fluent_autocorrect_20_filled:before {
    content: "\e190";
}
.icon-ic_fluent_autocorrect_20_regular:before {
    content: "\e191";
}
.icon-ic_fluent_autosum_20_filled:before {
    content: "\e192";
}
.icon-ic_fluent_autosum_20_regular:before {
    content: "\e193";
}
.icon-ic_fluent_backpack_20_filled:before {
    content: "\e194";
}
.icon-ic_fluent_backpack_20_regular:before {
    content: "\e195";
}
.icon-ic_fluent_backpack_add_20_filled:before {
    content: "\e196";
}
.icon-ic_fluent_backpack_add_20_regular:before {
    content: "\e197";
}
.icon-ic_fluent_backspace_20_filled:before {
    content: "\e198";
}
.icon-ic_fluent_backspace_20_regular:before {
    content: "\e199";
}
.icon-ic_fluent_badge_20_filled:before {
    content: "\e19a";
}
.icon-ic_fluent_badge_20_regular:before {
    content: "\e19b";
}
.icon-ic_fluent_balloon_20_filled:before {
    content: "\e19c";
}
.icon-ic_fluent_balloon_20_regular:before {
    content: "\e19d";
}
.icon-ic_fluent_barcode_scanner_20_filled:before {
    content: "\e19e";
}
.icon-ic_fluent_barcode_scanner_20_regular:before {
    content: "\e19f";
}
.icon-ic_fluent_battery_0_20_filled:before {
    content: "\e1a0";
}
.icon-ic_fluent_battery_0_20_regular:before {
    content: "\e1a1";
}
.icon-ic_fluent_battery_1_20_filled:before {
    content: "\e1a2";
}
.icon-ic_fluent_battery_1_20_regular:before {
    content: "\e1a3";
}
.icon-ic_fluent_battery_10_20_filled:before {
    content: "\e1a4";
}
.icon-ic_fluent_battery_10_20_regular:before {
    content: "\e1a5";
}
.icon-ic_fluent_battery_2_20_filled:before {
    content: "\e1a6";
}
.icon-ic_fluent_battery_2_20_regular:before {
    content: "\e1a7";
}
.icon-ic_fluent_battery_3_20_filled:before {
    content: "\e1a8";
}
.icon-ic_fluent_battery_3_20_regular:before {
    content: "\e1a9";
}
.icon-ic_fluent_battery_4_20_filled:before {
    content: "\e1aa";
}
.icon-ic_fluent_battery_4_20_regular:before {
    content: "\e1ab";
}
.icon-ic_fluent_battery_5_20_filled:before {
    content: "\e1ac";
}
.icon-ic_fluent_battery_5_20_regular:before {
    content: "\e1ad";
}
.icon-ic_fluent_battery_6_20_filled:before {
    content: "\e1ae";
}
.icon-ic_fluent_battery_6_20_regular:before {
    content: "\e1af";
}
.icon-ic_fluent_battery_7_20_filled:before {
    content: "\e1b0";
}
.icon-ic_fluent_battery_7_20_regular:before {
    content: "\e1b1";
}
.icon-ic_fluent_battery_8_20_filled:before {
    content: "\e1b2";
}
.icon-ic_fluent_battery_8_20_regular:before {
    content: "\e1b3";
}
.icon-ic_fluent_battery_9_20_filled:before {
    content: "\e1b4";
}
.icon-ic_fluent_battery_9_20_regular:before {
    content: "\e1b5";
}
.icon-ic_fluent_battery_charge_20_filled:before {
    content: "\e1b6";
}
.icon-ic_fluent_battery_charge_20_regular:before {
    content: "\e1b7";
}
.icon-ic_fluent_battery_checkmark_20_filled:before {
    content: "\e1b8";
}
.icon-ic_fluent_battery_checkmark_20_regular:before {
    content: "\e1b9";
}
.icon-ic_fluent_battery_saver_20_filled:before {
    content: "\e1ba";
}
.icon-ic_fluent_battery_saver_20_regular:before {
    content: "\e1bb";
}
.icon-ic_fluent_battery_warning_20_filled:before {
    content: "\e1bc";
}
.icon-ic_fluent_battery_warning_20_regular:before {
    content: "\e1bd";
}
.icon-ic_fluent_beach_20_filled:before {
    content: "\e1be";
}
.icon-ic_fluent_beach_20_regular:before {
    content: "\e1bf";
}
.icon-ic_fluent_beaker_20_filled:before {
    content: "\e1c0";
}
.icon-ic_fluent_beaker_20_regular:before {
    content: "\e1c1";
}
.icon-ic_fluent_beaker_add_20_filled:before {
    content: "\e1c2";
}
.icon-ic_fluent_beaker_add_20_regular:before {
    content: "\e1c3";
}
.icon-ic_fluent_beaker_dismiss_20_filled:before {
    content: "\e1c4";
}
.icon-ic_fluent_beaker_dismiss_20_regular:before {
    content: "\e1c5";
}
.icon-ic_fluent_beaker_edit_20_filled:before {
    content: "\e1c6";
}
.icon-ic_fluent_beaker_edit_20_regular:before {
    content: "\e1c7";
}
.icon-ic_fluent_beaker_off_20_filled:before {
    content: "\e1c8";
}
.icon-ic_fluent_beaker_off_20_regular:before {
    content: "\e1c9";
}
.icon-ic_fluent_beaker_settings_20_filled:before {
    content: "\e1ca";
}
.icon-ic_fluent_beaker_settings_20_regular:before {
    content: "\e1cb";
}
.icon-ic_fluent_bed_20_filled:before {
    content: "\e1cc";
}
.icon-ic_fluent_bed_20_regular:before {
    content: "\e1cd";
}
.icon-ic_fluent_bezier_curve_square_20_filled:before {
    content: "\e1ce";
}
.icon-ic_fluent_bezier_curve_square_20_regular:before {
    content: "\e1cf";
}
.icon-ic_fluent_bin_full_20_filled:before {
    content: "\e1d0";
}
.icon-ic_fluent_bin_full_20_regular:before {
    content: "\e1d1";
}
.icon-ic_fluent_bin_recycle_20_filled:before {
    content: "\e1d2";
}
.icon-ic_fluent_bin_recycle_20_regular:before {
    content: "\e1d3";
}
.icon-ic_fluent_bin_recycle_full_20_filled:before {
    content: "\e1d4";
}
.icon-ic_fluent_bin_recycle_full_20_regular:before {
    content: "\e1d5";
}
.icon-ic_fluent_binder_triangle_20_filled:before {
    content: "\e1d6";
}
.icon-ic_fluent_binder_triangle_20_regular:before {
    content: "\e1d7";
}
.icon-ic_fluent_bluetooth_20_filled:before {
    content: "\e1d8";
}
.icon-ic_fluent_bluetooth_20_regular:before {
    content: "\e1d9";
}
.icon-ic_fluent_bluetooth_connected_20_filled:before {
    content: "\e1da";
}
.icon-ic_fluent_bluetooth_connected_20_regular:before {
    content: "\e1db";
}
.icon-ic_fluent_bluetooth_disabled_20_filled:before {
    content: "\e1dc";
}
.icon-ic_fluent_bluetooth_disabled_20_regular:before {
    content: "\e1dd";
}
.icon-ic_fluent_bluetooth_searching_20_filled:before {
    content: "\e1de";
}
.icon-ic_fluent_bluetooth_searching_20_regular:before {
    content: "\e1df";
}
.icon-ic_fluent_blur_20_filled:before {
    content: "\e1e0";
}
.icon-ic_fluent_blur_20_regular:before {
    content: "\e1e1";
}
.icon-ic_fluent_board_20_filled:before {
    content: "\e1e2";
}
.icon-ic_fluent_board_20_regular:before {
    content: "\e1e3";
}
.icon-ic_fluent_board_games_20_filled:before {
    content: "\e1e4";
}
.icon-ic_fluent_board_games_20_regular:before {
    content: "\e1e5";
}
.icon-ic_fluent_board_heart_20_filled:before {
    content: "\e1e6";
}
.icon-ic_fluent_board_heart_20_regular:before {
    content: "\e1e7";
}
.icon-ic_fluent_board_split_20_filled:before {
    content: "\e1e8";
}
.icon-ic_fluent_board_split_20_regular:before {
    content: "\e1e9";
}
.icon-ic_fluent_book_20_filled:before {
    content: "\e1ea";
}
.icon-ic_fluent_book_20_regular:before {
    content: "\e1eb";
}
.icon-ic_fluent_book_add_20_filled:before {
    content: "\e1ec";
}
.icon-ic_fluent_book_add_20_regular:before {
    content: "\e1ed";
}
.icon-ic_fluent_book_arrow_clockwise_20_filled:before {
    content: "\e1ee";
}
.icon-ic_fluent_book_arrow_clockwise_20_regular:before {
    content: "\e1ef";
}
.icon-ic_fluent_book_clock_20_filled:before {
    content: "\e1f0";
}
.icon-ic_fluent_book_clock_20_regular:before {
    content: "\e1f1";
}
.icon-ic_fluent_book_coins_20_filled:before {
    content: "\e1f2";
}
.icon-ic_fluent_book_coins_20_regular:before {
    content: "\e1f3";
}
.icon-ic_fluent_book_compass_20_filled:before {
    content: "\e1f4";
}
.icon-ic_fluent_book_compass_20_regular:before {
    content: "\e1f5";
}
.icon-ic_fluent_book_contacts_20_filled:before {
    content: "\e1f6";
}
.icon-ic_fluent_book_contacts_20_regular:before {
    content: "\e1f7";
}
.icon-ic_fluent_book_database_20_filled:before {
    content: "\e1f8";
}
.icon-ic_fluent_book_database_20_regular:before {
    content: "\e1f9";
}
.icon-ic_fluent_book_default_20_filled:before {
    content: "\e1fa";
}
.icon-ic_fluent_book_dismiss_20_filled:before {
    content: "\e1fb";
}
.icon-ic_fluent_book_dismiss_20_regular:before {
    content: "\e1fc";
}
.icon-ic_fluent_book_exclamation_mark_20_filled:before {
    content: "\e1fd";
}
.icon-ic_fluent_book_exclamation_mark_20_regular:before {
    content: "\e1fe";
}
.icon-ic_fluent_book_globe_20_filled:before {
    content: "\e1ff";
}
.icon-ic_fluent_book_globe_20_regular:before {
    content: "\e200";
}
.icon-ic_fluent_book_information_20_filled:before {
    content: "\e201";
}
.icon-ic_fluent_book_information_20_regular:before {
    content: "\e202";
}
.icon-ic_fluent_book_letter_20_filled:before {
    content: "\e203";
}
.icon-ic_fluent_book_letter_20_regular:before {
    content: "\e204";
}
.icon-ic_fluent_book_number_20_filled:before {
    content: "\e205";
}
.icon-ic_fluent_book_number_20_regular:before {
    content: "\e206";
}
.icon-ic_fluent_book_open_20_filled:before {
    content: "\e207";
}
.icon-ic_fluent_book_open_20_regular:before {
    content: "\e208";
}
.icon-ic_fluent_book_open_globe_20_filled:before {
    content: "\e209";
}
.icon-ic_fluent_book_open_globe_20_regular:before {
    content: "\e20a";
}
.icon-ic_fluent_book_open_microphone_20_filled:before {
    content: "\e20b";
}
.icon-ic_fluent_book_open_microphone_20_regular:before {
    content: "\e20c";
}
.icon-ic_fluent_book_pulse_20_filled:before {
    content: "\e20d";
}
.icon-ic_fluent_book_pulse_20_regular:before {
    content: "\e20e";
}
.icon-ic_fluent_book_question_mark_20_filled:before {
    content: "\e20f";
}
.icon-ic_fluent_book_question_mark_20_regular:before {
    content: "\e210";
}
.icon-ic_fluent_book_question_mark_rtl_20_filled:before {
    content: "\e211";
}
.icon-ic_fluent_book_question_mark_rtl_20_regular:before {
    content: "\e212";
}
.icon-ic_fluent_book_search_20_filled:before {
    content: "\e213";
}
.icon-ic_fluent_book_search_20_regular:before {
    content: "\e214";
}
.icon-ic_fluent_book_star_20_filled:before {
    content: "\e215";
}
.icon-ic_fluent_book_star_20_regular:before {
    content: "\e216";
}
.icon-ic_fluent_book_template_20_filled:before {
    content: "\e217";
}
.icon-ic_fluent_book_template_20_regular:before {
    content: "\e218";
}
.icon-ic_fluent_book_theta_20_filled:before {
    content: "\e219";
}
.icon-ic_fluent_book_theta_20_regular:before {
    content: "\e21a";
}
.icon-ic_fluent_book_toolbox_20_filled:before {
    content: "\e21b";
}
.icon-ic_fluent_book_toolbox_20_regular:before {
    content: "\e21c";
}
.icon-ic_fluent_bookmark_20_filled:before {
    content: "\e21d";
}
.icon-ic_fluent_bookmark_20_regular:before {
    content: "\e21e";
}
.icon-ic_fluent_bookmark_add_20_filled:before {
    content: "\e21f";
}
.icon-ic_fluent_bookmark_add_20_regular:before {
    content: "\e220";
}
.icon-ic_fluent_bookmark_multiple_20_filled:before {
    content: "\e221";
}
.icon-ic_fluent_bookmark_multiple_20_regular:before {
    content: "\e222";
}
.icon-ic_fluent_bookmark_off_20_filled:before {
    content: "\e223";
}
.icon-ic_fluent_bookmark_off_20_regular:before {
    content: "\e224";
}
.icon-ic_fluent_bookmark_search_20_filled:before {
    content: "\e225";
}
.icon-ic_fluent_bookmark_search_20_regular:before {
    content: "\e226";
}
.icon-ic_fluent_border_all_20_filled:before {
    content: "\e227";
}
.icon-ic_fluent_border_all_20_regular:before {
    content: "\e228";
}
.icon-ic_fluent_border_bottom_20_filled:before {
    content: "\e229";
}
.icon-ic_fluent_border_bottom_20_regular:before {
    content: "\e22a";
}
.icon-ic_fluent_border_bottom_double_20_filled:before {
    content: "\e22b";
}
.icon-ic_fluent_border_bottom_double_20_regular:before {
    content: "\e22c";
}
.icon-ic_fluent_border_bottom_thick_20_filled:before {
    content: "\e22d";
}
.icon-ic_fluent_border_bottom_thick_20_regular:before {
    content: "\e22e";
}
.icon-ic_fluent_border_inside_20_filled:before {
    content: "\e22f";
}
.icon-ic_fluent_border_inside_20_regular:before {
    content: "\e230";
}
.icon-ic_fluent_border_left_20_filled:before {
    content: "\e231";
}
.icon-ic_fluent_border_left_20_regular:before {
    content: "\e232";
}
.icon-ic_fluent_border_left_right_20_filled:before {
    content: "\e233";
}
.icon-ic_fluent_border_left_right_20_regular:before {
    content: "\e234";
}
.icon-ic_fluent_border_none_20_filled:before {
    content: "\e235";
}
.icon-ic_fluent_border_none_20_regular:before {
    content: "\e236";
}
.icon-ic_fluent_border_outside_20_filled:before {
    content: "\e237";
}
.icon-ic_fluent_border_outside_20_regular:before {
    content: "\e238";
}
.icon-ic_fluent_border_outside_thick_20_filled:before {
    content: "\e239";
}
.icon-ic_fluent_border_outside_thick_20_regular:before {
    content: "\e23a";
}
.icon-ic_fluent_border_right_20_filled:before {
    content: "\e23b";
}
.icon-ic_fluent_border_right_20_regular:before {
    content: "\e23c";
}
.icon-ic_fluent_border_top_20_filled:before {
    content: "\e23d";
}
.icon-ic_fluent_border_top_20_regular:before {
    content: "\e23e";
}
.icon-ic_fluent_border_top_bottom_20_filled:before {
    content: "\e23f";
}
.icon-ic_fluent_border_top_bottom_20_regular:before {
    content: "\e240";
}
.icon-ic_fluent_border_top_bottom_double_20_filled:before {
    content: "\e241";
}
.icon-ic_fluent_border_top_bottom_double_20_regular:before {
    content: "\e242";
}
.icon-ic_fluent_border_top_bottom_thick_20_filled:before {
    content: "\e243";
}
.icon-ic_fluent_border_top_bottom_thick_20_regular:before {
    content: "\e244";
}
.icon-ic_fluent_bot_20_filled:before {
    content: "\e245";
}
.icon-ic_fluent_bot_20_regular:before {
    content: "\e246";
}
.icon-ic_fluent_bot_add_20_filled:before {
    content: "\e247";
}
.icon-ic_fluent_bot_add_20_regular:before {
    content: "\e248";
}
.icon-ic_fluent_bot_sparkle_20_filled:before {
    content: "\e249";
}
.icon-ic_fluent_bot_sparkle_20_regular:before {
    content: "\e24a";
}
.icon-ic_fluent_bow_tie_20_filled:before {
    content: "\e24b";
}
.icon-ic_fluent_bow_tie_20_regular:before {
    content: "\e24c";
}
.icon-ic_fluent_bowl_chopsticks_20_filled:before {
    content: "\e24d";
}
.icon-ic_fluent_bowl_chopsticks_20_regular:before {
    content: "\e24e";
}
.icon-ic_fluent_bowl_salad_20_filled:before {
    content: "\e24f";
}
.icon-ic_fluent_bowl_salad_20_regular:before {
    content: "\e250";
}
.icon-ic_fluent_box_20_filled:before {
    content: "\e251";
}
.icon-ic_fluent_box_20_regular:before {
    content: "\e252";
}
.icon-ic_fluent_box_arrow_left_20_filled:before {
    content: "\e253";
}
.icon-ic_fluent_box_arrow_left_20_regular:before {
    content: "\e254";
}
.icon-ic_fluent_box_arrow_up_20_filled:before {
    content: "\e255";
}
.icon-ic_fluent_box_arrow_up_20_regular:before {
    content: "\e256";
}
.icon-ic_fluent_box_checkmark_20_filled:before {
    content: "\e257";
}
.icon-ic_fluent_box_checkmark_20_regular:before {
    content: "\e258";
}
.icon-ic_fluent_box_dismiss_20_filled:before {
    content: "\e259";
}
.icon-ic_fluent_box_dismiss_20_regular:before {
    content: "\e25a";
}
.icon-ic_fluent_box_edit_20_filled:before {
    content: "\e25b";
}
.icon-ic_fluent_box_edit_20_regular:before {
    content: "\e25c";
}
.icon-ic_fluent_box_multiple_20_filled:before {
    content: "\e25d";
}
.icon-ic_fluent_box_multiple_20_regular:before {
    content: "\e25e";
}
.icon-ic_fluent_box_multiple_arrow_left_20_filled:before {
    content: "\e25f";
}
.icon-ic_fluent_box_multiple_arrow_left_20_regular:before {
    content: "\e260";
}
.icon-ic_fluent_box_multiple_arrow_right_20_filled:before {
    content: "\e261";
}
.icon-ic_fluent_box_multiple_arrow_right_20_regular:before {
    content: "\e262";
}
.icon-ic_fluent_box_multiple_checkmark_20_filled:before {
    content: "\e263";
}
.icon-ic_fluent_box_multiple_checkmark_20_regular:before {
    content: "\e264";
}
.icon-ic_fluent_box_multiple_search_20_filled:before {
    content: "\e265";
}
.icon-ic_fluent_box_multiple_search_20_regular:before {
    content: "\e266";
}
.icon-ic_fluent_box_search_20_filled:before {
    content: "\e267";
}
.icon-ic_fluent_box_search_20_regular:before {
    content: "\e268";
}
.icon-ic_fluent_box_toolbox_20_filled:before {
    content: "\e269";
}
.icon-ic_fluent_box_toolbox_20_regular:before {
    content: "\e26a";
}
.icon-ic_fluent_braces_20_filled:before {
    content: "\e26b";
}
.icon-ic_fluent_braces_20_regular:before {
    content: "\e26c";
}
.icon-ic_fluent_braces_variable_20_filled:before {
    content: "\e26d";
}
.icon-ic_fluent_braces_variable_20_regular:before {
    content: "\e26e";
}
.icon-ic_fluent_brain_circuit_20_filled:before {
    content: "\e26f";
}
.icon-ic_fluent_brain_circuit_20_regular:before {
    content: "\e270";
}
.icon-ic_fluent_branch_20_filled:before {
    content: "\e271";
}
.icon-ic_fluent_branch_20_regular:before {
    content: "\e272";
}
.icon-ic_fluent_branch_compare_20_filled:before {
    content: "\e273";
}
.icon-ic_fluent_branch_compare_20_regular:before {
    content: "\e274";
}
.icon-ic_fluent_branch_fork_20_filled:before {
    content: "\e275";
}
.icon-ic_fluent_branch_fork_20_regular:before {
    content: "\e276";
}
.icon-ic_fluent_branch_fork_hint_20_filled:before {
    content: "\e277";
}
.icon-ic_fluent_branch_fork_hint_20_regular:before {
    content: "\e278";
}
.icon-ic_fluent_branch_fork_link_20_filled:before {
    content: "\e279";
}
.icon-ic_fluent_branch_fork_link_20_regular:before {
    content: "\e27a";
}
.icon-ic_fluent_branch_request_20_filled:before {
    content: "\e27b";
}
.icon-ic_fluent_branch_request_20_regular:before {
    content: "\e27c";
}
.icon-ic_fluent_breakout_room_20_filled:before {
    content: "\e27d";
}
.icon-ic_fluent_breakout_room_20_regular:before {
    content: "\e27e";
}
.icon-ic_fluent_briefcase_20_filled:before {
    content: "\e27f";
}
.icon-ic_fluent_briefcase_20_regular:before {
    content: "\e280";
}
.icon-ic_fluent_briefcase_medical_20_filled:before {
    content: "\e281";
}
.icon-ic_fluent_briefcase_medical_20_regular:before {
    content: "\e282";
}
.icon-ic_fluent_briefcase_off_20_filled:before {
    content: "\e283";
}
.icon-ic_fluent_briefcase_off_20_regular:before {
    content: "\e284";
}
.icon-ic_fluent_briefcase_search_20_filled:before {
    content: "\e285";
}
.icon-ic_fluent_briefcase_search_20_regular:before {
    content: "\e286";
}
.icon-ic_fluent_brightness_high_20_filled:before {
    content: "\e287";
}
.icon-ic_fluent_brightness_high_20_regular:before {
    content: "\e288";
}
.icon-ic_fluent_brightness_low_20_filled:before {
    content: "\e289";
}
.icon-ic_fluent_brightness_low_20_regular:before {
    content: "\e28a";
}
.icon-ic_fluent_broad_activity_feed_20_filled:before {
    content: "\e28b";
}
.icon-ic_fluent_broad_activity_feed_20_regular:before {
    content: "\e28c";
}
.icon-ic_fluent_broom_20_filled:before {
    content: "\e28d";
}
.icon-ic_fluent_broom_20_regular:before {
    content: "\e28e";
}
.icon-ic_fluent_bubble_multiple_20_filled:before {
    content: "\e28f";
}
.icon-ic_fluent_bubble_multiple_20_regular:before {
    content: "\e290";
}
.icon-ic_fluent_bug_20_filled:before {
    content: "\e291";
}
.icon-ic_fluent_bug_20_regular:before {
    content: "\e292";
}
.icon-ic_fluent_bug_arrow_counterclockwise_20_filled:before {
    content: "\e293";
}
.icon-ic_fluent_bug_arrow_counterclockwise_20_regular:before {
    content: "\e294";
}
.icon-ic_fluent_bug_prohibited_20_filled:before {
    content: "\e295";
}
.icon-ic_fluent_bug_prohibited_20_regular:before {
    content: "\e296";
}
.icon-ic_fluent_building_20_filled:before {
    content: "\e297";
}
.icon-ic_fluent_building_20_regular:before {
    content: "\e298";
}
.icon-ic_fluent_building_bank_20_filled:before {
    content: "\e299";
}
.icon-ic_fluent_building_bank_20_regular:before {
    content: "\e29a";
}
.icon-ic_fluent_building_bank_link_20_filled:before {
    content: "\e29b";
}
.icon-ic_fluent_building_bank_link_20_regular:before {
    content: "\e29c";
}
.icon-ic_fluent_building_bank_toolbox_20_filled:before {
    content: "\e29d";
}
.icon-ic_fluent_building_bank_toolbox_20_regular:before {
    content: "\e29e";
}
.icon-ic_fluent_building_desktop_20_filled:before {
    content: "\e29f";
}
.icon-ic_fluent_building_desktop_20_regular:before {
    content: "\e2a0";
}
.icon-ic_fluent_building_factory_20_filled:before {
    content: "\e2a1";
}
.icon-ic_fluent_building_factory_20_regular:before {
    content: "\e2a2";
}
.icon-ic_fluent_building_government_20_filled:before {
    content: "\e2a3";
}
.icon-ic_fluent_building_government_20_regular:before {
    content: "\e2a4";
}
.icon-ic_fluent_building_government_search_20_filled:before {
    content: "\e2a5";
}
.icon-ic_fluent_building_government_search_20_regular:before {
    content: "\e2a6";
}
.icon-ic_fluent_building_home_20_filled:before {
    content: "\e2a7";
}
.icon-ic_fluent_building_home_20_regular:before {
    content: "\e2a8";
}
.icon-ic_fluent_building_lighthouse_20_filled:before {
    content: "\e2a9";
}
.icon-ic_fluent_building_lighthouse_20_regular:before {
    content: "\e2aa";
}
.icon-ic_fluent_building_mosque_20_filled:before {
    content: "\e2ab";
}
.icon-ic_fluent_building_mosque_20_regular:before {
    content: "\e2ac";
}
.icon-ic_fluent_building_multiple_20_filled:before {
    content: "\e2ad";
}
.icon-ic_fluent_building_multiple_20_regular:before {
    content: "\e2ae";
}
.icon-ic_fluent_building_people_20_filled:before {
    content: "\e2af";
}
.icon-ic_fluent_building_people_20_regular:before {
    content: "\e2b0";
}
.icon-ic_fluent_building_retail_20_filled:before {
    content: "\e2b1";
}
.icon-ic_fluent_building_retail_20_regular:before {
    content: "\e2b2";
}
.icon-ic_fluent_building_retail_money_20_filled:before {
    content: "\e2b3";
}
.icon-ic_fluent_building_retail_money_20_regular:before {
    content: "\e2b4";
}
.icon-ic_fluent_building_retail_more_20_filled:before {
    content: "\e2b5";
}
.icon-ic_fluent_building_retail_more_20_regular:before {
    content: "\e2b6";
}
.icon-ic_fluent_building_retail_shield_20_filled:before {
    content: "\e2b7";
}
.icon-ic_fluent_building_retail_shield_20_regular:before {
    content: "\e2b8";
}
.icon-ic_fluent_building_retail_toolbox_20_filled:before {
    content: "\e2b9";
}
.icon-ic_fluent_building_retail_toolbox_20_regular:before {
    content: "\e2ba";
}
.icon-ic_fluent_building_shop_20_filled:before {
    content: "\e2bb";
}
.icon-ic_fluent_building_shop_20_regular:before {
    content: "\e2bc";
}
.icon-ic_fluent_building_skyscraper_20_filled:before {
    content: "\e2bd";
}
.icon-ic_fluent_building_skyscraper_20_regular:before {
    content: "\e2be";
}
.icon-ic_fluent_building_swap_20_filled:before {
    content: "\e2bf";
}
.icon-ic_fluent_building_swap_20_regular:before {
    content: "\e2c0";
}
.icon-ic_fluent_building_townhouse_20_filled:before {
    content: "\e2c1";
}
.icon-ic_fluent_building_townhouse_20_regular:before {
    content: "\e2c2";
}
.icon-ic_fluent_button_20_filled:before {
    content: "\e2c3";
}
.icon-ic_fluent_button_20_regular:before {
    content: "\e2c4";
}
.icon-ic_fluent_calculator_20_filled:before {
    content: "\e2c5";
}
.icon-ic_fluent_calculator_20_regular:before {
    content: "\e2c6";
}
.icon-ic_fluent_calculator_arrow_clockwise_20_filled:before {
    content: "\e2c7";
}
.icon-ic_fluent_calculator_arrow_clockwise_20_regular:before {
    content: "\e2c8";
}
.icon-ic_fluent_calculator_multiple_20_filled:before {
    content: "\e2c9";
}
.icon-ic_fluent_calculator_multiple_20_regular:before {
    content: "\e2ca";
}
.icon-ic_fluent_calendar_20_filled:before {
    content: "\e2cb";
}
.icon-ic_fluent_calendar_20_regular:before {
    content: "\e2cc";
}
.icon-ic_fluent_calendar_3_day_20_filled:before {
    content: "\e2cd";
}
.icon-ic_fluent_calendar_3_day_20_regular:before {
    content: "\e2ce";
}
.icon-ic_fluent_calendar_add_20_filled:before {
    content: "\e2cf";
}
.icon-ic_fluent_calendar_add_20_regular:before {
    content: "\e2d0";
}
.icon-ic_fluent_calendar_agenda_20_filled:before {
    content: "\e2d1";
}
.icon-ic_fluent_calendar_agenda_20_regular:before {
    content: "\e2d2";
}
.icon-ic_fluent_calendar_arrow_counterclockwise_20_filled:before {
    content: "\e2d3";
}
.icon-ic_fluent_calendar_arrow_counterclockwise_20_regular:before {
    content: "\e2d4";
}
.icon-ic_fluent_calendar_arrow_down_20_filled:before {
    content: "\e2d5";
}
.icon-ic_fluent_calendar_arrow_down_20_regular:before {
    content: "\e2d6";
}
.icon-ic_fluent_calendar_arrow_right_20_filled:before {
    content: "\e2d7";
}
.icon-ic_fluent_calendar_arrow_right_20_regular:before {
    content: "\e2d8";
}
.icon-ic_fluent_calendar_assistant_20_filled:before {
    content: "\e2d9";
}
.icon-ic_fluent_calendar_assistant_20_regular:before {
    content: "\e2da";
}
.icon-ic_fluent_calendar_cancel_20_filled:before {
    content: "\e2db";
}
.icon-ic_fluent_calendar_cancel_20_regular:before {
    content: "\e2dc";
}
.icon-ic_fluent_calendar_chat_20_filled:before {
    content: "\e2dd";
}
.icon-ic_fluent_calendar_chat_20_regular:before {
    content: "\e2de";
}
.icon-ic_fluent_calendar_checkmark_20_filled:before {
    content: "\e2df";
}
.icon-ic_fluent_calendar_checkmark_20_regular:before {
    content: "\e2e0";
}
.icon-ic_fluent_calendar_clock_20_filled:before {
    content: "\e2e1";
}
.icon-ic_fluent_calendar_clock_20_regular:before {
    content: "\e2e2";
}
.icon-ic_fluent_calendar_data_bar_20_filled:before {
    content: "\e2e3";
}
.icon-ic_fluent_calendar_data_bar_20_regular:before {
    content: "\e2e4";
}
.icon-ic_fluent_calendar_date_20_filled:before {
    content: "\e2e5";
}
.icon-ic_fluent_calendar_date_20_regular:before {
    content: "\e2e6";
}
.icon-ic_fluent_calendar_day_20_filled:before {
    content: "\e2e7";
}
.icon-ic_fluent_calendar_day_20_regular:before {
    content: "\e2e8";
}
.icon-ic_fluent_calendar_edit_20_filled:before {
    content: "\e2e9";
}
.icon-ic_fluent_calendar_edit_20_regular:before {
    content: "\e2ea";
}
.icon-ic_fluent_calendar_empty_20_filled:before {
    content: "\e2eb";
}
.icon-ic_fluent_calendar_empty_20_regular:before {
    content: "\e2ec";
}
.icon-ic_fluent_calendar_error_20_filled:before {
    content: "\e2ed";
}
.icon-ic_fluent_calendar_error_20_regular:before {
    content: "\e2ee";
}
.icon-ic_fluent_calendar_eye_20_filled:before {
    content: "\e2ef";
}
.icon-ic_fluent_calendar_eye_20_regular:before {
    content: "\e2f0";
}
.icon-ic_fluent_calendar_info_20_filled:before {
    content: "\e2f1";
}
.icon-ic_fluent_calendar_info_20_regular:before {
    content: "\e2f2";
}
.icon-ic_fluent_calendar_lock_20_filled:before {
    content: "\e2f3";
}
.icon-ic_fluent_calendar_lock_20_regular:before {
    content: "\e2f4";
}
.icon-ic_fluent_calendar_ltr_20_filled:before {
    content: "\e2f5";
}
.icon-ic_fluent_calendar_ltr_20_regular:before {
    content: "\e2f6";
}
.icon-ic_fluent_calendar_mail_20_filled:before {
    content: "\e2f7";
}
.icon-ic_fluent_calendar_mail_20_regular:before {
    content: "\e2f8";
}
.icon-ic_fluent_calendar_mention_20_filled:before {
    content: "\e2f9";
}
.icon-ic_fluent_calendar_mention_20_regular:before {
    content: "\e2fa";
}
.icon-ic_fluent_calendar_month_20_filled:before {
    content: "\e2fb";
}
.icon-ic_fluent_calendar_month_20_regular:before {
    content: "\e2fc";
}
.icon-ic_fluent_calendar_multiple_20_filled:before {
    content: "\e2fd";
}
.icon-ic_fluent_calendar_multiple_20_regular:before {
    content: "\e2fe";
}
.icon-ic_fluent_calendar_note_20_filled:before {
    content: "\e2ff";
}
.icon-ic_fluent_calendar_note_20_regular:before {
    content: "\e300";
}
.icon-ic_fluent_calendar_pattern_20_filled:before {
    content: "\e301";
}
.icon-ic_fluent_calendar_pattern_20_regular:before {
    content: "\e302";
}
.icon-ic_fluent_calendar_person_20_filled:before {
    content: "\e303";
}
.icon-ic_fluent_calendar_person_20_regular:before {
    content: "\e304";
}
.icon-ic_fluent_calendar_phone_20_filled:before {
    content: "\e305";
}
.icon-ic_fluent_calendar_phone_20_regular:before {
    content: "\e306";
}
.icon-ic_fluent_calendar_play_20_filled:before {
    content: "\e307";
}
.icon-ic_fluent_calendar_play_20_regular:before {
    content: "\e308";
}
.icon-ic_fluent_calendar_question_mark_20_filled:before {
    content: "\e309";
}
.icon-ic_fluent_calendar_question_mark_20_regular:before {
    content: "\e30a";
}
.icon-ic_fluent_calendar_record_20_filled:before {
    content: "\e30b";
}
.icon-ic_fluent_calendar_record_20_regular:before {
    content: "\e30c";
}
.icon-ic_fluent_calendar_reply_20_filled:before {
    content: "\e30d";
}
.icon-ic_fluent_calendar_reply_20_regular:before {
    content: "\e30e";
}
.icon-ic_fluent_calendar_rtl_20_filled:before {
    content: "\e30f";
}
.icon-ic_fluent_calendar_rtl_20_regular:before {
    content: "\e310";
}
.icon-ic_fluent_calendar_search_20_filled:before {
    content: "\e311";
}
.icon-ic_fluent_calendar_search_20_regular:before {
    content: "\e312";
}
.icon-ic_fluent_calendar_settings_20_filled:before {
    content: "\e313";
}
.icon-ic_fluent_calendar_settings_20_regular:before {
    content: "\e314";
}
.icon-ic_fluent_calendar_shield_20_filled:before {
    content: "\e315";
}
.icon-ic_fluent_calendar_shield_20_regular:before {
    content: "\e316";
}
.icon-ic_fluent_calendar_star_20_filled:before {
    content: "\e317";
}
.icon-ic_fluent_calendar_star_20_regular:before {
    content: "\e318";
}
.icon-ic_fluent_calendar_sync_20_filled:before {
    content: "\e319";
}
.icon-ic_fluent_calendar_sync_20_regular:before {
    content: "\e31a";
}
.icon-ic_fluent_calendar_today_20_filled:before {
    content: "\e31b";
}
.icon-ic_fluent_calendar_today_20_regular:before {
    content: "\e31c";
}
.icon-ic_fluent_calendar_toolbox_20_filled:before {
    content: "\e31d";
}
.icon-ic_fluent_calendar_toolbox_20_regular:before {
    content: "\e31e";
}
.icon-ic_fluent_calendar_week_numbers_20_filled:before {
    content: "\e31f";
}
.icon-ic_fluent_calendar_week_numbers_20_regular:before {
    content: "\e320";
}
.icon-ic_fluent_calendar_week_start_20_filled:before {
    content: "\e321";
}
.icon-ic_fluent_calendar_week_start_20_regular:before {
    content: "\e322";
}
.icon-ic_fluent_calendar_work_week_20_filled:before {
    content: "\e323";
}
.icon-ic_fluent_calendar_work_week_20_regular:before {
    content: "\e324";
}
.icon-ic_fluent_call_20_filled:before {
    content: "\e325";
}
.icon-ic_fluent_call_20_regular:before {
    content: "\e326";
}
.icon-ic_fluent_call_add_20_filled:before {
    content: "\e327";
}
.icon-ic_fluent_call_add_20_regular:before {
    content: "\e328";
}
.icon-ic_fluent_call_checkmark_20_filled:before {
    content: "\e329";
}
.icon-ic_fluent_call_checkmark_20_regular:before {
    content: "\e32a";
}
.icon-ic_fluent_call_connecting_20_filled:before {
    content: "\e32b";
}
.icon-ic_fluent_call_connecting_20_regular:before {
    content: "\e32c";
}
.icon-ic_fluent_call_dismiss_20_filled:before {
    content: "\e32d";
}
.icon-ic_fluent_call_dismiss_20_regular:before {
    content: "\e32e";
}
.icon-ic_fluent_call_end_20_filled:before {
    content: "\e32f";
}
.icon-ic_fluent_call_end_20_regular:before {
    content: "\e330";
}
.icon-ic_fluent_call_exclamation_20_filled:before {
    content: "\e331";
}
.icon-ic_fluent_call_exclamation_20_regular:before {
    content: "\e332";
}
.icon-ic_fluent_call_forward_20_filled:before {
    content: "\e333";
}
.icon-ic_fluent_call_forward_20_regular:before {
    content: "\e334";
}
.icon-ic_fluent_call_inbound_20_filled:before {
    content: "\e335";
}
.icon-ic_fluent_call_inbound_20_regular:before {
    content: "\e336";
}
.icon-ic_fluent_call_missed_20_filled:before {
    content: "\e337";
}
.icon-ic_fluent_call_missed_20_regular:before {
    content: "\e338";
}
.icon-ic_fluent_call_outbound_20_filled:before {
    content: "\e339";
}
.icon-ic_fluent_call_outbound_20_regular:before {
    content: "\e33a";
}
.icon-ic_fluent_call_park_20_filled:before {
    content: "\e33b";
}
.icon-ic_fluent_call_park_20_regular:before {
    content: "\e33c";
}
.icon-ic_fluent_call_pause_20_filled:before {
    content: "\e33d";
}
.icon-ic_fluent_call_pause_20_regular:before {
    content: "\e33e";
}
.icon-ic_fluent_call_prohibited_20_filled:before {
    content: "\e33f";
}
.icon-ic_fluent_call_prohibited_20_regular:before {
    content: "\e340";
}
.icon-ic_fluent_call_transfer_20_filled:before {
    content: "\e341";
}
.icon-ic_fluent_call_transfer_20_regular:before {
    content: "\e342";
}
.icon-ic_fluent_call_warning_20_filled:before {
    content: "\e343";
}
.icon-ic_fluent_call_warning_20_regular:before {
    content: "\e344";
}
.icon-ic_fluent_calligraphy_pen_20_filled:before {
    content: "\e345";
}
.icon-ic_fluent_calligraphy_pen_20_regular:before {
    content: "\e346";
}
.icon-ic_fluent_calligraphy_pen_checkmark_20_filled:before {
    content: "\e347";
}
.icon-ic_fluent_calligraphy_pen_checkmark_20_regular:before {
    content: "\e348";
}
.icon-ic_fluent_calligraphy_pen_error_20_filled:before {
    content: "\e349";
}
.icon-ic_fluent_calligraphy_pen_error_20_regular:before {
    content: "\e34a";
}
.icon-ic_fluent_calligraphy_pen_question_mark_20_filled:before {
    content: "\e34b";
}
.icon-ic_fluent_calligraphy_pen_question_mark_20_regular:before {
    content: "\e34c";
}
.icon-ic_fluent_camera_20_filled:before {
    content: "\e34d";
}
.icon-ic_fluent_camera_20_regular:before {
    content: "\e34e";
}
.icon-ic_fluent_camera_add_20_filled:before {
    content: "\e34f";
}
.icon-ic_fluent_camera_add_20_regular:before {
    content: "\e350";
}
.icon-ic_fluent_camera_dome_20_filled:before {
    content: "\e351";
}
.icon-ic_fluent_camera_dome_20_regular:before {
    content: "\e352";
}
.icon-ic_fluent_camera_edit_20_filled:before {
    content: "\e353";
}
.icon-ic_fluent_camera_edit_20_regular:before {
    content: "\e354";
}
.icon-ic_fluent_camera_off_20_filled:before {
    content: "\e355";
}
.icon-ic_fluent_camera_off_20_regular:before {
    content: "\e356";
}
.icon-ic_fluent_camera_sparkles_20_filled:before {
    content: "\e357";
}
.icon-ic_fluent_camera_sparkles_20_regular:before {
    content: "\e358";
}
.icon-ic_fluent_camera_switch_20_filled:before {
    content: "\e359";
}
.icon-ic_fluent_camera_switch_20_regular:before {
    content: "\e35a";
}
.icon-ic_fluent_card_ui_20_filled:before {
    content: "\e35b";
}
.icon-ic_fluent_card_ui_20_regular:before {
    content: "\e35c";
}
.icon-ic_fluent_caret_down_20_filled:before {
    content: "\e35d";
}
.icon-ic_fluent_caret_down_20_regular:before {
    content: "\e35e";
}
.icon-ic_fluent_caret_down_right_20_filled:before {
    content: "\e35f";
}
.icon-ic_fluent_caret_down_right_20_regular:before {
    content: "\e360";
}
.icon-ic_fluent_caret_left_20_filled:before {
    content: "\e361";
}
.icon-ic_fluent_caret_left_20_regular:before {
    content: "\e362";
}
.icon-ic_fluent_caret_right_20_filled:before {
    content: "\e363";
}
.icon-ic_fluent_caret_right_20_regular:before {
    content: "\e364";
}
.icon-ic_fluent_caret_up_20_filled:before {
    content: "\e365";
}
.icon-ic_fluent_caret_up_20_regular:before {
    content: "\e366";
}
.icon-ic_fluent_cart_20_filled:before {
    content: "\e367";
}
.icon-ic_fluent_cart_20_regular:before {
    content: "\e368";
}
.icon-ic_fluent_cast_20_filled:before {
    content: "\e369";
}
.icon-ic_fluent_cast_20_regular:before {
    content: "\e36a";
}
.icon-ic_fluent_cast_multiple_20_filled:before {
    content: "\e36b";
}
.icon-ic_fluent_cast_multiple_20_regular:before {
    content: "\e36c";
}
.icon-ic_fluent_catch_up_20_filled:before {
    content: "\e36d";
}
.icon-ic_fluent_catch_up_20_regular:before {
    content: "\e36e";
}
.icon-ic_fluent_cellular_3g_20_filled:before {
    content: "\e36f";
}
.icon-ic_fluent_cellular_3g_20_regular:before {
    content: "\e370";
}
.icon-ic_fluent_cellular_4g_20_filled:before {
    content: "\e371";
}
.icon-ic_fluent_cellular_4g_20_regular:before {
    content: "\e372";
}
.icon-ic_fluent_cellular_5g_20_filled:before {
    content: "\e373";
}
.icon-ic_fluent_cellular_5g_20_regular:before {
    content: "\e374";
}
.icon-ic_fluent_cellular_data_1_20_filled:before {
    content: "\e375";
}
.icon-ic_fluent_cellular_data_1_20_regular:before {
    content: "\e376";
}
.icon-ic_fluent_cellular_data_2_20_filled:before {
    content: "\e377";
}
.icon-ic_fluent_cellular_data_2_20_regular:before {
    content: "\e378";
}
.icon-ic_fluent_cellular_data_3_20_filled:before {
    content: "\e379";
}
.icon-ic_fluent_cellular_data_3_20_regular:before {
    content: "\e37a";
}
.icon-ic_fluent_cellular_data_4_20_filled:before {
    content: "\e37b";
}
.icon-ic_fluent_cellular_data_4_20_regular:before {
    content: "\e37c";
}
.icon-ic_fluent_cellular_data_5_20_filled:before {
    content: "\e37d";
}
.icon-ic_fluent_cellular_data_5_20_regular:before {
    content: "\e37e";
}
.icon-ic_fluent_cellular_off_20_filled:before {
    content: "\e37f";
}
.icon-ic_fluent_cellular_off_20_regular:before {
    content: "\e380";
}
.icon-ic_fluent_cellular_warning_20_filled:before {
    content: "\e381";
}
.icon-ic_fluent_cellular_warning_20_regular:before {
    content: "\e382";
}
.icon-ic_fluent_center_horizontal_20_filled:before {
    content: "\e383";
}
.icon-ic_fluent_center_horizontal_20_regular:before {
    content: "\e384";
}
.icon-ic_fluent_center_vertical_20_filled:before {
    content: "\e385";
}
.icon-ic_fluent_center_vertical_20_regular:before {
    content: "\e386";
}
.icon-ic_fluent_certificate_20_filled:before {
    content: "\e387";
}
.icon-ic_fluent_certificate_20_regular:before {
    content: "\e388";
}
.icon-ic_fluent_channel_20_filled:before {
    content: "\e389";
}
.icon-ic_fluent_channel_20_regular:before {
    content: "\e38a";
}
.icon-ic_fluent_channel_add_20_filled:before {
    content: "\e38b";
}
.icon-ic_fluent_channel_add_20_regular:before {
    content: "\e38c";
}
.icon-ic_fluent_channel_alert_20_filled:before {
    content: "\e38d";
}
.icon-ic_fluent_channel_alert_20_regular:before {
    content: "\e38e";
}
.icon-ic_fluent_channel_arrow_left_20_filled:before {
    content: "\e38f";
}
.icon-ic_fluent_channel_arrow_left_20_regular:before {
    content: "\e390";
}
.icon-ic_fluent_channel_dismiss_20_filled:before {
    content: "\e391";
}
.icon-ic_fluent_channel_dismiss_20_regular:before {
    content: "\e392";
}
.icon-ic_fluent_channel_share_20_filled:before {
    content: "\e393";
}
.icon-ic_fluent_channel_share_20_regular:before {
    content: "\e394";
}
.icon-ic_fluent_channel_subtract_20_filled:before {
    content: "\e395";
}
.icon-ic_fluent_channel_subtract_20_regular:before {
    content: "\e396";
}
.icon-ic_fluent_chart_multiple_20_filled:before {
    content: "\e397";
}
.icon-ic_fluent_chart_multiple_20_regular:before {
    content: "\e398";
}
.icon-ic_fluent_chart_person_20_filled:before {
    content: "\e399";
}
.icon-ic_fluent_chart_person_20_regular:before {
    content: "\e39a";
}
.icon-ic_fluent_chat_20_filled:before {
    content: "\e39b";
}
.icon-ic_fluent_chat_20_regular:before {
    content: "\e39c";
}
.icon-ic_fluent_chat_add_20_filled:before {
    content: "\e39d";
}
.icon-ic_fluent_chat_add_20_regular:before {
    content: "\e39e";
}
.icon-ic_fluent_chat_arrow_back_20_filled:before {
    content: "\e39f";
}
.icon-ic_fluent_chat_arrow_back_20_regular:before {
    content: "\e3a0";
}
.icon-ic_fluent_chat_arrow_double_back_20_filled:before {
    content: "\e3a1";
}
.icon-ic_fluent_chat_arrow_double_back_20_regular:before {
    content: "\e3a2";
}
.icon-ic_fluent_chat_bubbles_question_20_filled:before {
    content: "\e3a3";
}
.icon-ic_fluent_chat_bubbles_question_20_regular:before {
    content: "\e3a4";
}
.icon-ic_fluent_chat_cursor_20_filled:before {
    content: "\e3a5";
}
.icon-ic_fluent_chat_cursor_20_regular:before {
    content: "\e3a6";
}
.icon-ic_fluent_chat_dismiss_20_filled:before {
    content: "\e3a7";
}
.icon-ic_fluent_chat_dismiss_20_regular:before {
    content: "\e3a8";
}
.icon-ic_fluent_chat_empty_20_filled:before {
    content: "\e3a9";
}
.icon-ic_fluent_chat_empty_20_regular:before {
    content: "\e3aa";
}
.icon-ic_fluent_chat_help_20_filled:before {
    content: "\e3ab";
}
.icon-ic_fluent_chat_help_20_regular:before {
    content: "\e3ac";
}
.icon-ic_fluent_chat_lock_20_filled:before {
    content: "\e3ad";
}
.icon-ic_fluent_chat_lock_20_regular:before {
    content: "\e3ae";
}
.icon-ic_fluent_chat_mail_20_filled:before {
    content: "\e3af";
}
.icon-ic_fluent_chat_mail_20_regular:before {
    content: "\e3b0";
}
.icon-ic_fluent_chat_multiple_20_filled:before {
    content: "\e3b1";
}
.icon-ic_fluent_chat_multiple_20_regular:before {
    content: "\e3b2";
}
.icon-ic_fluent_chat_multiple_heart_20_filled:before {
    content: "\e3b3";
}
.icon-ic_fluent_chat_multiple_heart_20_regular:before {
    content: "\e3b4";
}
.icon-ic_fluent_chat_off_20_filled:before {
    content: "\e3b5";
}
.icon-ic_fluent_chat_off_20_regular:before {
    content: "\e3b6";
}
.icon-ic_fluent_chat_settings_20_filled:before {
    content: "\e3b7";
}
.icon-ic_fluent_chat_settings_20_regular:before {
    content: "\e3b8";
}
.icon-ic_fluent_chat_sparkle_20_filled:before {
    content: "\e3b9";
}
.icon-ic_fluent_chat_sparkle_20_regular:before {
    content: "\e3ba";
}
.icon-ic_fluent_chat_video_20_filled:before {
    content: "\e3bb";
}
.icon-ic_fluent_chat_video_20_regular:before {
    content: "\e3bc";
}
.icon-ic_fluent_chat_warning_20_filled:before {
    content: "\e3bd";
}
.icon-ic_fluent_chat_warning_20_regular:before {
    content: "\e3be";
}
.icon-ic_fluent_check_20_filled:before {
    content: "\e3bf";
}
.icon-ic_fluent_check_20_regular:before {
    content: "\e3c0";
}
.icon-ic_fluent_checkbox_1_20_filled:before {
    content: "\e3c1";
}
.icon-ic_fluent_checkbox_1_20_regular:before {
    content: "\e3c2";
}
.icon-ic_fluent_checkbox_2_20_filled:before {
    content: "\e3c3";
}
.icon-ic_fluent_checkbox_2_20_regular:before {
    content: "\e3c4";
}
.icon-ic_fluent_checkbox_arrow_right_20_filled:before {
    content: "\e3c5";
}
.icon-ic_fluent_checkbox_arrow_right_20_regular:before {
    content: "\e3c6";
}
.icon-ic_fluent_checkbox_checked_20_filled:before {
    content: "\e3c7";
}
.icon-ic_fluent_checkbox_checked_20_regular:before {
    content: "\e3c8";
}
.icon-ic_fluent_checkbox_checked_sync_20_filled:before {
    content: "\e3c9";
}
.icon-ic_fluent_checkbox_checked_sync_20_regular:before {
    content: "\e3ca";
}
.icon-ic_fluent_checkbox_indeterminate_20_filled:before {
    content: "\e3cb";
}
.icon-ic_fluent_checkbox_indeterminate_20_regular:before {
    content: "\e3cc";
}
.icon-ic_fluent_checkbox_person_20_filled:before {
    content: "\e3cd";
}
.icon-ic_fluent_checkbox_person_20_regular:before {
    content: "\e3ce";
}
.icon-ic_fluent_checkbox_unchecked_20_filled:before {
    content: "\e3cf";
}
.icon-ic_fluent_checkbox_unchecked_20_regular:before {
    content: "\e3d0";
}
.icon-ic_fluent_checkbox_warning_20_filled:before {
    content: "\e3d1";
}
.icon-ic_fluent_checkbox_warning_20_regular:before {
    content: "\e3d2";
}
.icon-ic_fluent_checkmark_20_filled:before {
    content: "\e3d3";
}
.icon-ic_fluent_checkmark_20_regular:before {
    content: "\e3d4";
}
.icon-ic_fluent_checkmark_circle_20_filled:before {
    content: "\e3d5";
}
.icon-ic_fluent_checkmark_circle_20_regular:before {
    content: "\e3d6";
}
.icon-ic_fluent_checkmark_circle_square_20_filled:before {
    content: "\e3d7";
}
.icon-ic_fluent_checkmark_circle_square_20_regular:before {
    content: "\e3d8";
}
.icon-ic_fluent_checkmark_lock_20_filled:before {
    content: "\e3d9";
}
.icon-ic_fluent_checkmark_lock_20_regular:before {
    content: "\e3da";
}
.icon-ic_fluent_checkmark_note_20_filled:before {
    content: "\e3db";
}
.icon-ic_fluent_checkmark_note_20_regular:before {
    content: "\e3dc";
}
.icon-ic_fluent_checkmark_square_20_filled:before {
    content: "\e3dd";
}
.icon-ic_fluent_checkmark_square_20_regular:before {
    content: "\e3de";
}
.icon-ic_fluent_checkmark_starburst_20_filled:before {
    content: "\e3df";
}
.icon-ic_fluent_checkmark_starburst_20_regular:before {
    content: "\e3e0";
}
.icon-ic_fluent_checkmark_underline_circle_20_filled:before {
    content: "\e3e1";
}
.icon-ic_fluent_checkmark_underline_circle_20_regular:before {
    content: "\e3e2";
}
.icon-ic_fluent_chess_20_filled:before {
    content: "\e3e3";
}
.icon-ic_fluent_chess_20_regular:before {
    content: "\e3e4";
}
.icon-ic_fluent_chevron_circle_down_20_filled:before {
    content: "\e3e5";
}
.icon-ic_fluent_chevron_circle_down_20_regular:before {
    content: "\e3e6";
}
.icon-ic_fluent_chevron_circle_left_20_filled:before {
    content: "\e3e7";
}
.icon-ic_fluent_chevron_circle_left_20_regular:before {
    content: "\e3e8";
}
.icon-ic_fluent_chevron_circle_right_20_filled:before {
    content: "\e3e9";
}
.icon-ic_fluent_chevron_circle_right_20_regular:before {
    content: "\e3ea";
}
.icon-ic_fluent_chevron_circle_up_20_filled:before {
    content: "\e3eb";
}
.icon-ic_fluent_chevron_circle_up_20_regular:before {
    content: "\e3ec";
}
.icon-ic_fluent_chevron_double_down_20_filled:before {
    content: "\e3ed";
}
.icon-ic_fluent_chevron_double_down_20_regular:before {
    content: "\e3ee";
}
.icon-ic_fluent_chevron_double_left_20_filled:before {
    content: "\e3ef";
}
.icon-ic_fluent_chevron_double_left_20_regular:before {
    content: "\e3f0";
}
.icon-ic_fluent_chevron_double_right_20_filled:before {
    content: "\e3f1";
}
.icon-ic_fluent_chevron_double_right_20_regular:before {
    content: "\e3f2";
}
.icon-ic_fluent_chevron_double_up_20_filled:before {
    content: "\e3f3";
}
.icon-ic_fluent_chevron_double_up_20_regular:before {
    content: "\e3f4";
}
.icon-ic_fluent_chevron_down_20_filled:before {
    content: "\e3f5";
}
.icon-ic_fluent_chevron_down_20_regular:before {
    content: "\e3f6";
}
.icon-ic_fluent_chevron_down_up_20_filled:before {
    content: "\e3f7";
}
.icon-ic_fluent_chevron_down_up_20_regular:before {
    content: "\e3f8";
}
.icon-ic_fluent_chevron_left_20_filled:before {
    content: "\e3f9";
}
.icon-ic_fluent_chevron_left_20_regular:before {
    content: "\e3fa";
}
.icon-ic_fluent_chevron_right_20_filled:before {
    content: "\e3fb";
}
.icon-ic_fluent_chevron_right_20_regular:before {
    content: "\e3fc";
}
.icon-ic_fluent_chevron_up_20_filled:before {
    content: "\e3fd";
}
.icon-ic_fluent_chevron_up_20_regular:before {
    content: "\e3fe";
}
.icon-ic_fluent_chevron_up_down_20_filled:before {
    content: "\e3ff";
}
.icon-ic_fluent_chevron_up_down_20_regular:before {
    content: "\e400";
}
.icon-ic_fluent_circle_20_filled:before {
    content: "\e401";
}
.icon-ic_fluent_circle_20_regular:before {
    content: "\e402";
}
.icon-ic_fluent_circle_edit_20_filled:before {
    content: "\e403";
}
.icon-ic_fluent_circle_edit_20_regular:before {
    content: "\e404";
}
.icon-ic_fluent_circle_eraser_20_filled:before {
    content: "\e405";
}
.icon-ic_fluent_circle_eraser_20_regular:before {
    content: "\e406";
}
.icon-ic_fluent_circle_half_fill_20_filled:before {
    content: "\e407";
}
.icon-ic_fluent_circle_half_fill_20_regular:before {
    content: "\e408";
}
.icon-ic_fluent_circle_hint_20_filled:before {
    content: "\e409";
}
.icon-ic_fluent_circle_hint_20_regular:before {
    content: "\e40a";
}
.icon-ic_fluent_circle_hint_half_vertical_20_filled:before {
    content: "\e40b";
}
.icon-ic_fluent_circle_hint_half_vertical_20_regular:before {
    content: "\e40c";
}
.icon-ic_fluent_circle_image_20_filled:before {
    content: "\e40d";
}
.icon-ic_fluent_circle_image_20_regular:before {
    content: "\e40e";
}
.icon-ic_fluent_circle_line_20_filled:before {
    content: "\e40f";
}
.icon-ic_fluent_circle_line_20_regular:before {
    content: "\e410";
}
.icon-ic_fluent_circle_multiple_subtract_checkmark_20_filled:before {
    content: "\e411";
}
.icon-ic_fluent_circle_multiple_subtract_checkmark_20_regular:before {
    content: "\e412";
}
.icon-ic_fluent_circle_off_20_filled:before {
    content: "\e413";
}
.icon-ic_fluent_circle_off_20_regular:before {
    content: "\e414";
}
.icon-ic_fluent_circle_small_20_filled:before {
    content: "\e415";
}
.icon-ic_fluent_circle_small_20_regular:before {
    content: "\e416";
}
.icon-ic_fluent_city_20_filled:before {
    content: "\e417";
}
.icon-ic_fluent_city_20_regular:before {
    content: "\e418";
}
.icon-ic_fluent_class_20_filled:before {
    content: "\e419";
}
.icon-ic_fluent_class_20_regular:before {
    content: "\e41a";
}
.icon-ic_fluent_classification_20_filled:before {
    content: "\e41b";
}
.icon-ic_fluent_classification_20_regular:before {
    content: "\e41c";
}
.icon-ic_fluent_clear_formatting_20_filled:before {
    content: "\e41d";
}
.icon-ic_fluent_clear_formatting_20_regular:before {
    content: "\e41e";
}
.icon-ic_fluent_clipboard_20_filled:before {
    content: "\e41f";
}
.icon-ic_fluent_clipboard_20_regular:before {
    content: "\e420";
}
.icon-ic_fluent_clipboard_3_day_20_filled:before {
    content: "\e421";
}
.icon-ic_fluent_clipboard_3_day_20_regular:before {
    content: "\e422";
}
.icon-ic_fluent_clipboard_arrow_right_20_filled:before {
    content: "\e423";
}
.icon-ic_fluent_clipboard_arrow_right_20_regular:before {
    content: "\e424";
}
.icon-ic_fluent_clipboard_brush_20_filled:before {
    content: "\e425";
}
.icon-ic_fluent_clipboard_brush_20_regular:before {
    content: "\e426";
}
.icon-ic_fluent_clipboard_bullet_list_20_filled:before {
    content: "\e427";
}
.icon-ic_fluent_clipboard_bullet_list_20_regular:before {
    content: "\e428";
}
.icon-ic_fluent_clipboard_bullet_list_ltr_20_filled:before {
    content: "\e429";
}
.icon-ic_fluent_clipboard_bullet_list_ltr_20_regular:before {
    content: "\e42a";
}
.icon-ic_fluent_clipboard_bullet_list_rtl_20_filled:before {
    content: "\e42b";
}
.icon-ic_fluent_clipboard_bullet_list_rtl_20_regular:before {
    content: "\e42c";
}
.icon-ic_fluent_clipboard_checkmark_20_filled:before {
    content: "\e42d";
}
.icon-ic_fluent_clipboard_checkmark_20_regular:before {
    content: "\e42e";
}
.icon-ic_fluent_clipboard_clock_20_filled:before {
    content: "\e42f";
}
.icon-ic_fluent_clipboard_clock_20_regular:before {
    content: "\e430";
}
.icon-ic_fluent_clipboard_code_20_filled:before {
    content: "\e431";
}
.icon-ic_fluent_clipboard_code_20_regular:before {
    content: "\e432";
}
.icon-ic_fluent_clipboard_data_bar_20_filled:before {
    content: "\e433";
}
.icon-ic_fluent_clipboard_data_bar_20_regular:before {
    content: "\e434";
}
.icon-ic_fluent_clipboard_day_20_filled:before {
    content: "\e435";
}
.icon-ic_fluent_clipboard_day_20_regular:before {
    content: "\e436";
}
.icon-ic_fluent_clipboard_edit_20_filled:before {
    content: "\e437";
}
.icon-ic_fluent_clipboard_edit_20_regular:before {
    content: "\e438";
}
.icon-ic_fluent_clipboard_error_20_filled:before {
    content: "\e439";
}
.icon-ic_fluent_clipboard_error_20_regular:before {
    content: "\e43a";
}
.icon-ic_fluent_clipboard_heart_20_filled:before {
    content: "\e43b";
}
.icon-ic_fluent_clipboard_heart_20_regular:before {
    content: "\e43c";
}
.icon-ic_fluent_clipboard_image_20_filled:before {
    content: "\e43d";
}
.icon-ic_fluent_clipboard_image_20_regular:before {
    content: "\e43e";
}
.icon-ic_fluent_clipboard_letter_20_filled:before {
    content: "\e43f";
}
.icon-ic_fluent_clipboard_letter_20_regular:before {
    content: "\e440";
}
.icon-ic_fluent_clipboard_link_20_filled:before {
    content: "\e441";
}
.icon-ic_fluent_clipboard_link_20_regular:before {
    content: "\e442";
}
.icon-ic_fluent_clipboard_math_formula_20_filled:before {
    content: "\e443";
}
.icon-ic_fluent_clipboard_math_formula_20_regular:before {
    content: "\e444";
}
.icon-ic_fluent_clipboard_month_20_filled:before {
    content: "\e445";
}
.icon-ic_fluent_clipboard_month_20_regular:before {
    content: "\e446";
}
.icon-ic_fluent_clipboard_more_20_filled:before {
    content: "\e447";
}
.icon-ic_fluent_clipboard_more_20_regular:before {
    content: "\e448";
}
.icon-ic_fluent_clipboard_note_20_filled:before {
    content: "\e449";
}
.icon-ic_fluent_clipboard_note_20_regular:before {
    content: "\e44a";
}
.icon-ic_fluent_clipboard_number_123_20_filled:before {
    content: "\e44b";
}
.icon-ic_fluent_clipboard_number_123_20_regular:before {
    content: "\e44c";
}
.icon-ic_fluent_clipboard_paste_20_filled:before {
    content: "\e44d";
}
.icon-ic_fluent_clipboard_paste_20_regular:before {
    content: "\e44e";
}
.icon-ic_fluent_clipboard_pulse_20_filled:before {
    content: "\e44f";
}
.icon-ic_fluent_clipboard_pulse_20_regular:before {
    content: "\e450";
}
.icon-ic_fluent_clipboard_search_20_filled:before {
    content: "\e451";
}
.icon-ic_fluent_clipboard_search_20_regular:before {
    content: "\e452";
}
.icon-ic_fluent_clipboard_settings_20_filled:before {
    content: "\e453";
}
.icon-ic_fluent_clipboard_settings_20_regular:before {
    content: "\e454";
}
.icon-ic_fluent_clipboard_task_20_filled:before {
    content: "\e455";
}
.icon-ic_fluent_clipboard_task_20_regular:before {
    content: "\e456";
}
.icon-ic_fluent_clipboard_task_add_20_filled:before {
    content: "\e457";
}
.icon-ic_fluent_clipboard_task_add_20_regular:before {
    content: "\e458";
}
.icon-ic_fluent_clipboard_task_list_ltr_20_filled:before {
    content: "\e459";
}
.icon-ic_fluent_clipboard_task_list_ltr_20_regular:before {
    content: "\e45a";
}
.icon-ic_fluent_clipboard_task_list_rtl_20_filled:before {
    content: "\e45b";
}
.icon-ic_fluent_clipboard_task_list_rtl_20_regular:before {
    content: "\e45c";
}
.icon-ic_fluent_clipboard_text_edit_20_filled:before {
    content: "\e45d";
}
.icon-ic_fluent_clipboard_text_edit_20_regular:before {
    content: "\e45e";
}
.icon-ic_fluent_clipboard_text_ltr_20_filled:before {
    content: "\e45f";
}
.icon-ic_fluent_clipboard_text_ltr_20_regular:before {
    content: "\e460";
}
.icon-ic_fluent_clipboard_text_rtl_20_filled:before {
    content: "\e461";
}
.icon-ic_fluent_clipboard_text_rtl_20_regular:before {
    content: "\e462";
}
.icon-ic_fluent_clock_20_filled:before {
    content: "\e463";
}
.icon-ic_fluent_clock_20_regular:before {
    content: "\e464";
}
.icon-ic_fluent_clock_alarm_20_filled:before {
    content: "\e465";
}
.icon-ic_fluent_clock_alarm_20_regular:before {
    content: "\e466";
}
.icon-ic_fluent_clock_arrow_download_20_filled:before {
    content: "\e467";
}
.icon-ic_fluent_clock_arrow_download_20_regular:before {
    content: "\e468";
}
.icon-ic_fluent_clock_dismiss_20_filled:before {
    content: "\e469";
}
.icon-ic_fluent_clock_dismiss_20_regular:before {
    content: "\e46a";
}
.icon-ic_fluent_clock_lock_20_filled:before {
    content: "\e46b";
}
.icon-ic_fluent_clock_lock_20_regular:before {
    content: "\e46c";
}
.icon-ic_fluent_clock_pause_20_filled:before {
    content: "\e46d";
}
.icon-ic_fluent_clock_pause_20_regular:before {
    content: "\e46e";
}
.icon-ic_fluent_clock_toolbox_20_filled:before {
    content: "\e46f";
}
.icon-ic_fluent_clock_toolbox_20_regular:before {
    content: "\e470";
}
.icon-ic_fluent_closed_caption_20_filled:before {
    content: "\e471";
}
.icon-ic_fluent_closed_caption_20_regular:before {
    content: "\e472";
}
.icon-ic_fluent_closed_caption_off_20_filled:before {
    content: "\e473";
}
.icon-ic_fluent_closed_caption_off_20_regular:before {
    content: "\e474";
}
.icon-ic_fluent_cloud_20_filled:before {
    content: "\e475";
}
.icon-ic_fluent_cloud_20_regular:before {
    content: "\e476";
}
.icon-ic_fluent_cloud_add_20_filled:before {
    content: "\e477";
}
.icon-ic_fluent_cloud_add_20_regular:before {
    content: "\e478";
}
.icon-ic_fluent_cloud_archive_20_filled:before {
    content: "\e479";
}
.icon-ic_fluent_cloud_archive_20_regular:before {
    content: "\e47a";
}
.icon-ic_fluent_cloud_arrow_down_20_filled:before {
    content: "\e47b";
}
.icon-ic_fluent_cloud_arrow_down_20_regular:before {
    content: "\e47c";
}
.icon-ic_fluent_cloud_arrow_up_20_filled:before {
    content: "\e47d";
}
.icon-ic_fluent_cloud_arrow_up_20_regular:before {
    content: "\e47e";
}
.icon-ic_fluent_cloud_beaker_20_filled:before {
    content: "\e47f";
}
.icon-ic_fluent_cloud_beaker_20_regular:before {
    content: "\e480";
}
.icon-ic_fluent_cloud_bidirectional_20_filled:before {
    content: "\e481";
}
.icon-ic_fluent_cloud_bidirectional_20_regular:before {
    content: "\e482";
}
.icon-ic_fluent_cloud_checkmark_20_filled:before {
    content: "\e483";
}
.icon-ic_fluent_cloud_checkmark_20_regular:before {
    content: "\e484";
}
.icon-ic_fluent_cloud_cube_20_filled:before {
    content: "\e485";
}
.icon-ic_fluent_cloud_cube_20_regular:before {
    content: "\e486";
}
.icon-ic_fluent_cloud_database_20_filled:before {
    content: "\e487";
}
.icon-ic_fluent_cloud_database_20_regular:before {
    content: "\e488";
}
.icon-ic_fluent_cloud_desktop_20_filled:before {
    content: "\e489";
}
.icon-ic_fluent_cloud_desktop_20_regular:before {
    content: "\e48a";
}
.icon-ic_fluent_cloud_dismiss_20_filled:before {
    content: "\e48b";
}
.icon-ic_fluent_cloud_dismiss_20_regular:before {
    content: "\e48c";
}
.icon-ic_fluent_cloud_edit_20_filled:before {
    content: "\e48d";
}
.icon-ic_fluent_cloud_edit_20_regular:before {
    content: "\e48e";
}
.icon-ic_fluent_cloud_error_20_filled:before {
    content: "\e48f";
}
.icon-ic_fluent_cloud_error_20_regular:before {
    content: "\e490";
}
.icon-ic_fluent_cloud_flow_20_filled:before {
    content: "\e491";
}
.icon-ic_fluent_cloud_flow_20_regular:before {
    content: "\e492";
}
.icon-ic_fluent_cloud_link_20_filled:before {
    content: "\e493";
}
.icon-ic_fluent_cloud_link_20_regular:before {
    content: "\e494";
}
.icon-ic_fluent_cloud_off_20_filled:before {
    content: "\e495";
}
.icon-ic_fluent_cloud_off_20_regular:before {
    content: "\e496";
}
.icon-ic_fluent_cloud_swap_20_filled:before {
    content: "\e497";
}
.icon-ic_fluent_cloud_swap_20_regular:before {
    content: "\e498";
}
.icon-ic_fluent_cloud_sync_20_filled:before {
    content: "\e499";
}
.icon-ic_fluent_cloud_sync_20_regular:before {
    content: "\e49a";
}
.icon-ic_fluent_cloud_words_20_filled:before {
    content: "\e49b";
}
.icon-ic_fluent_cloud_words_20_regular:before {
    content: "\e49c";
}
.icon-ic_fluent_clover_20_filled:before {
    content: "\e49d";
}
.icon-ic_fluent_clover_20_regular:before {
    content: "\e49e";
}
.icon-ic_fluent_code_20_filled:before {
    content: "\e49f";
}
.icon-ic_fluent_code_20_regular:before {
    content: "\e4a0";
}
.icon-ic_fluent_code_block_20_filled:before {
    content: "\e4a1";
}
.icon-ic_fluent_code_block_20_regular:before {
    content: "\e4a2";
}
.icon-ic_fluent_code_circle_20_filled:before {
    content: "\e4a3";
}
.icon-ic_fluent_code_circle_20_regular:before {
    content: "\e4a4";
}
.icon-ic_fluent_code_text_20_filled:before {
    content: "\e4a5";
}
.icon-ic_fluent_code_text_20_regular:before {
    content: "\e4a6";
}
.icon-ic_fluent_code_text_edit_20_filled:before {
    content: "\e4a7";
}
.icon-ic_fluent_code_text_edit_20_regular:before {
    content: "\e4a8";
}
.icon-ic_fluent_collections_20_filled:before {
    content: "\e4a9";
}
.icon-ic_fluent_collections_20_regular:before {
    content: "\e4aa";
}
.icon-ic_fluent_collections_add_20_filled:before {
    content: "\e4ab";
}
.icon-ic_fluent_collections_add_20_regular:before {
    content: "\e4ac";
}
.icon-ic_fluent_color_20_filled:before {
    content: "\e4ad";
}
.icon-ic_fluent_color_20_regular:before {
    content: "\e4ae";
}
.icon-ic_fluent_color_background_20_filled:before {
    content: "\e4af";
}
.icon-ic_fluent_color_background_20_regular:before {
    content: "\e4b0";
}
.icon-ic_fluent_color_background_accent_20_regular:before {
    content: "\e4b1";
}
.icon-ic_fluent_color_fill_20_filled:before {
    content: "\e4b2";
}
.icon-ic_fluent_color_fill_20_regular:before {
    content: "\e4b3";
}
.icon-ic_fluent_color_fill_accent_20_regular:before {
    content: "\e4b4";
}
.icon-ic_fluent_color_line_20_filled:before {
    content: "\e4b5";
}
.icon-ic_fluent_color_line_20_regular:before {
    content: "\e4b6";
}
.icon-ic_fluent_color_line_accent_20_regular:before {
    content: "\e4b7";
}
.icon-ic_fluent_column_20_filled:before {
    content: "\e4b8";
}
.icon-ic_fluent_column_20_regular:before {
    content: "\e4b9";
}
.icon-ic_fluent_column_arrow_right_20_filled:before {
    content: "\e4ba";
}
.icon-ic_fluent_column_arrow_right_20_regular:before {
    content: "\e4bb";
}
.icon-ic_fluent_column_double_compare_20_filled:before {
    content: "\e4bc";
}
.icon-ic_fluent_column_double_compare_20_regular:before {
    content: "\e4bd";
}
.icon-ic_fluent_column_edit_20_filled:before {
    content: "\e4be";
}
.icon-ic_fluent_column_edit_20_regular:before {
    content: "\e4bf";
}
.icon-ic_fluent_column_single_compare_20_filled:before {
    content: "\e4c0";
}
.icon-ic_fluent_column_single_compare_20_regular:before {
    content: "\e4c1";
}
.icon-ic_fluent_column_triple_20_filled:before {
    content: "\e4c2";
}
.icon-ic_fluent_column_triple_20_regular:before {
    content: "\e4c3";
}
.icon-ic_fluent_column_triple_edit_20_filled:before {
    content: "\e4c4";
}
.icon-ic_fluent_column_triple_edit_20_regular:before {
    content: "\e4c5";
}
.icon-ic_fluent_comma_20_filled:before {
    content: "\e4c6";
}
.icon-ic_fluent_comma_20_regular:before {
    content: "\e4c7";
}
.icon-ic_fluent_comment_20_filled:before {
    content: "\e4c8";
}
.icon-ic_fluent_comment_20_regular:before {
    content: "\e4c9";
}
.icon-ic_fluent_comment_add_20_filled:before {
    content: "\e4ca";
}
.icon-ic_fluent_comment_add_20_regular:before {
    content: "\e4cb";
}
.icon-ic_fluent_comment_arrow_left_20_filled:before {
    content: "\e4cc";
}
.icon-ic_fluent_comment_arrow_left_20_regular:before {
    content: "\e4cd";
}
.icon-ic_fluent_comment_arrow_right_20_filled:before {
    content: "\e4ce";
}
.icon-ic_fluent_comment_arrow_right_20_regular:before {
    content: "\e4cf";
}
.icon-ic_fluent_comment_checkmark_20_filled:before {
    content: "\e4d0";
}
.icon-ic_fluent_comment_checkmark_20_regular:before {
    content: "\e4d1";
}
.icon-ic_fluent_comment_dismiss_20_filled:before {
    content: "\e4d2";
}
.icon-ic_fluent_comment_dismiss_20_regular:before {
    content: "\e4d3";
}
.icon-ic_fluent_comment_edit_20_filled:before {
    content: "\e4d4";
}
.icon-ic_fluent_comment_edit_20_regular:before {
    content: "\e4d5";
}
.icon-ic_fluent_comment_error_20_filled:before {
    content: "\e4d6";
}
.icon-ic_fluent_comment_error_20_regular:before {
    content: "\e4d7";
}
.icon-ic_fluent_comment_lightning_20_filled:before {
    content: "\e4d8";
}
.icon-ic_fluent_comment_lightning_20_regular:before {
    content: "\e4d9";
}
.icon-ic_fluent_comment_link_20_filled:before {
    content: "\e4da";
}
.icon-ic_fluent_comment_link_20_regular:before {
    content: "\e4db";
}
.icon-ic_fluent_comment_mention_20_filled:before {
    content: "\e4dc";
}
.icon-ic_fluent_comment_mention_20_regular:before {
    content: "\e4dd";
}
.icon-ic_fluent_comment_multiple_20_filled:before {
    content: "\e4de";
}
.icon-ic_fluent_comment_multiple_20_regular:before {
    content: "\e4df";
}
.icon-ic_fluent_comment_multiple_checkmark_20_filled:before {
    content: "\e4e0";
}
.icon-ic_fluent_comment_multiple_checkmark_20_regular:before {
    content: "\e4e1";
}
.icon-ic_fluent_comment_multiple_link_20_filled:before {
    content: "\e4e2";
}
.icon-ic_fluent_comment_multiple_link_20_regular:before {
    content: "\e4e3";
}
.icon-ic_fluent_comment_note_20_filled:before {
    content: "\e4e4";
}
.icon-ic_fluent_comment_note_20_regular:before {
    content: "\e4e5";
}
.icon-ic_fluent_comment_off_20_filled:before {
    content: "\e4e6";
}
.icon-ic_fluent_comment_off_20_regular:before {
    content: "\e4e7";
}
.icon-ic_fluent_communication_20_filled:before {
    content: "\e4e8";
}
.icon-ic_fluent_communication_20_regular:before {
    content: "\e4e9";
}
.icon-ic_fluent_communication_person_20_filled:before {
    content: "\e4ea";
}
.icon-ic_fluent_communication_person_20_regular:before {
    content: "\e4eb";
}
.icon-ic_fluent_communication_shield_20_filled:before {
    content: "\e4ec";
}
.icon-ic_fluent_communication_shield_20_regular:before {
    content: "\e4ed";
}
.icon-ic_fluent_compass_northwest_20_filled:before {
    content: "\e4ee";
}
.icon-ic_fluent_compass_northwest_20_regular:before {
    content: "\e4ef";
}
.icon-ic_fluent_compose_20_filled:before {
    content: "\e4f0";
}
.icon-ic_fluent_compose_20_regular:before {
    content: "\e4f1";
}
.icon-ic_fluent_conference_room_20_filled:before {
    content: "\e4f2";
}
.icon-ic_fluent_conference_room_20_regular:before {
    content: "\e4f3";
}
.icon-ic_fluent_connected_20_filled:before {
    content: "\e4f4";
}
.icon-ic_fluent_connected_20_regular:before {
    content: "\e4f5";
}
.icon-ic_fluent_connector_20_filled:before {
    content: "\e4f6";
}
.icon-ic_fluent_connector_20_regular:before {
    content: "\e4f7";
}
.icon-ic_fluent_contact_card_20_filled:before {
    content: "\e4f8";
}
.icon-ic_fluent_contact_card_20_regular:before {
    content: "\e4f9";
}
.icon-ic_fluent_contact_card_group_20_filled:before {
    content: "\e4fa";
}
.icon-ic_fluent_contact_card_group_20_regular:before {
    content: "\e4fb";
}
.icon-ic_fluent_contact_card_link_20_filled:before {
    content: "\e4fc";
}
.icon-ic_fluent_contact_card_link_20_regular:before {
    content: "\e4fd";
}
.icon-ic_fluent_contact_card_ribbon_20_filled:before {
    content: "\e4fe";
}
.icon-ic_fluent_contact_card_ribbon_20_regular:before {
    content: "\e4ff";
}
.icon-ic_fluent_content_settings_20_filled:before {
    content: "\e500";
}
.icon-ic_fluent_content_settings_20_regular:before {
    content: "\e501";
}
.icon-ic_fluent_content_view_20_filled:before {
    content: "\e502";
}
.icon-ic_fluent_content_view_20_regular:before {
    content: "\e503";
}
.icon-ic_fluent_content_view_gallery_20_filled:before {
    content: "\e504";
}
.icon-ic_fluent_content_view_gallery_20_regular:before {
    content: "\e505";
}
.icon-ic_fluent_content_view_gallery_lightning_20_filled:before {
    content: "\e506";
}
.icon-ic_fluent_content_view_gallery_lightning_20_regular:before {
    content: "\e507";
}
.icon-ic_fluent_contract_down_left_20_filled:before {
    content: "\e508";
}
.icon-ic_fluent_contract_down_left_20_regular:before {
    content: "\e509";
}
.icon-ic_fluent_contract_up_right_20_filled:before {
    content: "\e50a";
}
.icon-ic_fluent_contract_up_right_20_regular:before {
    content: "\e50b";
}
.icon-ic_fluent_control_button_20_filled:before {
    content: "\e50c";
}
.icon-ic_fluent_control_button_20_regular:before {
    content: "\e50d";
}
.icon-ic_fluent_convert_range_20_filled:before {
    content: "\e50e";
}
.icon-ic_fluent_convert_range_20_regular:before {
    content: "\e50f";
}
.icon-ic_fluent_cookies_20_filled:before {
    content: "\e510";
}
.icon-ic_fluent_cookies_20_regular:before {
    content: "\e511";
}
.icon-ic_fluent_copy_20_filled:before {
    content: "\e512";
}
.icon-ic_fluent_copy_20_regular:before {
    content: "\e513";
}
.icon-ic_fluent_copy_add_20_filled:before {
    content: "\e514";
}
.icon-ic_fluent_copy_add_20_regular:before {
    content: "\e515";
}
.icon-ic_fluent_copy_arrow_right_20_filled:before {
    content: "\e516";
}
.icon-ic_fluent_copy_arrow_right_20_regular:before {
    content: "\e517";
}
.icon-ic_fluent_copy_select_20_filled:before {
    content: "\e518";
}
.icon-ic_fluent_copy_select_20_regular:before {
    content: "\e519";
}
.icon-ic_fluent_couch_20_filled:before {
    content: "\e51a";
}
.icon-ic_fluent_couch_20_regular:before {
    content: "\e51b";
}
.icon-ic_fluent_credit_card_clock_20_filled:before {
    content: "\e51c";
}
.icon-ic_fluent_credit_card_clock_20_regular:before {
    content: "\e51d";
}
.icon-ic_fluent_credit_card_person_20_filled:before {
    content: "\e51e";
}
.icon-ic_fluent_credit_card_person_20_regular:before {
    content: "\e51f";
}
.icon-ic_fluent_credit_card_toolbox_20_filled:before {
    content: "\e520";
}
.icon-ic_fluent_credit_card_toolbox_20_regular:before {
    content: "\e521";
}
.icon-ic_fluent_crop_20_filled:before {
    content: "\e522";
}
.icon-ic_fluent_crop_20_regular:before {
    content: "\e523";
}
.icon-ic_fluent_crop_interim_20_filled:before {
    content: "\e524";
}
.icon-ic_fluent_crop_interim_20_regular:before {
    content: "\e525";
}
.icon-ic_fluent_crop_interim_off_20_filled:before {
    content: "\e526";
}
.icon-ic_fluent_crop_interim_off_20_regular:before {
    content: "\e527";
}
.icon-ic_fluent_crown_20_filled:before {
    content: "\e528";
}
.icon-ic_fluent_crown_20_regular:before {
    content: "\e529";
}
.icon-ic_fluent_cube_20_filled:before {
    content: "\e52a";
}
.icon-ic_fluent_cube_20_regular:before {
    content: "\e52b";
}
.icon-ic_fluent_cube_add_20_filled:before {
    content: "\e52c";
}
.icon-ic_fluent_cube_add_20_regular:before {
    content: "\e52d";
}
.icon-ic_fluent_cube_arrow_curve_down_20_filled:before {
    content: "\e52e";
}
.icon-ic_fluent_cube_arrow_curve_down_20_regular:before {
    content: "\e52f";
}
.icon-ic_fluent_cube_link_20_filled:before {
    content: "\e530";
}
.icon-ic_fluent_cube_link_20_regular:before {
    content: "\e531";
}
.icon-ic_fluent_cube_multiple_20_filled:before {
    content: "\e532";
}
.icon-ic_fluent_cube_multiple_20_regular:before {
    content: "\e533";
}
.icon-ic_fluent_cube_quick_20_filled:before {
    content: "\e534";
}
.icon-ic_fluent_cube_quick_20_regular:before {
    content: "\e535";
}
.icon-ic_fluent_cube_rotate_20_filled:before {
    content: "\e536";
}
.icon-ic_fluent_cube_rotate_20_regular:before {
    content: "\e537";
}
.icon-ic_fluent_cube_sync_20_filled:before {
    content: "\e538";
}
.icon-ic_fluent_cube_sync_20_regular:before {
    content: "\e539";
}
.icon-ic_fluent_cube_tree_20_filled:before {
    content: "\e53a";
}
.icon-ic_fluent_cube_tree_20_regular:before {
    content: "\e53b";
}
.icon-ic_fluent_currency_dollar_euro_20_filled:before {
    content: "\e53c";
}
.icon-ic_fluent_currency_dollar_euro_20_regular:before {
    content: "\e53d";
}
.icon-ic_fluent_currency_dollar_rupee_20_filled:before {
    content: "\e53e";
}
.icon-ic_fluent_currency_dollar_rupee_20_regular:before {
    content: "\e53f";
}
.icon-ic_fluent_cursor_20_filled:before {
    content: "\e540";
}
.icon-ic_fluent_cursor_20_regular:before {
    content: "\e541";
}
.icon-ic_fluent_cursor_click_20_filled:before {
    content: "\e542";
}
.icon-ic_fluent_cursor_click_20_regular:before {
    content: "\e543";
}
.icon-ic_fluent_cursor_hover_20_filled:before {
    content: "\e544";
}
.icon-ic_fluent_cursor_hover_20_regular:before {
    content: "\e545";
}
.icon-ic_fluent_cursor_hover_off_20_filled:before {
    content: "\e546";
}
.icon-ic_fluent_cursor_hover_off_20_regular:before {
    content: "\e547";
}
.icon-ic_fluent_cursor_prohibited_20_filled:before {
    content: "\e548";
}
.icon-ic_fluent_cursor_prohibited_20_regular:before {
    content: "\e549";
}
.icon-ic_fluent_cut_20_filled:before {
    content: "\e54a";
}
.icon-ic_fluent_cut_20_regular:before {
    content: "\e54b";
}
.icon-ic_fluent_dark_theme_20_filled:before {
    content: "\e54c";
}
.icon-ic_fluent_dark_theme_20_regular:before {
    content: "\e54d";
}
.icon-ic_fluent_data_area_20_filled:before {
    content: "\e54e";
}
.icon-ic_fluent_data_area_20_regular:before {
    content: "\e54f";
}
.icon-ic_fluent_data_bar_horizontal_20_filled:before {
    content: "\e550";
}
.icon-ic_fluent_data_bar_horizontal_20_regular:before {
    content: "\e551";
}
.icon-ic_fluent_data_bar_vertical_20_filled:before {
    content: "\e552";
}
.icon-ic_fluent_data_bar_vertical_20_regular:before {
    content: "\e553";
}
.icon-ic_fluent_data_bar_vertical_add_20_filled:before {
    content: "\e554";
}
.icon-ic_fluent_data_bar_vertical_add_20_regular:before {
    content: "\e555";
}
.icon-ic_fluent_data_bar_vertical_ascending_20_filled:before {
    content: "\e556";
}
.icon-ic_fluent_data_bar_vertical_ascending_20_regular:before {
    content: "\e557";
}
.icon-ic_fluent_data_bar_vertical_star_20_filled:before {
    content: "\e558";
}
.icon-ic_fluent_data_bar_vertical_star_20_regular:before {
    content: "\e559";
}
.icon-ic_fluent_data_funnel_20_filled:before {
    content: "\e55a";
}
.icon-ic_fluent_data_funnel_20_regular:before {
    content: "\e55b";
}
.icon-ic_fluent_data_histogram_20_filled:before {
    content: "\e55c";
}
.icon-ic_fluent_data_histogram_20_regular:before {
    content: "\e55d";
}
.icon-ic_fluent_data_line_20_filled:before {
    content: "\e55e";
}
.icon-ic_fluent_data_line_20_regular:before {
    content: "\e55f";
}
.icon-ic_fluent_data_pie_20_filled:before {
    content: "\e560";
}
.icon-ic_fluent_data_pie_20_regular:before {
    content: "\e561";
}
.icon-ic_fluent_data_scatter_20_filled:before {
    content: "\e562";
}
.icon-ic_fluent_data_scatter_20_regular:before {
    content: "\e563";
}
.icon-ic_fluent_data_sunburst_20_filled:before {
    content: "\e564";
}
.icon-ic_fluent_data_sunburst_20_regular:before {
    content: "\e565";
}
.icon-ic_fluent_data_treemap_20_filled:before {
    content: "\e566";
}
.icon-ic_fluent_data_treemap_20_regular:before {
    content: "\e567";
}
.icon-ic_fluent_data_trending_20_filled:before {
    content: "\e568";
}
.icon-ic_fluent_data_trending_20_regular:before {
    content: "\e569";
}
.icon-ic_fluent_data_usage_20_filled:before {
    content: "\e56a";
}
.icon-ic_fluent_data_usage_20_regular:before {
    content: "\e56b";
}
.icon-ic_fluent_data_usage_edit_20_filled:before {
    content: "\e56c";
}
.icon-ic_fluent_data_usage_edit_20_regular:before {
    content: "\e56d";
}
.icon-ic_fluent_data_usage_settings_20_filled:before {
    content: "\e56e";
}
.icon-ic_fluent_data_usage_settings_20_regular:before {
    content: "\e56f";
}
.icon-ic_fluent_data_usage_toolbox_20_filled:before {
    content: "\e570";
}
.icon-ic_fluent_data_usage_toolbox_20_regular:before {
    content: "\e571";
}
.icon-ic_fluent_data_waterfall_20_filled:before {
    content: "\e572";
}
.icon-ic_fluent_data_waterfall_20_regular:before {
    content: "\e573";
}
.icon-ic_fluent_data_whisker_20_filled:before {
    content: "\e574";
}
.icon-ic_fluent_data_whisker_20_regular:before {
    content: "\e575";
}
.icon-ic_fluent_database_20_filled:before {
    content: "\e576";
}
.icon-ic_fluent_database_20_regular:before {
    content: "\e577";
}
.icon-ic_fluent_database_arrow_down_20_filled:before {
    content: "\e578";
}
.icon-ic_fluent_database_arrow_down_20_regular:before {
    content: "\e579";
}
.icon-ic_fluent_database_arrow_right_20_filled:before {
    content: "\e57a";
}
.icon-ic_fluent_database_arrow_right_20_regular:before {
    content: "\e57b";
}
.icon-ic_fluent_database_arrow_up_20_filled:before {
    content: "\e57c";
}
.icon-ic_fluent_database_arrow_up_20_regular:before {
    content: "\e57d";
}
.icon-ic_fluent_database_lightning_20_filled:before {
    content: "\e57e";
}
.icon-ic_fluent_database_lightning_20_regular:before {
    content: "\e57f";
}
.icon-ic_fluent_database_link_20_filled:before {
    content: "\e580";
}
.icon-ic_fluent_database_link_20_regular:before {
    content: "\e581";
}
.icon-ic_fluent_database_multiple_20_filled:before {
    content: "\e582";
}
.icon-ic_fluent_database_multiple_20_regular:before {
    content: "\e583";
}
.icon-ic_fluent_database_person_20_filled:before {
    content: "\e584";
}
.icon-ic_fluent_database_person_20_regular:before {
    content: "\e585";
}
.icon-ic_fluent_database_plug_connected_20_filled:before {
    content: "\e586";
}
.icon-ic_fluent_database_plug_connected_20_regular:before {
    content: "\e587";
}
.icon-ic_fluent_database_search_20_filled:before {
    content: "\e588";
}
.icon-ic_fluent_database_search_20_regular:before {
    content: "\e589";
}
.icon-ic_fluent_database_switch_20_filled:before {
    content: "\e58a";
}
.icon-ic_fluent_database_switch_20_regular:before {
    content: "\e58b";
}
.icon-ic_fluent_database_warning_20_filled:before {
    content: "\e58c";
}
.icon-ic_fluent_database_warning_20_regular:before {
    content: "\e58d";
}
.icon-ic_fluent_database_window_20_filled:before {
    content: "\e58e";
}
.icon-ic_fluent_database_window_20_regular:before {
    content: "\e58f";
}
.icon-ic_fluent_decimal_arrow_left_20_filled:before {
    content: "\e590";
}
.icon-ic_fluent_decimal_arrow_left_20_regular:before {
    content: "\e591";
}
.icon-ic_fluent_decimal_arrow_right_20_filled:before {
    content: "\e592";
}
.icon-ic_fluent_decimal_arrow_right_20_regular:before {
    content: "\e593";
}
.icon-ic_fluent_delete_20_filled:before {
    content: "\e594";
}
.icon-ic_fluent_delete_20_regular:before {
    content: "\e595";
}
.icon-ic_fluent_delete_arrow_back_20_filled:before {
    content: "\e596";
}
.icon-ic_fluent_delete_arrow_back_20_regular:before {
    content: "\e597";
}
.icon-ic_fluent_delete_dismiss_20_filled:before {
    content: "\e598";
}
.icon-ic_fluent_delete_dismiss_20_regular:before {
    content: "\e599";
}
.icon-ic_fluent_delete_lines_20_filled:before {
    content: "\e59a";
}
.icon-ic_fluent_delete_lines_20_regular:before {
    content: "\e59b";
}
.icon-ic_fluent_delete_off_20_filled:before {
    content: "\e59c";
}
.icon-ic_fluent_delete_off_20_regular:before {
    content: "\e59d";
}
.icon-ic_fluent_dentist_20_filled:before {
    content: "\e59e";
}
.icon-ic_fluent_dentist_20_regular:before {
    content: "\e59f";
}
.icon-ic_fluent_design_ideas_20_filled:before {
    content: "\e5a0";
}
.icon-ic_fluent_design_ideas_20_regular:before {
    content: "\e5a1";
}
.icon-ic_fluent_desk_20_filled:before {
    content: "\e5a2";
}
.icon-ic_fluent_desk_20_regular:before {
    content: "\e5a3";
}
.icon-ic_fluent_desktop_20_filled:before {
    content: "\e5a4";
}
.icon-ic_fluent_desktop_20_regular:before {
    content: "\e5a5";
}
.icon-ic_fluent_desktop_arrow_down_20_filled:before {
    content: "\e5a6";
}
.icon-ic_fluent_desktop_arrow_down_20_regular:before {
    content: "\e5a7";
}
.icon-ic_fluent_desktop_arrow_right_20_filled:before {
    content: "\e5a8";
}
.icon-ic_fluent_desktop_arrow_right_20_regular:before {
    content: "\e5a9";
}
.icon-ic_fluent_desktop_checkmark_20_filled:before {
    content: "\e5aa";
}
.icon-ic_fluent_desktop_checkmark_20_regular:before {
    content: "\e5ab";
}
.icon-ic_fluent_desktop_cursor_20_filled:before {
    content: "\e5ac";
}
.icon-ic_fluent_desktop_cursor_20_regular:before {
    content: "\e5ad";
}
.icon-ic_fluent_desktop_edit_20_filled:before {
    content: "\e5ae";
}
.icon-ic_fluent_desktop_edit_20_regular:before {
    content: "\e5af";
}
.icon-ic_fluent_desktop_flow_20_filled:before {
    content: "\e5b0";
}
.icon-ic_fluent_desktop_flow_20_regular:before {
    content: "\e5b1";
}
.icon-ic_fluent_desktop_keyboard_20_filled:before {
    content: "\e5b2";
}
.icon-ic_fluent_desktop_keyboard_20_regular:before {
    content: "\e5b3";
}
.icon-ic_fluent_desktop_mac_20_filled:before {
    content: "\e5b4";
}
.icon-ic_fluent_desktop_mac_20_regular:before {
    content: "\e5b5";
}
.icon-ic_fluent_desktop_pulse_20_filled:before {
    content: "\e5b6";
}
.icon-ic_fluent_desktop_pulse_20_regular:before {
    content: "\e5b7";
}
.icon-ic_fluent_desktop_signal_20_filled:before {
    content: "\e5b8";
}
.icon-ic_fluent_desktop_signal_20_regular:before {
    content: "\e5b9";
}
.icon-ic_fluent_desktop_speaker_20_filled:before {
    content: "\e5ba";
}
.icon-ic_fluent_desktop_speaker_20_regular:before {
    content: "\e5bb";
}
.icon-ic_fluent_desktop_speaker_off_20_filled:before {
    content: "\e5bc";
}
.icon-ic_fluent_desktop_speaker_off_20_regular:before {
    content: "\e5bd";
}
.icon-ic_fluent_desktop_sync_20_filled:before {
    content: "\e5be";
}
.icon-ic_fluent_desktop_sync_20_regular:before {
    content: "\e5bf";
}
.icon-ic_fluent_desktop_toolbox_20_filled:before {
    content: "\e5c0";
}
.icon-ic_fluent_desktop_toolbox_20_regular:before {
    content: "\e5c1";
}
.icon-ic_fluent_desktop_tower_20_filled:before {
    content: "\e5c2";
}
.icon-ic_fluent_desktop_tower_20_regular:before {
    content: "\e5c3";
}
.icon-ic_fluent_developer_board_20_filled:before {
    content: "\e5c4";
}
.icon-ic_fluent_developer_board_20_regular:before {
    content: "\e5c5";
}
.icon-ic_fluent_developer_board_lightning_20_filled:before {
    content: "\e5c6";
}
.icon-ic_fluent_developer_board_lightning_20_regular:before {
    content: "\e5c7";
}
.icon-ic_fluent_developer_board_lightning_toolbox_20_filled:before {
    content: "\e5c8";
}
.icon-ic_fluent_developer_board_lightning_toolbox_20_regular:before {
    content: "\e5c9";
}
.icon-ic_fluent_developer_board_search_20_filled:before {
    content: "\e5ca";
}
.icon-ic_fluent_developer_board_search_20_regular:before {
    content: "\e5cb";
}
.icon-ic_fluent_device_eq_20_filled:before {
    content: "\e5cc";
}
.icon-ic_fluent_device_eq_20_regular:before {
    content: "\e5cd";
}
.icon-ic_fluent_device_meeting_room_20_filled:before {
    content: "\e5ce";
}
.icon-ic_fluent_device_meeting_room_20_regular:before {
    content: "\e5cf";
}
.icon-ic_fluent_device_meeting_room_remote_20_filled:before {
    content: "\e5d0";
}
.icon-ic_fluent_device_meeting_room_remote_20_regular:before {
    content: "\e5d1";
}
.icon-ic_fluent_diagram_20_filled:before {
    content: "\e5d2";
}
.icon-ic_fluent_diagram_20_regular:before {
    content: "\e5d3";
}
.icon-ic_fluent_dialpad_20_filled:before {
    content: "\e5d4";
}
.icon-ic_fluent_dialpad_20_regular:before {
    content: "\e5d5";
}
.icon-ic_fluent_dialpad_off_20_filled:before {
    content: "\e5d6";
}
.icon-ic_fluent_dialpad_off_20_regular:before {
    content: "\e5d7";
}
.icon-ic_fluent_dialpad_question_mark_20_filled:before {
    content: "\e5d8";
}
.icon-ic_fluent_dialpad_question_mark_20_regular:before {
    content: "\e5d9";
}
.icon-ic_fluent_diamond_20_filled:before {
    content: "\e5da";
}
.icon-ic_fluent_diamond_20_regular:before {
    content: "\e5db";
}
.icon-ic_fluent_directions_20_filled:before {
    content: "\e5dc";
}
.icon-ic_fluent_directions_20_regular:before {
    content: "\e5dd";
}
.icon-ic_fluent_dishwasher_20_filled:before {
    content: "\e5de";
}
.icon-ic_fluent_dishwasher_20_regular:before {
    content: "\e5df";
}
.icon-ic_fluent_dismiss_20_filled:before {
    content: "\e5e0";
}
.icon-ic_fluent_dismiss_20_regular:before {
    content: "\e5e1";
}
.icon-ic_fluent_dismiss_circle_20_filled:before {
    content: "\e5e2";
}
.icon-ic_fluent_dismiss_circle_20_regular:before {
    content: "\e5e3";
}
.icon-ic_fluent_dismiss_square_20_filled:before {
    content: "\e5e4";
}
.icon-ic_fluent_dismiss_square_20_regular:before {
    content: "\e5e5";
}
.icon-ic_fluent_dismiss_square_multiple_20_filled:before {
    content: "\e5e6";
}
.icon-ic_fluent_dismiss_square_multiple_20_regular:before {
    content: "\e5e7";
}
.icon-ic_fluent_diversity_20_filled:before {
    content: "\e5e8";
}
.icon-ic_fluent_diversity_20_regular:before {
    content: "\e5e9";
}
.icon-ic_fluent_divider_short_20_filled:before {
    content: "\e5ea";
}
.icon-ic_fluent_divider_short_20_regular:before {
    content: "\e5eb";
}
.icon-ic_fluent_divider_tall_20_filled:before {
    content: "\e5ec";
}
.icon-ic_fluent_divider_tall_20_regular:before {
    content: "\e5ed";
}
.icon-ic_fluent_dock_20_filled:before {
    content: "\e5ee";
}
.icon-ic_fluent_dock_20_regular:before {
    content: "\e5ef";
}
.icon-ic_fluent_dock_row_20_filled:before {
    content: "\e5f0";
}
.icon-ic_fluent_dock_row_20_regular:before {
    content: "\e5f1";
}
.icon-ic_fluent_doctor_20_filled:before {
    content: "\e5f2";
}
.icon-ic_fluent_doctor_20_regular:before {
    content: "\e5f3";
}
.icon-ic_fluent_document_100_20_filled:before {
    content: "\e5f4";
}
.icon-ic_fluent_document_100_20_regular:before {
    content: "\e5f5";
}
.icon-ic_fluent_document_20_filled:before {
    content: "\e5f6";
}
.icon-ic_fluent_document_20_regular:before {
    content: "\e5f7";
}
.icon-ic_fluent_document_add_20_filled:before {
    content: "\e5f8";
}
.icon-ic_fluent_document_add_20_regular:before {
    content: "\e5f9";
}
.icon-ic_fluent_document_arrow_down_20_filled:before {
    content: "\e5fa";
}
.icon-ic_fluent_document_arrow_down_20_regular:before {
    content: "\e5fb";
}
.icon-ic_fluent_document_arrow_left_20_filled:before {
    content: "\e5fc";
}
.icon-ic_fluent_document_arrow_left_20_regular:before {
    content: "\e5fd";
}
.icon-ic_fluent_document_arrow_right_20_filled:before {
    content: "\e5fe";
}
.icon-ic_fluent_document_arrow_right_20_regular:before {
    content: "\e5ff";
}
.icon-ic_fluent_document_arrow_up_20_filled:before {
    content: "\e600";
}
.icon-ic_fluent_document_arrow_up_20_regular:before {
    content: "\e601";
}
.icon-ic_fluent_document_border_20_filled:before {
    content: "\e602";
}
.icon-ic_fluent_document_border_20_regular:before {
    content: "\e603";
}
.icon-ic_fluent_document_border_print_20_filled:before {
    content: "\e604";
}
.icon-ic_fluent_document_border_print_20_regular:before {
    content: "\e605";
}
.icon-ic_fluent_document_briefcase_20_filled:before {
    content: "\e606";
}
.icon-ic_fluent_document_briefcase_20_regular:before {
    content: "\e607";
}
.icon-ic_fluent_document_bullet_list_20_filled:before {
    content: "\e608";
}
.icon-ic_fluent_document_bullet_list_20_regular:before {
    content: "\e609";
}
.icon-ic_fluent_document_bullet_list_arrow_left_20_filled:before {
    content: "\e60a";
}
.icon-ic_fluent_document_bullet_list_arrow_left_20_regular:before {
    content: "\e60b";
}
.icon-ic_fluent_document_bullet_list_clock_20_filled:before {
    content: "\e60c";
}
.icon-ic_fluent_document_bullet_list_clock_20_regular:before {
    content: "\e60d";
}
.icon-ic_fluent_document_bullet_list_cube_20_filled:before {
    content: "\e60e";
}
.icon-ic_fluent_document_bullet_list_cube_20_regular:before {
    content: "\e60f";
}
.icon-ic_fluent_document_bullet_list_multiple_20_filled:before {
    content: "\e610";
}
.icon-ic_fluent_document_bullet_list_multiple_20_regular:before {
    content: "\e611";
}
.icon-ic_fluent_document_bullet_list_off_20_filled:before {
    content: "\e612";
}
.icon-ic_fluent_document_bullet_list_off_20_regular:before {
    content: "\e613";
}
.icon-ic_fluent_document_catch_up_20_filled:before {
    content: "\e614";
}
.icon-ic_fluent_document_catch_up_20_regular:before {
    content: "\e615";
}
.icon-ic_fluent_document_checkmark_20_filled:before {
    content: "\e616";
}
.icon-ic_fluent_document_checkmark_20_regular:before {
    content: "\e617";
}
.icon-ic_fluent_document_chevron_double_20_filled:before {
    content: "\e618";
}
.icon-ic_fluent_document_chevron_double_20_regular:before {
    content: "\e619";
}
.icon-ic_fluent_document_copy_20_filled:before {
    content: "\e61a";
}
.icon-ic_fluent_document_copy_20_regular:before {
    content: "\e61b";
}
.icon-ic_fluent_document_css_20_filled:before {
    content: "\e61c";
}
.icon-ic_fluent_document_css_20_regular:before {
    content: "\e61d";
}
.icon-ic_fluent_document_cube_20_filled:before {
    content: "\e61e";
}
.icon-ic_fluent_document_cube_20_regular:before {
    content: "\e61f";
}
.icon-ic_fluent_document_data_20_filled:before {
    content: "\e620";
}
.icon-ic_fluent_document_data_20_regular:before {
    content: "\e621";
}
.icon-ic_fluent_document_data_link_20_filled:before {
    content: "\e622";
}
.icon-ic_fluent_document_data_link_20_regular:before {
    content: "\e623";
}
.icon-ic_fluent_document_data_lock_20_filled:before {
    content: "\e624";
}
.icon-ic_fluent_document_data_lock_20_regular:before {
    content: "\e625";
}
.icon-ic_fluent_document_database_20_filled:before {
    content: "\e626";
}
.icon-ic_fluent_document_database_20_regular:before {
    content: "\e627";
}
.icon-ic_fluent_document_dismiss_20_filled:before {
    content: "\e628";
}
.icon-ic_fluent_document_dismiss_20_regular:before {
    content: "\e629";
}
.icon-ic_fluent_document_edit_20_filled:before {
    content: "\e62a";
}
.icon-ic_fluent_document_edit_20_regular:before {
    content: "\e62b";
}
.icon-ic_fluent_document_endnote_20_filled:before {
    content: "\e62c";
}
.icon-ic_fluent_document_endnote_20_regular:before {
    content: "\e62d";
}
.icon-ic_fluent_document_error_20_filled:before {
    content: "\e62e";
}
.icon-ic_fluent_document_error_20_regular:before {
    content: "\e62f";
}
.icon-ic_fluent_document_fit_20_filled:before {
    content: "\e630";
}
.icon-ic_fluent_document_fit_20_regular:before {
    content: "\e631";
}
.icon-ic_fluent_document_flowchart_20_filled:before {
    content: "\e632";
}
.icon-ic_fluent_document_flowchart_20_regular:before {
    content: "\e633";
}
.icon-ic_fluent_document_folder_20_filled:before {
    content: "\e634";
}
.icon-ic_fluent_document_folder_20_regular:before {
    content: "\e635";
}
.icon-ic_fluent_document_footer_20_filled:before {
    content: "\e636";
}
.icon-ic_fluent_document_footer_20_regular:before {
    content: "\e637";
}
.icon-ic_fluent_document_footer_dismiss_20_filled:before {
    content: "\e638";
}
.icon-ic_fluent_document_footer_dismiss_20_regular:before {
    content: "\e639";
}
.icon-ic_fluent_document_header_20_filled:before {
    content: "\e63a";
}
.icon-ic_fluent_document_header_20_regular:before {
    content: "\e63b";
}
.icon-ic_fluent_document_header_arrow_down_20_filled:before {
    content: "\e63c";
}
.icon-ic_fluent_document_header_arrow_down_20_regular:before {
    content: "\e63d";
}
.icon-ic_fluent_document_header_dismiss_20_filled:before {
    content: "\e63e";
}
.icon-ic_fluent_document_header_dismiss_20_regular:before {
    content: "\e63f";
}
.icon-ic_fluent_document_header_footer_20_filled:before {
    content: "\e640";
}
.icon-ic_fluent_document_header_footer_20_regular:before {
    content: "\e641";
}
.icon-ic_fluent_document_heart_20_filled:before {
    content: "\e642";
}
.icon-ic_fluent_document_heart_20_regular:before {
    content: "\e643";
}
.icon-ic_fluent_document_heart_pulse_20_filled:before {
    content: "\e644";
}
.icon-ic_fluent_document_heart_pulse_20_regular:before {
    content: "\e645";
}
.icon-ic_fluent_document_image_20_filled:before {
    content: "\e646";
}
.icon-ic_fluent_document_image_20_regular:before {
    content: "\e647";
}
.icon-ic_fluent_document_java_20_filled:before {
    content: "\e648";
}
.icon-ic_fluent_document_java_20_regular:before {
    content: "\e649";
}
.icon-ic_fluent_document_javascript_20_filled:before {
    content: "\e64a";
}
.icon-ic_fluent_document_javascript_20_regular:before {
    content: "\e64b";
}
.icon-ic_fluent_document_key_20_filled:before {
    content: "\e64c";
}
.icon-ic_fluent_document_key_20_regular:before {
    content: "\e64d";
}
.icon-ic_fluent_document_landscape_20_filled:before {
    content: "\e64e";
}
.icon-ic_fluent_document_landscape_20_regular:before {
    content: "\e64f";
}
.icon-ic_fluent_document_landscape_data_20_filled:before {
    content: "\e650";
}
.icon-ic_fluent_document_landscape_data_20_regular:before {
    content: "\e651";
}
.icon-ic_fluent_document_landscape_split_20_filled:before {
    content: "\e652";
}
.icon-ic_fluent_document_landscape_split_20_regular:before {
    content: "\e653";
}
.icon-ic_fluent_document_landscape_split_hint_20_filled:before {
    content: "\e654";
}
.icon-ic_fluent_document_landscape_split_hint_20_regular:before {
    content: "\e655";
}
.icon-ic_fluent_document_lightning_20_filled:before {
    content: "\e656";
}
.icon-ic_fluent_document_lightning_20_regular:before {
    content: "\e657";
}
.icon-ic_fluent_document_link_20_filled:before {
    content: "\e658";
}
.icon-ic_fluent_document_link_20_regular:before {
    content: "\e659";
}
.icon-ic_fluent_document_lock_20_filled:before {
    content: "\e65a";
}
.icon-ic_fluent_document_lock_20_regular:before {
    content: "\e65b";
}
.icon-ic_fluent_document_margins_20_filled:before {
    content: "\e65c";
}
.icon-ic_fluent_document_margins_20_regular:before {
    content: "\e65d";
}
.icon-ic_fluent_document_mention_20_filled:before {
    content: "\e65e";
}
.icon-ic_fluent_document_mention_20_regular:before {
    content: "\e65f";
}
.icon-ic_fluent_document_multiple_20_filled:before {
    content: "\e660";
}
.icon-ic_fluent_document_multiple_20_regular:before {
    content: "\e661";
}
.icon-ic_fluent_document_multiple_percent_20_filled:before {
    content: "\e662";
}
.icon-ic_fluent_document_multiple_percent_20_regular:before {
    content: "\e663";
}
.icon-ic_fluent_document_multiple_prohibited_20_filled:before {
    content: "\e664";
}
.icon-ic_fluent_document_multiple_prohibited_20_regular:before {
    content: "\e665";
}
.icon-ic_fluent_document_multiple_sync_20_filled:before {
    content: "\e666";
}
.icon-ic_fluent_document_multiple_sync_20_regular:before {
    content: "\e667";
}
.icon-ic_fluent_document_one_page_20_filled:before {
    content: "\e668";
}
.icon-ic_fluent_document_one_page_20_regular:before {
    content: "\e669";
}
.icon-ic_fluent_document_one_page_add_20_filled:before {
    content: "\e66a";
}
.icon-ic_fluent_document_one_page_add_20_regular:before {
    content: "\e66b";
}
.icon-ic_fluent_document_one_page_columns_20_filled:before {
    content: "\e66c";
}
.icon-ic_fluent_document_one_page_columns_20_regular:before {
    content: "\e66d";
}
.icon-ic_fluent_document_one_page_link_20_filled:before {
    content: "\e66e";
}
.icon-ic_fluent_document_one_page_link_20_regular:before {
    content: "\e66f";
}
.icon-ic_fluent_document_one_page_multiple_20_filled:before {
    content: "\e670";
}
.icon-ic_fluent_document_one_page_multiple_20_regular:before {
    content: "\e671";
}
.icon-ic_fluent_document_one_page_sparkle_20_filled:before {
    content: "\e672";
}
.icon-ic_fluent_document_one_page_sparkle_20_regular:before {
    content: "\e673";
}
.icon-ic_fluent_document_page_bottom_center_20_filled:before {
    content: "\e674";
}
.icon-ic_fluent_document_page_bottom_center_20_regular:before {
    content: "\e675";
}
.icon-ic_fluent_document_page_bottom_left_20_filled:before {
    content: "\e676";
}
.icon-ic_fluent_document_page_bottom_left_20_regular:before {
    content: "\e677";
}
.icon-ic_fluent_document_page_bottom_right_20_filled:before {
    content: "\e678";
}
.icon-ic_fluent_document_page_bottom_right_20_regular:before {
    content: "\e679";
}
.icon-ic_fluent_document_page_break_20_filled:before {
    content: "\e67a";
}
.icon-ic_fluent_document_page_break_20_regular:before {
    content: "\e67b";
}
.icon-ic_fluent_document_page_number_20_filled:before {
    content: "\e67c";
}
.icon-ic_fluent_document_page_number_20_regular:before {
    content: "\e67d";
}
.icon-ic_fluent_document_page_top_center_20_filled:before {
    content: "\e67e";
}
.icon-ic_fluent_document_page_top_center_20_regular:before {
    content: "\e67f";
}
.icon-ic_fluent_document_page_top_left_20_filled:before {
    content: "\e680";
}
.icon-ic_fluent_document_page_top_left_20_regular:before {
    content: "\e681";
}
.icon-ic_fluent_document_page_top_right_20_filled:before {
    content: "\e682";
}
.icon-ic_fluent_document_page_top_right_20_regular:before {
    content: "\e683";
}
.icon-ic_fluent_document_pdf_20_filled:before {
    content: "\e684";
}
.icon-ic_fluent_document_pdf_20_regular:before {
    content: "\e685";
}
.icon-ic_fluent_document_percent_20_filled:before {
    content: "\e686";
}
.icon-ic_fluent_document_percent_20_regular:before {
    content: "\e687";
}
.icon-ic_fluent_document_person_20_filled:before {
    content: "\e688";
}
.icon-ic_fluent_document_person_20_regular:before {
    content: "\e689";
}
.icon-ic_fluent_document_pill_20_filled:before {
    content: "\e68a";
}
.icon-ic_fluent_document_pill_20_regular:before {
    content: "\e68b";
}
.icon-ic_fluent_document_print_20_filled:before {
    content: "\e68c";
}
.icon-ic_fluent_document_print_20_regular:before {
    content: "\e68d";
}
.icon-ic_fluent_document_prohibited_20_filled:before {
    content: "\e68e";
}
.icon-ic_fluent_document_prohibited_20_regular:before {
    content: "\e68f";
}
.icon-ic_fluent_document_question_mark_20_filled:before {
    content: "\e690";
}
.icon-ic_fluent_document_question_mark_20_regular:before {
    content: "\e691";
}
.icon-ic_fluent_document_queue_20_filled:before {
    content: "\e692";
}
.icon-ic_fluent_document_queue_20_regular:before {
    content: "\e693";
}
.icon-ic_fluent_document_queue_add_20_filled:before {
    content: "\e694";
}
.icon-ic_fluent_document_queue_add_20_regular:before {
    content: "\e695";
}
.icon-ic_fluent_document_queue_multiple_20_filled:before {
    content: "\e696";
}
.icon-ic_fluent_document_queue_multiple_20_regular:before {
    content: "\e697";
}
.icon-ic_fluent_document_ribbon_20_filled:before {
    content: "\e698";
}
.icon-ic_fluent_document_ribbon_20_regular:before {
    content: "\e699";
}
.icon-ic_fluent_document_sass_20_filled:before {
    content: "\e69a";
}
.icon-ic_fluent_document_sass_20_regular:before {
    content: "\e69b";
}
.icon-ic_fluent_document_save_20_filled:before {
    content: "\e69c";
}
.icon-ic_fluent_document_save_20_regular:before {
    content: "\e69d";
}
.icon-ic_fluent_document_search_20_filled:before {
    content: "\e69e";
}
.icon-ic_fluent_document_search_20_regular:before {
    content: "\e69f";
}
.icon-ic_fluent_document_settings_20_filled:before {
    content: "\e6a0";
}
.icon-ic_fluent_document_settings_20_regular:before {
    content: "\e6a1";
}
.icon-ic_fluent_document_split_hint_20_filled:before {
    content: "\e6a2";
}
.icon-ic_fluent_document_split_hint_20_regular:before {
    content: "\e6a3";
}
.icon-ic_fluent_document_split_hint_off_20_filled:before {
    content: "\e6a4";
}
.icon-ic_fluent_document_split_hint_off_20_regular:before {
    content: "\e6a5";
}
.icon-ic_fluent_document_sync_20_filled:before {
    content: "\e6a6";
}
.icon-ic_fluent_document_sync_20_regular:before {
    content: "\e6a7";
}
.icon-ic_fluent_document_table_20_filled:before {
    content: "\e6a8";
}
.icon-ic_fluent_document_table_20_regular:before {
    content: "\e6a9";
}
.icon-ic_fluent_document_table_arrow_right_20_filled:before {
    content: "\e6aa";
}
.icon-ic_fluent_document_table_arrow_right_20_regular:before {
    content: "\e6ab";
}
.icon-ic_fluent_document_table_checkmark_20_filled:before {
    content: "\e6ac";
}
.icon-ic_fluent_document_table_checkmark_20_regular:before {
    content: "\e6ad";
}
.icon-ic_fluent_document_table_cube_20_filled:before {
    content: "\e6ae";
}
.icon-ic_fluent_document_table_cube_20_regular:before {
    content: "\e6af";
}
.icon-ic_fluent_document_table_search_20_filled:before {
    content: "\e6b0";
}
.icon-ic_fluent_document_table_search_20_regular:before {
    content: "\e6b1";
}
.icon-ic_fluent_document_table_truck_20_filled:before {
    content: "\e6b2";
}
.icon-ic_fluent_document_table_truck_20_regular:before {
    content: "\e6b3";
}
.icon-ic_fluent_document_text_20_filled:before {
    content: "\e6b4";
}
.icon-ic_fluent_document_text_20_regular:before {
    content: "\e6b5";
}
.icon-ic_fluent_document_text_clock_20_filled:before {
    content: "\e6b6";
}
.icon-ic_fluent_document_text_clock_20_regular:before {
    content: "\e6b7";
}
.icon-ic_fluent_document_text_extract_20_filled:before {
    content: "\e6b8";
}
.icon-ic_fluent_document_text_extract_20_regular:before {
    content: "\e6b9";
}
.icon-ic_fluent_document_text_link_20_filled:before {
    content: "\e6ba";
}
.icon-ic_fluent_document_text_link_20_regular:before {
    content: "\e6bb";
}
.icon-ic_fluent_document_text_toolbox_20_filled:before {
    content: "\e6bc";
}
.icon-ic_fluent_document_text_toolbox_20_regular:before {
    content: "\e6bd";
}
.icon-ic_fluent_document_toolbox_20_filled:before {
    content: "\e6be";
}
.icon-ic_fluent_document_toolbox_20_regular:before {
    content: "\e6bf";
}
.icon-ic_fluent_document_width_20_filled:before {
    content: "\e6c0";
}
.icon-ic_fluent_document_width_20_regular:before {
    content: "\e6c1";
}
.icon-ic_fluent_document_yml_20_filled:before {
    content: "\e6c2";
}
.icon-ic_fluent_document_yml_20_regular:before {
    content: "\e6c3";
}
.icon-ic_fluent_door_20_filled:before {
    content: "\e6c4";
}
.icon-ic_fluent_door_20_regular:before {
    content: "\e6c5";
}
.icon-ic_fluent_door_arrow_left_20_filled:before {
    content: "\e6c6";
}
.icon-ic_fluent_door_arrow_left_20_regular:before {
    content: "\e6c7";
}
.icon-ic_fluent_door_arrow_right_20_filled:before {
    content: "\e6c8";
}
.icon-ic_fluent_door_arrow_right_20_regular:before {
    content: "\e6c9";
}
.icon-ic_fluent_door_tag_20_filled:before {
    content: "\e6ca";
}
.icon-ic_fluent_door_tag_20_regular:before {
    content: "\e6cb";
}
.icon-ic_fluent_double_swipe_down_20_filled:before {
    content: "\e6cc";
}
.icon-ic_fluent_double_swipe_down_20_regular:before {
    content: "\e6cd";
}
.icon-ic_fluent_double_swipe_up_20_filled:before {
    content: "\e6ce";
}
.icon-ic_fluent_double_swipe_up_20_regular:before {
    content: "\e6cf";
}
.icon-ic_fluent_double_tap_swipe_down_20_filled:before {
    content: "\e6d0";
}
.icon-ic_fluent_double_tap_swipe_down_20_regular:before {
    content: "\e6d1";
}
.icon-ic_fluent_double_tap_swipe_up_20_filled:before {
    content: "\e6d2";
}
.icon-ic_fluent_double_tap_swipe_up_20_regular:before {
    content: "\e6d3";
}
.icon-ic_fluent_drafts_20_filled:before {
    content: "\e6d4";
}
.icon-ic_fluent_drafts_20_regular:before {
    content: "\e6d5";
}
.icon-ic_fluent_drag_20_filled:before {
    content: "\e6d6";
}
.icon-ic_fluent_drag_20_regular:before {
    content: "\e6d7";
}
.icon-ic_fluent_draw_image_20_filled:before {
    content: "\e6d8";
}
.icon-ic_fluent_draw_image_20_regular:before {
    content: "\e6d9";
}
.icon-ic_fluent_draw_shape_20_filled:before {
    content: "\e6da";
}
.icon-ic_fluent_draw_shape_20_regular:before {
    content: "\e6db";
}
.icon-ic_fluent_draw_text_20_filled:before {
    content: "\e6dc";
}
.icon-ic_fluent_draw_text_20_regular:before {
    content: "\e6dd";
}
.icon-ic_fluent_drawer_20_filled:before {
    content: "\e6de";
}
.icon-ic_fluent_drawer_20_regular:before {
    content: "\e6df";
}
.icon-ic_fluent_drawer_add_20_filled:before {
    content: "\e6e0";
}
.icon-ic_fluent_drawer_add_20_regular:before {
    content: "\e6e1";
}
.icon-ic_fluent_drawer_arrow_download_20_filled:before {
    content: "\e6e2";
}
.icon-ic_fluent_drawer_arrow_download_20_regular:before {
    content: "\e6e3";
}
.icon-ic_fluent_drawer_dismiss_20_filled:before {
    content: "\e6e4";
}
.icon-ic_fluent_drawer_dismiss_20_regular:before {
    content: "\e6e5";
}
.icon-ic_fluent_drawer_play_20_filled:before {
    content: "\e6e6";
}
.icon-ic_fluent_drawer_play_20_regular:before {
    content: "\e6e7";
}
.icon-ic_fluent_drawer_subtract_20_filled:before {
    content: "\e6e8";
}
.icon-ic_fluent_drawer_subtract_20_regular:before {
    content: "\e6e9";
}
.icon-ic_fluent_drink_beer_20_filled:before {
    content: "\e6ea";
}
.icon-ic_fluent_drink_beer_20_regular:before {
    content: "\e6eb";
}
.icon-ic_fluent_drink_bottle_20_filled:before {
    content: "\e6ec";
}
.icon-ic_fluent_drink_bottle_20_regular:before {
    content: "\e6ed";
}
.icon-ic_fluent_drink_bottle_off_20_filled:before {
    content: "\e6ee";
}
.icon-ic_fluent_drink_bottle_off_20_regular:before {
    content: "\e6ef";
}
.icon-ic_fluent_drink_coffee_20_filled:before {
    content: "\e6f0";
}
.icon-ic_fluent_drink_coffee_20_regular:before {
    content: "\e6f1";
}
.icon-ic_fluent_drink_margarita_20_filled:before {
    content: "\e6f2";
}
.icon-ic_fluent_drink_margarita_20_regular:before {
    content: "\e6f3";
}
.icon-ic_fluent_drink_to_go_20_filled:before {
    content: "\e6f4";
}
.icon-ic_fluent_drink_to_go_20_regular:before {
    content: "\e6f5";
}
.icon-ic_fluent_drink_wine_20_filled:before {
    content: "\e6f6";
}
.icon-ic_fluent_drink_wine_20_regular:before {
    content: "\e6f7";
}
.icon-ic_fluent_drive_train_20_filled:before {
    content: "\e6f8";
}
.icon-ic_fluent_drive_train_20_regular:before {
    content: "\e6f9";
}
.icon-ic_fluent_drop_20_filled:before {
    content: "\e6fa";
}
.icon-ic_fluent_drop_20_regular:before {
    content: "\e6fb";
}
.icon-ic_fluent_dual_screen_20_filled:before {
    content: "\e6fc";
}
.icon-ic_fluent_dual_screen_20_regular:before {
    content: "\e6fd";
}
.icon-ic_fluent_dual_screen_add_20_filled:before {
    content: "\e6fe";
}
.icon-ic_fluent_dual_screen_add_20_regular:before {
    content: "\e6ff";
}
.icon-ic_fluent_dual_screen_arrow_right_20_filled:before {
    content: "\e700";
}
.icon-ic_fluent_dual_screen_arrow_right_20_regular:before {
    content: "\e701";
}
.icon-ic_fluent_dual_screen_arrow_up_20_filled:before {
    content: "\e702";
}
.icon-ic_fluent_dual_screen_arrow_up_20_regular:before {
    content: "\e703";
}
.icon-ic_fluent_dual_screen_clock_20_filled:before {
    content: "\e704";
}
.icon-ic_fluent_dual_screen_clock_20_regular:before {
    content: "\e705";
}
.icon-ic_fluent_dual_screen_closed_alert_20_filled:before {
    content: "\e706";
}
.icon-ic_fluent_dual_screen_closed_alert_20_regular:before {
    content: "\e707";
}
.icon-ic_fluent_dual_screen_desktop_20_filled:before {
    content: "\e708";
}
.icon-ic_fluent_dual_screen_desktop_20_regular:before {
    content: "\e709";
}
.icon-ic_fluent_dual_screen_dismiss_20_filled:before {
    content: "\e70a";
}
.icon-ic_fluent_dual_screen_dismiss_20_regular:before {
    content: "\e70b";
}
.icon-ic_fluent_dual_screen_group_20_filled:before {
    content: "\e70c";
}
.icon-ic_fluent_dual_screen_group_20_regular:before {
    content: "\e70d";
}
.icon-ic_fluent_dual_screen_header_20_filled:before {
    content: "\e70e";
}
.icon-ic_fluent_dual_screen_header_20_regular:before {
    content: "\e70f";
}
.icon-ic_fluent_dual_screen_lock_20_filled:before {
    content: "\e710";
}
.icon-ic_fluent_dual_screen_lock_20_regular:before {
    content: "\e711";
}
.icon-ic_fluent_dual_screen_mirror_20_filled:before {
    content: "\e712";
}
.icon-ic_fluent_dual_screen_mirror_20_regular:before {
    content: "\e713";
}
.icon-ic_fluent_dual_screen_pagination_20_filled:before {
    content: "\e714";
}
.icon-ic_fluent_dual_screen_pagination_20_regular:before {
    content: "\e715";
}
.icon-ic_fluent_dual_screen_settings_20_filled:before {
    content: "\e716";
}
.icon-ic_fluent_dual_screen_settings_20_regular:before {
    content: "\e717";
}
.icon-ic_fluent_dual_screen_span_20_filled:before {
    content: "\e718";
}
.icon-ic_fluent_dual_screen_span_20_regular:before {
    content: "\e719";
}
.icon-ic_fluent_dual_screen_speaker_20_filled:before {
    content: "\e71a";
}
.icon-ic_fluent_dual_screen_speaker_20_regular:before {
    content: "\e71b";
}
.icon-ic_fluent_dual_screen_status_bar_20_filled:before {
    content: "\e71c";
}
.icon-ic_fluent_dual_screen_status_bar_20_regular:before {
    content: "\e71d";
}
.icon-ic_fluent_dual_screen_tablet_20_filled:before {
    content: "\e71e";
}
.icon-ic_fluent_dual_screen_tablet_20_regular:before {
    content: "\e71f";
}
.icon-ic_fluent_dual_screen_update_20_filled:before {
    content: "\e720";
}
.icon-ic_fluent_dual_screen_update_20_regular:before {
    content: "\e721";
}
.icon-ic_fluent_dual_screen_vertical_scroll_20_filled:before {
    content: "\e722";
}
.icon-ic_fluent_dual_screen_vertical_scroll_20_regular:before {
    content: "\e723";
}
.icon-ic_fluent_dual_screen_vibrate_20_filled:before {
    content: "\e724";
}
.icon-ic_fluent_dual_screen_vibrate_20_regular:before {
    content: "\e725";
}
.icon-ic_fluent_dumbbell_20_filled:before {
    content: "\e726";
}
.icon-ic_fluent_dumbbell_20_regular:before {
    content: "\e727";
}
.icon-ic_fluent_dust_20_filled:before {
    content: "\e728";
}
.icon-ic_fluent_dust_20_regular:before {
    content: "\e729";
}
.icon-ic_fluent_earth_20_filled:before {
    content: "\e72a";
}
.icon-ic_fluent_earth_20_regular:before {
    content: "\e72b";
}
.icon-ic_fluent_earth_leaf_20_filled:before {
    content: "\e72c";
}
.icon-ic_fluent_earth_leaf_20_regular:before {
    content: "\e72d";
}
.icon-ic_fluent_edit_20_filled:before {
    content: "\e72e";
}
.icon-ic_fluent_edit_20_regular:before {
    content: "\e72f";
}
.icon-ic_fluent_edit_arrow_back_20_filled:before {
    content: "\e730";
}
.icon-ic_fluent_edit_arrow_back_20_regular:before {
    content: "\e731";
}
.icon-ic_fluent_edit_off_20_filled:before {
    content: "\e732";
}
.icon-ic_fluent_edit_off_20_regular:before {
    content: "\e733";
}
.icon-ic_fluent_edit_prohibited_20_filled:before {
    content: "\e734";
}
.icon-ic_fluent_edit_prohibited_20_regular:before {
    content: "\e735";
}
.icon-ic_fluent_edit_settings_20_filled:before {
    content: "\e736";
}
.icon-ic_fluent_edit_settings_20_regular:before {
    content: "\e737";
}
.icon-ic_fluent_elevator_20_filled:before {
    content: "\e738";
}
.icon-ic_fluent_elevator_20_regular:before {
    content: "\e739";
}
.icon-ic_fluent_emoji_20_filled:before {
    content: "\e73a";
}
.icon-ic_fluent_emoji_20_regular:before {
    content: "\e73b";
}
.icon-ic_fluent_emoji_add_20_filled:before {
    content: "\e73c";
}
.icon-ic_fluent_emoji_add_20_regular:before {
    content: "\e73d";
}
.icon-ic_fluent_emoji_angry_20_filled:before {
    content: "\e73e";
}
.icon-ic_fluent_emoji_angry_20_regular:before {
    content: "\e73f";
}
.icon-ic_fluent_emoji_edit_20_filled:before {
    content: "\e740";
}
.icon-ic_fluent_emoji_edit_20_regular:before {
    content: "\e741";
}
.icon-ic_fluent_emoji_hand_20_filled:before {
    content: "\e742";
}
.icon-ic_fluent_emoji_hand_20_regular:before {
    content: "\e743";
}
.icon-ic_fluent_emoji_hint_20_filled:before {
    content: "\e744";
}
.icon-ic_fluent_emoji_hint_20_regular:before {
    content: "\e745";
}
.icon-ic_fluent_emoji_laugh_20_filled:before {
    content: "\e746";
}
.icon-ic_fluent_emoji_laugh_20_regular:before {
    content: "\e747";
}
.icon-ic_fluent_emoji_meh_20_filled:before {
    content: "\e748";
}
.icon-ic_fluent_emoji_meh_20_regular:before {
    content: "\e749";
}
.icon-ic_fluent_emoji_multiple_20_filled:before {
    content: "\e74a";
}
.icon-ic_fluent_emoji_multiple_20_regular:before {
    content: "\e74b";
}
.icon-ic_fluent_emoji_sad_20_filled:before {
    content: "\e74c";
}
.icon-ic_fluent_emoji_sad_20_regular:before {
    content: "\e74d";
}
.icon-ic_fluent_emoji_sad_slight_20_filled:before {
    content: "\e74e";
}
.icon-ic_fluent_emoji_sad_slight_20_regular:before {
    content: "\e74f";
}
.icon-ic_fluent_emoji_smile_slight_20_filled:before {
    content: "\e750";
}
.icon-ic_fluent_emoji_smile_slight_20_regular:before {
    content: "\e751";
}
.icon-ic_fluent_emoji_sparkle_20_filled:before {
    content: "\e752";
}
.icon-ic_fluent_emoji_sparkle_20_regular:before {
    content: "\e753";
}
.icon-ic_fluent_emoji_surprise_20_filled:before {
    content: "\e754";
}
.icon-ic_fluent_emoji_surprise_20_regular:before {
    content: "\e755";
}
.icon-ic_fluent_engine_20_filled:before {
    content: "\e756";
}
.icon-ic_fluent_engine_20_regular:before {
    content: "\e757";
}
.icon-ic_fluent_equal_circle_20_filled:before {
    content: "\e758";
}
.icon-ic_fluent_equal_circle_20_regular:before {
    content: "\e759";
}
.icon-ic_fluent_equal_off_20_filled:before {
    content: "\e75a";
}
.icon-ic_fluent_equal_off_20_regular:before {
    content: "\e75b";
}
.icon-ic_fluent_eraser_20_filled:before {
    content: "\e75c";
}
.icon-ic_fluent_eraser_20_regular:before {
    content: "\e75d";
}
.icon-ic_fluent_eraser_medium_20_filled:before {
    content: "\e75e";
}
.icon-ic_fluent_eraser_medium_20_regular:before {
    content: "\e75f";
}
.icon-ic_fluent_eraser_segment_20_filled:before {
    content: "\e760";
}
.icon-ic_fluent_eraser_segment_20_regular:before {
    content: "\e761";
}
.icon-ic_fluent_eraser_small_20_filled:before {
    content: "\e762";
}
.icon-ic_fluent_eraser_small_20_regular:before {
    content: "\e763";
}
.icon-ic_fluent_eraser_tool_20_filled:before {
    content: "\e764";
}
.icon-ic_fluent_eraser_tool_20_regular:before {
    content: "\e765";
}
.icon-ic_fluent_error_circle_20_filled:before {
    content: "\e766";
}
.icon-ic_fluent_error_circle_20_regular:before {
    content: "\e767";
}
.icon-ic_fluent_error_circle_settings_20_filled:before {
    content: "\e768";
}
.icon-ic_fluent_error_circle_settings_20_regular:before {
    content: "\e769";
}
.icon-ic_fluent_expand_up_left_20_filled:before {
    content: "\e76a";
}
.icon-ic_fluent_expand_up_left_20_regular:before {
    content: "\e76b";
}
.icon-ic_fluent_expand_up_right_20_filled:before {
    content: "\e76c";
}
.icon-ic_fluent_expand_up_right_20_regular:before {
    content: "\e76d";
}
.icon-ic_fluent_extended_dock_20_filled:before {
    content: "\e76e";
}
.icon-ic_fluent_extended_dock_20_regular:before {
    content: "\e76f";
}
.icon-ic_fluent_eye_20_filled:before {
    content: "\e770";
}
.icon-ic_fluent_eye_20_regular:before {
    content: "\e771";
}
.icon-ic_fluent_eye_lines_20_filled:before {
    content: "\e772";
}
.icon-ic_fluent_eye_lines_20_regular:before {
    content: "\e773";
}
.icon-ic_fluent_eye_off_20_filled:before {
    content: "\e774";
}
.icon-ic_fluent_eye_off_20_regular:before {
    content: "\e775";
}
.icon-ic_fluent_eye_tracking_20_filled:before {
    content: "\e776";
}
.icon-ic_fluent_eye_tracking_20_regular:before {
    content: "\e777";
}
.icon-ic_fluent_eye_tracking_off_20_filled:before {
    content: "\e778";
}
.icon-ic_fluent_eye_tracking_off_20_regular:before {
    content: "\e779";
}
.icon-ic_fluent_eyedropper_20_filled:before {
    content: "\e77a";
}
.icon-ic_fluent_eyedropper_20_regular:before {
    content: "\e77b";
}
.icon-ic_fluent_eyedropper_off_20_filled:before {
    content: "\e77c";
}
.icon-ic_fluent_eyedropper_off_20_regular:before {
    content: "\e77d";
}
.icon-ic_fluent_f_stop_20_filled:before {
    content: "\e77e";
}
.icon-ic_fluent_f_stop_20_regular:before {
    content: "\e77f";
}
.icon-ic_fluent_fast_acceleration_20_filled:before {
    content: "\e780";
}
.icon-ic_fluent_fast_acceleration_20_regular:before {
    content: "\e781";
}
.icon-ic_fluent_fast_forward_20_filled:before {
    content: "\e782";
}
.icon-ic_fluent_fast_forward_20_regular:before {
    content: "\e783";
}
.icon-ic_fluent_fax_20_filled:before {
    content: "\e784";
}
.icon-ic_fluent_fax_20_regular:before {
    content: "\e785";
}
.icon-ic_fluent_feed_20_filled:before {
    content: "\e786";
}
.icon-ic_fluent_feed_20_regular:before {
    content: "\e787";
}
.icon-ic_fluent_filmstrip_20_filled:before {
    content: "\e788";
}
.icon-ic_fluent_filmstrip_20_regular:before {
    content: "\e789";
}
.icon-ic_fluent_filmstrip_image_20_filled:before {
    content: "\e78a";
}
.icon-ic_fluent_filmstrip_image_20_regular:before {
    content: "\e78b";
}
.icon-ic_fluent_filmstrip_play_20_filled:before {
    content: "\e78c";
}
.icon-ic_fluent_filmstrip_play_20_regular:before {
    content: "\e78d";
}
.icon-ic_fluent_filmstrip_split_20_filled:before {
    content: "\e78e";
}
.icon-ic_fluent_filmstrip_split_20_regular:before {
    content: "\e78f";
}
.icon-ic_fluent_filter_20_filled:before {
    content: "\e790";
}
.icon-ic_fluent_filter_20_regular:before {
    content: "\e791";
}
.icon-ic_fluent_filter_add_20_filled:before {
    content: "\e792";
}
.icon-ic_fluent_filter_add_20_regular:before {
    content: "\e793";
}
.icon-ic_fluent_filter_dismiss_20_filled:before {
    content: "\e794";
}
.icon-ic_fluent_filter_dismiss_20_regular:before {
    content: "\e795";
}
.icon-ic_fluent_filter_sync_20_filled:before {
    content: "\e796";
}
.icon-ic_fluent_filter_sync_20_regular:before {
    content: "\e797";
}
.icon-ic_fluent_fingerprint_20_filled:before {
    content: "\e798";
}
.icon-ic_fluent_fingerprint_20_regular:before {
    content: "\e799";
}
.icon-ic_fluent_fire_20_filled:before {
    content: "\e79a";
}
.icon-ic_fluent_fire_20_regular:before {
    content: "\e79b";
}
.icon-ic_fluent_fireplace_20_filled:before {
    content: "\e79c";
}
.icon-ic_fluent_fireplace_20_regular:before {
    content: "\e79d";
}
.icon-ic_fluent_fixed_width_20_filled:before {
    content: "\e79e";
}
.icon-ic_fluent_fixed_width_20_regular:before {
    content: "\e79f";
}
.icon-ic_fluent_flag_20_filled:before {
    content: "\e7a0";
}
.icon-ic_fluent_flag_20_regular:before {
    content: "\e7a1";
}
.icon-ic_fluent_flag_checkered_20_filled:before {
    content: "\e7a2";
}
.icon-ic_fluent_flag_checkered_20_regular:before {
    content: "\e7a3";
}
.icon-ic_fluent_flag_clock_20_filled:before {
    content: "\e7a4";
}
.icon-ic_fluent_flag_clock_20_regular:before {
    content: "\e7a5";
}
.icon-ic_fluent_flag_off_20_filled:before {
    content: "\e7a6";
}
.icon-ic_fluent_flag_off_20_regular:before {
    content: "\e7a7";
}
.icon-ic_fluent_flag_pride_20_filled:before {
    content: "\e7a8";
}
.icon-ic_fluent_flag_pride_intersex_inclusive_progress_20_filled:before {
    content: "\e7a9";
}
.icon-ic_fluent_flag_pride_philadelphia_20_filled:before {
    content: "\e7aa";
}
.icon-ic_fluent_flag_pride_progress_20_filled:before {
    content: "\e7ab";
}
.icon-ic_fluent_flash_20_filled:before {
    content: "\e7ac";
}
.icon-ic_fluent_flash_20_regular:before {
    content: "\e7ad";
}
.icon-ic_fluent_flash_add_20_filled:before {
    content: "\e7ae";
}
.icon-ic_fluent_flash_add_20_regular:before {
    content: "\e7af";
}
.icon-ic_fluent_flash_auto_20_filled:before {
    content: "\e7b0";
}
.icon-ic_fluent_flash_auto_20_regular:before {
    content: "\e7b1";
}
.icon-ic_fluent_flash_checkmark_20_filled:before {
    content: "\e7b2";
}
.icon-ic_fluent_flash_checkmark_20_regular:before {
    content: "\e7b3";
}
.icon-ic_fluent_flash_flow_20_filled:before {
    content: "\e7b4";
}
.icon-ic_fluent_flash_flow_20_regular:before {
    content: "\e7b5";
}
.icon-ic_fluent_flash_off_20_filled:before {
    content: "\e7b6";
}
.icon-ic_fluent_flash_off_20_regular:before {
    content: "\e7b7";
}
.icon-ic_fluent_flash_play_20_filled:before {
    content: "\e7b8";
}
.icon-ic_fluent_flash_play_20_regular:before {
    content: "\e7b9";
}
.icon-ic_fluent_flash_settings_20_filled:before {
    content: "\e7ba";
}
.icon-ic_fluent_flash_settings_20_regular:before {
    content: "\e7bb";
}
.icon-ic_fluent_flash_sparkle_20_filled:before {
    content: "\e7bc";
}
.icon-ic_fluent_flash_sparkle_20_regular:before {
    content: "\e7bd";
}
.icon-ic_fluent_flashlight_20_filled:before {
    content: "\e7be";
}
.icon-ic_fluent_flashlight_20_regular:before {
    content: "\e7bf";
}
.icon-ic_fluent_flashlight_off_20_filled:before {
    content: "\e7c0";
}
.icon-ic_fluent_flashlight_off_20_regular:before {
    content: "\e7c1";
}
.icon-ic_fluent_flip_horizontal_20_filled:before {
    content: "\e7c2";
}
.icon-ic_fluent_flip_horizontal_20_regular:before {
    content: "\e7c3";
}
.icon-ic_fluent_flip_vertical_20_filled:before {
    content: "\e7c4";
}
.icon-ic_fluent_flip_vertical_20_regular:before {
    content: "\e7c5";
}
.icon-ic_fluent_flow_20_filled:before {
    content: "\e7c6";
}
.icon-ic_fluent_flow_20_regular:before {
    content: "\e7c7";
}
.icon-ic_fluent_flowchart_20_filled:before {
    content: "\e7c8";
}
.icon-ic_fluent_flowchart_20_regular:before {
    content: "\e7c9";
}
.icon-ic_fluent_flowchart_circle_20_filled:before {
    content: "\e7ca";
}
.icon-ic_fluent_flowchart_circle_20_regular:before {
    content: "\e7cb";
}
.icon-ic_fluent_fluent_20_filled:before {
    content: "\e7cc";
}
.icon-ic_fluent_fluent_20_regular:before {
    content: "\e7cd";
}
.icon-ic_fluent_fluid_20_filled:before {
    content: "\e7ce";
}
.icon-ic_fluent_fluid_20_regular:before {
    content: "\e7cf";
}
.icon-ic_fluent_folder_20_filled:before {
    content: "\e7d0";
}
.icon-ic_fluent_folder_20_regular:before {
    content: "\e7d1";
}
.icon-ic_fluent_folder_add_20_filled:before {
    content: "\e7d2";
}
.icon-ic_fluent_folder_add_20_regular:before {
    content: "\e7d3";
}
.icon-ic_fluent_folder_arrow_left_20_filled:before {
    content: "\e7d4";
}
.icon-ic_fluent_folder_arrow_left_20_regular:before {
    content: "\e7d5";
}
.icon-ic_fluent_folder_arrow_right_20_filled:before {
    content: "\e7d6";
}
.icon-ic_fluent_folder_arrow_right_20_regular:before {
    content: "\e7d7";
}
.icon-ic_fluent_folder_arrow_up_20_filled:before {
    content: "\e7d8";
}
.icon-ic_fluent_folder_arrow_up_20_regular:before {
    content: "\e7d9";
}
.icon-ic_fluent_folder_briefcase_20_filled:before {
    content: "\e7da";
}
.icon-ic_fluent_folder_briefcase_20_regular:before {
    content: "\e7db";
}
.icon-ic_fluent_folder_globe_20_filled:before {
    content: "\e7dc";
}
.icon-ic_fluent_folder_globe_20_regular:before {
    content: "\e7dd";
}
.icon-ic_fluent_folder_lightning_20_filled:before {
    content: "\e7de";
}
.icon-ic_fluent_folder_lightning_20_regular:before {
    content: "\e7df";
}
.icon-ic_fluent_folder_link_20_filled:before {
    content: "\e7e0";
}
.icon-ic_fluent_folder_link_20_regular:before {
    content: "\e7e1";
}
.icon-ic_fluent_folder_list_20_filled:before {
    content: "\e7e2";
}
.icon-ic_fluent_folder_list_20_regular:before {
    content: "\e7e3";
}
.icon-ic_fluent_folder_mail_20_filled:before {
    content: "\e7e4";
}
.icon-ic_fluent_folder_mail_20_regular:before {
    content: "\e7e5";
}
.icon-ic_fluent_folder_open_20_filled:before {
    content: "\e7e6";
}
.icon-ic_fluent_folder_open_20_regular:before {
    content: "\e7e7";
}
.icon-ic_fluent_folder_open_vertical_20_filled:before {
    content: "\e7e8";
}
.icon-ic_fluent_folder_open_vertical_20_regular:before {
    content: "\e7e9";
}
.icon-ic_fluent_folder_people_20_filled:before {
    content: "\e7ea";
}
.icon-ic_fluent_folder_people_20_regular:before {
    content: "\e7eb";
}
.icon-ic_fluent_folder_person_20_filled:before {
    content: "\e7ec";
}
.icon-ic_fluent_folder_person_20_regular:before {
    content: "\e7ed";
}
.icon-ic_fluent_folder_prohibited_20_filled:before {
    content: "\e7ee";
}
.icon-ic_fluent_folder_prohibited_20_regular:before {
    content: "\e7ef";
}
.icon-ic_fluent_folder_search_20_filled:before {
    content: "\e7f0";
}
.icon-ic_fluent_folder_search_20_regular:before {
    content: "\e7f1";
}
.icon-ic_fluent_folder_swap_20_filled:before {
    content: "\e7f2";
}
.icon-ic_fluent_folder_swap_20_regular:before {
    content: "\e7f3";
}
.icon-ic_fluent_folder_sync_20_filled:before {
    content: "\e7f4";
}
.icon-ic_fluent_folder_sync_20_regular:before {
    content: "\e7f5";
}
.icon-ic_fluent_folder_zip_20_filled:before {
    content: "\e7f6";
}
.icon-ic_fluent_folder_zip_20_regular:before {
    content: "\e7f7";
}
.icon-ic_fluent_font_decrease_20_filled:before {
    content: "\e7f8";
}
.icon-ic_fluent_font_decrease_20_regular:before {
    content: "\e7f9";
}
.icon-ic_fluent_font_increase_20_filled:before {
    content: "\e7fa";
}
.icon-ic_fluent_font_increase_20_regular:before {
    content: "\e7fb";
}
.icon-ic_fluent_font_space_tracking_in_20_filled:before {
    content: "\e7fc";
}
.icon-ic_fluent_font_space_tracking_in_20_regular:before {
    content: "\e7fd";
}
.icon-ic_fluent_font_space_tracking_out_20_filled:before {
    content: "\e7fe";
}
.icon-ic_fluent_font_space_tracking_out_20_regular:before {
    content: "\e7ff";
}
.icon-ic_fluent_food_20_filled:before {
    content: "\e800";
}
.icon-ic_fluent_food_20_regular:before {
    content: "\e801";
}
.icon-ic_fluent_food_apple_20_filled:before {
    content: "\e802";
}
.icon-ic_fluent_food_apple_20_regular:before {
    content: "\e803";
}
.icon-ic_fluent_food_cake_20_filled:before {
    content: "\e804";
}
.icon-ic_fluent_food_cake_20_regular:before {
    content: "\e805";
}
.icon-ic_fluent_food_carrot_20_filled:before {
    content: "\e806";
}
.icon-ic_fluent_food_carrot_20_regular:before {
    content: "\e807";
}
.icon-ic_fluent_food_chicken_leg_20_filled:before {
    content: "\e808";
}
.icon-ic_fluent_food_chicken_leg_20_regular:before {
    content: "\e809";
}
.icon-ic_fluent_food_egg_20_filled:before {
    content: "\e80a";
}
.icon-ic_fluent_food_egg_20_regular:before {
    content: "\e80b";
}
.icon-ic_fluent_food_fish_20_filled:before {
    content: "\e80c";
}
.icon-ic_fluent_food_fish_20_regular:before {
    content: "\e80d";
}
.icon-ic_fluent_food_grains_20_filled:before {
    content: "\e80e";
}
.icon-ic_fluent_food_grains_20_regular:before {
    content: "\e80f";
}
.icon-ic_fluent_food_pizza_20_filled:before {
    content: "\e810";
}
.icon-ic_fluent_food_pizza_20_regular:before {
    content: "\e811";
}
.icon-ic_fluent_food_toast_20_filled:before {
    content: "\e812";
}
.icon-ic_fluent_food_toast_20_regular:before {
    content: "\e813";
}
.icon-ic_fluent_form_20_filled:before {
    content: "\e814";
}
.icon-ic_fluent_form_20_regular:before {
    content: "\e815";
}
.icon-ic_fluent_form_multiple_20_filled:before {
    content: "\e816";
}
.icon-ic_fluent_form_multiple_20_regular:before {
    content: "\e817";
}
.icon-ic_fluent_form_new_20_filled:before {
    content: "\e818";
}
.icon-ic_fluent_form_new_20_regular:before {
    content: "\e819";
}
.icon-ic_fluent_fps_120_20_filled:before {
    content: "\e81a";
}
.icon-ic_fluent_fps_120_20_regular:before {
    content: "\e81b";
}
.icon-ic_fluent_fps_240_20_filled:before {
    content: "\e81c";
}
.icon-ic_fluent_fps_240_20_regular:before {
    content: "\e81d";
}
.icon-ic_fluent_fps_30_20_filled:before {
    content: "\e81e";
}
.icon-ic_fluent_fps_30_20_regular:before {
    content: "\e81f";
}
.icon-ic_fluent_fps_60_20_filled:before {
    content: "\e820";
}
.icon-ic_fluent_fps_60_20_regular:before {
    content: "\e821";
}
.icon-ic_fluent_fps_960_20_filled:before {
    content: "\e822";
}
.icon-ic_fluent_fps_960_20_regular:before {
    content: "\e823";
}
.icon-ic_fluent_frame_20_filled:before {
    content: "\e824";
}
.icon-ic_fluent_frame_20_regular:before {
    content: "\e825";
}
.icon-ic_fluent_full_screen_maximize_20_filled:before {
    content: "\e826";
}
.icon-ic_fluent_full_screen_maximize_20_regular:before {
    content: "\e827";
}
.icon-ic_fluent_full_screen_minimize_20_filled:before {
    content: "\e828";
}
.icon-ic_fluent_full_screen_minimize_20_regular:before {
    content: "\e829";
}
.icon-ic_fluent_games_20_filled:before {
    content: "\e82a";
}
.icon-ic_fluent_games_20_regular:before {
    content: "\e82b";
}
.icon-ic_fluent_gantt_chart_20_filled:before {
    content: "\e82c";
}
.icon-ic_fluent_gantt_chart_20_regular:before {
    content: "\e82d";
}
.icon-ic_fluent_gas_20_filled:before {
    content: "\e82e";
}
.icon-ic_fluent_gas_20_regular:before {
    content: "\e82f";
}
.icon-ic_fluent_gas_pump_20_filled:before {
    content: "\e830";
}
.icon-ic_fluent_gas_pump_20_regular:before {
    content: "\e831";
}
.icon-ic_fluent_gather_20_filled:before {
    content: "\e832";
}
.icon-ic_fluent_gather_20_regular:before {
    content: "\e833";
}
.icon-ic_fluent_gauge_20_filled:before {
    content: "\e834";
}
.icon-ic_fluent_gauge_20_regular:before {
    content: "\e835";
}
.icon-ic_fluent_gauge_add_20_filled:before {
    content: "\e836";
}
.icon-ic_fluent_gauge_add_20_regular:before {
    content: "\e837";
}
.icon-ic_fluent_gavel_20_filled:before {
    content: "\e838";
}
.icon-ic_fluent_gavel_20_regular:before {
    content: "\e839";
}
.icon-ic_fluent_gavel_prohibited_20_filled:before {
    content: "\e83a";
}
.icon-ic_fluent_gavel_prohibited_20_regular:before {
    content: "\e83b";
}
.icon-ic_fluent_gesture_20_filled:before {
    content: "\e83c";
}
.icon-ic_fluent_gesture_20_regular:before {
    content: "\e83d";
}
.icon-ic_fluent_gif_20_filled:before {
    content: "\e83e";
}
.icon-ic_fluent_gif_20_regular:before {
    content: "\e83f";
}
.icon-ic_fluent_gift_20_filled:before {
    content: "\e840";
}
.icon-ic_fluent_gift_20_regular:before {
    content: "\e841";
}
.icon-ic_fluent_gift_card_20_filled:before {
    content: "\e842";
}
.icon-ic_fluent_gift_card_20_regular:before {
    content: "\e843";
}
.icon-ic_fluent_gift_card_add_20_filled:before {
    content: "\e844";
}
.icon-ic_fluent_gift_card_add_20_regular:before {
    content: "\e845";
}
.icon-ic_fluent_gift_card_arrow_right_20_filled:before {
    content: "\e846";
}
.icon-ic_fluent_gift_card_arrow_right_20_regular:before {
    content: "\e847";
}
.icon-ic_fluent_gift_card_money_20_filled:before {
    content: "\e848";
}
.icon-ic_fluent_gift_card_money_20_regular:before {
    content: "\e849";
}
.icon-ic_fluent_gift_card_multiple_20_filled:before {
    content: "\e84a";
}
.icon-ic_fluent_gift_card_multiple_20_regular:before {
    content: "\e84b";
}
.icon-ic_fluent_gift_open_20_filled:before {
    content: "\e84c";
}
.icon-ic_fluent_gift_open_20_regular:before {
    content: "\e84d";
}
.icon-ic_fluent_glance_20_filled:before {
    content: "\e84e";
}
.icon-ic_fluent_glance_20_regular:before {
    content: "\e84f";
}
.icon-ic_fluent_glance_horizontal_20_filled:before {
    content: "\e850";
}
.icon-ic_fluent_glance_horizontal_20_regular:before {
    content: "\e851";
}
.icon-ic_fluent_glance_horizontal_sparkles_20_filled:before {
    content: "\e852";
}
.icon-ic_fluent_glance_horizontal_sparkles_20_regular:before {
    content: "\e853";
}
.icon-ic_fluent_glasses_20_filled:before {
    content: "\e854";
}
.icon-ic_fluent_glasses_20_regular:before {
    content: "\e855";
}
.icon-ic_fluent_glasses_off_20_filled:before {
    content: "\e856";
}
.icon-ic_fluent_glasses_off_20_regular:before {
    content: "\e857";
}
.icon-ic_fluent_globe_20_filled:before {
    content: "\e858";
}
.icon-ic_fluent_globe_20_regular:before {
    content: "\e859";
}
.icon-ic_fluent_globe_add_20_filled:before {
    content: "\e85a";
}
.icon-ic_fluent_globe_add_20_regular:before {
    content: "\e85b";
}
.icon-ic_fluent_globe_arrow_forward_20_filled:before {
    content: "\e85c";
}
.icon-ic_fluent_globe_arrow_forward_20_regular:before {
    content: "\e85d";
}
.icon-ic_fluent_globe_arrow_up_20_filled:before {
    content: "\e85e";
}
.icon-ic_fluent_globe_arrow_up_20_regular:before {
    content: "\e85f";
}
.icon-ic_fluent_globe_clock_20_filled:before {
    content: "\e860";
}
.icon-ic_fluent_globe_clock_20_regular:before {
    content: "\e861";
}
.icon-ic_fluent_globe_desktop_20_filled:before {
    content: "\e862";
}
.icon-ic_fluent_globe_desktop_20_regular:before {
    content: "\e863";
}
.icon-ic_fluent_globe_error_20_filled:before {
    content: "\e864";
}
.icon-ic_fluent_globe_error_20_regular:before {
    content: "\e865";
}
.icon-ic_fluent_globe_location_20_filled:before {
    content: "\e866";
}
.icon-ic_fluent_globe_location_20_regular:before {
    content: "\e867";
}
.icon-ic_fluent_globe_person_20_filled:before {
    content: "\e868";
}
.icon-ic_fluent_globe_person_20_regular:before {
    content: "\e869";
}
.icon-ic_fluent_globe_prohibited_20_filled:before {
    content: "\e86a";
}
.icon-ic_fluent_globe_prohibited_20_regular:before {
    content: "\e86b";
}
.icon-ic_fluent_globe_search_20_filled:before {
    content: "\e86c";
}
.icon-ic_fluent_globe_search_20_regular:before {
    content: "\e86d";
}
.icon-ic_fluent_globe_shield_20_filled:before {
    content: "\e86e";
}
.icon-ic_fluent_globe_shield_20_regular:before {
    content: "\e86f";
}
.icon-ic_fluent_globe_star_20_filled:before {
    content: "\e870";
}
.icon-ic_fluent_globe_star_20_regular:before {
    content: "\e871";
}
.icon-ic_fluent_globe_surface_20_filled:before {
    content: "\e872";
}
.icon-ic_fluent_globe_surface_20_regular:before {
    content: "\e873";
}
.icon-ic_fluent_globe_sync_20_filled:before {
    content: "\e874";
}
.icon-ic_fluent_globe_sync_20_regular:before {
    content: "\e875";
}
.icon-ic_fluent_globe_video_20_filled:before {
    content: "\e876";
}
.icon-ic_fluent_globe_video_20_regular:before {
    content: "\e877";
}
.icon-ic_fluent_globe_warning_20_filled:before {
    content: "\e878";
}
.icon-ic_fluent_globe_warning_20_regular:before {
    content: "\e879";
}
.icon-ic_fluent_grid_20_filled:before {
    content: "\e87a";
}
.icon-ic_fluent_grid_20_regular:before {
    content: "\e87b";
}
.icon-ic_fluent_grid_dots_20_filled:before {
    content: "\e87c";
}
.icon-ic_fluent_grid_dots_20_regular:before {
    content: "\e87d";
}
.icon-ic_fluent_grid_kanban_20_filled:before {
    content: "\e87e";
}
.icon-ic_fluent_grid_kanban_20_regular:before {
    content: "\e87f";
}
.icon-ic_fluent_group_20_filled:before {
    content: "\e880";
}
.icon-ic_fluent_group_20_regular:before {
    content: "\e881";
}
.icon-ic_fluent_group_dismiss_20_filled:before {
    content: "\e882";
}
.icon-ic_fluent_group_dismiss_20_regular:before {
    content: "\e883";
}
.icon-ic_fluent_group_list_20_filled:before {
    content: "\e884";
}
.icon-ic_fluent_group_list_20_regular:before {
    content: "\e885";
}
.icon-ic_fluent_group_return_20_filled:before {
    content: "\e886";
}
.icon-ic_fluent_group_return_20_regular:before {
    content: "\e887";
}
.icon-ic_fluent_guardian_20_filled:before {
    content: "\e888";
}
.icon-ic_fluent_guardian_20_regular:before {
    content: "\e889";
}
.icon-ic_fluent_guest_20_filled:before {
    content: "\e88a";
}
.icon-ic_fluent_guest_20_regular:before {
    content: "\e88b";
}
.icon-ic_fluent_guest_add_20_filled:before {
    content: "\e88c";
}
.icon-ic_fluent_guest_add_20_regular:before {
    content: "\e88d";
}
.icon-ic_fluent_guitar_20_filled:before {
    content: "\e88e";
}
.icon-ic_fluent_guitar_20_regular:before {
    content: "\e88f";
}
.icon-ic_fluent_hand_draw_20_filled:before {
    content: "\e890";
}
.icon-ic_fluent_hand_draw_20_regular:before {
    content: "\e891";
}
.icon-ic_fluent_hand_left_20_filled:before {
    content: "\e892";
}
.icon-ic_fluent_hand_left_20_regular:before {
    content: "\e893";
}
.icon-ic_fluent_hand_left_chat_20_filled:before {
    content: "\e894";
}
.icon-ic_fluent_hand_left_chat_20_regular:before {
    content: "\e895";
}
.icon-ic_fluent_hand_open_heart_20_filled:before {
    content: "\e896";
}
.icon-ic_fluent_hand_open_heart_20_regular:before {
    content: "\e897";
}
.icon-ic_fluent_hand_right_20_filled:before {
    content: "\e898";
}
.icon-ic_fluent_hand_right_20_regular:before {
    content: "\e899";
}
.icon-ic_fluent_hand_right_off_20_filled:before {
    content: "\e89a";
}
.icon-ic_fluent_hand_right_off_20_regular:before {
    content: "\e89b";
}
.icon-ic_fluent_hand_wave_20_filled:before {
    content: "\e89c";
}
.icon-ic_fluent_hand_wave_20_regular:before {
    content: "\e89d";
}
.icon-ic_fluent_handshake_20_filled:before {
    content: "\e89e";
}
.icon-ic_fluent_handshake_20_regular:before {
    content: "\e89f";
}
.icon-ic_fluent_hard_drive_20_filled:before {
    content: "\e8a0";
}
.icon-ic_fluent_hard_drive_20_regular:before {
    content: "\e8a1";
}
.icon-ic_fluent_hat_graduation_20_filled:before {
    content: "\e8a2";
}
.icon-ic_fluent_hat_graduation_20_regular:before {
    content: "\e8a3";
}
.icon-ic_fluent_hat_graduation_add_20_filled:before {
    content: "\e8a4";
}
.icon-ic_fluent_hat_graduation_add_20_regular:before {
    content: "\e8a5";
}
.icon-ic_fluent_hat_graduation_sparkle_20_filled:before {
    content: "\e8a6";
}
.icon-ic_fluent_hat_graduation_sparkle_20_regular:before {
    content: "\e8a7";
}
.icon-ic_fluent_hd_20_filled:before {
    content: "\e8a8";
}
.icon-ic_fluent_hd_20_regular:before {
    content: "\e8a9";
}
.icon-ic_fluent_hdr_20_filled:before {
    content: "\e8aa";
}
.icon-ic_fluent_hdr_20_regular:before {
    content: "\e8ab";
}
.icon-ic_fluent_hdr_off_20_filled:before {
    content: "\e8ac";
}
.icon-ic_fluent_hdr_off_20_regular:before {
    content: "\e8ad";
}
.icon-ic_fluent_headphones_20_filled:before {
    content: "\e8ae";
}
.icon-ic_fluent_headphones_20_regular:before {
    content: "\e8af";
}
.icon-ic_fluent_headphones_sound_wave_20_filled:before {
    content: "\e8b0";
}
.icon-ic_fluent_headphones_sound_wave_20_regular:before {
    content: "\e8b1";
}
.icon-ic_fluent_headset_20_filled:before {
    content: "\e8b2";
}
.icon-ic_fluent_headset_20_regular:before {
    content: "\e8b3";
}
.icon-ic_fluent_headset_add_20_filled:before {
    content: "\e8b4";
}
.icon-ic_fluent_headset_add_20_regular:before {
    content: "\e8b5";
}
.icon-ic_fluent_headset_vr_20_filled:before {
    content: "\e8b6";
}
.icon-ic_fluent_headset_vr_20_regular:before {
    content: "\e8b7";
}
.icon-ic_fluent_heart_20_filled:before {
    content: "\e8b8";
}
.icon-ic_fluent_heart_20_regular:before {
    content: "\e8b9";
}
.icon-ic_fluent_heart_broken_20_filled:before {
    content: "\e8ba";
}
.icon-ic_fluent_heart_broken_20_regular:before {
    content: "\e8bb";
}
.icon-ic_fluent_heart_circle_20_filled:before {
    content: "\e8bc";
}
.icon-ic_fluent_heart_circle_20_regular:before {
    content: "\e8bd";
}
.icon-ic_fluent_heart_circle_hint_20_filled:before {
    content: "\e8be";
}
.icon-ic_fluent_heart_circle_hint_20_regular:before {
    content: "\e8bf";
}
.icon-ic_fluent_heart_off_20_filled:before {
    content: "\e8c0";
}
.icon-ic_fluent_heart_off_20_regular:before {
    content: "\e8c1";
}
.icon-ic_fluent_heart_pulse_20_filled:before {
    content: "\e8c2";
}
.icon-ic_fluent_heart_pulse_20_regular:before {
    content: "\e8c3";
}
.icon-ic_fluent_heart_pulse_checkmark_20_filled:before {
    content: "\e8c4";
}
.icon-ic_fluent_heart_pulse_checkmark_20_regular:before {
    content: "\e8c5";
}
.icon-ic_fluent_heart_pulse_error_20_filled:before {
    content: "\e8c6";
}
.icon-ic_fluent_heart_pulse_error_20_regular:before {
    content: "\e8c7";
}
.icon-ic_fluent_heart_pulse_warning_20_filled:before {
    content: "\e8c8";
}
.icon-ic_fluent_heart_pulse_warning_20_regular:before {
    content: "\e8c9";
}
.icon-ic_fluent_hexagon_20_filled:before {
    content: "\e8ca";
}
.icon-ic_fluent_hexagon_20_regular:before {
    content: "\e8cb";
}
.icon-ic_fluent_hexagon_three_20_filled:before {
    content: "\e8cc";
}
.icon-ic_fluent_hexagon_three_20_regular:before {
    content: "\e8cd";
}
.icon-ic_fluent_highlight_20_filled:before {
    content: "\e8ce";
}
.icon-ic_fluent_highlight_20_regular:before {
    content: "\e8cf";
}
.icon-ic_fluent_highlight_accent_20_filled:before {
    content: "\e8d0";
}
.icon-ic_fluent_highlight_link_20_filled:before {
    content: "\e8d1";
}
.icon-ic_fluent_highlight_link_20_regular:before {
    content: "\e8d2";
}
.icon-ic_fluent_history_20_filled:before {
    content: "\e8d3";
}
.icon-ic_fluent_history_20_regular:before {
    content: "\e8d4";
}
.icon-ic_fluent_history_dismiss_20_filled:before {
    content: "\e8d5";
}
.icon-ic_fluent_history_dismiss_20_regular:before {
    content: "\e8d6";
}
.icon-ic_fluent_home_20_filled:before {
    content: "\e8d7";
}
.icon-ic_fluent_home_20_regular:before {
    content: "\e8d8";
}
.icon-ic_fluent_home_add_20_filled:before {
    content: "\e8d9";
}
.icon-ic_fluent_home_add_20_regular:before {
    content: "\e8da";
}
.icon-ic_fluent_home_checkmark_20_filled:before {
    content: "\e8db";
}
.icon-ic_fluent_home_checkmark_20_regular:before {
    content: "\e8dc";
}
.icon-ic_fluent_home_database_20_filled:before {
    content: "\e8dd";
}
.icon-ic_fluent_home_database_20_regular:before {
    content: "\e8de";
}
.icon-ic_fluent_home_heart_20_filled:before {
    content: "\e8df";
}
.icon-ic_fluent_home_heart_20_regular:before {
    content: "\e8e0";
}
.icon-ic_fluent_home_more_20_filled:before {
    content: "\e8e1";
}
.icon-ic_fluent_home_more_20_regular:before {
    content: "\e8e2";
}
.icon-ic_fluent_home_person_20_filled:before {
    content: "\e8e3";
}
.icon-ic_fluent_home_person_20_regular:before {
    content: "\e8e4";
}
.icon-ic_fluent_home_split_20_filled:before {
    content: "\e8e5";
}
.icon-ic_fluent_home_split_20_regular:before {
    content: "\e8e6";
}
.icon-ic_fluent_hourglass_20_filled:before {
    content: "\e8e7";
}
.icon-ic_fluent_hourglass_20_regular:before {
    content: "\e8e8";
}
.icon-ic_fluent_hourglass_half_20_filled:before {
    content: "\e8e9";
}
.icon-ic_fluent_hourglass_half_20_regular:before {
    content: "\e8ea";
}
.icon-ic_fluent_hourglass_one_quarter_20_filled:before {
    content: "\e8eb";
}
.icon-ic_fluent_hourglass_one_quarter_20_regular:before {
    content: "\e8ec";
}
.icon-ic_fluent_hourglass_three_quarter_20_filled:before {
    content: "\e8ed";
}
.icon-ic_fluent_hourglass_three_quarter_20_regular:before {
    content: "\e8ee";
}
.icon-ic_fluent_icons_20_filled:before {
    content: "\e8ef";
}
.icon-ic_fluent_icons_20_regular:before {
    content: "\e8f0";
}
.icon-ic_fluent_image_20_filled:before {
    content: "\e8f1";
}
.icon-ic_fluent_image_20_regular:before {
    content: "\e8f2";
}
.icon-ic_fluent_image_add_20_filled:before {
    content: "\e8f3";
}
.icon-ic_fluent_image_add_20_regular:before {
    content: "\e8f4";
}
.icon-ic_fluent_image_alt_text_20_filled:before {
    content: "\e8f5";
}
.icon-ic_fluent_image_alt_text_20_regular:before {
    content: "\e8f6";
}
.icon-ic_fluent_image_arrow_back_20_filled:before {
    content: "\e8f7";
}
.icon-ic_fluent_image_arrow_back_20_regular:before {
    content: "\e8f8";
}
.icon-ic_fluent_image_arrow_counterclockwise_20_filled:before {
    content: "\e8f9";
}
.icon-ic_fluent_image_arrow_counterclockwise_20_regular:before {
    content: "\e8fa";
}
.icon-ic_fluent_image_arrow_forward_20_filled:before {
    content: "\e8fb";
}
.icon-ic_fluent_image_arrow_forward_20_regular:before {
    content: "\e8fc";
}
.icon-ic_fluent_image_border_20_filled:before {
    content: "\e8fd";
}
.icon-ic_fluent_image_border_20_regular:before {
    content: "\e8fe";
}
.icon-ic_fluent_image_circle_20_filled:before {
    content: "\e8ff";
}
.icon-ic_fluent_image_circle_20_regular:before {
    content: "\e900";
}
.icon-ic_fluent_image_copy_20_filled:before {
    content: "\e901";
}
.icon-ic_fluent_image_copy_20_regular:before {
    content: "\e902";
}
.icon-ic_fluent_image_edit_20_filled:before {
    content: "\e903";
}
.icon-ic_fluent_image_edit_20_regular:before {
    content: "\e904";
}
.icon-ic_fluent_image_globe_20_filled:before {
    content: "\e905";
}
.icon-ic_fluent_image_globe_20_regular:before {
    content: "\e906";
}
.icon-ic_fluent_image_multiple_20_filled:before {
    content: "\e907";
}
.icon-ic_fluent_image_multiple_20_regular:before {
    content: "\e908";
}
.icon-ic_fluent_image_multiple_off_20_filled:before {
    content: "\e909";
}
.icon-ic_fluent_image_multiple_off_20_regular:before {
    content: "\e90a";
}
.icon-ic_fluent_image_off_20_filled:before {
    content: "\e90b";
}
.icon-ic_fluent_image_off_20_regular:before {
    content: "\e90c";
}
.icon-ic_fluent_image_prohibited_20_filled:before {
    content: "\e90d";
}
.icon-ic_fluent_image_prohibited_20_regular:before {
    content: "\e90e";
}
.icon-ic_fluent_image_reflection_20_filled:before {
    content: "\e90f";
}
.icon-ic_fluent_image_reflection_20_regular:before {
    content: "\e910";
}
.icon-ic_fluent_image_search_20_filled:before {
    content: "\e911";
}
.icon-ic_fluent_image_search_20_regular:before {
    content: "\e912";
}
.icon-ic_fluent_image_shadow_20_filled:before {
    content: "\e913";
}
.icon-ic_fluent_image_shadow_20_regular:before {
    content: "\e914";
}
.icon-ic_fluent_image_sparkle_20_filled:before {
    content: "\e915";
}
.icon-ic_fluent_image_sparkle_20_regular:before {
    content: "\e916";
}
.icon-ic_fluent_image_stack_20_filled:before {
    content: "\e917";
}
.icon-ic_fluent_image_stack_20_regular:before {
    content: "\e918";
}
.icon-ic_fluent_image_table_20_filled:before {
    content: "\e919";
}
.icon-ic_fluent_image_table_20_regular:before {
    content: "\e91a";
}
.icon-ic_fluent_immersive_reader_20_filled:before {
    content: "\e91b";
}
.icon-ic_fluent_immersive_reader_20_regular:before {
    content: "\e91c";
}
.icon-ic_fluent_important_20_filled:before {
    content: "\e91d";
}
.icon-ic_fluent_important_20_regular:before {
    content: "\e91e";
}
.icon-ic_fluent_incognito_20_filled:before {
    content: "\e91f";
}
.icon-ic_fluent_incognito_20_regular:before {
    content: "\e920";
}
.icon-ic_fluent_info_20_filled:before {
    content: "\e921";
}
.icon-ic_fluent_info_20_regular:before {
    content: "\e922";
}
.icon-ic_fluent_info_shield_20_filled:before {
    content: "\e923";
}
.icon-ic_fluent_info_shield_20_regular:before {
    content: "\e924";
}
.icon-ic_fluent_ink_stroke_20_filled:before {
    content: "\e925";
}
.icon-ic_fluent_ink_stroke_20_regular:before {
    content: "\e926";
}
.icon-ic_fluent_ink_stroke_arrow_down_20_filled:before {
    content: "\e927";
}
.icon-ic_fluent_ink_stroke_arrow_down_20_regular:before {
    content: "\e928";
}
.icon-ic_fluent_ink_stroke_arrow_up_down_20_filled:before {
    content: "\e929";
}
.icon-ic_fluent_ink_stroke_arrow_up_down_20_regular:before {
    content: "\e92a";
}
.icon-ic_fluent_inking_tool_20_filled:before {
    content: "\e92b";
}
.icon-ic_fluent_inking_tool_20_regular:before {
    content: "\e92c";
}
.icon-ic_fluent_inking_tool_accent_20_filled:before {
    content: "\e92d";
}
.icon-ic_fluent_inprivate_account_20_filled:before {
    content: "\e92e";
}
.icon-ic_fluent_inprivate_account_20_regular:before {
    content: "\e92f";
}
.icon-ic_fluent_insert_20_filled:before {
    content: "\e930";
}
.icon-ic_fluent_insert_20_regular:before {
    content: "\e931";
}
.icon-ic_fluent_ios_chevron_right_20_filled:before {
    content: "\e932";
}
.icon-ic_fluent_ios_chevron_right_20_regular:before {
    content: "\e933";
}
.icon-ic_fluent_iot_20_filled:before {
    content: "\e934";
}
.icon-ic_fluent_iot_20_regular:before {
    content: "\e935";
}
.icon-ic_fluent_iot_alert_20_filled:before {
    content: "\e936";
}
.icon-ic_fluent_iot_alert_20_regular:before {
    content: "\e937";
}
.icon-ic_fluent_javascript_20_filled:before {
    content: "\e938";
}
.icon-ic_fluent_javascript_20_regular:before {
    content: "\e939";
}
.icon-ic_fluent_joystick_20_filled:before {
    content: "\e93a";
}
.icon-ic_fluent_joystick_20_regular:before {
    content: "\e93b";
}
.icon-ic_fluent_key_20_filled:before {
    content: "\e93c";
}
.icon-ic_fluent_key_20_regular:before {
    content: "\e93d";
}
.icon-ic_fluent_key_command_20_filled:before {
    content: "\e93e";
}
.icon-ic_fluent_key_command_20_regular:before {
    content: "\e93f";
}
.icon-ic_fluent_key_multiple_20_filled:before {
    content: "\e940";
}
.icon-ic_fluent_key_multiple_20_regular:before {
    content: "\e941";
}
.icon-ic_fluent_key_reset_20_filled:before {
    content: "\e942";
}
.icon-ic_fluent_key_reset_20_regular:before {
    content: "\e943";
}
.icon-ic_fluent_keyboard_123_20_filled:before {
    content: "\e944";
}
.icon-ic_fluent_keyboard_123_20_regular:before {
    content: "\e945";
}
.icon-ic_fluent_keyboard_20_filled:before {
    content: "\e946";
}
.icon-ic_fluent_keyboard_20_regular:before {
    content: "\e947";
}
.icon-ic_fluent_keyboard_dock_20_filled:before {
    content: "\e948";
}
.icon-ic_fluent_keyboard_dock_20_regular:before {
    content: "\e949";
}
.icon-ic_fluent_keyboard_layout_float_20_filled:before {
    content: "\e94a";
}
.icon-ic_fluent_keyboard_layout_float_20_regular:before {
    content: "\e94b";
}
.icon-ic_fluent_keyboard_layout_one_handed_left_20_filled:before {
    content: "\e94c";
}
.icon-ic_fluent_keyboard_layout_one_handed_left_20_regular:before {
    content: "\e94d";
}
.icon-ic_fluent_keyboard_layout_resize_20_filled:before {
    content: "\e94e";
}
.icon-ic_fluent_keyboard_layout_resize_20_regular:before {
    content: "\e94f";
}
.icon-ic_fluent_keyboard_layout_split_20_filled:before {
    content: "\e950";
}
.icon-ic_fluent_keyboard_layout_split_20_regular:before {
    content: "\e951";
}
.icon-ic_fluent_keyboard_shift_20_filled:before {
    content: "\e952";
}
.icon-ic_fluent_keyboard_shift_20_regular:before {
    content: "\e953";
}
.icon-ic_fluent_keyboard_shift_uppercase_20_filled:before {
    content: "\e954";
}
.icon-ic_fluent_keyboard_shift_uppercase_20_regular:before {
    content: "\e955";
}
.icon-ic_fluent_keyboard_tab_20_filled:before {
    content: "\e956";
}
.icon-ic_fluent_keyboard_tab_20_regular:before {
    content: "\e957";
}
.icon-ic_fluent_laptop_20_filled:before {
    content: "\e958";
}
.icon-ic_fluent_laptop_20_regular:before {
    content: "\e959";
}
.icon-ic_fluent_laptop_dismiss_20_filled:before {
    content: "\e95a";
}
.icon-ic_fluent_laptop_dismiss_20_regular:before {
    content: "\e95b";
}
.icon-ic_fluent_laptop_shield_20_filled:before {
    content: "\e95c";
}
.icon-ic_fluent_laptop_shield_20_regular:before {
    content: "\e95d";
}
.icon-ic_fluent_laser_tool_20_filled:before {
    content: "\e95e";
}
.icon-ic_fluent_laser_tool_20_regular:before {
    content: "\e95f";
}
.icon-ic_fluent_lasso_20_filled:before {
    content: "\e960";
}
.icon-ic_fluent_lasso_20_regular:before {
    content: "\e961";
}
.icon-ic_fluent_launcher_settings_20_filled:before {
    content: "\e962";
}
.icon-ic_fluent_launcher_settings_20_regular:before {
    content: "\e963";
}
.icon-ic_fluent_layer_20_filled:before {
    content: "\e964";
}
.icon-ic_fluent_layer_20_regular:before {
    content: "\e965";
}
.icon-ic_fluent_layer_diagonal_20_filled:before {
    content: "\e966";
}
.icon-ic_fluent_layer_diagonal_20_regular:before {
    content: "\e967";
}
.icon-ic_fluent_layer_diagonal_add_20_filled:before {
    content: "\e968";
}
.icon-ic_fluent_layer_diagonal_add_20_regular:before {
    content: "\e969";
}
.icon-ic_fluent_layer_diagonal_person_20_filled:before {
    content: "\e96a";
}
.icon-ic_fluent_layer_diagonal_person_20_regular:before {
    content: "\e96b";
}
.icon-ic_fluent_layout_cell_four_20_filled:before {
    content: "\e96c";
}
.icon-ic_fluent_layout_cell_four_20_regular:before {
    content: "\e96d";
}
.icon-ic_fluent_layout_cell_four_focus_bottom_left_20_filled:before {
    content: "\e96e";
}
.icon-ic_fluent_layout_cell_four_focus_bottom_right_20_filled:before {
    content: "\e96f";
}
.icon-ic_fluent_layout_cell_four_focus_top_left_20_filled:before {
    content: "\e970";
}
.icon-ic_fluent_layout_cell_four_focus_top_right_20_filled:before {
    content: "\e971";
}
.icon-ic_fluent_layout_column_four_20_filled:before {
    content: "\e972";
}
.icon-ic_fluent_layout_column_four_20_regular:before {
    content: "\e973";
}
.icon-ic_fluent_layout_column_four_focus_center_left_20_filled:before {
    content: "\e974";
}
.icon-ic_fluent_layout_column_four_focus_center_right_20_filled:before {
    content: "\e975";
}
.icon-ic_fluent_layout_column_four_focus_left_20_filled:before {
    content: "\e976";
}
.icon-ic_fluent_layout_column_four_focus_right_20_filled:before {
    content: "\e977";
}
.icon-ic_fluent_layout_column_one_third_left_20_filled:before {
    content: "\e978";
}
.icon-ic_fluent_layout_column_one_third_left_20_regular:before {
    content: "\e979";
}
.icon-ic_fluent_layout_column_one_third_right_20_filled:before {
    content: "\e97a";
}
.icon-ic_fluent_layout_column_one_third_right_20_regular:before {
    content: "\e97b";
}
.icon-ic_fluent_layout_column_one_third_right_hint_20_filled:before {
    content: "\e97c";
}
.icon-ic_fluent_layout_column_one_third_right_hint_20_regular:before {
    content: "\e97d";
}
.icon-ic_fluent_layout_column_three_20_filled:before {
    content: "\e97e";
}
.icon-ic_fluent_layout_column_three_20_regular:before {
    content: "\e97f";
}
.icon-ic_fluent_layout_column_three_focus_center_20_filled:before {
    content: "\e980";
}
.icon-ic_fluent_layout_column_three_focus_left_20_filled:before {
    content: "\e981";
}
.icon-ic_fluent_layout_column_three_focus_right_20_filled:before {
    content: "\e982";
}
.icon-ic_fluent_layout_column_two_20_filled:before {
    content: "\e983";
}
.icon-ic_fluent_layout_column_two_20_regular:before {
    content: "\e984";
}
.icon-ic_fluent_layout_column_two_focus_left_20_filled:before {
    content: "\e985";
}
.icon-ic_fluent_layout_column_two_focus_right_20_filled:before {
    content: "\e986";
}
.icon-ic_fluent_layout_column_two_split_left_20_filled:before {
    content: "\e987";
}
.icon-ic_fluent_layout_column_two_split_left_20_regular:before {
    content: "\e988";
}
.icon-ic_fluent_layout_column_two_split_left_focus_bottom_left_20_filled:before {
    content: "\e989";
}
.icon-ic_fluent_layout_column_two_split_left_focus_right_20_filled:before {
    content: "\e98a";
}
.icon-ic_fluent_layout_column_two_split_left_focus_top_left_20_filled:before {
    content: "\e98b";
}
.icon-ic_fluent_layout_column_two_split_right_20_filled:before {
    content: "\e98c";
}
.icon-ic_fluent_layout_column_two_split_right_20_regular:before {
    content: "\e98d";
}
.icon-ic_fluent_layout_column_two_split_right_focus_bottom_right_20_filled:before {
    content: "\e98e";
}
.icon-ic_fluent_layout_column_two_split_right_focus_left_20_filled:before {
    content: "\e98f";
}
.icon-ic_fluent_layout_column_two_split_right_focus_top_right_20_filled:before {
    content: "\e990";
}
.icon-ic_fluent_layout_row_four_20_filled:before {
    content: "\e991";
}
.icon-ic_fluent_layout_row_four_20_regular:before {
    content: "\e992";
}
.icon-ic_fluent_layout_row_four_focus_bottom_20_filled:before {
    content: "\e993";
}
.icon-ic_fluent_layout_row_four_focus_center_bottom_20_filled:before {
    content: "\e994";
}
.icon-ic_fluent_layout_row_four_focus_center_top_20_filled:before {
    content: "\e995";
}
.icon-ic_fluent_layout_row_four_focus_top_20_filled:before {
    content: "\e996";
}
.icon-ic_fluent_layout_row_three_20_filled:before {
    content: "\e997";
}
.icon-ic_fluent_layout_row_three_20_regular:before {
    content: "\e998";
}
.icon-ic_fluent_layout_row_three_focus_bottom_20_filled:before {
    content: "\e999";
}
.icon-ic_fluent_layout_row_three_focus_center_20_filled:before {
    content: "\e99a";
}
.icon-ic_fluent_layout_row_three_focus_top_20_filled:before {
    content: "\e99b";
}
.icon-ic_fluent_layout_row_two_20_filled:before {
    content: "\e99c";
}
.icon-ic_fluent_layout_row_two_20_regular:before {
    content: "\e99d";
}
.icon-ic_fluent_layout_row_two_focus_bottom_20_filled:before {
    content: "\e99e";
}
.icon-ic_fluent_layout_row_two_focus_top_20_filled:before {
    content: "\e99f";
}
.icon-ic_fluent_layout_row_two_split_bottom_20_filled:before {
    content: "\e9a0";
}
.icon-ic_fluent_layout_row_two_split_bottom_20_regular:before {
    content: "\e9a1";
}
.icon-ic_fluent_layout_row_two_split_bottom_focus_bottom_left_20_filled:before {
    content: "\e9a2";
}
.icon-ic_fluent_layout_row_two_split_bottom_focus_bottom_right_20_filled:before {
    content: "\e9a3";
}
.icon-ic_fluent_layout_row_two_split_bottom_focus_top_20_filled:before {
    content: "\e9a4";
}
.icon-ic_fluent_layout_row_two_split_top_20_filled:before {
    content: "\e9a5";
}
.icon-ic_fluent_layout_row_two_split_top_20_regular:before {
    content: "\e9a6";
}
.icon-ic_fluent_layout_row_two_split_top_focus_bottom_20_filled:before {
    content: "\e9a7";
}
.icon-ic_fluent_layout_row_two_split_top_focus_top_left_20_filled:before {
    content: "\e9a8";
}
.icon-ic_fluent_layout_row_two_split_top_focus_top_right_20_filled:before {
    content: "\e9a9";
}
.icon-ic_fluent_leaf_one_20_filled:before {
    content: "\e9aa";
}
.icon-ic_fluent_leaf_one_20_regular:before {
    content: "\e9ab";
}
.icon-ic_fluent_leaf_three_20_filled:before {
    content: "\e9ac";
}
.icon-ic_fluent_leaf_three_20_regular:before {
    content: "\e9ad";
}
.icon-ic_fluent_leaf_two_20_filled:before {
    content: "\e9ae";
}
.icon-ic_fluent_leaf_two_20_regular:before {
    content: "\e9af";
}
.icon-ic_fluent_learning_app_20_filled:before {
    content: "\e9b0";
}
.icon-ic_fluent_learning_app_20_regular:before {
    content: "\e9b1";
}
.icon-ic_fluent_library_20_filled:before {
    content: "\e9b2";
}
.icon-ic_fluent_library_20_regular:before {
    content: "\e9b3";
}
.icon-ic_fluent_lightbulb_20_filled:before {
    content: "\e9b4";
}
.icon-ic_fluent_lightbulb_20_regular:before {
    content: "\e9b5";
}
.icon-ic_fluent_lightbulb_checkmark_20_filled:before {
    content: "\e9b6";
}
.icon-ic_fluent_lightbulb_checkmark_20_regular:before {
    content: "\e9b7";
}
.icon-ic_fluent_lightbulb_circle_20_filled:before {
    content: "\e9b8";
}
.icon-ic_fluent_lightbulb_circle_20_regular:before {
    content: "\e9b9";
}
.icon-ic_fluent_lightbulb_filament_20_filled:before {
    content: "\e9ba";
}
.icon-ic_fluent_lightbulb_filament_20_regular:before {
    content: "\e9bb";
}
.icon-ic_fluent_lightbulb_person_20_filled:before {
    content: "\e9bc";
}
.icon-ic_fluent_lightbulb_person_20_regular:before {
    content: "\e9bd";
}
.icon-ic_fluent_likert_20_filled:before {
    content: "\e9be";
}
.icon-ic_fluent_likert_20_regular:before {
    content: "\e9bf";
}
.icon-ic_fluent_line_20_filled:before {
    content: "\e9c0";
}
.icon-ic_fluent_line_20_regular:before {
    content: "\e9c1";
}
.icon-ic_fluent_line_dashes_20_filled:before {
    content: "\e9c2";
}
.icon-ic_fluent_line_dashes_20_regular:before {
    content: "\e9c3";
}
.icon-ic_fluent_line_horizontal_1_20_filled:before {
    content: "\e9c4";
}
.icon-ic_fluent_line_horizontal_1_20_regular:before {
    content: "\e9c5";
}
.icon-ic_fluent_line_horizontal_1_dashes_20_filled:before {
    content: "\e9c6";
}
.icon-ic_fluent_line_horizontal_1_dashes_20_regular:before {
    content: "\e9c7";
}
.icon-ic_fluent_line_horizontal_2_dashes_solid_20_filled:before {
    content: "\e9c8";
}
.icon-ic_fluent_line_horizontal_2_dashes_solid_20_regular:before {
    content: "\e9c9";
}
.icon-ic_fluent_line_horizontal_3_20_filled:before {
    content: "\e9ca";
}
.icon-ic_fluent_line_horizontal_3_20_regular:before {
    content: "\e9cb";
}
.icon-ic_fluent_line_horizontal_4_20_filled:before {
    content: "\e9cc";
}
.icon-ic_fluent_line_horizontal_4_20_regular:before {
    content: "\e9cd";
}
.icon-ic_fluent_line_horizontal_4_search_20_filled:before {
    content: "\e9ce";
}
.icon-ic_fluent_line_horizontal_4_search_20_regular:before {
    content: "\e9cf";
}
.icon-ic_fluent_line_horizontal_5_20_filled:before {
    content: "\e9d0";
}
.icon-ic_fluent_line_horizontal_5_20_regular:before {
    content: "\e9d1";
}
.icon-ic_fluent_line_horizontal_5_error_20_filled:before {
    content: "\e9d2";
}
.icon-ic_fluent_line_horizontal_5_error_20_regular:before {
    content: "\e9d3";
}
.icon-ic_fluent_line_style_20_filled:before {
    content: "\e9d4";
}
.icon-ic_fluent_line_style_20_regular:before {
    content: "\e9d5";
}
.icon-ic_fluent_line_thickness_20_filled:before {
    content: "\e9d6";
}
.icon-ic_fluent_line_thickness_20_regular:before {
    content: "\e9d7";
}
.icon-ic_fluent_link_20_filled:before {
    content: "\e9d8";
}
.icon-ic_fluent_link_20_regular:before {
    content: "\e9d9";
}
.icon-ic_fluent_link_add_20_filled:before {
    content: "\e9da";
}
.icon-ic_fluent_link_add_20_regular:before {
    content: "\e9db";
}
.icon-ic_fluent_link_dismiss_20_filled:before {
    content: "\e9dc";
}
.icon-ic_fluent_link_dismiss_20_regular:before {
    content: "\e9dd";
}
.icon-ic_fluent_link_edit_20_filled:before {
    content: "\e9de";
}
.icon-ic_fluent_link_edit_20_regular:before {
    content: "\e9df";
}
.icon-ic_fluent_link_multiple_20_filled:before {
    content: "\e9e0";
}
.icon-ic_fluent_link_multiple_20_regular:before {
    content: "\e9e1";
}
.icon-ic_fluent_link_person_20_filled:before {
    content: "\e9e2";
}
.icon-ic_fluent_link_person_20_regular:before {
    content: "\e9e3";
}
.icon-ic_fluent_link_square_20_filled:before {
    content: "\e9e4";
}
.icon-ic_fluent_link_square_20_regular:before {
    content: "\e9e5";
}
.icon-ic_fluent_link_toolbox_20_filled:before {
    content: "\e9e6";
}
.icon-ic_fluent_link_toolbox_20_regular:before {
    content: "\e9e7";
}
.icon-ic_fluent_list_20_filled:before {
    content: "\e9e8";
}
.icon-ic_fluent_list_20_regular:before {
    content: "\e9e9";
}
.icon-ic_fluent_list_bar_20_filled:before {
    content: "\e9ea";
}
.icon-ic_fluent_list_bar_20_regular:before {
    content: "\e9eb";
}
.icon-ic_fluent_list_bar_tree_20_filled:before {
    content: "\e9ec";
}
.icon-ic_fluent_list_bar_tree_20_regular:before {
    content: "\e9ed";
}
.icon-ic_fluent_list_bar_tree_offset_20_filled:before {
    content: "\e9ee";
}
.icon-ic_fluent_list_bar_tree_offset_20_regular:before {
    content: "\e9ef";
}
.icon-ic_fluent_list_rtl_20_filled:before {
    content: "\e9f0";
}
.icon-ic_fluent_list_rtl_20_regular:before {
    content: "\e9f1";
}
.icon-ic_fluent_live_20_filled:before {
    content: "\e9f2";
}
.icon-ic_fluent_live_20_regular:before {
    content: "\e9f3";
}
.icon-ic_fluent_live_off_20_filled:before {
    content: "\e9f4";
}
.icon-ic_fluent_live_off_20_regular:before {
    content: "\e9f5";
}
.icon-ic_fluent_local_language_20_filled:before {
    content: "\e9f6";
}
.icon-ic_fluent_local_language_20_regular:before {
    content: "\e9f7";
}
.icon-ic_fluent_location_20_filled:before {
    content: "\e9f8";
}
.icon-ic_fluent_location_20_regular:before {
    content: "\e9f9";
}
.icon-ic_fluent_location_add_20_filled:before {
    content: "\e9fa";
}
.icon-ic_fluent_location_add_20_regular:before {
    content: "\e9fb";
}
.icon-ic_fluent_location_add_left_20_filled:before {
    content: "\e9fc";
}
.icon-ic_fluent_location_add_left_20_regular:before {
    content: "\e9fd";
}
.icon-ic_fluent_location_add_right_20_filled:before {
    content: "\e9fe";
}
.icon-ic_fluent_location_add_right_20_regular:before {
    content: "\e9ff";
}
.icon-ic_fluent_location_add_up_20_filled:before {
    content: "\ea00";
}
.icon-ic_fluent_location_add_up_20_regular:before {
    content: "\ea01";
}
.icon-ic_fluent_location_arrow_20_filled:before {
    content: "\ea02";
}
.icon-ic_fluent_location_arrow_20_regular:before {
    content: "\ea03";
}
.icon-ic_fluent_location_arrow_left_20_filled:before {
    content: "\ea04";
}
.icon-ic_fluent_location_arrow_left_20_regular:before {
    content: "\ea05";
}
.icon-ic_fluent_location_arrow_right_20_filled:before {
    content: "\ea06";
}
.icon-ic_fluent_location_arrow_right_20_regular:before {
    content: "\ea07";
}
.icon-ic_fluent_location_arrow_up_20_filled:before {
    content: "\ea08";
}
.icon-ic_fluent_location_arrow_up_20_regular:before {
    content: "\ea09";
}
.icon-ic_fluent_location_dismiss_20_filled:before {
    content: "\ea0a";
}
.icon-ic_fluent_location_dismiss_20_regular:before {
    content: "\ea0b";
}
.icon-ic_fluent_location_live_20_filled:before {
    content: "\ea0c";
}
.icon-ic_fluent_location_live_20_regular:before {
    content: "\ea0d";
}
.icon-ic_fluent_location_off_20_filled:before {
    content: "\ea0e";
}
.icon-ic_fluent_location_off_20_regular:before {
    content: "\ea0f";
}
.icon-ic_fluent_location_target_square_20_filled:before {
    content: "\ea10";
}
.icon-ic_fluent_location_target_square_20_regular:before {
    content: "\ea11";
}
.icon-ic_fluent_lock_closed_20_filled:before {
    content: "\ea12";
}
.icon-ic_fluent_lock_closed_20_regular:before {
    content: "\ea13";
}
.icon-ic_fluent_lock_closed_key_20_filled:before {
    content: "\ea14";
}
.icon-ic_fluent_lock_closed_key_20_regular:before {
    content: "\ea15";
}
.icon-ic_fluent_lock_multiple_20_filled:before {
    content: "\ea16";
}
.icon-ic_fluent_lock_multiple_20_regular:before {
    content: "\ea17";
}
.icon-ic_fluent_lock_open_20_filled:before {
    content: "\ea18";
}
.icon-ic_fluent_lock_open_20_regular:before {
    content: "\ea19";
}
.icon-ic_fluent_lock_shield_20_filled:before {
    content: "\ea1a";
}
.icon-ic_fluent_lock_shield_20_regular:before {
    content: "\ea1b";
}
.icon-ic_fluent_lottery_20_filled:before {
    content: "\ea1c";
}
.icon-ic_fluent_lottery_20_regular:before {
    content: "\ea1d";
}
.icon-ic_fluent_luggage_20_filled:before {
    content: "\ea1e";
}
.icon-ic_fluent_luggage_20_regular:before {
    content: "\ea1f";
}
.icon-ic_fluent_mail_20_filled:before {
    content: "\ea20";
}
.icon-ic_fluent_mail_20_regular:before {
    content: "\ea21";
}
.icon-ic_fluent_mail_add_20_filled:before {
    content: "\ea22";
}
.icon-ic_fluent_mail_add_20_regular:before {
    content: "\ea23";
}
.icon-ic_fluent_mail_alert_20_filled:before {
    content: "\ea24";
}
.icon-ic_fluent_mail_alert_20_regular:before {
    content: "\ea25";
}
.icon-ic_fluent_mail_all_read_20_filled:before {
    content: "\ea26";
}
.icon-ic_fluent_mail_all_read_20_regular:before {
    content: "\ea27";
}
.icon-ic_fluent_mail_all_unread_20_filled:before {
    content: "\ea28";
}
.icon-ic_fluent_mail_all_unread_20_regular:before {
    content: "\ea29";
}
.icon-ic_fluent_mail_arrow_double_back_20_filled:before {
    content: "\ea2a";
}
.icon-ic_fluent_mail_arrow_double_back_20_regular:before {
    content: "\ea2b";
}
.icon-ic_fluent_mail_arrow_down_20_filled:before {
    content: "\ea2c";
}
.icon-ic_fluent_mail_arrow_down_20_regular:before {
    content: "\ea2d";
}
.icon-ic_fluent_mail_arrow_forward_20_filled:before {
    content: "\ea2e";
}
.icon-ic_fluent_mail_arrow_forward_20_regular:before {
    content: "\ea2f";
}
.icon-ic_fluent_mail_arrow_up_20_filled:before {
    content: "\ea30";
}
.icon-ic_fluent_mail_arrow_up_20_regular:before {
    content: "\ea31";
}
.icon-ic_fluent_mail_attach_20_filled:before {
    content: "\ea32";
}
.icon-ic_fluent_mail_attach_20_regular:before {
    content: "\ea33";
}
.icon-ic_fluent_mail_checkmark_20_filled:before {
    content: "\ea34";
}
.icon-ic_fluent_mail_checkmark_20_regular:before {
    content: "\ea35";
}
.icon-ic_fluent_mail_clock_20_filled:before {
    content: "\ea36";
}
.icon-ic_fluent_mail_clock_20_regular:before {
    content: "\ea37";
}
.icon-ic_fluent_mail_copy_20_filled:before {
    content: "\ea38";
}
.icon-ic_fluent_mail_copy_20_regular:before {
    content: "\ea39";
}
.icon-ic_fluent_mail_dismiss_20_filled:before {
    content: "\ea3a";
}
.icon-ic_fluent_mail_dismiss_20_regular:before {
    content: "\ea3b";
}
.icon-ic_fluent_mail_edit_20_filled:before {
    content: "\ea3c";
}
.icon-ic_fluent_mail_edit_20_regular:before {
    content: "\ea3d";
}
.icon-ic_fluent_mail_error_20_filled:before {
    content: "\ea3e";
}
.icon-ic_fluent_mail_error_20_regular:before {
    content: "\ea3f";
}
.icon-ic_fluent_mail_inbox_20_filled:before {
    content: "\ea40";
}
.icon-ic_fluent_mail_inbox_20_regular:before {
    content: "\ea41";
}
.icon-ic_fluent_mail_inbox_add_20_filled:before {
    content: "\ea42";
}
.icon-ic_fluent_mail_inbox_add_20_regular:before {
    content: "\ea43";
}
.icon-ic_fluent_mail_inbox_all_20_filled:before {
    content: "\ea44";
}
.icon-ic_fluent_mail_inbox_all_20_regular:before {
    content: "\ea45";
}
.icon-ic_fluent_mail_inbox_arrow_down_20_filled:before {
    content: "\ea46";
}
.icon-ic_fluent_mail_inbox_arrow_down_20_regular:before {
    content: "\ea47";
}
.icon-ic_fluent_mail_inbox_arrow_right_20_filled:before {
    content: "\ea48";
}
.icon-ic_fluent_mail_inbox_arrow_right_20_regular:before {
    content: "\ea49";
}
.icon-ic_fluent_mail_inbox_arrow_up_20_filled:before {
    content: "\ea4a";
}
.icon-ic_fluent_mail_inbox_arrow_up_20_regular:before {
    content: "\ea4b";
}
.icon-ic_fluent_mail_inbox_checkmark_20_filled:before {
    content: "\ea4c";
}
.icon-ic_fluent_mail_inbox_checkmark_20_regular:before {
    content: "\ea4d";
}
.icon-ic_fluent_mail_inbox_dismiss_20_filled:before {
    content: "\ea4e";
}
.icon-ic_fluent_mail_inbox_dismiss_20_regular:before {
    content: "\ea4f";
}
.icon-ic_fluent_mail_link_20_filled:before {
    content: "\ea50";
}
.icon-ic_fluent_mail_link_20_regular:before {
    content: "\ea51";
}
.icon-ic_fluent_mail_list_20_filled:before {
    content: "\ea52";
}
.icon-ic_fluent_mail_list_20_regular:before {
    content: "\ea53";
}
.icon-ic_fluent_mail_multiple_20_filled:before {
    content: "\ea54";
}
.icon-ic_fluent_mail_multiple_20_regular:before {
    content: "\ea55";
}
.icon-ic_fluent_mail_off_20_filled:before {
    content: "\ea56";
}
.icon-ic_fluent_mail_off_20_regular:before {
    content: "\ea57";
}
.icon-ic_fluent_mail_open_person_20_filled:before {
    content: "\ea58";
}
.icon-ic_fluent_mail_open_person_20_regular:before {
    content: "\ea59";
}
.icon-ic_fluent_mail_pause_20_filled:before {
    content: "\ea5a";
}
.icon-ic_fluent_mail_pause_20_regular:before {
    content: "\ea5b";
}
.icon-ic_fluent_mail_prohibited_20_filled:before {
    content: "\ea5c";
}
.icon-ic_fluent_mail_prohibited_20_regular:before {
    content: "\ea5d";
}
.icon-ic_fluent_mail_read_20_filled:before {
    content: "\ea5e";
}
.icon-ic_fluent_mail_read_20_regular:before {
    content: "\ea5f";
}
.icon-ic_fluent_mail_read_multiple_20_filled:before {
    content: "\ea60";
}
.icon-ic_fluent_mail_read_multiple_20_regular:before {
    content: "\ea61";
}
.icon-ic_fluent_mail_rewind_20_filled:before {
    content: "\ea62";
}
.icon-ic_fluent_mail_rewind_20_regular:before {
    content: "\ea63";
}
.icon-ic_fluent_mail_settings_20_filled:before {
    content: "\ea64";
}
.icon-ic_fluent_mail_settings_20_regular:before {
    content: "\ea65";
}
.icon-ic_fluent_mail_shield_20_filled:before {
    content: "\ea66";
}
.icon-ic_fluent_mail_shield_20_regular:before {
    content: "\ea67";
}
.icon-ic_fluent_mail_template_20_filled:before {
    content: "\ea68";
}
.icon-ic_fluent_mail_template_20_regular:before {
    content: "\ea69";
}
.icon-ic_fluent_mail_unread_20_filled:before {
    content: "\ea6a";
}
.icon-ic_fluent_mail_unread_20_regular:before {
    content: "\ea6b";
}
.icon-ic_fluent_mail_warning_20_filled:before {
    content: "\ea6c";
}
.icon-ic_fluent_mail_warning_20_regular:before {
    content: "\ea6d";
}
.icon-ic_fluent_mailbox_20_filled:before {
    content: "\ea6e";
}
.icon-ic_fluent_mailbox_20_regular:before {
    content: "\ea6f";
}
.icon-ic_fluent_map_20_filled:before {
    content: "\ea70";
}
.icon-ic_fluent_map_20_regular:before {
    content: "\ea71";
}
.icon-ic_fluent_map_drive_20_filled:before {
    content: "\ea72";
}
.icon-ic_fluent_map_drive_20_regular:before {
    content: "\ea73";
}
.icon-ic_fluent_markdown_20_filled:before {
    content: "\ea74";
}
.icon-ic_fluent_markdown_20_regular:before {
    content: "\ea75";
}
.icon-ic_fluent_match_app_layout_20_filled:before {
    content: "\ea76";
}
.icon-ic_fluent_match_app_layout_20_regular:before {
    content: "\ea77";
}
.icon-ic_fluent_math_format_linear_20_filled:before {
    content: "\ea78";
}
.icon-ic_fluent_math_format_linear_20_regular:before {
    content: "\ea79";
}
.icon-ic_fluent_math_format_professional_20_filled:before {
    content: "\ea7a";
}
.icon-ic_fluent_math_format_professional_20_regular:before {
    content: "\ea7b";
}
.icon-ic_fluent_math_formula_20_filled:before {
    content: "\ea7c";
}
.icon-ic_fluent_math_formula_20_regular:before {
    content: "\ea7d";
}
.icon-ic_fluent_math_symbols_20_filled:before {
    content: "\ea7e";
}
.icon-ic_fluent_math_symbols_20_regular:before {
    content: "\ea7f";
}
.icon-ic_fluent_maximize_20_filled:before {
    content: "\ea80";
}
.icon-ic_fluent_maximize_20_regular:before {
    content: "\ea81";
}
.icon-ic_fluent_meet_now_20_filled:before {
    content: "\ea82";
}
.icon-ic_fluent_meet_now_20_regular:before {
    content: "\ea83";
}
.icon-ic_fluent_megaphone_20_filled:before {
    content: "\ea84";
}
.icon-ic_fluent_megaphone_20_regular:before {
    content: "\ea85";
}
.icon-ic_fluent_megaphone_circle_20_filled:before {
    content: "\ea86";
}
.icon-ic_fluent_megaphone_circle_20_regular:before {
    content: "\ea87";
}
.icon-ic_fluent_megaphone_loud_20_filled:before {
    content: "\ea88";
}
.icon-ic_fluent_megaphone_loud_20_regular:before {
    content: "\ea89";
}
.icon-ic_fluent_megaphone_off_20_filled:before {
    content: "\ea8a";
}
.icon-ic_fluent_megaphone_off_20_regular:before {
    content: "\ea8b";
}
.icon-ic_fluent_mention_20_filled:before {
    content: "\ea8c";
}
.icon-ic_fluent_mention_20_regular:before {
    content: "\ea8d";
}
.icon-ic_fluent_mention_arrow_down_20_filled:before {
    content: "\ea8e";
}
.icon-ic_fluent_mention_arrow_down_20_regular:before {
    content: "\ea8f";
}
.icon-ic_fluent_mention_brackets_20_filled:before {
    content: "\ea90";
}
.icon-ic_fluent_mention_brackets_20_regular:before {
    content: "\ea91";
}
.icon-ic_fluent_merge_20_filled:before {
    content: "\ea92";
}
.icon-ic_fluent_merge_20_regular:before {
    content: "\ea93";
}
.icon-ic_fluent_mic_20_filled:before {
    content: "\ea94";
}
.icon-ic_fluent_mic_20_regular:before {
    content: "\ea95";
}
.icon-ic_fluent_mic_off_20_filled:before {
    content: "\ea96";
}
.icon-ic_fluent_mic_off_20_regular:before {
    content: "\ea97";
}
.icon-ic_fluent_mic_prohibited_20_filled:before {
    content: "\ea98";
}
.icon-ic_fluent_mic_prohibited_20_regular:before {
    content: "\ea99";
}
.icon-ic_fluent_mic_pulse_20_filled:before {
    content: "\ea9a";
}
.icon-ic_fluent_mic_pulse_20_regular:before {
    content: "\ea9b";
}
.icon-ic_fluent_mic_pulse_off_20_filled:before {
    content: "\ea9c";
}
.icon-ic_fluent_mic_pulse_off_20_regular:before {
    content: "\ea9d";
}
.icon-ic_fluent_mic_record_20_filled:before {
    content: "\ea9e";
}
.icon-ic_fluent_mic_record_20_regular:before {
    content: "\ea9f";
}
.icon-ic_fluent_mic_settings_20_filled:before {
    content: "\eaa0";
}
.icon-ic_fluent_mic_settings_20_regular:before {
    content: "\eaa1";
}
.icon-ic_fluent_mic_sparkle_20_filled:before {
    content: "\eaa2";
}
.icon-ic_fluent_mic_sparkle_20_regular:before {
    content: "\eaa3";
}
.icon-ic_fluent_mic_sync_20_filled:before {
    content: "\eaa4";
}
.icon-ic_fluent_mic_sync_20_regular:before {
    content: "\eaa5";
}
.icon-ic_fluent_microscope_20_filled:before {
    content: "\eaa6";
}
.icon-ic_fluent_microscope_20_regular:before {
    content: "\eaa7";
}
.icon-ic_fluent_midi_20_filled:before {
    content: "\eaa8";
}
.icon-ic_fluent_midi_20_regular:before {
    content: "\eaa9";
}
.icon-ic_fluent_mobile_optimized_20_filled:before {
    content: "\eaaa";
}
.icon-ic_fluent_mobile_optimized_20_regular:before {
    content: "\eaab";
}
.icon-ic_fluent_mold_20_filled:before {
    content: "\eaac";
}
.icon-ic_fluent_mold_20_regular:before {
    content: "\eaad";
}
.icon-ic_fluent_molecule_20_filled:before {
    content: "\eaae";
}
.icon-ic_fluent_molecule_20_regular:before {
    content: "\eaaf";
}
.icon-ic_fluent_money_20_filled:before {
    content: "\eab0";
}
.icon-ic_fluent_money_20_regular:before {
    content: "\eab1";
}
.icon-ic_fluent_money_calculator_20_filled:before {
    content: "\eab2";
}
.icon-ic_fluent_money_calculator_20_regular:before {
    content: "\eab3";
}
.icon-ic_fluent_money_dismiss_20_filled:before {
    content: "\eab4";
}
.icon-ic_fluent_money_dismiss_20_regular:before {
    content: "\eab5";
}
.icon-ic_fluent_money_hand_20_filled:before {
    content: "\eab6";
}
.icon-ic_fluent_money_hand_20_regular:before {
    content: "\eab7";
}
.icon-ic_fluent_money_off_20_filled:before {
    content: "\eab8";
}
.icon-ic_fluent_money_off_20_regular:before {
    content: "\eab9";
}
.icon-ic_fluent_money_settings_20_filled:before {
    content: "\eaba";
}
.icon-ic_fluent_money_settings_20_regular:before {
    content: "\eabb";
}
.icon-ic_fluent_more_circle_20_filled:before {
    content: "\eabc";
}
.icon-ic_fluent_more_circle_20_regular:before {
    content: "\eabd";
}
.icon-ic_fluent_more_horizontal_20_filled:before {
    content: "\eabe";
}
.icon-ic_fluent_more_horizontal_20_regular:before {
    content: "\eabf";
}
.icon-ic_fluent_more_vertical_20_filled:before {
    content: "\eac0";
}
.icon-ic_fluent_more_vertical_20_regular:before {
    content: "\eac1";
}
.icon-ic_fluent_mountain_location_bottom_20_filled:before {
    content: "\eac2";
}
.icon-ic_fluent_mountain_location_bottom_20_regular:before {
    content: "\eac3";
}
.icon-ic_fluent_mountain_location_top_20_filled:before {
    content: "\eac4";
}
.icon-ic_fluent_mountain_location_top_20_regular:before {
    content: "\eac5";
}
.icon-ic_fluent_mountain_trail_20_filled:before {
    content: "\eac6";
}
.icon-ic_fluent_mountain_trail_20_regular:before {
    content: "\eac7";
}
.icon-ic_fluent_movies_and_tv_20_filled:before {
    content: "\eac8";
}
.icon-ic_fluent_movies_and_tv_20_regular:before {
    content: "\eac9";
}
.icon-ic_fluent_multiplier_1_2x_20_filled:before {
    content: "\eaca";
}
.icon-ic_fluent_multiplier_1_2x_20_regular:before {
    content: "\eacb";
}
.icon-ic_fluent_multiplier_1_5x_20_filled:before {
    content: "\eacc";
}
.icon-ic_fluent_multiplier_1_5x_20_regular:before {
    content: "\eacd";
}
.icon-ic_fluent_multiplier_1_8x_20_filled:before {
    content: "\eace";
}
.icon-ic_fluent_multiplier_1_8x_20_regular:before {
    content: "\eacf";
}
.icon-ic_fluent_multiplier_1x_20_filled:before {
    content: "\ead0";
}
.icon-ic_fluent_multiplier_1x_20_regular:before {
    content: "\ead1";
}
.icon-ic_fluent_multiplier_2x_20_filled:before {
    content: "\ead2";
}
.icon-ic_fluent_multiplier_2x_20_regular:before {
    content: "\ead3";
}
.icon-ic_fluent_multiplier_5x_20_filled:before {
    content: "\ead4";
}
.icon-ic_fluent_multiplier_5x_20_regular:before {
    content: "\ead5";
}
.icon-ic_fluent_multiselect_ltr_20_filled:before {
    content: "\ead6";
}
.icon-ic_fluent_multiselect_ltr_20_regular:before {
    content: "\ead7";
}
.icon-ic_fluent_multiselect_rtl_20_filled:before {
    content: "\ead8";
}
.icon-ic_fluent_multiselect_rtl_20_regular:before {
    content: "\ead9";
}
.icon-ic_fluent_music_note_1_20_filled:before {
    content: "\eada";
}
.icon-ic_fluent_music_note_1_20_regular:before {
    content: "\eadb";
}
.icon-ic_fluent_music_note_2_20_filled:before {
    content: "\eadc";
}
.icon-ic_fluent_music_note_2_20_regular:before {
    content: "\eadd";
}
.icon-ic_fluent_music_note_2_play_20_filled:before {
    content: "\eade";
}
.icon-ic_fluent_music_note_2_play_20_regular:before {
    content: "\eadf";
}
.icon-ic_fluent_music_note_off_1_20_filled:before {
    content: "\eae0";
}
.icon-ic_fluent_music_note_off_1_20_regular:before {
    content: "\eae1";
}
.icon-ic_fluent_music_note_off_2_20_filled:before {
    content: "\eae2";
}
.icon-ic_fluent_music_note_off_2_20_regular:before {
    content: "\eae3";
}
.icon-ic_fluent_my_location_20_filled:before {
    content: "\eae4";
}
.icon-ic_fluent_my_location_20_regular:before {
    content: "\eae5";
}
.icon-ic_fluent_navigation_20_filled:before {
    content: "\eae6";
}
.icon-ic_fluent_navigation_20_regular:before {
    content: "\eae7";
}
.icon-ic_fluent_navigation_location_target_20_filled:before {
    content: "\eae8";
}
.icon-ic_fluent_navigation_location_target_20_regular:before {
    content: "\eae9";
}
.icon-ic_fluent_navigation_play_20_filled:before {
    content: "\eaea";
}
.icon-ic_fluent_navigation_play_20_regular:before {
    content: "\eaeb";
}
.icon-ic_fluent_navigation_unread_20_filled:before {
    content: "\eaec";
}
.icon-ic_fluent_navigation_unread_20_regular:before {
    content: "\eaed";
}
.icon-ic_fluent_network_check_20_filled:before {
    content: "\eaee";
}
.icon-ic_fluent_network_check_20_regular:before {
    content: "\eaef";
}
.icon-ic_fluent_new_20_filled:before {
    content: "\eaf0";
}
.icon-ic_fluent_new_20_regular:before {
    content: "\eaf1";
}
.icon-ic_fluent_news_20_filled:before {
    content: "\eaf2";
}
.icon-ic_fluent_news_20_regular:before {
    content: "\eaf3";
}
.icon-ic_fluent_next_20_filled:before {
    content: "\eaf4";
}
.icon-ic_fluent_next_20_regular:before {
    content: "\eaf5";
}
.icon-ic_fluent_next_frame_20_filled:before {
    content: "\eaf6";
}
.icon-ic_fluent_next_frame_20_regular:before {
    content: "\eaf7";
}
.icon-ic_fluent_note_20_filled:before {
    content: "\eaf8";
}
.icon-ic_fluent_note_20_regular:before {
    content: "\eaf9";
}
.icon-ic_fluent_note_add_20_filled:before {
    content: "\eafa";
}
.icon-ic_fluent_note_add_20_regular:before {
    content: "\eafb";
}
.icon-ic_fluent_note_edit_20_filled:before {
    content: "\eafc";
}
.icon-ic_fluent_note_edit_20_regular:before {
    content: "\eafd";
}
.icon-ic_fluent_note_pin_20_filled:before {
    content: "\eafe";
}
.icon-ic_fluent_note_pin_20_regular:before {
    content: "\eaff";
}
.icon-ic_fluent_notebook_20_filled:before {
    content: "\eb00";
}
.icon-ic_fluent_notebook_20_regular:before {
    content: "\eb01";
}
.icon-ic_fluent_notebook_add_20_filled:before {
    content: "\eb02";
}
.icon-ic_fluent_notebook_add_20_regular:before {
    content: "\eb03";
}
.icon-ic_fluent_notebook_arrow_curve_down_20_filled:before {
    content: "\eb04";
}
.icon-ic_fluent_notebook_arrow_curve_down_20_regular:before {
    content: "\eb05";
}
.icon-ic_fluent_notebook_error_20_filled:before {
    content: "\eb06";
}
.icon-ic_fluent_notebook_error_20_regular:before {
    content: "\eb07";
}
.icon-ic_fluent_notebook_eye_20_filled:before {
    content: "\eb08";
}
.icon-ic_fluent_notebook_eye_20_regular:before {
    content: "\eb09";
}
.icon-ic_fluent_notebook_lightning_20_filled:before {
    content: "\eb0a";
}
.icon-ic_fluent_notebook_lightning_20_regular:before {
    content: "\eb0b";
}
.icon-ic_fluent_notebook_question_mark_20_filled:before {
    content: "\eb0c";
}
.icon-ic_fluent_notebook_question_mark_20_regular:before {
    content: "\eb0d";
}
.icon-ic_fluent_notebook_section_20_filled:before {
    content: "\eb0e";
}
.icon-ic_fluent_notebook_section_20_regular:before {
    content: "\eb0f";
}
.icon-ic_fluent_notebook_section_arrow_right_20_filled:before {
    content: "\eb10";
}
.icon-ic_fluent_notebook_section_arrow_right_20_regular:before {
    content: "\eb11";
}
.icon-ic_fluent_notebook_subsection_20_filled:before {
    content: "\eb12";
}
.icon-ic_fluent_notebook_subsection_20_regular:before {
    content: "\eb13";
}
.icon-ic_fluent_notebook_sync_20_filled:before {
    content: "\eb14";
}
.icon-ic_fluent_notebook_sync_20_regular:before {
    content: "\eb15";
}
.icon-ic_fluent_notepad_20_filled:before {
    content: "\eb16";
}
.icon-ic_fluent_notepad_20_regular:before {
    content: "\eb17";
}
.icon-ic_fluent_notepad_edit_20_filled:before {
    content: "\eb18";
}
.icon-ic_fluent_notepad_edit_20_regular:before {
    content: "\eb19";
}
.icon-ic_fluent_notepad_person_20_filled:before {
    content: "\eb1a";
}
.icon-ic_fluent_notepad_person_20_regular:before {
    content: "\eb1b";
}
.icon-ic_fluent_number_circle_0_20_filled:before {
    content: "\eb1c";
}
.icon-ic_fluent_number_circle_0_20_regular:before {
    content: "\eb1d";
}
.icon-ic_fluent_number_circle_1_20_filled:before {
    content: "\eb1e";
}
.icon-ic_fluent_number_circle_1_20_regular:before {
    content: "\eb1f";
}
.icon-ic_fluent_number_circle_2_20_filled:before {
    content: "\eb20";
}
.icon-ic_fluent_number_circle_2_20_regular:before {
    content: "\eb21";
}
.icon-ic_fluent_number_circle_3_20_filled:before {
    content: "\eb22";
}
.icon-ic_fluent_number_circle_3_20_regular:before {
    content: "\eb23";
}
.icon-ic_fluent_number_circle_4_20_filled:before {
    content: "\eb24";
}
.icon-ic_fluent_number_circle_4_20_regular:before {
    content: "\eb25";
}
.icon-ic_fluent_number_circle_5_20_filled:before {
    content: "\eb26";
}
.icon-ic_fluent_number_circle_5_20_regular:before {
    content: "\eb27";
}
.icon-ic_fluent_number_circle_6_20_filled:before {
    content: "\eb28";
}
.icon-ic_fluent_number_circle_6_20_regular:before {
    content: "\eb29";
}
.icon-ic_fluent_number_circle_7_20_filled:before {
    content: "\eb2a";
}
.icon-ic_fluent_number_circle_7_20_regular:before {
    content: "\eb2b";
}
.icon-ic_fluent_number_circle_8_20_filled:before {
    content: "\eb2c";
}
.icon-ic_fluent_number_circle_8_20_regular:before {
    content: "\eb2d";
}
.icon-ic_fluent_number_circle_9_20_filled:before {
    content: "\eb2e";
}
.icon-ic_fluent_number_circle_9_20_regular:before {
    content: "\eb2f";
}
.icon-ic_fluent_number_row_20_filled:before {
    content: "\eb30";
}
.icon-ic_fluent_number_row_20_regular:before {
    content: "\eb31";
}
.icon-ic_fluent_number_symbol_20_filled:before {
    content: "\eb32";
}
.icon-ic_fluent_number_symbol_20_regular:before {
    content: "\eb33";
}
.icon-ic_fluent_number_symbol_dismiss_20_filled:before {
    content: "\eb34";
}
.icon-ic_fluent_number_symbol_dismiss_20_regular:before {
    content: "\eb35";
}
.icon-ic_fluent_number_symbol_square_20_filled:before {
    content: "\eb36";
}
.icon-ic_fluent_number_symbol_square_20_regular:before {
    content: "\eb37";
}
.icon-ic_fluent_open_20_filled:before {
    content: "\eb38";
}
.icon-ic_fluent_open_20_regular:before {
    content: "\eb39";
}
.icon-ic_fluent_open_folder_20_filled:before {
    content: "\eb3a";
}
.icon-ic_fluent_open_folder_20_regular:before {
    content: "\eb3b";
}
.icon-ic_fluent_open_off_20_filled:before {
    content: "\eb3c";
}
.icon-ic_fluent_open_off_20_regular:before {
    content: "\eb3d";
}
.icon-ic_fluent_options_20_filled:before {
    content: "\eb3e";
}
.icon-ic_fluent_options_20_regular:before {
    content: "\eb3f";
}
.icon-ic_fluent_organization_20_filled:before {
    content: "\eb40";
}
.icon-ic_fluent_organization_20_regular:before {
    content: "\eb41";
}
.icon-ic_fluent_organization_horizontal_20_filled:before {
    content: "\eb42";
}
.icon-ic_fluent_organization_horizontal_20_regular:before {
    content: "\eb43";
}
.icon-ic_fluent_orientation_20_filled:before {
    content: "\eb44";
}
.icon-ic_fluent_orientation_20_regular:before {
    content: "\eb45";
}
.icon-ic_fluent_oval_20_filled:before {
    content: "\eb46";
}
.icon-ic_fluent_oval_20_regular:before {
    content: "\eb47";
}
.icon-ic_fluent_oven_20_filled:before {
    content: "\eb48";
}
.icon-ic_fluent_oven_20_regular:before {
    content: "\eb49";
}
.icon-ic_fluent_padding_down_20_filled:before {
    content: "\eb4a";
}
.icon-ic_fluent_padding_down_20_regular:before {
    content: "\eb4b";
}
.icon-ic_fluent_padding_left_20_filled:before {
    content: "\eb4c";
}
.icon-ic_fluent_padding_left_20_regular:before {
    content: "\eb4d";
}
.icon-ic_fluent_padding_right_20_filled:before {
    content: "\eb4e";
}
.icon-ic_fluent_padding_right_20_regular:before {
    content: "\eb4f";
}
.icon-ic_fluent_padding_top_20_filled:before {
    content: "\eb50";
}
.icon-ic_fluent_padding_top_20_regular:before {
    content: "\eb51";
}
.icon-ic_fluent_page_fit_20_filled:before {
    content: "\eb52";
}
.icon-ic_fluent_page_fit_20_regular:before {
    content: "\eb53";
}
.icon-ic_fluent_paint_brush_20_filled:before {
    content: "\eb54";
}
.icon-ic_fluent_paint_brush_20_regular:before {
    content: "\eb55";
}
.icon-ic_fluent_paint_brush_arrow_down_20_filled:before {
    content: "\eb56";
}
.icon-ic_fluent_paint_brush_arrow_down_20_regular:before {
    content: "\eb57";
}
.icon-ic_fluent_paint_brush_arrow_up_20_filled:before {
    content: "\eb58";
}
.icon-ic_fluent_paint_brush_arrow_up_20_regular:before {
    content: "\eb59";
}
.icon-ic_fluent_paint_bucket_20_filled:before {
    content: "\eb5a";
}
.icon-ic_fluent_paint_bucket_20_regular:before {
    content: "\eb5b";
}
.icon-ic_fluent_pair_20_filled:before {
    content: "\eb5c";
}
.icon-ic_fluent_pair_20_regular:before {
    content: "\eb5d";
}
.icon-ic_fluent_panel_bottom_20_filled:before {
    content: "\eb5e";
}
.icon-ic_fluent_panel_bottom_20_regular:before {
    content: "\eb5f";
}
.icon-ic_fluent_panel_bottom_contract_20_filled:before {
    content: "\eb60";
}
.icon-ic_fluent_panel_bottom_contract_20_regular:before {
    content: "\eb61";
}
.icon-ic_fluent_panel_bottom_expand_20_filled:before {
    content: "\eb62";
}
.icon-ic_fluent_panel_bottom_expand_20_regular:before {
    content: "\eb63";
}
.icon-ic_fluent_panel_left_20_filled:before {
    content: "\eb64";
}
.icon-ic_fluent_panel_left_20_regular:before {
    content: "\eb65";
}
.icon-ic_fluent_panel_left_add_20_filled:before {
    content: "\eb66";
}
.icon-ic_fluent_panel_left_add_20_regular:before {
    content: "\eb67";
}
.icon-ic_fluent_panel_left_contract_20_filled:before {
    content: "\eb68";
}
.icon-ic_fluent_panel_left_contract_20_regular:before {
    content: "\eb69";
}
.icon-ic_fluent_panel_left_expand_20_filled:before {
    content: "\eb6a";
}
.icon-ic_fluent_panel_left_expand_20_regular:before {
    content: "\eb6b";
}
.icon-ic_fluent_panel_left_focus_right_20_filled:before {
    content: "\eb6c";
}
.icon-ic_fluent_panel_left_header_20_filled:before {
    content: "\eb6d";
}
.icon-ic_fluent_panel_left_header_20_regular:before {
    content: "\eb6e";
}
.icon-ic_fluent_panel_left_header_add_20_filled:before {
    content: "\eb6f";
}
.icon-ic_fluent_panel_left_header_add_20_regular:before {
    content: "\eb70";
}
.icon-ic_fluent_panel_left_header_key_20_filled:before {
    content: "\eb71";
}
.icon-ic_fluent_panel_left_header_key_20_regular:before {
    content: "\eb72";
}
.icon-ic_fluent_panel_left_key_20_filled:before {
    content: "\eb73";
}
.icon-ic_fluent_panel_left_key_20_regular:before {
    content: "\eb74";
}
.icon-ic_fluent_panel_left_text_20_filled:before {
    content: "\eb75";
}
.icon-ic_fluent_panel_left_text_20_regular:before {
    content: "\eb76";
}
.icon-ic_fluent_panel_left_text_add_20_filled:before {
    content: "\eb77";
}
.icon-ic_fluent_panel_left_text_add_20_regular:before {
    content: "\eb78";
}
.icon-ic_fluent_panel_left_text_dismiss_20_filled:before {
    content: "\eb79";
}
.icon-ic_fluent_panel_left_text_dismiss_20_regular:before {
    content: "\eb7a";
}
.icon-ic_fluent_panel_right_20_filled:before {
    content: "\eb7b";
}
.icon-ic_fluent_panel_right_20_regular:before {
    content: "\eb7c";
}
.icon-ic_fluent_panel_right_add_20_filled:before {
    content: "\eb7d";
}
.icon-ic_fluent_panel_right_add_20_regular:before {
    content: "\eb7e";
}
.icon-ic_fluent_panel_right_contract_20_filled:before {
    content: "\eb7f";
}
.icon-ic_fluent_panel_right_contract_20_regular:before {
    content: "\eb80";
}
.icon-ic_fluent_panel_right_cursor_20_filled:before {
    content: "\eb81";
}
.icon-ic_fluent_panel_right_cursor_20_regular:before {
    content: "\eb82";
}
.icon-ic_fluent_panel_right_expand_20_filled:before {
    content: "\eb83";
}
.icon-ic_fluent_panel_right_expand_20_regular:before {
    content: "\eb84";
}
.icon-ic_fluent_panel_right_gallery_20_filled:before {
    content: "\eb85";
}
.icon-ic_fluent_panel_right_gallery_20_regular:before {
    content: "\eb86";
}
.icon-ic_fluent_panel_separate_window_20_filled:before {
    content: "\eb87";
}
.icon-ic_fluent_panel_separate_window_20_regular:before {
    content: "\eb88";
}
.icon-ic_fluent_panel_top_contract_20_filled:before {
    content: "\eb89";
}
.icon-ic_fluent_panel_top_contract_20_regular:before {
    content: "\eb8a";
}
.icon-ic_fluent_panel_top_expand_20_filled:before {
    content: "\eb8b";
}
.icon-ic_fluent_panel_top_expand_20_regular:before {
    content: "\eb8c";
}
.icon-ic_fluent_panel_top_gallery_20_filled:before {
    content: "\eb8d";
}
.icon-ic_fluent_panel_top_gallery_20_regular:before {
    content: "\eb8e";
}
.icon-ic_fluent_password_20_filled:before {
    content: "\eb8f";
}
.icon-ic_fluent_password_20_regular:before {
    content: "\eb90";
}
.icon-ic_fluent_patch_20_filled:before {
    content: "\eb91";
}
.icon-ic_fluent_patch_20_regular:before {
    content: "\eb92";
}
.icon-ic_fluent_patient_20_filled:before {
    content: "\eb93";
}
.icon-ic_fluent_patient_20_regular:before {
    content: "\eb94";
}
.icon-ic_fluent_pause_20_filled:before {
    content: "\eb95";
}
.icon-ic_fluent_pause_20_regular:before {
    content: "\eb96";
}
.icon-ic_fluent_pause_circle_20_filled:before {
    content: "\eb97";
}
.icon-ic_fluent_pause_circle_20_regular:before {
    content: "\eb98";
}
.icon-ic_fluent_pause_off_20_filled:before {
    content: "\eb99";
}
.icon-ic_fluent_pause_off_20_regular:before {
    content: "\eb9a";
}
.icon-ic_fluent_pause_settings_20_filled:before {
    content: "\eb9b";
}
.icon-ic_fluent_pause_settings_20_regular:before {
    content: "\eb9c";
}
.icon-ic_fluent_payment_20_filled:before {
    content: "\eb9d";
}
.icon-ic_fluent_payment_20_regular:before {
    content: "\eb9e";
}
.icon-ic_fluent_pen_20_filled:before {
    content: "\eb9f";
}
.icon-ic_fluent_pen_20_regular:before {
    content: "\eba0";
}
.icon-ic_fluent_pen_dismiss_20_filled:before {
    content: "\eba1";
}
.icon-ic_fluent_pen_dismiss_20_regular:before {
    content: "\eba2";
}
.icon-ic_fluent_pen_off_20_filled:before {
    content: "\eba3";
}
.icon-ic_fluent_pen_off_20_regular:before {
    content: "\eba4";
}
.icon-ic_fluent_pen_prohibited_20_filled:before {
    content: "\eba5";
}
.icon-ic_fluent_pen_prohibited_20_regular:before {
    content: "\eba6";
}
.icon-ic_fluent_pen_sparkle_20_filled:before {
    content: "\eba7";
}
.icon-ic_fluent_pen_sparkle_20_regular:before {
    content: "\eba8";
}
.icon-ic_fluent_pentagon_20_filled:before {
    content: "\eba9";
}
.icon-ic_fluent_pentagon_20_regular:before {
    content: "\ebaa";
}
.icon-ic_fluent_people_20_filled:before {
    content: "\ebab";
}
.icon-ic_fluent_people_20_regular:before {
    content: "\ebac";
}
.icon-ic_fluent_people_add_20_filled:before {
    content: "\ebad";
}
.icon-ic_fluent_people_add_20_regular:before {
    content: "\ebae";
}
.icon-ic_fluent_people_audience_20_filled:before {
    content: "\ebaf";
}
.icon-ic_fluent_people_audience_20_regular:before {
    content: "\ebb0";
}
.icon-ic_fluent_people_call_20_filled:before {
    content: "\ebb1";
}
.icon-ic_fluent_people_call_20_regular:before {
    content: "\ebb2";
}
.icon-ic_fluent_people_chat_20_filled:before {
    content: "\ebb3";
}
.icon-ic_fluent_people_chat_20_regular:before {
    content: "\ebb4";
}
.icon-ic_fluent_people_checkmark_20_filled:before {
    content: "\ebb5";
}
.icon-ic_fluent_people_checkmark_20_regular:before {
    content: "\ebb6";
}
.icon-ic_fluent_people_community_20_filled:before {
    content: "\ebb7";
}
.icon-ic_fluent_people_community_20_regular:before {
    content: "\ebb8";
}
.icon-ic_fluent_people_community_add_20_filled:before {
    content: "\ebb9";
}
.icon-ic_fluent_people_community_add_20_regular:before {
    content: "\ebba";
}
.icon-ic_fluent_people_edit_20_filled:before {
    content: "\ebbb";
}
.icon-ic_fluent_people_edit_20_regular:before {
    content: "\ebbc";
}
.icon-ic_fluent_people_error_20_filled:before {
    content: "\ebbd";
}
.icon-ic_fluent_people_error_20_regular:before {
    content: "\ebbe";
}
.icon-ic_fluent_people_list_20_filled:before {
    content: "\ebbf";
}
.icon-ic_fluent_people_list_20_regular:before {
    content: "\ebc0";
}
.icon-ic_fluent_people_lock_20_filled:before {
    content: "\ebc1";
}
.icon-ic_fluent_people_lock_20_regular:before {
    content: "\ebc2";
}
.icon-ic_fluent_people_money_20_filled:before {
    content: "\ebc3";
}
.icon-ic_fluent_people_money_20_regular:before {
    content: "\ebc4";
}
.icon-ic_fluent_people_prohibited_20_filled:before {
    content: "\ebc5";
}
.icon-ic_fluent_people_prohibited_20_regular:before {
    content: "\ebc6";
}
.icon-ic_fluent_people_queue_20_filled:before {
    content: "\ebc7";
}
.icon-ic_fluent_people_queue_20_regular:before {
    content: "\ebc8";
}
.icon-ic_fluent_people_search_20_filled:before {
    content: "\ebc9";
}
.icon-ic_fluent_people_search_20_regular:before {
    content: "\ebca";
}
.icon-ic_fluent_people_settings_20_filled:before {
    content: "\ebcb";
}
.icon-ic_fluent_people_settings_20_regular:before {
    content: "\ebcc";
}
.icon-ic_fluent_people_star_20_filled:before {
    content: "\ebcd";
}
.icon-ic_fluent_people_star_20_regular:before {
    content: "\ebce";
}
.icon-ic_fluent_people_swap_20_filled:before {
    content: "\ebcf";
}
.icon-ic_fluent_people_swap_20_regular:before {
    content: "\ebd0";
}
.icon-ic_fluent_people_sync_20_filled:before {
    content: "\ebd1";
}
.icon-ic_fluent_people_sync_20_regular:before {
    content: "\ebd2";
}
.icon-ic_fluent_people_team_20_filled:before {
    content: "\ebd3";
}
.icon-ic_fluent_people_team_20_regular:before {
    content: "\ebd4";
}
.icon-ic_fluent_people_team_add_20_filled:before {
    content: "\ebd5";
}
.icon-ic_fluent_people_team_add_20_regular:before {
    content: "\ebd6";
}
.icon-ic_fluent_people_team_delete_20_filled:before {
    content: "\ebd7";
}
.icon-ic_fluent_people_team_delete_20_regular:before {
    content: "\ebd8";
}
.icon-ic_fluent_people_team_toolbox_20_filled:before {
    content: "\ebd9";
}
.icon-ic_fluent_people_team_toolbox_20_regular:before {
    content: "\ebda";
}
.icon-ic_fluent_people_toolbox_20_filled:before {
    content: "\ebdb";
}
.icon-ic_fluent_people_toolbox_20_regular:before {
    content: "\ebdc";
}
.icon-ic_fluent_person_20_filled:before {
    content: "\ebdd";
}
.icon-ic_fluent_person_20_regular:before {
    content: "\ebde";
}
.icon-ic_fluent_person_5_20_filled:before {
    content: "\ebdf";
}
.icon-ic_fluent_person_5_20_regular:before {
    content: "\ebe0";
}
.icon-ic_fluent_person_6_20_filled:before {
    content: "\ebe1";
}
.icon-ic_fluent_person_6_20_regular:before {
    content: "\ebe2";
}
.icon-ic_fluent_person_accounts_20_filled:before {
    content: "\ebe3";
}
.icon-ic_fluent_person_accounts_20_regular:before {
    content: "\ebe4";
}
.icon-ic_fluent_person_add_20_filled:before {
    content: "\ebe5";
}
.icon-ic_fluent_person_add_20_regular:before {
    content: "\ebe6";
}
.icon-ic_fluent_person_alert_20_filled:before {
    content: "\ebe7";
}
.icon-ic_fluent_person_alert_20_regular:before {
    content: "\ebe8";
}
.icon-ic_fluent_person_arrow_back_20_filled:before {
    content: "\ebe9";
}
.icon-ic_fluent_person_arrow_back_20_regular:before {
    content: "\ebea";
}
.icon-ic_fluent_person_arrow_left_20_filled:before {
    content: "\ebeb";
}
.icon-ic_fluent_person_arrow_left_20_regular:before {
    content: "\ebec";
}
.icon-ic_fluent_person_arrow_right_20_filled:before {
    content: "\ebed";
}
.icon-ic_fluent_person_arrow_right_20_regular:before {
    content: "\ebee";
}
.icon-ic_fluent_person_available_20_filled:before {
    content: "\ebef";
}
.icon-ic_fluent_person_available_20_regular:before {
    content: "\ebf0";
}
.icon-ic_fluent_person_board_20_filled:before {
    content: "\ebf1";
}
.icon-ic_fluent_person_board_20_regular:before {
    content: "\ebf2";
}
.icon-ic_fluent_person_call_20_filled:before {
    content: "\ebf3";
}
.icon-ic_fluent_person_call_20_regular:before {
    content: "\ebf4";
}
.icon-ic_fluent_person_chat_20_filled:before {
    content: "\ebf5";
}
.icon-ic_fluent_person_chat_20_regular:before {
    content: "\ebf6";
}
.icon-ic_fluent_person_circle_20_filled:before {
    content: "\ebf7";
}
.icon-ic_fluent_person_circle_20_regular:before {
    content: "\ebf8";
}
.icon-ic_fluent_person_clock_20_filled:before {
    content: "\ebf9";
}
.icon-ic_fluent_person_clock_20_regular:before {
    content: "\ebfa";
}
.icon-ic_fluent_person_delete_20_filled:before {
    content: "\ebfb";
}
.icon-ic_fluent_person_delete_20_regular:before {
    content: "\ebfc";
}
.icon-ic_fluent_person_desktop_20_filled:before {
    content: "\ebfd";
}
.icon-ic_fluent_person_desktop_20_regular:before {
    content: "\ebfe";
}
.icon-ic_fluent_person_edit_20_filled:before {
    content: "\ebff";
}
.icon-ic_fluent_person_edit_20_regular:before {
    content: "\ec00";
}
.icon-ic_fluent_person_feedback_20_filled:before {
    content: "\ec01";
}
.icon-ic_fluent_person_feedback_20_regular:before {
    content: "\ec02";
}
.icon-ic_fluent_person_heart_20_filled:before {
    content: "\ec03";
}
.icon-ic_fluent_person_heart_20_regular:before {
    content: "\ec04";
}
.icon-ic_fluent_person_info_20_filled:before {
    content: "\ec05";
}
.icon-ic_fluent_person_info_20_regular:before {
    content: "\ec06";
}
.icon-ic_fluent_person_key_20_filled:before {
    content: "\ec07";
}
.icon-ic_fluent_person_key_20_regular:before {
    content: "\ec08";
}
.icon-ic_fluent_person_lightbulb_20_filled:before {
    content: "\ec09";
}
.icon-ic_fluent_person_lightbulb_20_regular:before {
    content: "\ec0a";
}
.icon-ic_fluent_person_lightning_20_filled:before {
    content: "\ec0b";
}
.icon-ic_fluent_person_lightning_20_regular:before {
    content: "\ec0c";
}
.icon-ic_fluent_person_link_20_filled:before {
    content: "\ec0d";
}
.icon-ic_fluent_person_link_20_regular:before {
    content: "\ec0e";
}
.icon-ic_fluent_person_lock_20_filled:before {
    content: "\ec0f";
}
.icon-ic_fluent_person_lock_20_regular:before {
    content: "\ec10";
}
.icon-ic_fluent_person_mail_20_filled:before {
    content: "\ec11";
}
.icon-ic_fluent_person_mail_20_regular:before {
    content: "\ec12";
}
.icon-ic_fluent_person_money_20_filled:before {
    content: "\ec13";
}
.icon-ic_fluent_person_money_20_regular:before {
    content: "\ec14";
}
.icon-ic_fluent_person_note_20_filled:before {
    content: "\ec15";
}
.icon-ic_fluent_person_note_20_regular:before {
    content: "\ec16";
}
.icon-ic_fluent_person_pill_20_filled:before {
    content: "\ec17";
}
.icon-ic_fluent_person_pill_20_regular:before {
    content: "\ec18";
}
.icon-ic_fluent_person_prohibited_20_filled:before {
    content: "\ec19";
}
.icon-ic_fluent_person_prohibited_20_regular:before {
    content: "\ec1a";
}
.icon-ic_fluent_person_question_mark_20_filled:before {
    content: "\ec1b";
}
.icon-ic_fluent_person_question_mark_20_regular:before {
    content: "\ec1c";
}
.icon-ic_fluent_person_ribbon_20_filled:before {
    content: "\ec1d";
}
.icon-ic_fluent_person_ribbon_20_regular:before {
    content: "\ec1e";
}
.icon-ic_fluent_person_running_20_filled:before {
    content: "\ec1f";
}
.icon-ic_fluent_person_running_20_regular:before {
    content: "\ec20";
}
.icon-ic_fluent_person_search_20_filled:before {
    content: "\ec21";
}
.icon-ic_fluent_person_search_20_regular:before {
    content: "\ec22";
}
.icon-ic_fluent_person_settings_20_filled:before {
    content: "\ec23";
}
.icon-ic_fluent_person_settings_20_regular:before {
    content: "\ec24";
}
.icon-ic_fluent_person_square_20_filled:before {
    content: "\ec25";
}
.icon-ic_fluent_person_square_20_regular:before {
    content: "\ec26";
}
.icon-ic_fluent_person_square_checkmark_20_filled:before {
    content: "\ec27";
}
.icon-ic_fluent_person_square_checkmark_20_regular:before {
    content: "\ec28";
}
.icon-ic_fluent_person_star_20_filled:before {
    content: "\ec29";
}
.icon-ic_fluent_person_star_20_regular:before {
    content: "\ec2a";
}
.icon-ic_fluent_person_starburst_20_filled:before {
    content: "\ec2b";
}
.icon-ic_fluent_person_starburst_20_regular:before {
    content: "\ec2c";
}
.icon-ic_fluent_person_subtract_20_filled:before {
    content: "\ec2d";
}
.icon-ic_fluent_person_subtract_20_regular:before {
    content: "\ec2e";
}
.icon-ic_fluent_person_support_20_filled:before {
    content: "\ec2f";
}
.icon-ic_fluent_person_support_20_regular:before {
    content: "\ec30";
}
.icon-ic_fluent_person_swap_20_filled:before {
    content: "\ec31";
}
.icon-ic_fluent_person_swap_20_regular:before {
    content: "\ec32";
}
.icon-ic_fluent_person_sync_20_filled:before {
    content: "\ec33";
}
.icon-ic_fluent_person_sync_20_regular:before {
    content: "\ec34";
}
.icon-ic_fluent_person_tag_20_filled:before {
    content: "\ec35";
}
.icon-ic_fluent_person_tag_20_regular:before {
    content: "\ec36";
}
.icon-ic_fluent_person_voice_20_filled:before {
    content: "\ec37";
}
.icon-ic_fluent_person_voice_20_regular:before {
    content: "\ec38";
}
.icon-ic_fluent_person_walking_20_filled:before {
    content: "\ec39";
}
.icon-ic_fluent_person_walking_20_regular:before {
    content: "\ec3a";
}
.icon-ic_fluent_person_warning_20_filled:before {
    content: "\ec3b";
}
.icon-ic_fluent_person_warning_20_regular:before {
    content: "\ec3c";
}
.icon-ic_fluent_person_wrench_20_filled:before {
    content: "\ec3d";
}
.icon-ic_fluent_person_wrench_20_regular:before {
    content: "\ec3e";
}
.icon-ic_fluent_phone_20_filled:before {
    content: "\ec3f";
}
.icon-ic_fluent_phone_20_regular:before {
    content: "\ec40";
}
.icon-ic_fluent_phone_add_20_filled:before {
    content: "\ec41";
}
.icon-ic_fluent_phone_add_20_regular:before {
    content: "\ec42";
}
.icon-ic_fluent_phone_arrow_right_20_filled:before {
    content: "\ec43";
}
.icon-ic_fluent_phone_arrow_right_20_regular:before {
    content: "\ec44";
}
.icon-ic_fluent_phone_chat_20_filled:before {
    content: "\ec45";
}
.icon-ic_fluent_phone_chat_20_regular:before {
    content: "\ec46";
}
.icon-ic_fluent_phone_checkmark_20_filled:before {
    content: "\ec47";
}
.icon-ic_fluent_phone_checkmark_20_regular:before {
    content: "\ec48";
}
.icon-ic_fluent_phone_desktop_20_filled:before {
    content: "\ec49";
}
.icon-ic_fluent_phone_desktop_20_regular:before {
    content: "\ec4a";
}
.icon-ic_fluent_phone_desktop_add_20_filled:before {
    content: "\ec4b";
}
.icon-ic_fluent_phone_desktop_add_20_regular:before {
    content: "\ec4c";
}
.icon-ic_fluent_phone_dismiss_20_filled:before {
    content: "\ec4d";
}
.icon-ic_fluent_phone_dismiss_20_regular:before {
    content: "\ec4e";
}
.icon-ic_fluent_phone_edit_20_filled:before {
    content: "\ec4f";
}
.icon-ic_fluent_phone_edit_20_regular:before {
    content: "\ec50";
}
.icon-ic_fluent_phone_eraser_20_filled:before {
    content: "\ec51";
}
.icon-ic_fluent_phone_eraser_20_regular:before {
    content: "\ec52";
}
.icon-ic_fluent_phone_footer_arrow_down_20_filled:before {
    content: "\ec53";
}
.icon-ic_fluent_phone_footer_arrow_down_20_regular:before {
    content: "\ec54";
}
.icon-ic_fluent_phone_header_arrow_up_20_filled:before {
    content: "\ec55";
}
.icon-ic_fluent_phone_header_arrow_up_20_regular:before {
    content: "\ec56";
}
.icon-ic_fluent_phone_key_20_filled:before {
    content: "\ec57";
}
.icon-ic_fluent_phone_key_20_regular:before {
    content: "\ec58";
}
.icon-ic_fluent_phone_laptop_20_filled:before {
    content: "\ec59";
}
.icon-ic_fluent_phone_laptop_20_regular:before {
    content: "\ec5a";
}
.icon-ic_fluent_phone_link_setup_20_filled:before {
    content: "\ec5b";
}
.icon-ic_fluent_phone_link_setup_20_regular:before {
    content: "\ec5c";
}
.icon-ic_fluent_phone_lock_20_filled:before {
    content: "\ec5d";
}
.icon-ic_fluent_phone_lock_20_regular:before {
    content: "\ec5e";
}
.icon-ic_fluent_phone_page_header_20_filled:before {
    content: "\ec5f";
}
.icon-ic_fluent_phone_page_header_20_regular:before {
    content: "\ec60";
}
.icon-ic_fluent_phone_pagination_20_filled:before {
    content: "\ec61";
}
.icon-ic_fluent_phone_pagination_20_regular:before {
    content: "\ec62";
}
.icon-ic_fluent_phone_screen_time_20_filled:before {
    content: "\ec63";
}
.icon-ic_fluent_phone_screen_time_20_regular:before {
    content: "\ec64";
}
.icon-ic_fluent_phone_shake_20_filled:before {
    content: "\ec65";
}
.icon-ic_fluent_phone_shake_20_regular:before {
    content: "\ec66";
}
.icon-ic_fluent_phone_span_in_20_filled:before {
    content: "\ec67";
}
.icon-ic_fluent_phone_span_in_20_regular:before {
    content: "\ec68";
}
.icon-ic_fluent_phone_span_out_20_filled:before {
    content: "\ec69";
}
.icon-ic_fluent_phone_span_out_20_regular:before {
    content: "\ec6a";
}
.icon-ic_fluent_phone_speaker_20_filled:before {
    content: "\ec6b";
}
.icon-ic_fluent_phone_speaker_20_regular:before {
    content: "\ec6c";
}
.icon-ic_fluent_phone_status_bar_20_filled:before {
    content: "\ec6d";
}
.icon-ic_fluent_phone_status_bar_20_regular:before {
    content: "\ec6e";
}
.icon-ic_fluent_phone_tablet_20_filled:before {
    content: "\ec6f";
}
.icon-ic_fluent_phone_tablet_20_regular:before {
    content: "\ec70";
}
.icon-ic_fluent_phone_update_20_filled:before {
    content: "\ec71";
}
.icon-ic_fluent_phone_update_20_regular:before {
    content: "\ec72";
}
.icon-ic_fluent_phone_update_checkmark_20_filled:before {
    content: "\ec73";
}
.icon-ic_fluent_phone_update_checkmark_20_regular:before {
    content: "\ec74";
}
.icon-ic_fluent_phone_vertical_scroll_20_filled:before {
    content: "\ec75";
}
.icon-ic_fluent_phone_vertical_scroll_20_regular:before {
    content: "\ec76";
}
.icon-ic_fluent_phone_vibrate_20_filled:before {
    content: "\ec77";
}
.icon-ic_fluent_phone_vibrate_20_regular:before {
    content: "\ec78";
}
.icon-ic_fluent_photo_filter_20_filled:before {
    content: "\ec79";
}
.icon-ic_fluent_photo_filter_20_regular:before {
    content: "\ec7a";
}
.icon-ic_fluent_pi_20_filled:before {
    content: "\ec7b";
}
.icon-ic_fluent_pi_20_regular:before {
    content: "\ec7c";
}
.icon-ic_fluent_picture_in_picture_20_filled:before {
    content: "\ec7d";
}
.icon-ic_fluent_picture_in_picture_20_regular:before {
    content: "\ec7e";
}
.icon-ic_fluent_picture_in_picture_enter_20_filled:before {
    content: "\ec7f";
}
.icon-ic_fluent_picture_in_picture_enter_20_regular:before {
    content: "\ec80";
}
.icon-ic_fluent_picture_in_picture_exit_20_filled:before {
    content: "\ec81";
}
.icon-ic_fluent_picture_in_picture_exit_20_regular:before {
    content: "\ec82";
}
.icon-ic_fluent_pill_20_filled:before {
    content: "\ec83";
}
.icon-ic_fluent_pill_20_regular:before {
    content: "\ec84";
}
.icon-ic_fluent_pin_20_filled:before {
    content: "\ec85";
}
.icon-ic_fluent_pin_20_regular:before {
    content: "\ec86";
}
.icon-ic_fluent_pin_off_20_filled:before {
    content: "\ec87";
}
.icon-ic_fluent_pin_off_20_regular:before {
    content: "\ec88";
}
.icon-ic_fluent_pipeline_20_filled:before {
    content: "\ec89";
}
.icon-ic_fluent_pipeline_20_regular:before {
    content: "\ec8a";
}
.icon-ic_fluent_pipeline_add_20_filled:before {
    content: "\ec8b";
}
.icon-ic_fluent_pipeline_add_20_regular:before {
    content: "\ec8c";
}
.icon-ic_fluent_pipeline_arrow_curve_down_20_filled:before {
    content: "\ec8d";
}
.icon-ic_fluent_pipeline_arrow_curve_down_20_regular:before {
    content: "\ec8e";
}
.icon-ic_fluent_pipeline_play_20_filled:before {
    content: "\ec8f";
}
.icon-ic_fluent_pipeline_play_20_regular:before {
    content: "\ec90";
}
.icon-ic_fluent_pivot_20_filled:before {
    content: "\ec91";
}
.icon-ic_fluent_pivot_20_regular:before {
    content: "\ec92";
}
.icon-ic_fluent_plant_grass_20_filled:before {
    content: "\ec93";
}
.icon-ic_fluent_plant_grass_20_regular:before {
    content: "\ec94";
}
.icon-ic_fluent_plant_ragweed_20_filled:before {
    content: "\ec95";
}
.icon-ic_fluent_plant_ragweed_20_regular:before {
    content: "\ec96";
}
.icon-ic_fluent_play_20_filled:before {
    content: "\ec97";
}
.icon-ic_fluent_play_20_regular:before {
    content: "\ec98";
}
.icon-ic_fluent_play_circle_20_filled:before {
    content: "\ec99";
}
.icon-ic_fluent_play_circle_20_regular:before {
    content: "\ec9a";
}
.icon-ic_fluent_play_circle_hint_20_filled:before {
    content: "\ec9b";
}
.icon-ic_fluent_play_circle_hint_20_regular:before {
    content: "\ec9c";
}
.icon-ic_fluent_play_settings_20_filled:before {
    content: "\ec9d";
}
.icon-ic_fluent_play_settings_20_regular:before {
    content: "\ec9e";
}
.icon-ic_fluent_playing_cards_20_filled:before {
    content: "\ec9f";
}
.icon-ic_fluent_playing_cards_20_regular:before {
    content: "\eca0";
}
.icon-ic_fluent_plug_connected_20_filled:before {
    content: "\eca1";
}
.icon-ic_fluent_plug_connected_20_regular:before {
    content: "\eca2";
}
.icon-ic_fluent_plug_connected_add_20_filled:before {
    content: "\eca3";
}
.icon-ic_fluent_plug_connected_add_20_regular:before {
    content: "\eca4";
}
.icon-ic_fluent_plug_connected_checkmark_20_filled:before {
    content: "\eca5";
}
.icon-ic_fluent_plug_connected_checkmark_20_regular:before {
    content: "\eca6";
}
.icon-ic_fluent_plug_connected_settings_20_filled:before {
    content: "\eca7";
}
.icon-ic_fluent_plug_connected_settings_20_regular:before {
    content: "\eca8";
}
.icon-ic_fluent_plug_disconnected_20_filled:before {
    content: "\eca9";
}
.icon-ic_fluent_plug_disconnected_20_regular:before {
    content: "\ecaa";
}
.icon-ic_fluent_point_scan_20_filled:before {
    content: "\ecab";
}
.icon-ic_fluent_point_scan_20_regular:before {
    content: "\ecac";
}
.icon-ic_fluent_poll_20_filled:before {
    content: "\ecad";
}
.icon-ic_fluent_poll_20_regular:before {
    content: "\ecae";
}
.icon-ic_fluent_poll_horizontal_20_filled:before {
    content: "\ecaf";
}
.icon-ic_fluent_poll_horizontal_20_regular:before {
    content: "\ecb0";
}
.icon-ic_fluent_port_hdmi_20_filled:before {
    content: "\ecb1";
}
.icon-ic_fluent_port_hdmi_20_regular:before {
    content: "\ecb2";
}
.icon-ic_fluent_port_micro_usb_20_filled:before {
    content: "\ecb3";
}
.icon-ic_fluent_port_micro_usb_20_regular:before {
    content: "\ecb4";
}
.icon-ic_fluent_port_usb_a_20_filled:before {
    content: "\ecb5";
}
.icon-ic_fluent_port_usb_a_20_regular:before {
    content: "\ecb6";
}
.icon-ic_fluent_port_usb_c_20_filled:before {
    content: "\ecb7";
}
.icon-ic_fluent_port_usb_c_20_regular:before {
    content: "\ecb8";
}
.icon-ic_fluent_position_backward_20_filled:before {
    content: "\ecb9";
}
.icon-ic_fluent_position_backward_20_regular:before {
    content: "\ecba";
}
.icon-ic_fluent_position_forward_20_filled:before {
    content: "\ecbb";
}
.icon-ic_fluent_position_forward_20_regular:before {
    content: "\ecbc";
}
.icon-ic_fluent_position_to_back_20_filled:before {
    content: "\ecbd";
}
.icon-ic_fluent_position_to_back_20_regular:before {
    content: "\ecbe";
}
.icon-ic_fluent_position_to_front_20_filled:before {
    content: "\ecbf";
}
.icon-ic_fluent_position_to_front_20_regular:before {
    content: "\ecc0";
}
.icon-ic_fluent_power_20_filled:before {
    content: "\ecc1";
}
.icon-ic_fluent_power_20_regular:before {
    content: "\ecc2";
}
.icon-ic_fluent_predictions_20_filled:before {
    content: "\ecc3";
}
.icon-ic_fluent_predictions_20_regular:before {
    content: "\ecc4";
}
.icon-ic_fluent_premium_20_filled:before {
    content: "\ecc5";
}
.icon-ic_fluent_premium_20_regular:before {
    content: "\ecc6";
}
.icon-ic_fluent_premium_person_20_filled:before {
    content: "\ecc7";
}
.icon-ic_fluent_premium_person_20_regular:before {
    content: "\ecc8";
}
.icon-ic_fluent_presence_available_20_filled:before {
    content: "\ecc9";
}
.icon-ic_fluent_presence_available_20_regular:before {
    content: "\ecca";
}
.icon-ic_fluent_presence_away_20_filled:before {
    content: "\eccb";
}
.icon-ic_fluent_presence_away_20_regular:before {
    content: "\eccc";
}
.icon-ic_fluent_presence_blocked_20_regular:before {
    content: "\eccd";
}
.icon-ic_fluent_presence_busy_20_filled:before {
    content: "\ecce";
}
.icon-ic_fluent_presence_dnd_20_filled:before {
    content: "\eccf";
}
.icon-ic_fluent_presence_dnd_20_regular:before {
    content: "\ecd0";
}
.icon-ic_fluent_presence_offline_20_regular:before {
    content: "\ecd1";
}
.icon-ic_fluent_presence_oof_20_regular:before {
    content: "\ecd2";
}
.icon-ic_fluent_presence_unknown_20_regular:before {
    content: "\ecd3";
}
.icon-ic_fluent_presenter_20_filled:before {
    content: "\ecd4";
}
.icon-ic_fluent_presenter_20_regular:before {
    content: "\ecd5";
}
.icon-ic_fluent_presenter_off_20_filled:before {
    content: "\ecd6";
}
.icon-ic_fluent_presenter_off_20_regular:before {
    content: "\ecd7";
}
.icon-ic_fluent_preview_link_20_filled:before {
    content: "\ecd8";
}
.icon-ic_fluent_preview_link_20_regular:before {
    content: "\ecd9";
}
.icon-ic_fluent_previous_20_filled:before {
    content: "\ecda";
}
.icon-ic_fluent_previous_20_regular:before {
    content: "\ecdb";
}
.icon-ic_fluent_previous_frame_20_filled:before {
    content: "\ecdc";
}
.icon-ic_fluent_previous_frame_20_regular:before {
    content: "\ecdd";
}
.icon-ic_fluent_print_20_filled:before {
    content: "\ecde";
}
.icon-ic_fluent_print_20_regular:before {
    content: "\ecdf";
}
.icon-ic_fluent_print_add_20_filled:before {
    content: "\ece0";
}
.icon-ic_fluent_print_add_20_regular:before {
    content: "\ece1";
}
.icon-ic_fluent_production_20_filled:before {
    content: "\ece2";
}
.icon-ic_fluent_production_20_regular:before {
    content: "\ece3";
}
.icon-ic_fluent_production_checkmark_20_filled:before {
    content: "\ece4";
}
.icon-ic_fluent_production_checkmark_20_regular:before {
    content: "\ece5";
}
.icon-ic_fluent_prohibited_20_filled:before {
    content: "\ece6";
}
.icon-ic_fluent_prohibited_20_regular:before {
    content: "\ece7";
}
.icon-ic_fluent_prohibited_multiple_20_filled:before {
    content: "\ece8";
}
.icon-ic_fluent_prohibited_multiple_20_regular:before {
    content: "\ece9";
}
.icon-ic_fluent_prohibited_note_20_filled:before {
    content: "\ecea";
}
.icon-ic_fluent_prohibited_note_20_regular:before {
    content: "\eceb";
}
.icon-ic_fluent_projection_screen_20_filled:before {
    content: "\ecec";
}
.icon-ic_fluent_projection_screen_20_regular:before {
    content: "\eced";
}
.icon-ic_fluent_projection_screen_dismiss_20_filled:before {
    content: "\ecee";
}
.icon-ic_fluent_projection_screen_dismiss_20_regular:before {
    content: "\ecef";
}
.icon-ic_fluent_projection_screen_text_20_filled:before {
    content: "\ecf0";
}
.icon-ic_fluent_projection_screen_text_20_regular:before {
    content: "\ecf1";
}
.icon-ic_fluent_protocol_handler_20_filled:before {
    content: "\ecf2";
}
.icon-ic_fluent_protocol_handler_20_regular:before {
    content: "\ecf3";
}
.icon-ic_fluent_pulse_20_filled:before {
    content: "\ecf4";
}
.icon-ic_fluent_pulse_20_regular:before {
    content: "\ecf5";
}
.icon-ic_fluent_pulse_square_20_filled:before {
    content: "\ecf6";
}
.icon-ic_fluent_pulse_square_20_regular:before {
    content: "\ecf7";
}
.icon-ic_fluent_puzzle_cube_20_filled:before {
    content: "\ecf8";
}
.icon-ic_fluent_puzzle_cube_20_regular:before {
    content: "\ecf9";
}
.icon-ic_fluent_puzzle_cube_piece_20_filled:before {
    content: "\ecfa";
}
.icon-ic_fluent_puzzle_cube_piece_20_regular:before {
    content: "\ecfb";
}
.icon-ic_fluent_puzzle_piece_20_filled:before {
    content: "\ecfc";
}
.icon-ic_fluent_puzzle_piece_20_regular:before {
    content: "\ecfd";
}
.icon-ic_fluent_puzzle_piece_shield_20_filled:before {
    content: "\ecfe";
}
.icon-ic_fluent_puzzle_piece_shield_20_regular:before {
    content: "\ecff";
}
.icon-ic_fluent_qr_code_20_filled:before {
    content: "\ed00";
}
.icon-ic_fluent_qr_code_20_regular:before {
    content: "\ed01";
}
.icon-ic_fluent_question_20_filled:before {
    content: "\ed02";
}
.icon-ic_fluent_question_20_regular:before {
    content: "\ed03";
}
.icon-ic_fluent_question_circle_20_filled:before {
    content: "\ed04";
}
.icon-ic_fluent_question_circle_20_regular:before {
    content: "\ed05";
}
.icon-ic_fluent_quiz_new_20_filled:before {
    content: "\ed06";
}
.icon-ic_fluent_quiz_new_20_regular:before {
    content: "\ed07";
}
.icon-ic_fluent_radar_20_filled:before {
    content: "\ed08";
}
.icon-ic_fluent_radar_20_regular:before {
    content: "\ed09";
}
.icon-ic_fluent_radar_checkmark_20_filled:before {
    content: "\ed0a";
}
.icon-ic_fluent_radar_checkmark_20_regular:before {
    content: "\ed0b";
}
.icon-ic_fluent_radar_rectangle_multiple_20_filled:before {
    content: "\ed0c";
}
.icon-ic_fluent_radar_rectangle_multiple_20_regular:before {
    content: "\ed0d";
}
.icon-ic_fluent_radio_button_20_filled:before {
    content: "\ed0e";
}
.icon-ic_fluent_radio_button_20_regular:before {
    content: "\ed0f";
}
.icon-ic_fluent_ram_20_filled:before {
    content: "\ed10";
}
.icon-ic_fluent_ram_20_regular:before {
    content: "\ed11";
}
.icon-ic_fluent_rating_mature_20_filled:before {
    content: "\ed12";
}
.icon-ic_fluent_rating_mature_20_regular:before {
    content: "\ed13";
}
.icon-ic_fluent_ratio_one_to_one_20_filled:before {
    content: "\ed14";
}
.icon-ic_fluent_ratio_one_to_one_20_regular:before {
    content: "\ed15";
}
.icon-ic_fluent_re_order_20_filled:before {
    content: "\ed16";
}
.icon-ic_fluent_re_order_20_regular:before {
    content: "\ed17";
}
.icon-ic_fluent_re_order_dots_horizontal_20_filled:before {
    content: "\ed18";
}
.icon-ic_fluent_re_order_dots_horizontal_20_regular:before {
    content: "\ed19";
}
.icon-ic_fluent_re_order_dots_vertical_20_filled:before {
    content: "\ed1a";
}
.icon-ic_fluent_re_order_dots_vertical_20_regular:before {
    content: "\ed1b";
}
.icon-ic_fluent_read_aloud_20_filled:before {
    content: "\ed1c";
}
.icon-ic_fluent_read_aloud_20_regular:before {
    content: "\ed1d";
}
.icon-ic_fluent_reading_list_20_filled:before {
    content: "\ed1e";
}
.icon-ic_fluent_reading_list_20_regular:before {
    content: "\ed1f";
}
.icon-ic_fluent_reading_list_add_20_filled:before {
    content: "\ed20";
}
.icon-ic_fluent_reading_list_add_20_regular:before {
    content: "\ed21";
}
.icon-ic_fluent_reading_mode_mobile_20_filled:before {
    content: "\ed22";
}
.icon-ic_fluent_reading_mode_mobile_20_regular:before {
    content: "\ed23";
}
.icon-ic_fluent_real_estate_20_filled:before {
    content: "\ed24";
}
.icon-ic_fluent_real_estate_20_regular:before {
    content: "\ed25";
}
.icon-ic_fluent_receipt_20_filled:before {
    content: "\ed26";
}
.icon-ic_fluent_receipt_20_regular:before {
    content: "\ed27";
}
.icon-ic_fluent_receipt_add_20_filled:before {
    content: "\ed28";
}
.icon-ic_fluent_receipt_add_20_regular:before {
    content: "\ed29";
}
.icon-ic_fluent_receipt_bag_20_filled:before {
    content: "\ed2a";
}
.icon-ic_fluent_receipt_bag_20_regular:before {
    content: "\ed2b";
}
.icon-ic_fluent_receipt_cube_20_filled:before {
    content: "\ed2c";
}
.icon-ic_fluent_receipt_cube_20_regular:before {
    content: "\ed2d";
}
.icon-ic_fluent_receipt_money_20_filled:before {
    content: "\ed2e";
}
.icon-ic_fluent_receipt_money_20_regular:before {
    content: "\ed2f";
}
.icon-ic_fluent_receipt_play_20_filled:before {
    content: "\ed30";
}
.icon-ic_fluent_receipt_play_20_regular:before {
    content: "\ed31";
}
.icon-ic_fluent_receipt_search_20_filled:before {
    content: "\ed32";
}
.icon-ic_fluent_receipt_search_20_regular:before {
    content: "\ed33";
}
.icon-ic_fluent_receipt_sparkles_20_filled:before {
    content: "\ed34";
}
.icon-ic_fluent_receipt_sparkles_20_regular:before {
    content: "\ed35";
}
.icon-ic_fluent_record_20_filled:before {
    content: "\ed36";
}
.icon-ic_fluent_record_20_regular:before {
    content: "\ed37";
}
.icon-ic_fluent_record_stop_20_filled:before {
    content: "\ed38";
}
.icon-ic_fluent_record_stop_20_regular:before {
    content: "\ed39";
}
.icon-ic_fluent_rectangle_landscape_20_filled:before {
    content: "\ed3a";
}
.icon-ic_fluent_rectangle_landscape_20_regular:before {
    content: "\ed3b";
}
.icon-ic_fluent_rectangle_landscape_hint_copy_20_filled:before {
    content: "\ed3c";
}
.icon-ic_fluent_rectangle_landscape_hint_copy_20_regular:before {
    content: "\ed3d";
}
.icon-ic_fluent_rectangle_landscape_sparkle_20_filled:before {
    content: "\ed3e";
}
.icon-ic_fluent_rectangle_landscape_sparkle_20_regular:before {
    content: "\ed3f";
}
.icon-ic_fluent_rectangle_portrait_location_target_20_filled:before {
    content: "\ed40";
}
.icon-ic_fluent_rectangle_portrait_location_target_20_regular:before {
    content: "\ed41";
}
.icon-ic_fluent_recycle_20_filled:before {
    content: "\ed42";
}
.icon-ic_fluent_recycle_20_regular:before {
    content: "\ed43";
}
.icon-ic_fluent_remix_add_20_filled:before {
    content: "\ed44";
}
.icon-ic_fluent_remix_add_20_regular:before {
    content: "\ed45";
}
.icon-ic_fluent_remote_20_filled:before {
    content: "\ed46";
}
.icon-ic_fluent_remote_20_regular:before {
    content: "\ed47";
}
.icon-ic_fluent_rename_20_filled:before {
    content: "\ed48";
}
.icon-ic_fluent_rename_20_regular:before {
    content: "\ed49";
}
.icon-ic_fluent_reorder_20_filled:before {
    content: "\ed4a";
}
.icon-ic_fluent_reorder_20_regular:before {
    content: "\ed4b";
}
.icon-ic_fluent_replay_20_filled:before {
    content: "\ed4c";
}
.icon-ic_fluent_replay_20_regular:before {
    content: "\ed4d";
}
.icon-ic_fluent_resize_20_filled:before {
    content: "\ed4e";
}
.icon-ic_fluent_resize_20_regular:before {
    content: "\ed4f";
}
.icon-ic_fluent_resize_image_20_filled:before {
    content: "\ed50";
}
.icon-ic_fluent_resize_image_20_regular:before {
    content: "\ed51";
}
.icon-ic_fluent_resize_large_20_filled:before {
    content: "\ed52";
}
.icon-ic_fluent_resize_large_20_regular:before {
    content: "\ed53";
}
.icon-ic_fluent_resize_small_20_filled:before {
    content: "\ed54";
}
.icon-ic_fluent_resize_small_20_regular:before {
    content: "\ed55";
}
.icon-ic_fluent_resize_table_20_filled:before {
    content: "\ed56";
}
.icon-ic_fluent_resize_table_20_regular:before {
    content: "\ed57";
}
.icon-ic_fluent_resize_video_20_filled:before {
    content: "\ed58";
}
.icon-ic_fluent_resize_video_20_regular:before {
    content: "\ed59";
}
.icon-ic_fluent_reward_20_filled:before {
    content: "\ed5a";
}
.icon-ic_fluent_reward_20_regular:before {
    content: "\ed5b";
}
.icon-ic_fluent_rewind_20_filled:before {
    content: "\ed5c";
}
.icon-ic_fluent_rewind_20_regular:before {
    content: "\ed5d";
}
.icon-ic_fluent_rhombus_20_filled:before {
    content: "\ed5e";
}
.icon-ic_fluent_rhombus_20_regular:before {
    content: "\ed5f";
}
.icon-ic_fluent_ribbon_20_filled:before {
    content: "\ed60";
}
.icon-ic_fluent_ribbon_20_regular:before {
    content: "\ed61";
}
.icon-ic_fluent_ribbon_add_20_filled:before {
    content: "\ed62";
}
.icon-ic_fluent_ribbon_add_20_regular:before {
    content: "\ed63";
}
.icon-ic_fluent_ribbon_off_20_filled:before {
    content: "\ed64";
}
.icon-ic_fluent_ribbon_off_20_regular:before {
    content: "\ed65";
}
.icon-ic_fluent_ribbon_star_20_filled:before {
    content: "\ed66";
}
.icon-ic_fluent_ribbon_star_20_regular:before {
    content: "\ed67";
}
.icon-ic_fluent_road_cone_20_filled:before {
    content: "\ed68";
}
.icon-ic_fluent_road_cone_20_regular:before {
    content: "\ed69";
}
.icon-ic_fluent_rocket_20_filled:before {
    content: "\ed6a";
}
.icon-ic_fluent_rocket_20_regular:before {
    content: "\ed6b";
}
.icon-ic_fluent_rotate_left_20_filled:before {
    content: "\ed6c";
}
.icon-ic_fluent_rotate_left_20_regular:before {
    content: "\ed6d";
}
.icon-ic_fluent_rotate_right_20_filled:before {
    content: "\ed6e";
}
.icon-ic_fluent_rotate_right_20_regular:before {
    content: "\ed6f";
}
.icon-ic_fluent_router_20_filled:before {
    content: "\ed70";
}
.icon-ic_fluent_router_20_regular:before {
    content: "\ed71";
}
.icon-ic_fluent_row_triple_20_filled:before {
    content: "\ed72";
}
.icon-ic_fluent_row_triple_20_regular:before {
    content: "\ed73";
}
.icon-ic_fluent_rss_20_filled:before {
    content: "\ed74";
}
.icon-ic_fluent_rss_20_regular:before {
    content: "\ed75";
}
.icon-ic_fluent_ruler_20_filled:before {
    content: "\ed76";
}
.icon-ic_fluent_ruler_20_regular:before {
    content: "\ed77";
}
.icon-ic_fluent_run_20_filled:before {
    content: "\ed78";
}
.icon-ic_fluent_run_20_regular:before {
    content: "\ed79";
}
.icon-ic_fluent_sanitize_20_filled:before {
    content: "\ed7a";
}
.icon-ic_fluent_sanitize_20_regular:before {
    content: "\ed7b";
}
.icon-ic_fluent_save_20_filled:before {
    content: "\ed7c";
}
.icon-ic_fluent_save_20_regular:before {
    content: "\ed7d";
}
.icon-ic_fluent_save_arrow_right_20_filled:before {
    content: "\ed7e";
}
.icon-ic_fluent_save_arrow_right_20_regular:before {
    content: "\ed7f";
}
.icon-ic_fluent_save_copy_20_filled:before {
    content: "\ed80";
}
.icon-ic_fluent_save_copy_20_regular:before {
    content: "\ed81";
}
.icon-ic_fluent_save_edit_20_filled:before {
    content: "\ed82";
}
.icon-ic_fluent_save_edit_20_regular:before {
    content: "\ed83";
}
.icon-ic_fluent_save_image_20_filled:before {
    content: "\ed84";
}
.icon-ic_fluent_save_image_20_regular:before {
    content: "\ed85";
}
.icon-ic_fluent_save_multiple_20_filled:before {
    content: "\ed86";
}
.icon-ic_fluent_save_multiple_20_regular:before {
    content: "\ed87";
}
.icon-ic_fluent_save_search_20_filled:before {
    content: "\ed88";
}
.icon-ic_fluent_save_search_20_regular:before {
    content: "\ed89";
}
.icon-ic_fluent_save_sync_20_filled:before {
    content: "\ed8a";
}
.icon-ic_fluent_save_sync_20_regular:before {
    content: "\ed8b";
}
.icon-ic_fluent_savings_20_filled:before {
    content: "\ed8c";
}
.icon-ic_fluent_savings_20_regular:before {
    content: "\ed8d";
}
.icon-ic_fluent_scale_fill_20_filled:before {
    content: "\ed8e";
}
.icon-ic_fluent_scale_fill_20_regular:before {
    content: "\ed8f";
}
.icon-ic_fluent_scale_fit_20_filled:before {
    content: "\ed90";
}
.icon-ic_fluent_scale_fit_20_regular:before {
    content: "\ed91";
}
.icon-ic_fluent_scales_20_filled:before {
    content: "\ed92";
}
.icon-ic_fluent_scales_20_regular:before {
    content: "\ed93";
}
.icon-ic_fluent_scan_20_filled:before {
    content: "\ed94";
}
.icon-ic_fluent_scan_20_regular:before {
    content: "\ed95";
}
.icon-ic_fluent_scan_camera_20_filled:before {
    content: "\ed96";
}
.icon-ic_fluent_scan_camera_20_regular:before {
    content: "\ed97";
}
.icon-ic_fluent_scan_dash_20_filled:before {
    content: "\ed98";
}
.icon-ic_fluent_scan_dash_20_regular:before {
    content: "\ed99";
}
.icon-ic_fluent_scan_object_20_filled:before {
    content: "\ed9a";
}
.icon-ic_fluent_scan_object_20_regular:before {
    content: "\ed9b";
}
.icon-ic_fluent_scan_person_20_filled:before {
    content: "\ed9c";
}
.icon-ic_fluent_scan_person_20_regular:before {
    content: "\ed9d";
}
.icon-ic_fluent_scan_table_20_filled:before {
    content: "\ed9e";
}
.icon-ic_fluent_scan_table_20_regular:before {
    content: "\ed9f";
}
.icon-ic_fluent_scan_text_20_filled:before {
    content: "\eda0";
}
.icon-ic_fluent_scan_text_20_regular:before {
    content: "\eda1";
}
.icon-ic_fluent_scan_thumb_up_20_filled:before {
    content: "\eda2";
}
.icon-ic_fluent_scan_thumb_up_20_regular:before {
    content: "\eda3";
}
.icon-ic_fluent_scan_thumb_up_off_20_filled:before {
    content: "\eda4";
}
.icon-ic_fluent_scan_thumb_up_off_20_regular:before {
    content: "\eda5";
}
.icon-ic_fluent_scan_type_20_filled:before {
    content: "\eda6";
}
.icon-ic_fluent_scan_type_20_regular:before {
    content: "\eda7";
}
.icon-ic_fluent_scan_type_checkmark_20_filled:before {
    content: "\eda8";
}
.icon-ic_fluent_scan_type_checkmark_20_regular:before {
    content: "\eda9";
}
.icon-ic_fluent_scan_type_off_20_filled:before {
    content: "\edaa";
}
.icon-ic_fluent_scan_type_off_20_regular:before {
    content: "\edab";
}
.icon-ic_fluent_scratchpad_20_filled:before {
    content: "\edac";
}
.icon-ic_fluent_scratchpad_20_regular:before {
    content: "\edad";
}
.icon-ic_fluent_screen_cut_20_filled:before {
    content: "\edae";
}
.icon-ic_fluent_screen_cut_20_regular:before {
    content: "\edaf";
}
.icon-ic_fluent_screen_person_20_filled:before {
    content: "\edb0";
}
.icon-ic_fluent_screen_person_20_regular:before {
    content: "\edb1";
}
.icon-ic_fluent_screen_search_20_filled:before {
    content: "\edb2";
}
.icon-ic_fluent_screen_search_20_regular:before {
    content: "\edb3";
}
.icon-ic_fluent_screenshot_20_filled:before {
    content: "\edb4";
}
.icon-ic_fluent_screenshot_20_regular:before {
    content: "\edb5";
}
.icon-ic_fluent_screenshot_record_20_filled:before {
    content: "\edb6";
}
.icon-ic_fluent_screenshot_record_20_regular:before {
    content: "\edb7";
}
.icon-ic_fluent_script_20_filled:before {
    content: "\edb8";
}
.icon-ic_fluent_script_20_regular:before {
    content: "\edb9";
}
.icon-ic_fluent_search_20_filled:before {
    content: "\edba";
}
.icon-ic_fluent_search_20_regular:before {
    content: "\edbb";
}
.icon-ic_fluent_search_info_20_filled:before {
    content: "\edbc";
}
.icon-ic_fluent_search_info_20_regular:before {
    content: "\edbd";
}
.icon-ic_fluent_search_settings_20_filled:before {
    content: "\edbe";
}
.icon-ic_fluent_search_settings_20_regular:before {
    content: "\edbf";
}
.icon-ic_fluent_search_shield_20_filled:before {
    content: "\edc0";
}
.icon-ic_fluent_search_shield_20_regular:before {
    content: "\edc1";
}
.icon-ic_fluent_search_square_20_filled:before {
    content: "\edc2";
}
.icon-ic_fluent_search_square_20_regular:before {
    content: "\edc3";
}
.icon-ic_fluent_search_visual_20_filled:before {
    content: "\edc4";
}
.icon-ic_fluent_search_visual_20_regular:before {
    content: "\edc5";
}
.icon-ic_fluent_select_all_off_20_filled:before {
    content: "\edc6";
}
.icon-ic_fluent_select_all_off_20_regular:before {
    content: "\edc7";
}
.icon-ic_fluent_select_all_on_20_filled:before {
    content: "\edc8";
}
.icon-ic_fluent_select_all_on_20_regular:before {
    content: "\edc9";
}
.icon-ic_fluent_select_object_20_filled:before {
    content: "\edca";
}
.icon-ic_fluent_select_object_20_regular:before {
    content: "\edcb";
}
.icon-ic_fluent_select_object_skew_20_filled:before {
    content: "\edcc";
}
.icon-ic_fluent_select_object_skew_20_regular:before {
    content: "\edcd";
}
.icon-ic_fluent_select_object_skew_dismiss_20_filled:before {
    content: "\edce";
}
.icon-ic_fluent_select_object_skew_dismiss_20_regular:before {
    content: "\edcf";
}
.icon-ic_fluent_select_object_skew_edit_20_filled:before {
    content: "\edd0";
}
.icon-ic_fluent_select_object_skew_edit_20_regular:before {
    content: "\edd1";
}
.icon-ic_fluent_send_20_filled:before {
    content: "\edd2";
}
.icon-ic_fluent_send_20_regular:before {
    content: "\edd3";
}
.icon-ic_fluent_send_beaker_20_filled:before {
    content: "\edd4";
}
.icon-ic_fluent_send_beaker_20_regular:before {
    content: "\edd5";
}
.icon-ic_fluent_send_clock_20_filled:before {
    content: "\edd6";
}
.icon-ic_fluent_send_clock_20_regular:before {
    content: "\edd7";
}
.icon-ic_fluent_send_copy_20_filled:before {
    content: "\edd8";
}
.icon-ic_fluent_send_copy_20_regular:before {
    content: "\edd9";
}
.icon-ic_fluent_serial_port_20_filled:before {
    content: "\edda";
}
.icon-ic_fluent_serial_port_20_regular:before {
    content: "\eddb";
}
.icon-ic_fluent_server_20_filled:before {
    content: "\eddc";
}
.icon-ic_fluent_server_20_regular:before {
    content: "\eddd";
}
.icon-ic_fluent_server_link_20_filled:before {
    content: "\edde";
}
.icon-ic_fluent_server_link_20_regular:before {
    content: "\eddf";
}
.icon-ic_fluent_server_multiple_20_filled:before {
    content: "\ede0";
}
.icon-ic_fluent_server_multiple_20_regular:before {
    content: "\ede1";
}
.icon-ic_fluent_server_play_20_filled:before {
    content: "\ede2";
}
.icon-ic_fluent_server_play_20_regular:before {
    content: "\ede3";
}
.icon-ic_fluent_service_bell_20_filled:before {
    content: "\ede4";
}
.icon-ic_fluent_service_bell_20_regular:before {
    content: "\ede5";
}
.icon-ic_fluent_settings_20_filled:before {
    content: "\ede6";
}
.icon-ic_fluent_settings_20_regular:before {
    content: "\ede7";
}
.icon-ic_fluent_settings_chat_20_filled:before {
    content: "\ede8";
}
.icon-ic_fluent_settings_chat_20_regular:before {
    content: "\ede9";
}
.icon-ic_fluent_settings_cog_multiple_20_filled:before {
    content: "\edea";
}
.icon-ic_fluent_settings_cog_multiple_20_regular:before {
    content: "\edeb";
}
.icon-ic_fluent_shape_exclude_20_filled:before {
    content: "\edec";
}
.icon-ic_fluent_shape_exclude_20_regular:before {
    content: "\eded";
}
.icon-ic_fluent_shape_intersect_20_filled:before {
    content: "\edee";
}
.icon-ic_fluent_shape_intersect_20_regular:before {
    content: "\edef";
}
.icon-ic_fluent_shape_organic_20_filled:before {
    content: "\edf0";
}
.icon-ic_fluent_shape_organic_20_regular:before {
    content: "\edf1";
}
.icon-ic_fluent_shape_subtract_20_filled:before {
    content: "\edf2";
}
.icon-ic_fluent_shape_subtract_20_regular:before {
    content: "\edf3";
}
.icon-ic_fluent_shape_union_20_filled:before {
    content: "\edf4";
}
.icon-ic_fluent_shape_union_20_regular:before {
    content: "\edf5";
}
.icon-ic_fluent_shapes_20_filled:before {
    content: "\edf6";
}
.icon-ic_fluent_shapes_20_regular:before {
    content: "\edf7";
}
.icon-ic_fluent_share_20_filled:before {
    content: "\edf8";
}
.icon-ic_fluent_share_20_regular:before {
    content: "\edf9";
}
.icon-ic_fluent_share_android_20_filled:before {
    content: "\edfa";
}
.icon-ic_fluent_share_android_20_regular:before {
    content: "\edfb";
}
.icon-ic_fluent_share_close_tray_20_filled:before {
    content: "\edfc";
}
.icon-ic_fluent_share_close_tray_20_regular:before {
    content: "\edfd";
}
.icon-ic_fluent_share_ios_20_filled:before {
    content: "\edfe";
}
.icon-ic_fluent_share_ios_20_regular:before {
    content: "\edff";
}
.icon-ic_fluent_share_screen_person_20_filled:before {
    content: "\ee00";
}
.icon-ic_fluent_share_screen_person_20_regular:before {
    content: "\ee01";
}
.icon-ic_fluent_share_screen_person_overlay_20_filled:before {
    content: "\ee02";
}
.icon-ic_fluent_share_screen_person_overlay_20_regular:before {
    content: "\ee03";
}
.icon-ic_fluent_share_screen_person_overlay_inside_20_filled:before {
    content: "\ee04";
}
.icon-ic_fluent_share_screen_person_overlay_inside_20_regular:before {
    content: "\ee05";
}
.icon-ic_fluent_share_screen_person_p_20_filled:before {
    content: "\ee06";
}
.icon-ic_fluent_share_screen_person_p_20_regular:before {
    content: "\ee07";
}
.icon-ic_fluent_share_screen_start_20_filled:before {
    content: "\ee08";
}
.icon-ic_fluent_share_screen_start_20_regular:before {
    content: "\ee09";
}
.icon-ic_fluent_share_screen_stop_20_filled:before {
    content: "\ee0a";
}
.icon-ic_fluent_share_screen_stop_20_regular:before {
    content: "\ee0b";
}
.icon-ic_fluent_shield_20_filled:before {
    content: "\ee0c";
}
.icon-ic_fluent_shield_20_regular:before {
    content: "\ee0d";
}
.icon-ic_fluent_shield_add_20_filled:before {
    content: "\ee0e";
}
.icon-ic_fluent_shield_add_20_regular:before {
    content: "\ee0f";
}
.icon-ic_fluent_shield_badge_20_filled:before {
    content: "\ee10";
}
.icon-ic_fluent_shield_badge_20_regular:before {
    content: "\ee11";
}
.icon-ic_fluent_shield_checkmark_20_filled:before {
    content: "\ee12";
}
.icon-ic_fluent_shield_checkmark_20_regular:before {
    content: "\ee13";
}
.icon-ic_fluent_shield_dismiss_20_filled:before {
    content: "\ee14";
}
.icon-ic_fluent_shield_dismiss_20_regular:before {
    content: "\ee15";
}
.icon-ic_fluent_shield_dismiss_shield_20_filled:before {
    content: "\ee16";
}
.icon-ic_fluent_shield_dismiss_shield_20_regular:before {
    content: "\ee17";
}
.icon-ic_fluent_shield_error_20_filled:before {
    content: "\ee18";
}
.icon-ic_fluent_shield_error_20_regular:before {
    content: "\ee19";
}
.icon-ic_fluent_shield_globe_20_filled:before {
    content: "\ee1a";
}
.icon-ic_fluent_shield_globe_20_regular:before {
    content: "\ee1b";
}
.icon-ic_fluent_shield_keyhole_20_filled:before {
    content: "\ee1c";
}
.icon-ic_fluent_shield_keyhole_20_regular:before {
    content: "\ee1d";
}
.icon-ic_fluent_shield_lock_20_filled:before {
    content: "\ee1e";
}
.icon-ic_fluent_shield_lock_20_regular:before {
    content: "\ee1f";
}
.icon-ic_fluent_shield_person_20_filled:before {
    content: "\ee20";
}
.icon-ic_fluent_shield_person_20_regular:before {
    content: "\ee21";
}
.icon-ic_fluent_shield_person_add_20_filled:before {
    content: "\ee22";
}
.icon-ic_fluent_shield_person_add_20_regular:before {
    content: "\ee23";
}
.icon-ic_fluent_shield_prohibited_20_filled:before {
    content: "\ee24";
}
.icon-ic_fluent_shield_prohibited_20_regular:before {
    content: "\ee25";
}
.icon-ic_fluent_shield_question_20_filled:before {
    content: "\ee26";
}
.icon-ic_fluent_shield_question_20_regular:before {
    content: "\ee27";
}
.icon-ic_fluent_shield_task_20_filled:before {
    content: "\ee28";
}
.icon-ic_fluent_shield_task_20_regular:before {
    content: "\ee29";
}
.icon-ic_fluent_shifts_20_filled:before {
    content: "\ee2a";
}
.icon-ic_fluent_shifts_20_regular:before {
    content: "\ee2b";
}
.icon-ic_fluent_shifts_30_minutes_20_filled:before {
    content: "\ee2c";
}
.icon-ic_fluent_shifts_30_minutes_20_regular:before {
    content: "\ee2d";
}
.icon-ic_fluent_shifts_activity_20_filled:before {
    content: "\ee2e";
}
.icon-ic_fluent_shifts_activity_20_regular:before {
    content: "\ee2f";
}
.icon-ic_fluent_shifts_add_20_filled:before {
    content: "\ee30";
}
.icon-ic_fluent_shifts_add_20_regular:before {
    content: "\ee31";
}
.icon-ic_fluent_shifts_availability_20_filled:before {
    content: "\ee32";
}
.icon-ic_fluent_shifts_availability_20_regular:before {
    content: "\ee33";
}
.icon-ic_fluent_shifts_checkmark_20_filled:before {
    content: "\ee34";
}
.icon-ic_fluent_shifts_checkmark_20_regular:before {
    content: "\ee35";
}
.icon-ic_fluent_shifts_day_20_filled:before {
    content: "\ee36";
}
.icon-ic_fluent_shifts_day_20_regular:before {
    content: "\ee37";
}
.icon-ic_fluent_shifts_open_20_filled:before {
    content: "\ee38";
}
.icon-ic_fluent_shifts_open_20_regular:before {
    content: "\ee39";
}
.icon-ic_fluent_shifts_prohibited_20_filled:before {
    content: "\ee3a";
}
.icon-ic_fluent_shifts_prohibited_20_regular:before {
    content: "\ee3b";
}
.icon-ic_fluent_shifts_question_mark_20_filled:before {
    content: "\ee3c";
}
.icon-ic_fluent_shifts_question_mark_20_regular:before {
    content: "\ee3d";
}
.icon-ic_fluent_shifts_team_20_filled:before {
    content: "\ee3e";
}
.icon-ic_fluent_shifts_team_20_regular:before {
    content: "\ee3f";
}
.icon-ic_fluent_shopping_bag_20_filled:before {
    content: "\ee40";
}
.icon-ic_fluent_shopping_bag_20_regular:before {
    content: "\ee41";
}
.icon-ic_fluent_shopping_bag_arrow_left_20_filled:before {
    content: "\ee42";
}
.icon-ic_fluent_shopping_bag_arrow_left_20_regular:before {
    content: "\ee43";
}
.icon-ic_fluent_shopping_bag_dismiss_20_filled:before {
    content: "\ee44";
}
.icon-ic_fluent_shopping_bag_dismiss_20_regular:before {
    content: "\ee45";
}
.icon-ic_fluent_shopping_bag_pause_20_filled:before {
    content: "\ee46";
}
.icon-ic_fluent_shopping_bag_pause_20_regular:before {
    content: "\ee47";
}
.icon-ic_fluent_shopping_bag_percent_20_filled:before {
    content: "\ee48";
}
.icon-ic_fluent_shopping_bag_percent_20_regular:before {
    content: "\ee49";
}
.icon-ic_fluent_shopping_bag_play_20_filled:before {
    content: "\ee4a";
}
.icon-ic_fluent_shopping_bag_play_20_regular:before {
    content: "\ee4b";
}
.icon-ic_fluent_shopping_bag_tag_20_filled:before {
    content: "\ee4c";
}
.icon-ic_fluent_shopping_bag_tag_20_regular:before {
    content: "\ee4d";
}
.icon-ic_fluent_shortpick_20_filled:before {
    content: "\ee4e";
}
.icon-ic_fluent_shortpick_20_regular:before {
    content: "\ee4f";
}
.icon-ic_fluent_showerhead_20_filled:before {
    content: "\ee50";
}
.icon-ic_fluent_showerhead_20_regular:before {
    content: "\ee51";
}
.icon-ic_fluent_sidebar_search_ltr_20_filled:before {
    content: "\ee52";
}
.icon-ic_fluent_sidebar_search_ltr_20_regular:before {
    content: "\ee53";
}
.icon-ic_fluent_sidebar_search_rtl_20_filled:before {
    content: "\ee54";
}
.icon-ic_fluent_sidebar_search_rtl_20_regular:before {
    content: "\ee55";
}
.icon-ic_fluent_sign_out_20_filled:before {
    content: "\ee56";
}
.icon-ic_fluent_sign_out_20_regular:before {
    content: "\ee57";
}
.icon-ic_fluent_signature_20_filled:before {
    content: "\ee58";
}
.icon-ic_fluent_signature_20_regular:before {
    content: "\ee59";
}
.icon-ic_fluent_sim_20_filled:before {
    content: "\ee5a";
}
.icon-ic_fluent_sim_20_regular:before {
    content: "\ee5b";
}
.icon-ic_fluent_skip_back_10_20_filled:before {
    content: "\ee5c";
}
.icon-ic_fluent_skip_back_10_20_regular:before {
    content: "\ee5d";
}
.icon-ic_fluent_skip_forward_10_20_filled:before {
    content: "\ee5e";
}
.icon-ic_fluent_skip_forward_10_20_regular:before {
    content: "\ee5f";
}
.icon-ic_fluent_skip_forward_30_20_filled:before {
    content: "\ee60";
}
.icon-ic_fluent_skip_forward_30_20_regular:before {
    content: "\ee61";
}
.icon-ic_fluent_skip_forward_tab_20_filled:before {
    content: "\ee62";
}
.icon-ic_fluent_skip_forward_tab_20_regular:before {
    content: "\ee63";
}
.icon-ic_fluent_slash_forward_20_filled:before {
    content: "\ee64";
}
.icon-ic_fluent_slash_forward_20_regular:before {
    content: "\ee65";
}
.icon-ic_fluent_sleep_20_filled:before {
    content: "\ee66";
}
.icon-ic_fluent_sleep_20_regular:before {
    content: "\ee67";
}
.icon-ic_fluent_slide_add_20_filled:before {
    content: "\ee68";
}
.icon-ic_fluent_slide_add_20_regular:before {
    content: "\ee69";
}
.icon-ic_fluent_slide_arrow_right_20_filled:before {
    content: "\ee6a";
}
.icon-ic_fluent_slide_arrow_right_20_regular:before {
    content: "\ee6b";
}
.icon-ic_fluent_slide_eraser_20_filled:before {
    content: "\ee6c";
}
.icon-ic_fluent_slide_eraser_20_regular:before {
    content: "\ee6d";
}
.icon-ic_fluent_slide_grid_20_filled:before {
    content: "\ee6e";
}
.icon-ic_fluent_slide_grid_20_regular:before {
    content: "\ee6f";
}
.icon-ic_fluent_slide_hide_20_filled:before {
    content: "\ee70";
}
.icon-ic_fluent_slide_hide_20_regular:before {
    content: "\ee71";
}
.icon-ic_fluent_slide_layout_20_filled:before {
    content: "\ee72";
}
.icon-ic_fluent_slide_layout_20_regular:before {
    content: "\ee73";
}
.icon-ic_fluent_slide_link_20_filled:before {
    content: "\ee74";
}
.icon-ic_fluent_slide_link_20_regular:before {
    content: "\ee75";
}
.icon-ic_fluent_slide_microphone_20_filled:before {
    content: "\ee76";
}
.icon-ic_fluent_slide_microphone_20_regular:before {
    content: "\ee77";
}
.icon-ic_fluent_slide_multiple_20_filled:before {
    content: "\ee78";
}
.icon-ic_fluent_slide_multiple_20_regular:before {
    content: "\ee79";
}
.icon-ic_fluent_slide_multiple_arrow_right_20_filled:before {
    content: "\ee7a";
}
.icon-ic_fluent_slide_multiple_arrow_right_20_regular:before {
    content: "\ee7b";
}
.icon-ic_fluent_slide_multiple_search_20_filled:before {
    content: "\ee7c";
}
.icon-ic_fluent_slide_multiple_search_20_regular:before {
    content: "\ee7d";
}
.icon-ic_fluent_slide_record_20_filled:before {
    content: "\ee7e";
}
.icon-ic_fluent_slide_record_20_regular:before {
    content: "\ee7f";
}
.icon-ic_fluent_slide_search_20_filled:before {
    content: "\ee80";
}
.icon-ic_fluent_slide_search_20_regular:before {
    content: "\ee81";
}
.icon-ic_fluent_slide_settings_20_filled:before {
    content: "\ee82";
}
.icon-ic_fluent_slide_settings_20_regular:before {
    content: "\ee83";
}
.icon-ic_fluent_slide_size_20_filled:before {
    content: "\ee84";
}
.icon-ic_fluent_slide_size_20_regular:before {
    content: "\ee85";
}
.icon-ic_fluent_slide_text_20_filled:before {
    content: "\ee86";
}
.icon-ic_fluent_slide_text_20_regular:before {
    content: "\ee87";
}
.icon-ic_fluent_slide_text_edit_20_filled:before {
    content: "\ee88";
}
.icon-ic_fluent_slide_text_edit_20_regular:before {
    content: "\ee89";
}
.icon-ic_fluent_slide_text_multiple_20_filled:before {
    content: "\ee8a";
}
.icon-ic_fluent_slide_text_multiple_20_regular:before {
    content: "\ee8b";
}
.icon-ic_fluent_slide_text_person_20_filled:before {
    content: "\ee8c";
}
.icon-ic_fluent_slide_text_person_20_regular:before {
    content: "\ee8d";
}
.icon-ic_fluent_slide_text_sparkle_20_filled:before {
    content: "\ee8e";
}
.icon-ic_fluent_slide_text_sparkle_20_regular:before {
    content: "\ee8f";
}
.icon-ic_fluent_slide_transition_20_filled:before {
    content: "\ee90";
}
.icon-ic_fluent_slide_transition_20_regular:before {
    content: "\ee91";
}
.icon-ic_fluent_smartwatch_20_filled:before {
    content: "\ee92";
}
.icon-ic_fluent_smartwatch_20_regular:before {
    content: "\ee93";
}
.icon-ic_fluent_smartwatch_dot_20_filled:before {
    content: "\ee94";
}
.icon-ic_fluent_smartwatch_dot_20_regular:before {
    content: "\ee95";
}
.icon-ic_fluent_snooze_20_filled:before {
    content: "\ee96";
}
.icon-ic_fluent_snooze_20_regular:before {
    content: "\ee97";
}
.icon-ic_fluent_sound_source_20_filled:before {
    content: "\ee98";
}
.icon-ic_fluent_sound_source_20_regular:before {
    content: "\ee99";
}
.icon-ic_fluent_sound_wave_circle_20_filled:before {
    content: "\ee9a";
}
.icon-ic_fluent_sound_wave_circle_20_regular:before {
    content: "\ee9b";
}
.icon-ic_fluent_space_3d_20_filled:before {
    content: "\ee9c";
}
.icon-ic_fluent_space_3d_20_regular:before {
    content: "\ee9d";
}
.icon-ic_fluent_spacebar_20_filled:before {
    content: "\ee9e";
}
.icon-ic_fluent_spacebar_20_regular:before {
    content: "\ee9f";
}
.icon-ic_fluent_sparkle_20_filled:before {
    content: "\eea0";
}
.icon-ic_fluent_sparkle_20_regular:before {
    content: "\eea1";
}
.icon-ic_fluent_sparkle_circle_20_filled:before {
    content: "\eea2";
}
.icon-ic_fluent_sparkle_circle_20_regular:before {
    content: "\eea3";
}
.icon-ic_fluent_speaker_0_20_filled:before {
    content: "\eea4";
}
.icon-ic_fluent_speaker_0_20_regular:before {
    content: "\eea5";
}
.icon-ic_fluent_speaker_1_20_filled:before {
    content: "\eea6";
}
.icon-ic_fluent_speaker_1_20_regular:before {
    content: "\eea7";
}
.icon-ic_fluent_speaker_2_20_filled:before {
    content: "\eea8";
}
.icon-ic_fluent_speaker_2_20_regular:before {
    content: "\eea9";
}
.icon-ic_fluent_speaker_bluetooth_20_filled:before {
    content: "\eeaa";
}
.icon-ic_fluent_speaker_bluetooth_20_regular:before {
    content: "\eeab";
}
.icon-ic_fluent_speaker_edit_20_filled:before {
    content: "\eeac";
}
.icon-ic_fluent_speaker_edit_20_regular:before {
    content: "\eead";
}
.icon-ic_fluent_speaker_mute_20_filled:before {
    content: "\eeae";
}
.icon-ic_fluent_speaker_mute_20_regular:before {
    content: "\eeaf";
}
.icon-ic_fluent_speaker_off_20_filled:before {
    content: "\eeb0";
}
.icon-ic_fluent_speaker_off_20_regular:before {
    content: "\eeb1";
}
.icon-ic_fluent_speaker_settings_20_filled:before {
    content: "\eeb2";
}
.icon-ic_fluent_speaker_settings_20_regular:before {
    content: "\eeb3";
}
.icon-ic_fluent_speaker_usb_20_filled:before {
    content: "\eeb4";
}
.icon-ic_fluent_speaker_usb_20_regular:before {
    content: "\eeb5";
}
.icon-ic_fluent_spinner_ios_20_filled:before {
    content: "\eeb6";
}
.icon-ic_fluent_spinner_ios_20_regular:before {
    content: "\eeb7";
}
.icon-ic_fluent_split_hint_20_filled:before {
    content: "\eeb8";
}
.icon-ic_fluent_split_hint_20_regular:before {
    content: "\eeb9";
}
.icon-ic_fluent_split_horizontal_20_filled:before {
    content: "\eeba";
}
.icon-ic_fluent_split_horizontal_20_regular:before {
    content: "\eebb";
}
.icon-ic_fluent_split_vertical_20_filled:before {
    content: "\eebc";
}
.icon-ic_fluent_split_vertical_20_regular:before {
    content: "\eebd";
}
.icon-ic_fluent_sport_20_filled:before {
    content: "\eebe";
}
.icon-ic_fluent_sport_20_regular:before {
    content: "\eebf";
}
.icon-ic_fluent_sport_american_football_20_filled:before {
    content: "\eec0";
}
.icon-ic_fluent_sport_american_football_20_regular:before {
    content: "\eec1";
}
.icon-ic_fluent_sport_baseball_20_filled:before {
    content: "\eec2";
}
.icon-ic_fluent_sport_baseball_20_regular:before {
    content: "\eec3";
}
.icon-ic_fluent_sport_basketball_20_filled:before {
    content: "\eec4";
}
.icon-ic_fluent_sport_basketball_20_regular:before {
    content: "\eec5";
}
.icon-ic_fluent_sport_hockey_20_filled:before {
    content: "\eec6";
}
.icon-ic_fluent_sport_hockey_20_regular:before {
    content: "\eec7";
}
.icon-ic_fluent_sport_soccer_20_filled:before {
    content: "\eec8";
}
.icon-ic_fluent_sport_soccer_20_regular:before {
    content: "\eec9";
}
.icon-ic_fluent_square_20_filled:before {
    content: "\eeca";
}
.icon-ic_fluent_square_20_regular:before {
    content: "\eecb";
}
.icon-ic_fluent_square_add_20_filled:before {
    content: "\eecc";
}
.icon-ic_fluent_square_add_20_regular:before {
    content: "\eecd";
}
.icon-ic_fluent_square_arrow_forward_20_filled:before {
    content: "\eece";
}
.icon-ic_fluent_square_arrow_forward_20_regular:before {
    content: "\eecf";
}
.icon-ic_fluent_square_dismiss_20_filled:before {
    content: "\eed0";
}
.icon-ic_fluent_square_dismiss_20_regular:before {
    content: "\eed1";
}
.icon-ic_fluent_square_eraser_20_filled:before {
    content: "\eed2";
}
.icon-ic_fluent_square_eraser_20_regular:before {
    content: "\eed3";
}
.icon-ic_fluent_square_hint_20_filled:before {
    content: "\eed4";
}
.icon-ic_fluent_square_hint_20_regular:before {
    content: "\eed5";
}
.icon-ic_fluent_square_hint_apps_20_filled:before {
    content: "\eed6";
}
.icon-ic_fluent_square_hint_apps_20_regular:before {
    content: "\eed7";
}
.icon-ic_fluent_square_hint_arrow_back_20_filled:before {
    content: "\eed8";
}
.icon-ic_fluent_square_hint_arrow_back_20_regular:before {
    content: "\eed9";
}
.icon-ic_fluent_square_hint_hexagon_20_filled:before {
    content: "\eeda";
}
.icon-ic_fluent_square_hint_hexagon_20_regular:before {
    content: "\eedb";
}
.icon-ic_fluent_square_hint_sparkles_20_filled:before {
    content: "\eedc";
}
.icon-ic_fluent_square_hint_sparkles_20_regular:before {
    content: "\eedd";
}
.icon-ic_fluent_square_multiple_20_filled:before {
    content: "\eede";
}
.icon-ic_fluent_square_multiple_20_regular:before {
    content: "\eedf";
}
.icon-ic_fluent_square_shadow_20_filled:before {
    content: "\eee0";
}
.icon-ic_fluent_square_shadow_20_regular:before {
    content: "\eee1";
}
.icon-ic_fluent_squares_nested_20_filled:before {
    content: "\eee2";
}
.icon-ic_fluent_squares_nested_20_regular:before {
    content: "\eee3";
}
.icon-ic_fluent_stack_20_filled:before {
    content: "\eee4";
}
.icon-ic_fluent_stack_20_regular:before {
    content: "\eee5";
}
.icon-ic_fluent_stack_add_20_filled:before {
    content: "\eee6";
}
.icon-ic_fluent_stack_add_20_regular:before {
    content: "\eee7";
}
.icon-ic_fluent_stack_arrow_forward_20_filled:before {
    content: "\eee8";
}
.icon-ic_fluent_stack_arrow_forward_20_regular:before {
    content: "\eee9";
}
.icon-ic_fluent_stack_star_20_filled:before {
    content: "\eeea";
}
.icon-ic_fluent_stack_star_20_regular:before {
    content: "\eeeb";
}
.icon-ic_fluent_stack_vertical_20_filled:before {
    content: "\eeec";
}
.icon-ic_fluent_stack_vertical_20_regular:before {
    content: "\eeed";
}
.icon-ic_fluent_star_20_filled:before {
    content: "\eeee";
}
.icon-ic_fluent_star_20_regular:before {
    content: "\eeef";
}
.icon-ic_fluent_star_add_20_filled:before {
    content: "\eef0";
}
.icon-ic_fluent_star_add_20_regular:before {
    content: "\eef1";
}
.icon-ic_fluent_star_arrow_back_20_filled:before {
    content: "\eef2";
}
.icon-ic_fluent_star_arrow_back_20_regular:before {
    content: "\eef3";
}
.icon-ic_fluent_star_arrow_right_end_20_filled:before {
    content: "\eef4";
}
.icon-ic_fluent_star_arrow_right_end_20_regular:before {
    content: "\eef5";
}
.icon-ic_fluent_star_arrow_right_start_20_filled:before {
    content: "\eef6";
}
.icon-ic_fluent_star_arrow_right_start_20_regular:before {
    content: "\eef7";
}
.icon-ic_fluent_star_checkmark_20_filled:before {
    content: "\eef8";
}
.icon-ic_fluent_star_checkmark_20_regular:before {
    content: "\eef9";
}
.icon-ic_fluent_star_dismiss_20_filled:before {
    content: "\eefa";
}
.icon-ic_fluent_star_dismiss_20_regular:before {
    content: "\eefb";
}
.icon-ic_fluent_star_edit_20_filled:before {
    content: "\eefc";
}
.icon-ic_fluent_star_edit_20_regular:before {
    content: "\eefd";
}
.icon-ic_fluent_star_emphasis_20_filled:before {
    content: "\eefe";
}
.icon-ic_fluent_star_emphasis_20_regular:before {
    content: "\eeff";
}
.icon-ic_fluent_star_half_20_filled:before {
    content: "\ef00";
}
.icon-ic_fluent_star_half_20_regular:before {
    content: "\ef01";
}
.icon-ic_fluent_star_line_horizontal_3_20_filled:before {
    content: "\ef02";
}
.icon-ic_fluent_star_line_horizontal_3_20_regular:before {
    content: "\ef03";
}
.icon-ic_fluent_star_off_20_filled:before {
    content: "\ef04";
}
.icon-ic_fluent_star_off_20_regular:before {
    content: "\ef05";
}
.icon-ic_fluent_star_one_quarter_20_filled:before {
    content: "\ef06";
}
.icon-ic_fluent_star_one_quarter_20_regular:before {
    content: "\ef07";
}
.icon-ic_fluent_star_prohibited_20_filled:before {
    content: "\ef08";
}
.icon-ic_fluent_star_prohibited_20_regular:before {
    content: "\ef09";
}
.icon-ic_fluent_star_settings_20_filled:before {
    content: "\ef0a";
}
.icon-ic_fluent_star_settings_20_regular:before {
    content: "\ef0b";
}
.icon-ic_fluent_star_three_quarter_20_filled:before {
    content: "\ef0c";
}
.icon-ic_fluent_star_three_quarter_20_regular:before {
    content: "\ef0d";
}
.icon-ic_fluent_status_20_filled:before {
    content: "\ef0e";
}
.icon-ic_fluent_status_20_regular:before {
    content: "\ef0f";
}
.icon-ic_fluent_step_20_filled:before {
    content: "\ef10";
}
.icon-ic_fluent_step_20_regular:before {
    content: "\ef11";
}
.icon-ic_fluent_steps_20_filled:before {
    content: "\ef12";
}
.icon-ic_fluent_steps_20_regular:before {
    content: "\ef13";
}
.icon-ic_fluent_stethoscope_20_filled:before {
    content: "\ef14";
}
.icon-ic_fluent_stethoscope_20_regular:before {
    content: "\ef15";
}
.icon-ic_fluent_sticker_20_filled:before {
    content: "\ef16";
}
.icon-ic_fluent_sticker_20_regular:before {
    content: "\ef17";
}
.icon-ic_fluent_sticker_add_20_filled:before {
    content: "\ef18";
}
.icon-ic_fluent_sticker_add_20_regular:before {
    content: "\ef19";
}
.icon-ic_fluent_stop_20_filled:before {
    content: "\ef1a";
}
.icon-ic_fluent_stop_20_regular:before {
    content: "\ef1b";
}
.icon-ic_fluent_storage_20_filled:before {
    content: "\ef1c";
}
.icon-ic_fluent_storage_20_regular:before {
    content: "\ef1d";
}
.icon-ic_fluent_store_microsoft_20_filled:before {
    content: "\ef1e";
}
.icon-ic_fluent_store_microsoft_20_regular:before {
    content: "\ef1f";
}
.icon-ic_fluent_stream_20_filled:before {
    content: "\ef20";
}
.icon-ic_fluent_stream_20_regular:before {
    content: "\ef21";
}
.icon-ic_fluent_stream_input_20_filled:before {
    content: "\ef22";
}
.icon-ic_fluent_stream_input_20_regular:before {
    content: "\ef23";
}
.icon-ic_fluent_stream_input_output_20_filled:before {
    content: "\ef24";
}
.icon-ic_fluent_stream_input_output_20_regular:before {
    content: "\ef25";
}
.icon-ic_fluent_stream_output_20_filled:before {
    content: "\ef26";
}
.icon-ic_fluent_stream_output_20_regular:before {
    content: "\ef27";
}
.icon-ic_fluent_style_guide_20_filled:before {
    content: "\ef28";
}
.icon-ic_fluent_style_guide_20_regular:before {
    content: "\ef29";
}
.icon-ic_fluent_sub_grid_20_filled:before {
    content: "\ef2a";
}
.icon-ic_fluent_sub_grid_20_regular:before {
    content: "\ef2b";
}
.icon-ic_fluent_subtitles_20_filled:before {
    content: "\ef2c";
}
.icon-ic_fluent_subtitles_20_regular:before {
    content: "\ef2d";
}
.icon-ic_fluent_subtract_20_filled:before {
    content: "\ef2e";
}
.icon-ic_fluent_subtract_20_regular:before {
    content: "\ef2f";
}
.icon-ic_fluent_subtract_circle_20_filled:before {
    content: "\ef30";
}
.icon-ic_fluent_subtract_circle_20_regular:before {
    content: "\ef31";
}
.icon-ic_fluent_subtract_circle_arrow_back_20_filled:before {
    content: "\ef32";
}
.icon-ic_fluent_subtract_circle_arrow_back_20_regular:before {
    content: "\ef33";
}
.icon-ic_fluent_subtract_circle_arrow_forward_20_filled:before {
    content: "\ef34";
}
.icon-ic_fluent_subtract_circle_arrow_forward_20_regular:before {
    content: "\ef35";
}
.icon-ic_fluent_subtract_parentheses_20_filled:before {
    content: "\ef36";
}
.icon-ic_fluent_subtract_parentheses_20_regular:before {
    content: "\ef37";
}
.icon-ic_fluent_subtract_square_20_filled:before {
    content: "\ef38";
}
.icon-ic_fluent_subtract_square_20_regular:before {
    content: "\ef39";
}
.icon-ic_fluent_subtract_square_multiple_20_filled:before {
    content: "\ef3a";
}
.icon-ic_fluent_subtract_square_multiple_20_regular:before {
    content: "\ef3b";
}
.icon-ic_fluent_surface_earbuds_20_filled:before {
    content: "\ef3c";
}
.icon-ic_fluent_surface_earbuds_20_regular:before {
    content: "\ef3d";
}
.icon-ic_fluent_surface_hub_20_filled:before {
    content: "\ef3e";
}
.icon-ic_fluent_surface_hub_20_regular:before {
    content: "\ef3f";
}
.icon-ic_fluent_swimming_pool_20_filled:before {
    content: "\ef40";
}
.icon-ic_fluent_swimming_pool_20_regular:before {
    content: "\ef41";
}
.icon-ic_fluent_swipe_down_20_filled:before {
    content: "\ef42";
}
.icon-ic_fluent_swipe_down_20_regular:before {
    content: "\ef43";
}
.icon-ic_fluent_swipe_right_20_filled:before {
    content: "\ef44";
}
.icon-ic_fluent_swipe_right_20_regular:before {
    content: "\ef45";
}
.icon-ic_fluent_swipe_up_20_filled:before {
    content: "\ef46";
}
.icon-ic_fluent_swipe_up_20_regular:before {
    content: "\ef47";
}
.icon-ic_fluent_symbols_20_filled:before {
    content: "\ef48";
}
.icon-ic_fluent_symbols_20_regular:before {
    content: "\ef49";
}
.icon-ic_fluent_sync_off_20_filled:before {
    content: "\ef4a";
}
.icon-ic_fluent_sync_off_20_regular:before {
    content: "\ef4b";
}
.icon-ic_fluent_syringe_20_filled:before {
    content: "\ef4c";
}
.icon-ic_fluent_syringe_20_regular:before {
    content: "\ef4d";
}
.icon-ic_fluent_system_20_filled:before {
    content: "\ef4e";
}
.icon-ic_fluent_system_20_regular:before {
    content: "\ef4f";
}
.icon-ic_fluent_tab_20_filled:before {
    content: "\ef50";
}
.icon-ic_fluent_tab_20_regular:before {
    content: "\ef51";
}
.icon-ic_fluent_tab_add_20_filled:before {
    content: "\ef52";
}
.icon-ic_fluent_tab_add_20_regular:before {
    content: "\ef53";
}
.icon-ic_fluent_tab_arrow_left_20_filled:before {
    content: "\ef54";
}
.icon-ic_fluent_tab_arrow_left_20_regular:before {
    content: "\ef55";
}
.icon-ic_fluent_tab_desktop_20_filled:before {
    content: "\ef56";
}
.icon-ic_fluent_tab_desktop_20_regular:before {
    content: "\ef57";
}
.icon-ic_fluent_tab_desktop_arrow_clockwise_20_filled:before {
    content: "\ef58";
}
.icon-ic_fluent_tab_desktop_arrow_clockwise_20_regular:before {
    content: "\ef59";
}
.icon-ic_fluent_tab_desktop_arrow_left_20_filled:before {
    content: "\ef5a";
}
.icon-ic_fluent_tab_desktop_arrow_left_20_regular:before {
    content: "\ef5b";
}
.icon-ic_fluent_tab_desktop_bottom_20_filled:before {
    content: "\ef5c";
}
.icon-ic_fluent_tab_desktop_bottom_20_regular:before {
    content: "\ef5d";
}
.icon-ic_fluent_tab_desktop_clock_20_filled:before {
    content: "\ef5e";
}
.icon-ic_fluent_tab_desktop_clock_20_regular:before {
    content: "\ef5f";
}
.icon-ic_fluent_tab_desktop_copy_20_filled:before {
    content: "\ef60";
}
.icon-ic_fluent_tab_desktop_copy_20_regular:before {
    content: "\ef61";
}
.icon-ic_fluent_tab_desktop_image_20_filled:before {
    content: "\ef62";
}
.icon-ic_fluent_tab_desktop_image_20_regular:before {
    content: "\ef63";
}
.icon-ic_fluent_tab_desktop_link_20_filled:before {
    content: "\ef64";
}
.icon-ic_fluent_tab_desktop_link_20_regular:before {
    content: "\ef65";
}
.icon-ic_fluent_tab_desktop_multiple_20_filled:before {
    content: "\ef66";
}
.icon-ic_fluent_tab_desktop_multiple_20_regular:before {
    content: "\ef67";
}
.icon-ic_fluent_tab_desktop_multiple_add_20_filled:before {
    content: "\ef68";
}
.icon-ic_fluent_tab_desktop_multiple_add_20_regular:before {
    content: "\ef69";
}
.icon-ic_fluent_tab_desktop_multiple_bottom_20_filled:before {
    content: "\ef6a";
}
.icon-ic_fluent_tab_desktop_multiple_bottom_20_regular:before {
    content: "\ef6b";
}
.icon-ic_fluent_tab_desktop_new_page_20_filled:before {
    content: "\ef6c";
}
.icon-ic_fluent_tab_desktop_new_page_20_regular:before {
    content: "\ef6d";
}
.icon-ic_fluent_tab_in_private_20_filled:before {
    content: "\ef6e";
}
.icon-ic_fluent_tab_in_private_20_regular:before {
    content: "\ef6f";
}
.icon-ic_fluent_tab_inprivate_account_20_filled:before {
    content: "\ef70";
}
.icon-ic_fluent_tab_inprivate_account_20_regular:before {
    content: "\ef71";
}
.icon-ic_fluent_tab_prohibited_20_filled:before {
    content: "\ef72";
}
.icon-ic_fluent_tab_prohibited_20_regular:before {
    content: "\ef73";
}
.icon-ic_fluent_tab_shield_dismiss_20_filled:before {
    content: "\ef74";
}
.icon-ic_fluent_tab_shield_dismiss_20_regular:before {
    content: "\ef75";
}
.icon-ic_fluent_table_20_filled:before {
    content: "\ef76";
}
.icon-ic_fluent_table_20_regular:before {
    content: "\ef77";
}
.icon-ic_fluent_table_add_20_filled:before {
    content: "\ef78";
}
.icon-ic_fluent_table_add_20_regular:before {
    content: "\ef79";
}
.icon-ic_fluent_table_arrow_up_20_filled:before {
    content: "\ef7a";
}
.icon-ic_fluent_table_arrow_up_20_regular:before {
    content: "\ef7b";
}
.icon-ic_fluent_table_bottom_row_20_filled:before {
    content: "\ef7c";
}
.icon-ic_fluent_table_bottom_row_20_regular:before {
    content: "\ef7d";
}
.icon-ic_fluent_table_calculator_20_filled:before {
    content: "\ef7e";
}
.icon-ic_fluent_table_calculator_20_regular:before {
    content: "\ef7f";
}
.icon-ic_fluent_table_cell_edit_20_filled:before {
    content: "\ef80";
}
.icon-ic_fluent_table_cell_edit_20_regular:before {
    content: "\ef81";
}
.icon-ic_fluent_table_cells_merge_20_filled:before {
    content: "\ef82";
}
.icon-ic_fluent_table_cells_merge_20_regular:before {
    content: "\ef83";
}
.icon-ic_fluent_table_cells_split_20_filled:before {
    content: "\ef84";
}
.icon-ic_fluent_table_cells_split_20_regular:before {
    content: "\ef85";
}
.icon-ic_fluent_table_checker_20_filled:before {
    content: "\ef86";
}
.icon-ic_fluent_table_checker_20_regular:before {
    content: "\ef87";
}
.icon-ic_fluent_table_column_top_bottom_20_filled:before {
    content: "\ef88";
}
.icon-ic_fluent_table_column_top_bottom_20_regular:before {
    content: "\ef89";
}
.icon-ic_fluent_table_copy_20_filled:before {
    content: "\ef8a";
}
.icon-ic_fluent_table_copy_20_regular:before {
    content: "\ef8b";
}
.icon-ic_fluent_table_delete_column_20_filled:before {
    content: "\ef8c";
}
.icon-ic_fluent_table_delete_column_20_regular:before {
    content: "\ef8d";
}
.icon-ic_fluent_table_delete_row_20_filled:before {
    content: "\ef8e";
}
.icon-ic_fluent_table_delete_row_20_regular:before {
    content: "\ef8f";
}
.icon-ic_fluent_table_dismiss_20_filled:before {
    content: "\ef90";
}
.icon-ic_fluent_table_dismiss_20_regular:before {
    content: "\ef91";
}
.icon-ic_fluent_table_edit_20_filled:before {
    content: "\ef92";
}
.icon-ic_fluent_table_edit_20_regular:before {
    content: "\ef93";
}
.icon-ic_fluent_table_freeze_column_20_filled:before {
    content: "\ef94";
}
.icon-ic_fluent_table_freeze_column_20_regular:before {
    content: "\ef95";
}
.icon-ic_fluent_table_freeze_column_and_row_20_filled:before {
    content: "\ef96";
}
.icon-ic_fluent_table_freeze_column_and_row_20_regular:before {
    content: "\ef97";
}
.icon-ic_fluent_table_freeze_row_20_filled:before {
    content: "\ef98";
}
.icon-ic_fluent_table_freeze_row_20_regular:before {
    content: "\ef99";
}
.icon-ic_fluent_table_image_20_filled:before {
    content: "\ef9a";
}
.icon-ic_fluent_table_image_20_regular:before {
    content: "\ef9b";
}
.icon-ic_fluent_table_insert_column_20_filled:before {
    content: "\ef9c";
}
.icon-ic_fluent_table_insert_column_20_regular:before {
    content: "\ef9d";
}
.icon-ic_fluent_table_insert_row_20_filled:before {
    content: "\ef9e";
}
.icon-ic_fluent_table_insert_row_20_regular:before {
    content: "\ef9f";
}
.icon-ic_fluent_table_lightning_20_filled:before {
    content: "\efa0";
}
.icon-ic_fluent_table_lightning_20_regular:before {
    content: "\efa1";
}
.icon-ic_fluent_table_link_20_filled:before {
    content: "\efa2";
}
.icon-ic_fluent_table_link_20_regular:before {
    content: "\efa3";
}
.icon-ic_fluent_table_lock_20_filled:before {
    content: "\efa4";
}
.icon-ic_fluent_table_lock_20_regular:before {
    content: "\efa5";
}
.icon-ic_fluent_table_move_above_20_filled:before {
    content: "\efa6";
}
.icon-ic_fluent_table_move_above_20_regular:before {
    content: "\efa7";
}
.icon-ic_fluent_table_move_below_20_filled:before {
    content: "\efa8";
}
.icon-ic_fluent_table_move_below_20_regular:before {
    content: "\efa9";
}
.icon-ic_fluent_table_move_left_20_filled:before {
    content: "\efaa";
}
.icon-ic_fluent_table_move_left_20_regular:before {
    content: "\efab";
}
.icon-ic_fluent_table_move_right_20_filled:before {
    content: "\efac";
}
.icon-ic_fluent_table_move_right_20_regular:before {
    content: "\efad";
}
.icon-ic_fluent_table_multiple_20_filled:before {
    content: "\efae";
}
.icon-ic_fluent_table_multiple_20_regular:before {
    content: "\efaf";
}
.icon-ic_fluent_table_offset_20_filled:before {
    content: "\efb0";
}
.icon-ic_fluent_table_offset_20_regular:before {
    content: "\efb1";
}
.icon-ic_fluent_table_offset_add_20_filled:before {
    content: "\efb2";
}
.icon-ic_fluent_table_offset_add_20_regular:before {
    content: "\efb3";
}
.icon-ic_fluent_table_offset_less_than_or_equal_to_20_filled:before {
    content: "\efb4";
}
.icon-ic_fluent_table_offset_less_than_or_equal_to_20_regular:before {
    content: "\efb5";
}
.icon-ic_fluent_table_offset_settings_20_filled:before {
    content: "\efb6";
}
.icon-ic_fluent_table_offset_settings_20_regular:before {
    content: "\efb7";
}
.icon-ic_fluent_table_resize_column_20_filled:before {
    content: "\efb8";
}
.icon-ic_fluent_table_resize_column_20_regular:before {
    content: "\efb9";
}
.icon-ic_fluent_table_resize_row_20_filled:before {
    content: "\efba";
}
.icon-ic_fluent_table_resize_row_20_regular:before {
    content: "\efbb";
}
.icon-ic_fluent_table_search_20_filled:before {
    content: "\efbc";
}
.icon-ic_fluent_table_search_20_regular:before {
    content: "\efbd";
}
.icon-ic_fluent_table_settings_20_filled:before {
    content: "\efbe";
}
.icon-ic_fluent_table_settings_20_regular:before {
    content: "\efbf";
}
.icon-ic_fluent_table_simple_20_filled:before {
    content: "\efc0";
}
.icon-ic_fluent_table_simple_20_regular:before {
    content: "\efc1";
}
.icon-ic_fluent_table_simple_checkmark_20_filled:before {
    content: "\efc2";
}
.icon-ic_fluent_table_simple_checkmark_20_regular:before {
    content: "\efc3";
}
.icon-ic_fluent_table_simple_exclude_20_filled:before {
    content: "\efc4";
}
.icon-ic_fluent_table_simple_exclude_20_regular:before {
    content: "\efc5";
}
.icon-ic_fluent_table_simple_include_20_filled:before {
    content: "\efc6";
}
.icon-ic_fluent_table_simple_include_20_regular:before {
    content: "\efc7";
}
.icon-ic_fluent_table_simple_multiple_20_filled:before {
    content: "\efc8";
}
.icon-ic_fluent_table_simple_multiple_20_regular:before {
    content: "\efc9";
}
.icon-ic_fluent_table_split_20_filled:before {
    content: "\efca";
}
.icon-ic_fluent_table_split_20_regular:before {
    content: "\efcb";
}
.icon-ic_fluent_table_stack_above_20_filled:before {
    content: "\efcc";
}
.icon-ic_fluent_table_stack_above_20_regular:before {
    content: "\efcd";
}
.icon-ic_fluent_table_stack_below_20_filled:before {
    content: "\efce";
}
.icon-ic_fluent_table_stack_below_20_regular:before {
    content: "\efcf";
}
.icon-ic_fluent_table_stack_left_20_filled:before {
    content: "\efd0";
}
.icon-ic_fluent_table_stack_left_20_regular:before {
    content: "\efd1";
}
.icon-ic_fluent_table_stack_right_20_filled:before {
    content: "\efd2";
}
.icon-ic_fluent_table_stack_right_20_regular:before {
    content: "\efd3";
}
.icon-ic_fluent_table_switch_20_filled:before {
    content: "\efd4";
}
.icon-ic_fluent_table_switch_20_regular:before {
    content: "\efd5";
}
.icon-ic_fluent_tablet_20_filled:before {
    content: "\efd6";
}
.icon-ic_fluent_tablet_20_regular:before {
    content: "\efd7";
}
.icon-ic_fluent_tablet_laptop_20_filled:before {
    content: "\efd8";
}
.icon-ic_fluent_tablet_laptop_20_regular:before {
    content: "\efd9";
}
.icon-ic_fluent_tablet_speaker_20_filled:before {
    content: "\efda";
}
.icon-ic_fluent_tablet_speaker_20_regular:before {
    content: "\efdb";
}
.icon-ic_fluent_tabs_20_filled:before {
    content: "\efdc";
}
.icon-ic_fluent_tabs_20_regular:before {
    content: "\efdd";
}
.icon-ic_fluent_tag_20_filled:before {
    content: "\efde";
}
.icon-ic_fluent_tag_20_regular:before {
    content: "\efdf";
}
.icon-ic_fluent_tag_circle_20_filled:before {
    content: "\efe0";
}
.icon-ic_fluent_tag_circle_20_regular:before {
    content: "\efe1";
}
.icon-ic_fluent_tag_dismiss_20_filled:before {
    content: "\efe2";
}
.icon-ic_fluent_tag_dismiss_20_regular:before {
    content: "\efe3";
}
.icon-ic_fluent_tag_error_20_filled:before {
    content: "\efe4";
}
.icon-ic_fluent_tag_error_20_regular:before {
    content: "\efe5";
}
.icon-ic_fluent_tag_lock_20_filled:before {
    content: "\efe6";
}
.icon-ic_fluent_tag_lock_20_regular:before {
    content: "\efe7";
}
.icon-ic_fluent_tag_lock_accent_20_filled:before {
    content: "\efe8";
}
.icon-ic_fluent_tag_multiple_20_filled:before {
    content: "\efe9";
}
.icon-ic_fluent_tag_multiple_20_regular:before {
    content: "\efea";
}
.icon-ic_fluent_tag_off_20_filled:before {
    content: "\efeb";
}
.icon-ic_fluent_tag_off_20_regular:before {
    content: "\efec";
}
.icon-ic_fluent_tag_question_mark_20_filled:before {
    content: "\efed";
}
.icon-ic_fluent_tag_question_mark_20_regular:before {
    content: "\efee";
}
.icon-ic_fluent_tag_reset_20_filled:before {
    content: "\efef";
}
.icon-ic_fluent_tag_reset_20_regular:before {
    content: "\eff0";
}
.icon-ic_fluent_tag_search_20_filled:before {
    content: "\eff1";
}
.icon-ic_fluent_tag_search_20_regular:before {
    content: "\eff2";
}
.icon-ic_fluent_tap_double_20_filled:before {
    content: "\eff3";
}
.icon-ic_fluent_tap_double_20_regular:before {
    content: "\eff4";
}
.icon-ic_fluent_tap_single_20_filled:before {
    content: "\eff5";
}
.icon-ic_fluent_tap_single_20_regular:before {
    content: "\eff6";
}
.icon-ic_fluent_target_20_filled:before {
    content: "\eff7";
}
.icon-ic_fluent_target_20_regular:before {
    content: "\eff8";
}
.icon-ic_fluent_target_add_20_filled:before {
    content: "\eff9";
}
.icon-ic_fluent_target_add_20_regular:before {
    content: "\effa";
}
.icon-ic_fluent_target_arrow_20_filled:before {
    content: "\effb";
}
.icon-ic_fluent_target_arrow_20_regular:before {
    content: "\effc";
}
.icon-ic_fluent_target_dismiss_20_filled:before {
    content: "\effd";
}
.icon-ic_fluent_target_dismiss_20_regular:before {
    content: "\effe";
}
.icon-ic_fluent_target_edit_20_filled:before {
    content: "\efff";
}
.icon-ic_fluent_target_edit_20_regular:before {
    content: "\f000";
}
.icon-ic_fluent_task_list_add_20_filled:before {
    content: "\f001";
}
.icon-ic_fluent_task_list_add_20_regular:before {
    content: "\f002";
}
.icon-ic_fluent_task_list_ltr_20_filled:before {
    content: "\f003";
}
.icon-ic_fluent_task_list_ltr_20_regular:before {
    content: "\f004";
}
.icon-ic_fluent_task_list_rtl_20_filled:before {
    content: "\f005";
}
.icon-ic_fluent_task_list_rtl_20_regular:before {
    content: "\f006";
}
.icon-ic_fluent_task_list_square_add_20_filled:before {
    content: "\f007";
}
.icon-ic_fluent_task_list_square_add_20_regular:before {
    content: "\f008";
}
.icon-ic_fluent_task_list_square_database_20_filled:before {
    content: "\f009";
}
.icon-ic_fluent_task_list_square_database_20_regular:before {
    content: "\f00a";
}
.icon-ic_fluent_task_list_square_ltr_20_filled:before {
    content: "\f00b";
}
.icon-ic_fluent_task_list_square_ltr_20_regular:before {
    content: "\f00c";
}
.icon-ic_fluent_task_list_square_person_20_filled:before {
    content: "\f00d";
}
.icon-ic_fluent_task_list_square_person_20_regular:before {
    content: "\f00e";
}
.icon-ic_fluent_task_list_square_rtl_20_filled:before {
    content: "\f00f";
}
.icon-ic_fluent_task_list_square_rtl_20_regular:before {
    content: "\f010";
}
.icon-ic_fluent_task_list_square_settings_20_filled:before {
    content: "\f011";
}
.icon-ic_fluent_task_list_square_settings_20_regular:before {
    content: "\f012";
}
.icon-ic_fluent_tasks_app_20_filled:before {
    content: "\f013";
}
.icon-ic_fluent_tasks_app_20_regular:before {
    content: "\f014";
}
.icon-ic_fluent_teardrop_bottom_right_20_filled:before {
    content: "\f015";
}
.icon-ic_fluent_teardrop_bottom_right_20_regular:before {
    content: "\f016";
}
.icon-ic_fluent_teddy_20_filled:before {
    content: "\f017";
}
.icon-ic_fluent_teddy_20_regular:before {
    content: "\f018";
}
.icon-ic_fluent_temperature_20_filled:before {
    content: "\f019";
}
.icon-ic_fluent_temperature_20_regular:before {
    content: "\f01a";
}
.icon-ic_fluent_tent_20_filled:before {
    content: "\f01b";
}
.icon-ic_fluent_tent_20_regular:before {
    content: "\f01c";
}
.icon-ic_fluent_tetris_app_20_filled:before {
    content: "\f01d";
}
.icon-ic_fluent_tetris_app_20_regular:before {
    content: "\f01e";
}
.icon-ic_fluent_text_add_20_filled:before {
    content: "\f01f";
}
.icon-ic_fluent_text_add_20_regular:before {
    content: "\f020";
}
.icon-ic_fluent_text_add_space_after_20_filled:before {
    content: "\f021";
}
.icon-ic_fluent_text_add_space_after_20_regular:before {
    content: "\f022";
}
.icon-ic_fluent_text_add_space_before_20_filled:before {
    content: "\f023";
}
.icon-ic_fluent_text_add_space_before_20_regular:before {
    content: "\f024";
}
.icon-ic_fluent_text_add_t_20_filled:before {
    content: "\f025";
}
.icon-ic_fluent_text_add_t_20_regular:before {
    content: "\f026";
}
.icon-ic_fluent_text_align_center_20_filled:before {
    content: "\f027";
}
.icon-ic_fluent_text_align_center_20_regular:before {
    content: "\f028";
}
.icon-ic_fluent_text_align_center_rotate_270_20_filled:before {
    content: "\f029";
}
.icon-ic_fluent_text_align_center_rotate_270_20_regular:before {
    content: "\f02a";
}
.icon-ic_fluent_text_align_center_rotate_90_20_filled:before {
    content: "\f02b";
}
.icon-ic_fluent_text_align_center_rotate_90_20_regular:before {
    content: "\f02c";
}
.icon-ic_fluent_text_align_distributed_20_filled:before {
    content: "\f02d";
}
.icon-ic_fluent_text_align_distributed_20_regular:before {
    content: "\f02e";
}
.icon-ic_fluent_text_align_distributed_evenly_20_filled:before {
    content: "\f02f";
}
.icon-ic_fluent_text_align_distributed_evenly_20_regular:before {
    content: "\f030";
}
.icon-ic_fluent_text_align_distributed_vertical_20_filled:before {
    content: "\f031";
}
.icon-ic_fluent_text_align_distributed_vertical_20_regular:before {
    content: "\f032";
}
.icon-ic_fluent_text_align_justify_20_filled:before {
    content: "\f033";
}
.icon-ic_fluent_text_align_justify_20_regular:before {
    content: "\f034";
}
.icon-ic_fluent_text_align_justify_low_20_filled:before {
    content: "\f035";
}
.icon-ic_fluent_text_align_justify_low_20_regular:before {
    content: "\f036";
}
.icon-ic_fluent_text_align_justify_low_90_20_filled:before {
    content: "\f037";
}
.icon-ic_fluent_text_align_justify_low_90_20_regular:before {
    content: "\f038";
}
.icon-ic_fluent_text_align_justify_low_rotate_270_20_filled:before {
    content: "\f039";
}
.icon-ic_fluent_text_align_justify_low_rotate_270_20_regular:before {
    content: "\f03a";
}
.icon-ic_fluent_text_align_justify_low_rotate_90_20_filled:before {
    content: "\f03b";
}
.icon-ic_fluent_text_align_justify_low_rotate_90_20_regular:before {
    content: "\f03c";
}
.icon-ic_fluent_text_align_justify_rotate_270_20_filled:before {
    content: "\f03d";
}
.icon-ic_fluent_text_align_justify_rotate_270_20_regular:before {
    content: "\f03e";
}
.icon-ic_fluent_text_align_justify_rotate_90_20_filled:before {
    content: "\f03f";
}
.icon-ic_fluent_text_align_justify_rotate_90_20_regular:before {
    content: "\f040";
}
.icon-ic_fluent_text_align_left_20_filled:before {
    content: "\f041";
}
.icon-ic_fluent_text_align_left_20_regular:before {
    content: "\f042";
}
.icon-ic_fluent_text_align_left_rotate_270_20_filled:before {
    content: "\f043";
}
.icon-ic_fluent_text_align_left_rotate_270_20_regular:before {
    content: "\f044";
}
.icon-ic_fluent_text_align_left_rotate_90_20_filled:before {
    content: "\f045";
}
.icon-ic_fluent_text_align_left_rotate_90_20_regular:before {
    content: "\f046";
}
.icon-ic_fluent_text_align_right_20_filled:before {
    content: "\f047";
}
.icon-ic_fluent_text_align_right_20_regular:before {
    content: "\f048";
}
.icon-ic_fluent_text_align_right_rotate_270_20_filled:before {
    content: "\f049";
}
.icon-ic_fluent_text_align_right_rotate_270_20_regular:before {
    content: "\f04a";
}
.icon-ic_fluent_text_align_right_rotate_90_20_filled:before {
    content: "\f04b";
}
.icon-ic_fluent_text_align_right_rotate_90_20_regular:before {
    content: "\f04c";
}
.icon-ic_fluent_text_arrow_down_right_column_20_filled:before {
    content: "\f04d";
}
.icon-ic_fluent_text_arrow_down_right_column_20_regular:before {
    content: "\f04e";
}
.icon-ic_fluent_text_asterisk_20_filled:before {
    content: "\f04f";
}
.icon-ic_fluent_text_asterisk_20_regular:before {
    content: "\f050";
}
.icon-ic_fluent_text_baseline_20_filled:before {
    content: "\f051";
}
.icon-ic_fluent_text_baseline_20_regular:before {
    content: "\f052";
}
.icon-ic_fluent_text_bold_20_filled:before {
    content: "\f053";
}
.icon-ic_fluent_text_bold_20_regular:before {
    content: "\f054";
}
.icon-ic_fluent_text_box_settings_20_filled:before {
    content: "\f055";
}
.icon-ic_fluent_text_box_settings_20_regular:before {
    content: "\f056";
}
.icon-ic_fluent_text_bullet_list_20_filled:before {
    content: "\f057";
}
.icon-ic_fluent_text_bullet_list_20_regular:before {
    content: "\f058";
}
.icon-ic_fluent_text_bullet_list_90_20_filled:before {
    content: "\f059";
}
.icon-ic_fluent_text_bullet_list_90_20_regular:before {
    content: "\f05a";
}
.icon-ic_fluent_text_bullet_list_add_20_filled:before {
    content: "\f05b";
}
.icon-ic_fluent_text_bullet_list_add_20_regular:before {
    content: "\f05c";
}
.icon-ic_fluent_text_bullet_list_checkmark_20_filled:before {
    content: "\f05d";
}
.icon-ic_fluent_text_bullet_list_checkmark_20_regular:before {
    content: "\f05e";
}
.icon-ic_fluent_text_bullet_list_dismiss_20_filled:before {
    content: "\f05f";
}
.icon-ic_fluent_text_bullet_list_dismiss_20_regular:before {
    content: "\f060";
}
.icon-ic_fluent_text_bullet_list_ltr_20_filled:before {
    content: "\f061";
}
.icon-ic_fluent_text_bullet_list_ltr_20_regular:before {
    content: "\f062";
}
.icon-ic_fluent_text_bullet_list_ltr_90_20_filled:before {
    content: "\f063";
}
.icon-ic_fluent_text_bullet_list_ltr_90_20_regular:before {
    content: "\f064";
}
.icon-ic_fluent_text_bullet_list_rtl_20_filled:before {
    content: "\f065";
}
.icon-ic_fluent_text_bullet_list_rtl_20_regular:before {
    content: "\f066";
}
.icon-ic_fluent_text_bullet_list_rtl_90_20_filled:before {
    content: "\f067";
}
.icon-ic_fluent_text_bullet_list_rtl_90_20_regular:before {
    content: "\f068";
}
.icon-ic_fluent_text_bullet_list_square_20_filled:before {
    content: "\f069";
}
.icon-ic_fluent_text_bullet_list_square_20_regular:before {
    content: "\f06a";
}
.icon-ic_fluent_text_bullet_list_square_clock_20_filled:before {
    content: "\f06b";
}
.icon-ic_fluent_text_bullet_list_square_clock_20_regular:before {
    content: "\f06c";
}
.icon-ic_fluent_text_bullet_list_square_edit_20_filled:before {
    content: "\f06d";
}
.icon-ic_fluent_text_bullet_list_square_edit_20_regular:before {
    content: "\f06e";
}
.icon-ic_fluent_text_bullet_list_square_person_20_filled:before {
    content: "\f06f";
}
.icon-ic_fluent_text_bullet_list_square_person_20_regular:before {
    content: "\f070";
}
.icon-ic_fluent_text_bullet_list_square_search_20_filled:before {
    content: "\f071";
}
.icon-ic_fluent_text_bullet_list_square_search_20_regular:before {
    content: "\f072";
}
.icon-ic_fluent_text_bullet_list_square_settings_20_filled:before {
    content: "\f073";
}
.icon-ic_fluent_text_bullet_list_square_settings_20_regular:before {
    content: "\f074";
}
.icon-ic_fluent_text_bullet_list_square_shield_20_filled:before {
    content: "\f075";
}
.icon-ic_fluent_text_bullet_list_square_shield_20_regular:before {
    content: "\f076";
}
.icon-ic_fluent_text_bullet_list_square_sparkle_20_filled:before {
    content: "\f077";
}
.icon-ic_fluent_text_bullet_list_square_sparkle_20_regular:before {
    content: "\f078";
}
.icon-ic_fluent_text_bullet_list_square_toolbox_20_filled:before {
    content: "\f079";
}
.icon-ic_fluent_text_bullet_list_square_toolbox_20_regular:before {
    content: "\f07a";
}
.icon-ic_fluent_text_bullet_list_square_warning_20_filled:before {
    content: "\f07b";
}
.icon-ic_fluent_text_bullet_list_square_warning_20_regular:before {
    content: "\f07c";
}
.icon-ic_fluent_text_bullet_list_tree_20_filled:before {
    content: "\f07d";
}
.icon-ic_fluent_text_bullet_list_tree_20_regular:before {
    content: "\f07e";
}
.icon-ic_fluent_text_case_lowercase_20_filled:before {
    content: "\f07f";
}
.icon-ic_fluent_text_case_lowercase_20_regular:before {
    content: "\f080";
}
.icon-ic_fluent_text_case_title_20_filled:before {
    content: "\f081";
}
.icon-ic_fluent_text_case_title_20_regular:before {
    content: "\f082";
}
.icon-ic_fluent_text_case_uppercase_20_filled:before {
    content: "\f083";
}
.icon-ic_fluent_text_case_uppercase_20_regular:before {
    content: "\f084";
}
.icon-ic_fluent_text_change_case_20_filled:before {
    content: "\f085";
}
.icon-ic_fluent_text_change_case_20_regular:before {
    content: "\f086";
}
.icon-ic_fluent_text_clear_formatting_20_filled:before {
    content: "\f087";
}
.icon-ic_fluent_text_clear_formatting_20_regular:before {
    content: "\f088";
}
.icon-ic_fluent_text_collapse_20_filled:before {
    content: "\f089";
}
.icon-ic_fluent_text_collapse_20_regular:before {
    content: "\f08a";
}
.icon-ic_fluent_text_color_20_filled:before {
    content: "\f08b";
}
.icon-ic_fluent_text_color_20_regular:before {
    content: "\f08c";
}
.icon-ic_fluent_text_color_accent_20_filled:before {
    content: "\f08d";
}
.icon-ic_fluent_text_column_one_20_filled:before {
    content: "\f08e";
}
.icon-ic_fluent_text_column_one_20_regular:before {
    content: "\f08f";
}
.icon-ic_fluent_text_column_one_narrow_20_filled:before {
    content: "\f090";
}
.icon-ic_fluent_text_column_one_narrow_20_regular:before {
    content: "\f091";
}
.icon-ic_fluent_text_column_one_semi_narrow_20_filled:before {
    content: "\f092";
}
.icon-ic_fluent_text_column_one_semi_narrow_20_regular:before {
    content: "\f093";
}
.icon-ic_fluent_text_column_one_wide_20_filled:before {
    content: "\f094";
}
.icon-ic_fluent_text_column_one_wide_20_regular:before {
    content: "\f095";
}
.icon-ic_fluent_text_column_one_wide_lightning_20_filled:before {
    content: "\f096";
}
.icon-ic_fluent_text_column_one_wide_lightning_20_regular:before {
    content: "\f097";
}
.icon-ic_fluent_text_column_three_20_filled:before {
    content: "\f098";
}
.icon-ic_fluent_text_column_three_20_regular:before {
    content: "\f099";
}
.icon-ic_fluent_text_column_two_20_filled:before {
    content: "\f09a";
}
.icon-ic_fluent_text_column_two_20_regular:before {
    content: "\f09b";
}
.icon-ic_fluent_text_column_two_left_20_filled:before {
    content: "\f09c";
}
.icon-ic_fluent_text_column_two_left_20_regular:before {
    content: "\f09d";
}
.icon-ic_fluent_text_column_two_right_20_filled:before {
    content: "\f09e";
}
.icon-ic_fluent_text_column_two_right_20_regular:before {
    content: "\f09f";
}
.icon-ic_fluent_text_column_wide_20_filled:before {
    content: "\f0a0";
}
.icon-ic_fluent_text_column_wide_20_regular:before {
    content: "\f0a1";
}
.icon-ic_fluent_text_continuous_20_filled:before {
    content: "\f0a2";
}
.icon-ic_fluent_text_continuous_20_regular:before {
    content: "\f0a3";
}
.icon-ic_fluent_text_density_20_filled:before {
    content: "\f0a4";
}
.icon-ic_fluent_text_density_20_regular:before {
    content: "\f0a5";
}
.icon-ic_fluent_text_description_20_filled:before {
    content: "\f0a6";
}
.icon-ic_fluent_text_description_20_regular:before {
    content: "\f0a7";
}
.icon-ic_fluent_text_description_ltr_20_filled:before {
    content: "\f0a8";
}
.icon-ic_fluent_text_description_ltr_20_regular:before {
    content: "\f0a9";
}
.icon-ic_fluent_text_description_rtl_20_filled:before {
    content: "\f0aa";
}
.icon-ic_fluent_text_description_rtl_20_regular:before {
    content: "\f0ab";
}
.icon-ic_fluent_text_direction_horizontal_left_20_filled:before {
    content: "\f0ac";
}
.icon-ic_fluent_text_direction_horizontal_left_20_regular:before {
    content: "\f0ad";
}
.icon-ic_fluent_text_direction_horizontal_ltr_20_filled:before {
    content: "\f0ae";
}
.icon-ic_fluent_text_direction_horizontal_ltr_20_regular:before {
    content: "\f0af";
}
.icon-ic_fluent_text_direction_horizontal_right_20_filled:before {
    content: "\f0b0";
}
.icon-ic_fluent_text_direction_horizontal_right_20_regular:before {
    content: "\f0b1";
}
.icon-ic_fluent_text_direction_horizontal_rtl_20_filled:before {
    content: "\f0b2";
}
.icon-ic_fluent_text_direction_horizontal_rtl_20_regular:before {
    content: "\f0b3";
}
.icon-ic_fluent_text_direction_rotate_270_right_20_filled:before {
    content: "\f0b4";
}
.icon-ic_fluent_text_direction_rotate_270_right_20_regular:before {
    content: "\f0b5";
}
.icon-ic_fluent_text_direction_rotate_315_right_20_filled:before {
    content: "\f0b6";
}
.icon-ic_fluent_text_direction_rotate_315_right_20_regular:before {
    content: "\f0b7";
}
.icon-ic_fluent_text_direction_rotate_45_right_20_filled:before {
    content: "\f0b8";
}
.icon-ic_fluent_text_direction_rotate_45_right_20_regular:before {
    content: "\f0b9";
}
.icon-ic_fluent_text_direction_rotate_90_left_20_filled:before {
    content: "\f0ba";
}
.icon-ic_fluent_text_direction_rotate_90_left_20_regular:before {
    content: "\f0bb";
}
.icon-ic_fluent_text_direction_rotate_90_ltr_20_filled:before {
    content: "\f0bc";
}
.icon-ic_fluent_text_direction_rotate_90_ltr_20_regular:before {
    content: "\f0bd";
}
.icon-ic_fluent_text_direction_rotate_90_right_20_filled:before {
    content: "\f0be";
}
.icon-ic_fluent_text_direction_rotate_90_right_20_regular:before {
    content: "\f0bf";
}
.icon-ic_fluent_text_direction_rotate_90_rtl_20_filled:before {
    content: "\f0c0";
}
.icon-ic_fluent_text_direction_rotate_90_rtl_20_regular:before {
    content: "\f0c1";
}
.icon-ic_fluent_text_direction_vertical_20_filled:before {
    content: "\f0c2";
}
.icon-ic_fluent_text_direction_vertical_20_regular:before {
    content: "\f0c3";
}
.icon-ic_fluent_text_edit_style_20_filled:before {
    content: "\f0c4";
}
.icon-ic_fluent_text_edit_style_20_regular:before {
    content: "\f0c5";
}
.icon-ic_fluent_text_effects_20_filled:before {
    content: "\f0c6";
}
.icon-ic_fluent_text_effects_20_regular:before {
    content: "\f0c7";
}
.icon-ic_fluent_text_effects_sparkle_20_filled:before {
    content: "\f0c8";
}
.icon-ic_fluent_text_effects_sparkle_20_regular:before {
    content: "\f0c9";
}
.icon-ic_fluent_text_expand_20_filled:before {
    content: "\f0ca";
}
.icon-ic_fluent_text_expand_20_regular:before {
    content: "\f0cb";
}
.icon-ic_fluent_text_field_20_filled:before {
    content: "\f0cc";
}
.icon-ic_fluent_text_field_20_regular:before {
    content: "\f0cd";
}
.icon-ic_fluent_text_first_line_20_filled:before {
    content: "\f0ce";
}
.icon-ic_fluent_text_first_line_20_regular:before {
    content: "\f0cf";
}
.icon-ic_fluent_text_font_20_filled:before {
    content: "\f0d0";
}
.icon-ic_fluent_text_font_20_regular:before {
    content: "\f0d1";
}
.icon-ic_fluent_text_font_info_20_filled:before {
    content: "\f0d2";
}
.icon-ic_fluent_text_font_info_20_regular:before {
    content: "\f0d3";
}
.icon-ic_fluent_text_font_size_20_filled:before {
    content: "\f0d4";
}
.icon-ic_fluent_text_font_size_20_regular:before {
    content: "\f0d5";
}
.icon-ic_fluent_text_footnote_20_filled:before {
    content: "\f0d6";
}
.icon-ic_fluent_text_footnote_20_regular:before {
    content: "\f0d7";
}
.icon-ic_fluent_text_grammar_arrow_left_20_filled:before {
    content: "\f0d8";
}
.icon-ic_fluent_text_grammar_arrow_left_20_regular:before {
    content: "\f0d9";
}
.icon-ic_fluent_text_grammar_arrow_right_20_filled:before {
    content: "\f0da";
}
.icon-ic_fluent_text_grammar_arrow_right_20_regular:before {
    content: "\f0db";
}
.icon-ic_fluent_text_grammar_checkmark_20_filled:before {
    content: "\f0dc";
}
.icon-ic_fluent_text_grammar_checkmark_20_regular:before {
    content: "\f0dd";
}
.icon-ic_fluent_text_grammar_dismiss_20_filled:before {
    content: "\f0de";
}
.icon-ic_fluent_text_grammar_dismiss_20_regular:before {
    content: "\f0df";
}
.icon-ic_fluent_text_grammar_error_20_filled:before {
    content: "\f0e0";
}
.icon-ic_fluent_text_grammar_error_20_regular:before {
    content: "\f0e1";
}
.icon-ic_fluent_text_grammar_lightning_20_filled:before {
    content: "\f0e2";
}
.icon-ic_fluent_text_grammar_lightning_20_regular:before {
    content: "\f0e3";
}
.icon-ic_fluent_text_grammar_settings_20_filled:before {
    content: "\f0e4";
}
.icon-ic_fluent_text_grammar_settings_20_regular:before {
    content: "\f0e5";
}
.icon-ic_fluent_text_grammar_wand_20_filled:before {
    content: "\f0e6";
}
.icon-ic_fluent_text_grammar_wand_20_regular:before {
    content: "\f0e7";
}
.icon-ic_fluent_text_hanging_20_filled:before {
    content: "\f0e8";
}
.icon-ic_fluent_text_hanging_20_regular:before {
    content: "\f0e9";
}
.icon-ic_fluent_text_header_1_20_filled:before {
    content: "\f0ea";
}
.icon-ic_fluent_text_header_1_20_regular:before {
    content: "\f0eb";
}
.icon-ic_fluent_text_header_1_lines_20_filled:before {
    content: "\f0ec";
}
.icon-ic_fluent_text_header_1_lines_20_regular:before {
    content: "\f0ed";
}
.icon-ic_fluent_text_header_1_lines_caret_20_filled:before {
    content: "\f0ee";
}
.icon-ic_fluent_text_header_1_lines_caret_20_regular:before {
    content: "\f0ef";
}
.icon-ic_fluent_text_header_2_20_filled:before {
    content: "\f0f0";
}
.icon-ic_fluent_text_header_2_20_regular:before {
    content: "\f0f1";
}
.icon-ic_fluent_text_header_2_lines_20_filled:before {
    content: "\f0f2";
}
.icon-ic_fluent_text_header_2_lines_20_regular:before {
    content: "\f0f3";
}
.icon-ic_fluent_text_header_2_lines_caret_20_filled:before {
    content: "\f0f4";
}
.icon-ic_fluent_text_header_2_lines_caret_20_regular:before {
    content: "\f0f5";
}
.icon-ic_fluent_text_header_3_20_filled:before {
    content: "\f0f6";
}
.icon-ic_fluent_text_header_3_20_regular:before {
    content: "\f0f7";
}
.icon-ic_fluent_text_header_3_lines_20_filled:before {
    content: "\f0f8";
}
.icon-ic_fluent_text_header_3_lines_20_regular:before {
    content: "\f0f9";
}
.icon-ic_fluent_text_header_3_lines_caret_20_filled:before {
    content: "\f0fa";
}
.icon-ic_fluent_text_header_3_lines_caret_20_regular:before {
    content: "\f0fb";
}
.icon-ic_fluent_text_indent_decrease_20_filled:before {
    content: "\f0fc";
}
.icon-ic_fluent_text_indent_decrease_20_regular:before {
    content: "\f0fd";
}
.icon-ic_fluent_text_indent_decrease_ltr_20_filled:before {
    content: "\f0fe";
}
.icon-ic_fluent_text_indent_decrease_ltr_20_regular:before {
    content: "\f0ff";
}
.icon-ic_fluent_text_indent_decrease_ltr_90_20_filled:before {
    content: "\f100";
}
.icon-ic_fluent_text_indent_decrease_ltr_90_20_regular:before {
    content: "\f101";
}
.icon-ic_fluent_text_indent_decrease_ltr_rotate_270_20_filled:before {
    content: "\f102";
}
.icon-ic_fluent_text_indent_decrease_ltr_rotate_270_20_regular:before {
    content: "\f103";
}
.icon-ic_fluent_text_indent_decrease_rotate_270_20_filled:before {
    content: "\f104";
}
.icon-ic_fluent_text_indent_decrease_rotate_270_20_regular:before {
    content: "\f105";
}
.icon-ic_fluent_text_indent_decrease_rotate_90_20_filled:before {
    content: "\f106";
}
.icon-ic_fluent_text_indent_decrease_rotate_90_20_regular:before {
    content: "\f107";
}
.icon-ic_fluent_text_indent_decrease_rtl_20_filled:before {
    content: "\f108";
}
.icon-ic_fluent_text_indent_decrease_rtl_20_regular:before {
    content: "\f109";
}
.icon-ic_fluent_text_indent_decrease_rtl_90_20_filled:before {
    content: "\f10a";
}
.icon-ic_fluent_text_indent_decrease_rtl_90_20_regular:before {
    content: "\f10b";
}
.icon-ic_fluent_text_indent_decrease_rtl_rotate_270_20_filled:before {
    content: "\f10c";
}
.icon-ic_fluent_text_indent_decrease_rtl_rotate_270_20_regular:before {
    content: "\f10d";
}
.icon-ic_fluent_text_indent_increase_20_filled:before {
    content: "\f10e";
}
.icon-ic_fluent_text_indent_increase_20_regular:before {
    content: "\f10f";
}
.icon-ic_fluent_text_indent_increase_ltr_20_filled:before {
    content: "\f110";
}
.icon-ic_fluent_text_indent_increase_ltr_20_regular:before {
    content: "\f111";
}
.icon-ic_fluent_text_indent_increase_ltr_90_20_filled:before {
    content: "\f112";
}
.icon-ic_fluent_text_indent_increase_ltr_90_20_regular:before {
    content: "\f113";
}
.icon-ic_fluent_text_indent_increase_ltr_rotate_270_20_filled:before {
    content: "\f114";
}
.icon-ic_fluent_text_indent_increase_ltr_rotate_270_20_regular:before {
    content: "\f115";
}
.icon-ic_fluent_text_indent_increase_rotate_270_20_filled:before {
    content: "\f116";
}
.icon-ic_fluent_text_indent_increase_rotate_270_20_regular:before {
    content: "\f117";
}
.icon-ic_fluent_text_indent_increase_rotate_90_20_filled:before {
    content: "\f118";
}
.icon-ic_fluent_text_indent_increase_rotate_90_20_regular:before {
    content: "\f119";
}
.icon-ic_fluent_text_indent_increase_rtl_20_filled:before {
    content: "\f11a";
}
.icon-ic_fluent_text_indent_increase_rtl_20_regular:before {
    content: "\f11b";
}
.icon-ic_fluent_text_indent_increase_rtl_90_20_filled:before {
    content: "\f11c";
}
.icon-ic_fluent_text_indent_increase_rtl_90_20_regular:before {
    content: "\f11d";
}
.icon-ic_fluent_text_indent_increase_rtl_rotate_270_20_filled:before {
    content: "\f11e";
}
.icon-ic_fluent_text_indent_increase_rtl_rotate_270_20_regular:before {
    content: "\f11f";
}
.icon-ic_fluent_text_italic_20_filled:before {
    content: "\f120";
}
.icon-ic_fluent_text_italic_20_regular:before {
    content: "\f121";
}
.icon-ic_fluent_text_line_spacing_20_filled:before {
    content: "\f122";
}
.icon-ic_fluent_text_line_spacing_20_regular:before {
    content: "\f123";
}
.icon-ic_fluent_text_more_20_filled:before {
    content: "\f124";
}
.icon-ic_fluent_text_more_20_regular:before {
    content: "\f125";
}
.icon-ic_fluent_text_number_format_20_filled:before {
    content: "\f126";
}
.icon-ic_fluent_text_number_format_20_regular:before {
    content: "\f127";
}
.icon-ic_fluent_text_number_list_ltr_20_filled:before {
    content: "\f128";
}
.icon-ic_fluent_text_number_list_ltr_20_regular:before {
    content: "\f129";
}
.icon-ic_fluent_text_number_list_ltr_90_20_filled:before {
    content: "\f12a";
}
.icon-ic_fluent_text_number_list_ltr_90_20_regular:before {
    content: "\f12b";
}
.icon-ic_fluent_text_number_list_ltr_rotate_270_20_filled:before {
    content: "\f12c";
}
.icon-ic_fluent_text_number_list_ltr_rotate_270_20_regular:before {
    content: "\f12d";
}
.icon-ic_fluent_text_number_list_rotate_270_20_filled:before {
    content: "\f12e";
}
.icon-ic_fluent_text_number_list_rotate_270_20_regular:before {
    content: "\f12f";
}
.icon-ic_fluent_text_number_list_rotate_90_20_filled:before {
    content: "\f130";
}
.icon-ic_fluent_text_number_list_rotate_90_20_regular:before {
    content: "\f131";
}
.icon-ic_fluent_text_number_list_rtl_20_filled:before {
    content: "\f132";
}
.icon-ic_fluent_text_number_list_rtl_20_regular:before {
    content: "\f133";
}
.icon-ic_fluent_text_number_list_rtl_90_20_filled:before {
    content: "\f134";
}
.icon-ic_fluent_text_number_list_rtl_90_20_regular:before {
    content: "\f135";
}
.icon-ic_fluent_text_number_list_rtl_rotate_270_20_filled:before {
    content: "\f136";
}
.icon-ic_fluent_text_number_list_rtl_rotate_270_20_regular:before {
    content: "\f137";
}
.icon-ic_fluent_text_paragraph_20_filled:before {
    content: "\f138";
}
.icon-ic_fluent_text_paragraph_20_regular:before {
    content: "\f139";
}
.icon-ic_fluent_text_paragraph_direction_20_filled:before {
    content: "\f13a";
}
.icon-ic_fluent_text_paragraph_direction_20_regular:before {
    content: "\f13b";
}
.icon-ic_fluent_text_paragraph_direction_left_20_filled:before {
    content: "\f13c";
}
.icon-ic_fluent_text_paragraph_direction_left_20_regular:before {
    content: "\f13d";
}
.icon-ic_fluent_text_paragraph_direction_right_20_filled:before {
    content: "\f13e";
}
.icon-ic_fluent_text_paragraph_direction_right_20_regular:before {
    content: "\f13f";
}
.icon-ic_fluent_text_period_asterisk_20_filled:before {
    content: "\f140";
}
.icon-ic_fluent_text_period_asterisk_20_regular:before {
    content: "\f141";
}
.icon-ic_fluent_text_position_behind_20_filled:before {
    content: "\f142";
}
.icon-ic_fluent_text_position_behind_20_regular:before {
    content: "\f143";
}
.icon-ic_fluent_text_position_front_20_filled:before {
    content: "\f144";
}
.icon-ic_fluent_text_position_front_20_regular:before {
    content: "\f145";
}
.icon-ic_fluent_text_position_line_20_filled:before {
    content: "\f146";
}
.icon-ic_fluent_text_position_line_20_regular:before {
    content: "\f147";
}
.icon-ic_fluent_text_position_square_20_filled:before {
    content: "\f148";
}
.icon-ic_fluent_text_position_square_20_regular:before {
    content: "\f149";
}
.icon-ic_fluent_text_position_square_left_20_filled:before {
    content: "\f14a";
}
.icon-ic_fluent_text_position_square_left_20_regular:before {
    content: "\f14b";
}
.icon-ic_fluent_text_position_square_right_20_filled:before {
    content: "\f14c";
}
.icon-ic_fluent_text_position_square_right_20_regular:before {
    content: "\f14d";
}
.icon-ic_fluent_text_position_through_20_filled:before {
    content: "\f14e";
}
.icon-ic_fluent_text_position_through_20_regular:before {
    content: "\f14f";
}
.icon-ic_fluent_text_position_tight_20_filled:before {
    content: "\f150";
}
.icon-ic_fluent_text_position_tight_20_regular:before {
    content: "\f151";
}
.icon-ic_fluent_text_position_top_bottom_20_filled:before {
    content: "\f152";
}
.icon-ic_fluent_text_position_top_bottom_20_regular:before {
    content: "\f153";
}
.icon-ic_fluent_text_proofing_tools_20_filled:before {
    content: "\f154";
}
.icon-ic_fluent_text_proofing_tools_20_regular:before {
    content: "\f155";
}
.icon-ic_fluent_text_quote_20_filled:before {
    content: "\f156";
}
.icon-ic_fluent_text_quote_20_regular:before {
    content: "\f157";
}
.icon-ic_fluent_text_sort_ascending_20_filled:before {
    content: "\f158";
}
.icon-ic_fluent_text_sort_ascending_20_regular:before {
    content: "\f159";
}
.icon-ic_fluent_text_sort_descending_20_filled:before {
    content: "\f15a";
}
.icon-ic_fluent_text_sort_descending_20_regular:before {
    content: "\f15b";
}
.icon-ic_fluent_text_strikethrough_20_filled:before {
    content: "\f15c";
}
.icon-ic_fluent_text_strikethrough_20_regular:before {
    content: "\f15d";
}
.icon-ic_fluent_text_subscript_20_filled:before {
    content: "\f15e";
}
.icon-ic_fluent_text_subscript_20_regular:before {
    content: "\f15f";
}
.icon-ic_fluent_text_superscript_20_filled:before {
    content: "\f160";
}
.icon-ic_fluent_text_superscript_20_regular:before {
    content: "\f161";
}
.icon-ic_fluent_text_t_20_filled:before {
    content: "\f162";
}
.icon-ic_fluent_text_t_20_regular:before {
    content: "\f163";
}
.icon-ic_fluent_text_underline_20_filled:before {
    content: "\f164";
}
.icon-ic_fluent_text_underline_20_regular:before {
    content: "\f165";
}
.icon-ic_fluent_text_underline_character_u_20_filled:before {
    content: "\f166";
}
.icon-ic_fluent_text_underline_character_u_20_regular:before {
    content: "\f167";
}
.icon-ic_fluent_text_underline_double_20_filled:before {
    content: "\f168";
}
.icon-ic_fluent_text_underline_double_20_regular:before {
    content: "\f169";
}
.icon-ic_fluent_text_whole_word_20_filled:before {
    content: "\f16a";
}
.icon-ic_fluent_text_whole_word_20_regular:before {
    content: "\f16b";
}
.icon-ic_fluent_text_word_count_20_filled:before {
    content: "\f16c";
}
.icon-ic_fluent_text_word_count_20_regular:before {
    content: "\f16d";
}
.icon-ic_fluent_text_wrap_20_filled:before {
    content: "\f16e";
}
.icon-ic_fluent_text_wrap_20_regular:before {
    content: "\f16f";
}
.icon-ic_fluent_text_wrap_off_20_filled:before {
    content: "\f170";
}
.icon-ic_fluent_text_wrap_off_20_regular:before {
    content: "\f171";
}
.icon-ic_fluent_textbox_20_filled:before {
    content: "\f172";
}
.icon-ic_fluent_textbox_20_regular:before {
    content: "\f173";
}
.icon-ic_fluent_textbox_align_bottom_20_filled:before {
    content: "\f174";
}
.icon-ic_fluent_textbox_align_bottom_20_regular:before {
    content: "\f175";
}
.icon-ic_fluent_textbox_align_bottom_center_20_filled:before {
    content: "\f176";
}
.icon-ic_fluent_textbox_align_bottom_center_20_regular:before {
    content: "\f177";
}
.icon-ic_fluent_textbox_align_bottom_left_20_filled:before {
    content: "\f178";
}
.icon-ic_fluent_textbox_align_bottom_left_20_regular:before {
    content: "\f179";
}
.icon-ic_fluent_textbox_align_bottom_right_20_filled:before {
    content: "\f17a";
}
.icon-ic_fluent_textbox_align_bottom_right_20_regular:before {
    content: "\f17b";
}
.icon-ic_fluent_textbox_align_bottom_rotate_90_20_filled:before {
    content: "\f17c";
}
.icon-ic_fluent_textbox_align_bottom_rotate_90_20_regular:before {
    content: "\f17d";
}
.icon-ic_fluent_textbox_align_center_20_filled:before {
    content: "\f17e";
}
.icon-ic_fluent_textbox_align_center_20_regular:before {
    content: "\f17f";
}
.icon-ic_fluent_textbox_align_middle_20_filled:before {
    content: "\f180";
}
.icon-ic_fluent_textbox_align_middle_20_regular:before {
    content: "\f181";
}
.icon-ic_fluent_textbox_align_middle_left_20_filled:before {
    content: "\f182";
}
.icon-ic_fluent_textbox_align_middle_left_20_regular:before {
    content: "\f183";
}
.icon-ic_fluent_textbox_align_middle_right_20_filled:before {
    content: "\f184";
}
.icon-ic_fluent_textbox_align_middle_right_20_regular:before {
    content: "\f185";
}
.icon-ic_fluent_textbox_align_middle_rotate_90_20_filled:before {
    content: "\f186";
}
.icon-ic_fluent_textbox_align_middle_rotate_90_20_regular:before {
    content: "\f187";
}
.icon-ic_fluent_textbox_align_top_20_filled:before {
    content: "\f188";
}
.icon-ic_fluent_textbox_align_top_20_regular:before {
    content: "\f189";
}
.icon-ic_fluent_textbox_align_top_center_20_filled:before {
    content: "\f18a";
}
.icon-ic_fluent_textbox_align_top_center_20_regular:before {
    content: "\f18b";
}
.icon-ic_fluent_textbox_align_top_left_20_filled:before {
    content: "\f18c";
}
.icon-ic_fluent_textbox_align_top_left_20_regular:before {
    content: "\f18d";
}
.icon-ic_fluent_textbox_align_top_right_20_filled:before {
    content: "\f18e";
}
.icon-ic_fluent_textbox_align_top_right_20_regular:before {
    content: "\f18f";
}
.icon-ic_fluent_textbox_align_top_rotate_90_20_filled:before {
    content: "\f190";
}
.icon-ic_fluent_textbox_align_top_rotate_90_20_regular:before {
    content: "\f191";
}
.icon-ic_fluent_textbox_more_20_filled:before {
    content: "\f192";
}
.icon-ic_fluent_textbox_more_20_regular:before {
    content: "\f193";
}
.icon-ic_fluent_textbox_rotate_90_20_filled:before {
    content: "\f194";
}
.icon-ic_fluent_textbox_rotate_90_20_regular:before {
    content: "\f195";
}
.icon-ic_fluent_textbox_settings_20_filled:before {
    content: "\f196";
}
.icon-ic_fluent_textbox_settings_20_regular:before {
    content: "\f197";
}
.icon-ic_fluent_thinking_20_filled:before {
    content: "\f198";
}
.icon-ic_fluent_thinking_20_regular:before {
    content: "\f199";
}
.icon-ic_fluent_thumb_dislike_20_filled:before {
    content: "\f19a";
}
.icon-ic_fluent_thumb_dislike_20_regular:before {
    content: "\f19b";
}
.icon-ic_fluent_thumb_like_20_filled:before {
    content: "\f19c";
}
.icon-ic_fluent_thumb_like_20_regular:before {
    content: "\f19d";
}
.icon-ic_fluent_thumb_like_dislike_20_filled:before {
    content: "\f19e";
}
.icon-ic_fluent_thumb_like_dislike_20_regular:before {
    content: "\f19f";
}
.icon-ic_fluent_ticket_diagonal_20_filled:before {
    content: "\f1a0";
}
.icon-ic_fluent_ticket_diagonal_20_regular:before {
    content: "\f1a1";
}
.icon-ic_fluent_ticket_horizontal_20_filled:before {
    content: "\f1a2";
}
.icon-ic_fluent_ticket_horizontal_20_regular:before {
    content: "\f1a3";
}
.icon-ic_fluent_time_and_weather_20_filled:before {
    content: "\f1a4";
}
.icon-ic_fluent_time_and_weather_20_regular:before {
    content: "\f1a5";
}
.icon-ic_fluent_time_picker_20_filled:before {
    content: "\f1a6";
}
.icon-ic_fluent_time_picker_20_regular:before {
    content: "\f1a7";
}
.icon-ic_fluent_timeline_20_filled:before {
    content: "\f1a8";
}
.icon-ic_fluent_timeline_20_regular:before {
    content: "\f1a9";
}
.icon-ic_fluent_timer_10_20_filled:before {
    content: "\f1aa";
}
.icon-ic_fluent_timer_10_20_regular:before {
    content: "\f1ab";
}
.icon-ic_fluent_timer_2_20_filled:before {
    content: "\f1ac";
}
.icon-ic_fluent_timer_2_20_regular:before {
    content: "\f1ad";
}
.icon-ic_fluent_timer_20_filled:before {
    content: "\f1ae";
}
.icon-ic_fluent_timer_20_regular:before {
    content: "\f1af";
}
.icon-ic_fluent_timer_3_20_filled:before {
    content: "\f1b0";
}
.icon-ic_fluent_timer_3_20_regular:before {
    content: "\f1b1";
}
.icon-ic_fluent_timer_off_20_filled:before {
    content: "\f1b2";
}
.icon-ic_fluent_timer_off_20_regular:before {
    content: "\f1b3";
}
.icon-ic_fluent_toggle_left_20_filled:before {
    content: "\f1b4";
}
.icon-ic_fluent_toggle_left_20_regular:before {
    content: "\f1b5";
}
.icon-ic_fluent_toggle_multiple_20_filled:before {
    content: "\f1b6";
}
.icon-ic_fluent_toggle_multiple_20_regular:before {
    content: "\f1b7";
}
.icon-ic_fluent_toggle_right_20_filled:before {
    content: "\f1b8";
}
.icon-ic_fluent_toggle_right_20_regular:before {
    content: "\f1b9";
}
.icon-ic_fluent_toolbox_20_filled:before {
    content: "\f1ba";
}
.icon-ic_fluent_toolbox_20_regular:before {
    content: "\f1bb";
}
.icon-ic_fluent_tooltip_quote_20_filled:before {
    content: "\f1bc";
}
.icon-ic_fluent_tooltip_quote_20_regular:before {
    content: "\f1bd";
}
.icon-ic_fluent_top_speed_20_filled:before {
    content: "\f1be";
}
.icon-ic_fluent_top_speed_20_regular:before {
    content: "\f1bf";
}
.icon-ic_fluent_translate_20_filled:before {
    content: "\f1c0";
}
.icon-ic_fluent_translate_20_regular:before {
    content: "\f1c1";
}
.icon-ic_fluent_translate_auto_20_filled:before {
    content: "\f1c2";
}
.icon-ic_fluent_translate_auto_20_regular:before {
    content: "\f1c3";
}
.icon-ic_fluent_translate_off_20_filled:before {
    content: "\f1c4";
}
.icon-ic_fluent_translate_off_20_regular:before {
    content: "\f1c5";
}
.icon-ic_fluent_transmission_20_filled:before {
    content: "\f1c6";
}
.icon-ic_fluent_transmission_20_regular:before {
    content: "\f1c7";
}
.icon-ic_fluent_tray_item_add_20_filled:before {
    content: "\f1c8";
}
.icon-ic_fluent_tray_item_add_20_regular:before {
    content: "\f1c9";
}
.icon-ic_fluent_tray_item_remove_20_filled:before {
    content: "\f1ca";
}
.icon-ic_fluent_tray_item_remove_20_regular:before {
    content: "\f1cb";
}
.icon-ic_fluent_tree_deciduous_20_filled:before {
    content: "\f1cc";
}
.icon-ic_fluent_tree_deciduous_20_regular:before {
    content: "\f1cd";
}
.icon-ic_fluent_tree_evergreen_20_filled:before {
    content: "\f1ce";
}
.icon-ic_fluent_tree_evergreen_20_regular:before {
    content: "\f1cf";
}
.icon-ic_fluent_triangle_20_filled:before {
    content: "\f1d0";
}
.icon-ic_fluent_triangle_20_regular:before {
    content: "\f1d1";
}
.icon-ic_fluent_triangle_down_20_filled:before {
    content: "\f1d2";
}
.icon-ic_fluent_triangle_down_20_regular:before {
    content: "\f1d3";
}
.icon-ic_fluent_triangle_left_20_filled:before {
    content: "\f1d4";
}
.icon-ic_fluent_triangle_left_20_regular:before {
    content: "\f1d5";
}
.icon-ic_fluent_triangle_right_20_filled:before {
    content: "\f1d6";
}
.icon-ic_fluent_triangle_right_20_regular:before {
    content: "\f1d7";
}
.icon-ic_fluent_triangle_up_20_filled:before {
    content: "\f1d8";
}
.icon-ic_fluent_triangle_up_20_regular:before {
    content: "\f1d9";
}
.icon-ic_fluent_trophy_20_filled:before {
    content: "\f1da";
}
.icon-ic_fluent_trophy_20_regular:before {
    content: "\f1db";
}
.icon-ic_fluent_trophy_lock_20_filled:before {
    content: "\f1dc";
}
.icon-ic_fluent_trophy_lock_20_regular:before {
    content: "\f1dd";
}
.icon-ic_fluent_trophy_off_20_filled:before {
    content: "\f1de";
}
.icon-ic_fluent_trophy_off_20_regular:before {
    content: "\f1df";
}
.icon-ic_fluent_tv_20_filled:before {
    content: "\f1e0";
}
.icon-ic_fluent_tv_20_regular:before {
    content: "\f1e1";
}
.icon-ic_fluent_tv_arrow_right_20_filled:before {
    content: "\f1e2";
}
.icon-ic_fluent_tv_arrow_right_20_regular:before {
    content: "\f1e3";
}
.icon-ic_fluent_tv_usb_20_filled:before {
    content: "\f1e4";
}
.icon-ic_fluent_tv_usb_20_regular:before {
    content: "\f1e5";
}
.icon-ic_fluent_umbrella_20_filled:before {
    content: "\f1e6";
}
.icon-ic_fluent_umbrella_20_regular:before {
    content: "\f1e7";
}
.icon-ic_fluent_uninstall_app_20_filled:before {
    content: "\f1e8";
}
.icon-ic_fluent_uninstall_app_20_regular:before {
    content: "\f1e9";
}
.icon-ic_fluent_usb_plug_20_filled:before {
    content: "\f1ea";
}
.icon-ic_fluent_usb_plug_20_regular:before {
    content: "\f1eb";
}
.icon-ic_fluent_usb_stick_20_filled:before {
    content: "\f1ec";
}
.icon-ic_fluent_usb_stick_20_regular:before {
    content: "\f1ed";
}
.icon-ic_fluent_vault_20_filled:before {
    content: "\f1ee";
}
.icon-ic_fluent_vault_20_regular:before {
    content: "\f1ef";
}
.icon-ic_fluent_vehicle_bicycle_20_filled:before {
    content: "\f1f0";
}
.icon-ic_fluent_vehicle_bicycle_20_regular:before {
    content: "\f1f1";
}
.icon-ic_fluent_vehicle_bus_20_filled:before {
    content: "\f1f2";
}
.icon-ic_fluent_vehicle_bus_20_regular:before {
    content: "\f1f3";
}
.icon-ic_fluent_vehicle_cab_20_filled:before {
    content: "\f1f4";
}
.icon-ic_fluent_vehicle_cab_20_regular:before {
    content: "\f1f5";
}
.icon-ic_fluent_vehicle_cable_car_20_filled:before {
    content: "\f1f6";
}
.icon-ic_fluent_vehicle_cable_car_20_regular:before {
    content: "\f1f7";
}
.icon-ic_fluent_vehicle_car_20_filled:before {
    content: "\f1f8";
}
.icon-ic_fluent_vehicle_car_20_regular:before {
    content: "\f1f9";
}
.icon-ic_fluent_vehicle_car_collision_20_filled:before {
    content: "\f1fa";
}
.icon-ic_fluent_vehicle_car_collision_20_regular:before {
    content: "\f1fb";
}
.icon-ic_fluent_vehicle_car_parking_20_filled:before {
    content: "\f1fc";
}
.icon-ic_fluent_vehicle_car_parking_20_regular:before {
    content: "\f1fd";
}
.icon-ic_fluent_vehicle_car_profile_20_filled:before {
    content: "\f1fe";
}
.icon-ic_fluent_vehicle_car_profile_20_regular:before {
    content: "\f1ff";
}
.icon-ic_fluent_vehicle_car_profile_ltr_20_filled:before {
    content: "\f200";
}
.icon-ic_fluent_vehicle_car_profile_ltr_20_regular:before {
    content: "\f201";
}
.icon-ic_fluent_vehicle_car_profile_ltr_clock_20_filled:before {
    content: "\f202";
}
.icon-ic_fluent_vehicle_car_profile_ltr_clock_20_regular:before {
    content: "\f203";
}
.icon-ic_fluent_vehicle_car_profile_rtl_20_filled:before {
    content: "\f204";
}
.icon-ic_fluent_vehicle_car_profile_rtl_20_regular:before {
    content: "\f205";
}
.icon-ic_fluent_vehicle_ship_20_filled:before {
    content: "\f206";
}
.icon-ic_fluent_vehicle_ship_20_regular:before {
    content: "\f207";
}
.icon-ic_fluent_vehicle_subway_20_filled:before {
    content: "\f208";
}
.icon-ic_fluent_vehicle_subway_20_regular:before {
    content: "\f209";
}
.icon-ic_fluent_vehicle_subway_clock_20_filled:before {
    content: "\f20a";
}
.icon-ic_fluent_vehicle_subway_clock_20_regular:before {
    content: "\f20b";
}
.icon-ic_fluent_vehicle_truck_20_filled:before {
    content: "\f20c";
}
.icon-ic_fluent_vehicle_truck_20_regular:before {
    content: "\f20d";
}
.icon-ic_fluent_vehicle_truck_bag_20_filled:before {
    content: "\f20e";
}
.icon-ic_fluent_vehicle_truck_bag_20_regular:before {
    content: "\f20f";
}
.icon-ic_fluent_vehicle_truck_cube_20_filled:before {
    content: "\f210";
}
.icon-ic_fluent_vehicle_truck_cube_20_regular:before {
    content: "\f211";
}
.icon-ic_fluent_vehicle_truck_profile_20_filled:before {
    content: "\f212";
}
.icon-ic_fluent_vehicle_truck_profile_20_regular:before {
    content: "\f213";
}
.icon-ic_fluent_video_20_filled:before {
    content: "\f214";
}
.icon-ic_fluent_video_20_regular:before {
    content: "\f215";
}
.icon-ic_fluent_video_360_20_filled:before {
    content: "\f216";
}
.icon-ic_fluent_video_360_20_regular:before {
    content: "\f217";
}
.icon-ic_fluent_video_360_off_20_filled:before {
    content: "\f218";
}
.icon-ic_fluent_video_360_off_20_regular:before {
    content: "\f219";
}
.icon-ic_fluent_video_add_20_filled:before {
    content: "\f21a";
}
.icon-ic_fluent_video_add_20_regular:before {
    content: "\f21b";
}
.icon-ic_fluent_video_background_effect_20_filled:before {
    content: "\f21c";
}
.icon-ic_fluent_video_background_effect_20_regular:before {
    content: "\f21d";
}
.icon-ic_fluent_video_background_effect_horizontal_20_filled:before {
    content: "\f21e";
}
.icon-ic_fluent_video_background_effect_horizontal_20_regular:before {
    content: "\f21f";
}
.icon-ic_fluent_video_chat_20_filled:before {
    content: "\f220";
}
.icon-ic_fluent_video_chat_20_regular:before {
    content: "\f221";
}
.icon-ic_fluent_video_clip_20_filled:before {
    content: "\f222";
}
.icon-ic_fluent_video_clip_20_regular:before {
    content: "\f223";
}
.icon-ic_fluent_video_clip_multiple_20_filled:before {
    content: "\f224";
}
.icon-ic_fluent_video_clip_multiple_20_regular:before {
    content: "\f225";
}
.icon-ic_fluent_video_clip_off_20_filled:before {
    content: "\f226";
}
.icon-ic_fluent_video_clip_off_20_regular:before {
    content: "\f227";
}
.icon-ic_fluent_video_clip_optimize_20_filled:before {
    content: "\f228";
}
.icon-ic_fluent_video_clip_optimize_20_regular:before {
    content: "\f229";
}
.icon-ic_fluent_video_off_20_filled:before {
    content: "\f22a";
}
.icon-ic_fluent_video_off_20_regular:before {
    content: "\f22b";
}
.icon-ic_fluent_video_person_20_filled:before {
    content: "\f22c";
}
.icon-ic_fluent_video_person_20_regular:before {
    content: "\f22d";
}
.icon-ic_fluent_video_person_call_20_filled:before {
    content: "\f22e";
}
.icon-ic_fluent_video_person_call_20_regular:before {
    content: "\f22f";
}
.icon-ic_fluent_video_person_clock_20_filled:before {
    content: "\f230";
}
.icon-ic_fluent_video_person_clock_20_regular:before {
    content: "\f231";
}
.icon-ic_fluent_video_person_off_20_filled:before {
    content: "\f232";
}
.icon-ic_fluent_video_person_off_20_regular:before {
    content: "\f233";
}
.icon-ic_fluent_video_person_pulse_20_filled:before {
    content: "\f234";
}
.icon-ic_fluent_video_person_pulse_20_regular:before {
    content: "\f235";
}
.icon-ic_fluent_video_person_sparkle_20_filled:before {
    content: "\f236";
}
.icon-ic_fluent_video_person_sparkle_20_regular:before {
    content: "\f237";
}
.icon-ic_fluent_video_person_sparkle_off_20_filled:before {
    content: "\f238";
}
.icon-ic_fluent_video_person_sparkle_off_20_regular:before {
    content: "\f239";
}
.icon-ic_fluent_video_person_star_20_filled:before {
    content: "\f23a";
}
.icon-ic_fluent_video_person_star_20_regular:before {
    content: "\f23b";
}
.icon-ic_fluent_video_person_star_off_20_filled:before {
    content: "\f23c";
}
.icon-ic_fluent_video_person_star_off_20_regular:before {
    content: "\f23d";
}
.icon-ic_fluent_video_play_pause_20_filled:before {
    content: "\f23e";
}
.icon-ic_fluent_video_play_pause_20_regular:before {
    content: "\f23f";
}
.icon-ic_fluent_video_prohibited_20_filled:before {
    content: "\f240";
}
.icon-ic_fluent_video_prohibited_20_regular:before {
    content: "\f241";
}
.icon-ic_fluent_video_recording_20_filled:before {
    content: "\f242";
}
.icon-ic_fluent_video_recording_20_regular:before {
    content: "\f243";
}
.icon-ic_fluent_video_security_20_filled:before {
    content: "\f244";
}
.icon-ic_fluent_video_security_20_regular:before {
    content: "\f245";
}
.icon-ic_fluent_video_switch_20_filled:before {
    content: "\f246";
}
.icon-ic_fluent_video_switch_20_regular:before {
    content: "\f247";
}
.icon-ic_fluent_video_sync_20_filled:before {
    content: "\f248";
}
.icon-ic_fluent_video_sync_20_regular:before {
    content: "\f249";
}
.icon-ic_fluent_view_desktop_20_filled:before {
    content: "\f24a";
}
.icon-ic_fluent_view_desktop_20_regular:before {
    content: "\f24b";
}
.icon-ic_fluent_view_desktop_mobile_20_filled:before {
    content: "\f24c";
}
.icon-ic_fluent_view_desktop_mobile_20_regular:before {
    content: "\f24d";
}
.icon-ic_fluent_virtual_network_20_filled:before {
    content: "\f24e";
}
.icon-ic_fluent_virtual_network_20_regular:before {
    content: "\f24f";
}
.icon-ic_fluent_virtual_network_toolbox_20_filled:before {
    content: "\f250";
}
.icon-ic_fluent_virtual_network_toolbox_20_regular:before {
    content: "\f251";
}
.icon-ic_fluent_voicemail_20_filled:before {
    content: "\f252";
}
.icon-ic_fluent_voicemail_20_regular:before {
    content: "\f253";
}
.icon-ic_fluent_voicemail_arrow_back_20_filled:before {
    content: "\f254";
}
.icon-ic_fluent_voicemail_arrow_back_20_regular:before {
    content: "\f255";
}
.icon-ic_fluent_voicemail_arrow_forward_20_filled:before {
    content: "\f256";
}
.icon-ic_fluent_voicemail_arrow_forward_20_regular:before {
    content: "\f257";
}
.icon-ic_fluent_voicemail_arrow_subtract_20_filled:before {
    content: "\f258";
}
.icon-ic_fluent_voicemail_arrow_subtract_20_regular:before {
    content: "\f259";
}
.icon-ic_fluent_voicemail_shield_20_filled:before {
    content: "\f25a";
}
.icon-ic_fluent_voicemail_shield_20_regular:before {
    content: "\f25b";
}
.icon-ic_fluent_voicemail_subtract_20_filled:before {
    content: "\f25c";
}
.icon-ic_fluent_voicemail_subtract_20_regular:before {
    content: "\f25d";
}
.icon-ic_fluent_vote_20_filled:before {
    content: "\f25e";
}
.icon-ic_fluent_vote_20_regular:before {
    content: "\f25f";
}
.icon-ic_fluent_walkie_talkie_20_filled:before {
    content: "\f260";
}
.icon-ic_fluent_walkie_talkie_20_regular:before {
    content: "\f261";
}
.icon-ic_fluent_wallet_20_filled:before {
    content: "\f262";
}
.icon-ic_fluent_wallet_20_regular:before {
    content: "\f263";
}
.icon-ic_fluent_wallet_credit_card_20_filled:before {
    content: "\f264";
}
.icon-ic_fluent_wallet_credit_card_20_regular:before {
    content: "\f265";
}
.icon-ic_fluent_wallpaper_20_filled:before {
    content: "\f266";
}
.icon-ic_fluent_wallpaper_20_regular:before {
    content: "\f267";
}
.icon-ic_fluent_wand_20_filled:before {
    content: "\f268";
}
.icon-ic_fluent_wand_20_regular:before {
    content: "\f269";
}
.icon-ic_fluent_warning_20_filled:before {
    content: "\f26a";
}
.icon-ic_fluent_warning_20_regular:before {
    content: "\f26b";
}
.icon-ic_fluent_warning_shield_20_filled:before {
    content: "\f26c";
}
.icon-ic_fluent_warning_shield_20_regular:before {
    content: "\f26d";
}
.icon-ic_fluent_washer_20_filled:before {
    content: "\f26e";
}
.icon-ic_fluent_washer_20_regular:before {
    content: "\f26f";
}
.icon-ic_fluent_water_20_filled:before {
    content: "\f270";
}
.icon-ic_fluent_water_20_regular:before {
    content: "\f271";
}
.icon-ic_fluent_weather_blowing_snow_20_filled:before {
    content: "\f272";
}
.icon-ic_fluent_weather_blowing_snow_20_regular:before {
    content: "\f273";
}
.icon-ic_fluent_weather_cloudy_20_filled:before {
    content: "\f274";
}
.icon-ic_fluent_weather_cloudy_20_regular:before {
    content: "\f275";
}
.icon-ic_fluent_weather_drizzle_20_filled:before {
    content: "\f276";
}
.icon-ic_fluent_weather_drizzle_20_regular:before {
    content: "\f277";
}
.icon-ic_fluent_weather_duststorm_20_filled:before {
    content: "\f278";
}
.icon-ic_fluent_weather_duststorm_20_regular:before {
    content: "\f279";
}
.icon-ic_fluent_weather_fog_20_filled:before {
    content: "\f27a";
}
.icon-ic_fluent_weather_fog_20_regular:before {
    content: "\f27b";
}
.icon-ic_fluent_weather_hail_day_20_filled:before {
    content: "\f27c";
}
.icon-ic_fluent_weather_hail_day_20_regular:before {
    content: "\f27d";
}
.icon-ic_fluent_weather_hail_night_20_filled:before {
    content: "\f27e";
}
.icon-ic_fluent_weather_hail_night_20_regular:before {
    content: "\f27f";
}
.icon-ic_fluent_weather_haze_20_filled:before {
    content: "\f280";
}
.icon-ic_fluent_weather_haze_20_regular:before {
    content: "\f281";
}
.icon-ic_fluent_weather_moon_20_filled:before {
    content: "\f282";
}
.icon-ic_fluent_weather_moon_20_regular:before {
    content: "\f283";
}
.icon-ic_fluent_weather_moon_off_20_filled:before {
    content: "\f284";
}
.icon-ic_fluent_weather_moon_off_20_regular:before {
    content: "\f285";
}
.icon-ic_fluent_weather_partly_cloudy_day_20_filled:before {
    content: "\f286";
}
.icon-ic_fluent_weather_partly_cloudy_day_20_regular:before {
    content: "\f287";
}
.icon-ic_fluent_weather_partly_cloudy_night_20_filled:before {
    content: "\f288";
}
.icon-ic_fluent_weather_partly_cloudy_night_20_regular:before {
    content: "\f289";
}
.icon-ic_fluent_weather_rain_20_filled:before {
    content: "\f28a";
}
.icon-ic_fluent_weather_rain_20_regular:before {
    content: "\f28b";
}
.icon-ic_fluent_weather_rain_showers_day_20_filled:before {
    content: "\f28c";
}
.icon-ic_fluent_weather_rain_showers_day_20_regular:before {
    content: "\f28d";
}
.icon-ic_fluent_weather_rain_showers_night_20_filled:before {
    content: "\f28e";
}
.icon-ic_fluent_weather_rain_showers_night_20_regular:before {
    content: "\f28f";
}
.icon-ic_fluent_weather_rain_snow_20_filled:before {
    content: "\f290";
}
.icon-ic_fluent_weather_rain_snow_20_regular:before {
    content: "\f291";
}
.icon-ic_fluent_weather_snow_20_filled:before {
    content: "\f292";
}
.icon-ic_fluent_weather_snow_20_regular:before {
    content: "\f293";
}
.icon-ic_fluent_weather_snow_shower_day_20_filled:before {
    content: "\f294";
}
.icon-ic_fluent_weather_snow_shower_day_20_regular:before {
    content: "\f295";
}
.icon-ic_fluent_weather_snow_shower_night_20_filled:before {
    content: "\f296";
}
.icon-ic_fluent_weather_snow_shower_night_20_regular:before {
    content: "\f297";
}
.icon-ic_fluent_weather_snowflake_20_filled:before {
    content: "\f298";
}
.icon-ic_fluent_weather_snowflake_20_regular:before {
    content: "\f299";
}
.icon-ic_fluent_weather_squalls_20_filled:before {
    content: "\f29a";
}
.icon-ic_fluent_weather_squalls_20_regular:before {
    content: "\f29b";
}
.icon-ic_fluent_weather_sunny_20_filled:before {
    content: "\f29c";
}
.icon-ic_fluent_weather_sunny_20_regular:before {
    content: "\f29d";
}
.icon-ic_fluent_weather_sunny_high_20_filled:before {
    content: "\f29e";
}
.icon-ic_fluent_weather_sunny_high_20_regular:before {
    content: "\f29f";
}
.icon-ic_fluent_weather_sunny_low_20_filled:before {
    content: "\f2a0";
}
.icon-ic_fluent_weather_sunny_low_20_regular:before {
    content: "\f2a1";
}
.icon-ic_fluent_weather_thunderstorm_20_filled:before {
    content: "\f2a2";
}
.icon-ic_fluent_weather_thunderstorm_20_regular:before {
    content: "\f2a3";
}
.icon-ic_fluent_web_asset_20_filled:before {
    content: "\f2a4";
}
.icon-ic_fluent_web_asset_20_regular:before {
    content: "\f2a5";
}
.icon-ic_fluent_whiteboard_20_filled:before {
    content: "\f2a6";
}
.icon-ic_fluent_whiteboard_20_regular:before {
    content: "\f2a7";
}
.icon-ic_fluent_whiteboard_off_20_filled:before {
    content: "\f2a8";
}
.icon-ic_fluent_whiteboard_off_20_regular:before {
    content: "\f2a9";
}
.icon-ic_fluent_wifi_1_20_filled:before {
    content: "\f2aa";
}
.icon-ic_fluent_wifi_1_20_regular:before {
    content: "\f2ab";
}
.icon-ic_fluent_wifi_2_20_filled:before {
    content: "\f2ac";
}
.icon-ic_fluent_wifi_2_20_regular:before {
    content: "\f2ad";
}
.icon-ic_fluent_wifi_3_20_filled:before {
    content: "\f2ae";
}
.icon-ic_fluent_wifi_3_20_regular:before {
    content: "\f2af";
}
.icon-ic_fluent_wifi_4_20_filled:before {
    content: "\f2b0";
}
.icon-ic_fluent_wifi_4_20_regular:before {
    content: "\f2b1";
}
.icon-ic_fluent_wifi_lock_20_filled:before {
    content: "\f2b2";
}
.icon-ic_fluent_wifi_lock_20_regular:before {
    content: "\f2b3";
}
.icon-ic_fluent_wifi_off_20_filled:before {
    content: "\f2b4";
}
.icon-ic_fluent_wifi_off_20_regular:before {
    content: "\f2b5";
}
.icon-ic_fluent_wifi_settings_20_filled:before {
    content: "\f2b6";
}
.icon-ic_fluent_wifi_settings_20_regular:before {
    content: "\f2b7";
}
.icon-ic_fluent_wifi_warning_20_filled:before {
    content: "\f2b8";
}
.icon-ic_fluent_wifi_warning_20_regular:before {
    content: "\f2b9";
}
.icon-ic_fluent_window_20_filled:before {
    content: "\f2ba";
}
.icon-ic_fluent_window_20_regular:before {
    content: "\f2bb";
}
.icon-ic_fluent_window_ad_20_filled:before {
    content: "\f2bc";
}
.icon-ic_fluent_window_ad_20_regular:before {
    content: "\f2bd";
}
.icon-ic_fluent_window_ad_off_20_filled:before {
    content: "\f2be";
}
.icon-ic_fluent_window_ad_off_20_regular:before {
    content: "\f2bf";
}
.icon-ic_fluent_window_ad_person_20_filled:before {
    content: "\f2c0";
}
.icon-ic_fluent_window_ad_person_20_regular:before {
    content: "\f2c1";
}
.icon-ic_fluent_window_apps_20_filled:before {
    content: "\f2c2";
}
.icon-ic_fluent_window_apps_20_regular:before {
    content: "\f2c3";
}
.icon-ic_fluent_window_arrow_up_20_filled:before {
    content: "\f2c4";
}
.icon-ic_fluent_window_arrow_up_20_regular:before {
    content: "\f2c5";
}
.icon-ic_fluent_window_bullet_list_20_filled:before {
    content: "\f2c6";
}
.icon-ic_fluent_window_bullet_list_20_regular:before {
    content: "\f2c7";
}
.icon-ic_fluent_window_bullet_list_add_20_filled:before {
    content: "\f2c8";
}
.icon-ic_fluent_window_bullet_list_add_20_regular:before {
    content: "\f2c9";
}
.icon-ic_fluent_window_console_20_filled:before {
    content: "\f2ca";
}
.icon-ic_fluent_window_console_20_regular:before {
    content: "\f2cb";
}
.icon-ic_fluent_window_database_20_filled:before {
    content: "\f2cc";
}
.icon-ic_fluent_window_database_20_regular:before {
    content: "\f2cd";
}
.icon-ic_fluent_window_dev_edit_20_filled:before {
    content: "\f2ce";
}
.icon-ic_fluent_window_dev_edit_20_regular:before {
    content: "\f2cf";
}
.icon-ic_fluent_window_dev_tools_20_filled:before {
    content: "\f2d0";
}
.icon-ic_fluent_window_dev_tools_20_regular:before {
    content: "\f2d1";
}
.icon-ic_fluent_window_edit_20_filled:before {
    content: "\f2d2";
}
.icon-ic_fluent_window_edit_20_regular:before {
    content: "\f2d3";
}
.icon-ic_fluent_window_header_horizontal_20_filled:before {
    content: "\f2d4";
}
.icon-ic_fluent_window_header_horizontal_20_regular:before {
    content: "\f2d5";
}
.icon-ic_fluent_window_header_horizontal_off_20_filled:before {
    content: "\f2d6";
}
.icon-ic_fluent_window_header_horizontal_off_20_regular:before {
    content: "\f2d7";
}
.icon-ic_fluent_window_header_vertical_20_filled:before {
    content: "\f2d8";
}
.icon-ic_fluent_window_header_vertical_20_regular:before {
    content: "\f2d9";
}
.icon-ic_fluent_window_inprivate_20_filled:before {
    content: "\f2da";
}
.icon-ic_fluent_window_inprivate_20_regular:before {
    content: "\f2db";
}
.icon-ic_fluent_window_inprivate_account_20_filled:before {
    content: "\f2dc";
}
.icon-ic_fluent_window_inprivate_account_20_regular:before {
    content: "\f2dd";
}
.icon-ic_fluent_window_location_target_20_filled:before {
    content: "\f2de";
}
.icon-ic_fluent_window_location_target_20_regular:before {
    content: "\f2df";
}
.icon-ic_fluent_window_multiple_20_filled:before {
    content: "\f2e0";
}
.icon-ic_fluent_window_multiple_20_regular:before {
    content: "\f2e1";
}
.icon-ic_fluent_window_multiple_swap_20_filled:before {
    content: "\f2e2";
}
.icon-ic_fluent_window_multiple_swap_20_regular:before {
    content: "\f2e3";
}
.icon-ic_fluent_window_new_20_filled:before {
    content: "\f2e4";
}
.icon-ic_fluent_window_new_20_regular:before {
    content: "\f2e5";
}
.icon-ic_fluent_window_play_20_filled:before {
    content: "\f2e6";
}
.icon-ic_fluent_window_play_20_regular:before {
    content: "\f2e7";
}
.icon-ic_fluent_window_settings_20_filled:before {
    content: "\f2e8";
}
.icon-ic_fluent_window_settings_20_regular:before {
    content: "\f2e9";
}
.icon-ic_fluent_window_shield_20_filled:before {
    content: "\f2ea";
}
.icon-ic_fluent_window_shield_20_regular:before {
    content: "\f2eb";
}
.icon-ic_fluent_window_text_20_filled:before {
    content: "\f2ec";
}
.icon-ic_fluent_window_text_20_regular:before {
    content: "\f2ed";
}
.icon-ic_fluent_window_wrench_20_filled:before {
    content: "\f2ee";
}
.icon-ic_fluent_window_wrench_20_regular:before {
    content: "\f2ef";
}
.icon-ic_fluent_wrench_20_filled:before {
    content: "\f2f0";
}
.icon-ic_fluent_wrench_20_regular:before {
    content: "\f2f1";
}
.icon-ic_fluent_wrench_screwdriver_20_filled:before {
    content: "\f2f2";
}
.icon-ic_fluent_wrench_screwdriver_20_regular:before {
    content: "\f2f3";
}
.icon-ic_fluent_xbox_console_20_filled:before {
    content: "\f2f4";
}
.icon-ic_fluent_xbox_console_20_regular:before {
    content: "\f2f5";
}
.icon-ic_fluent_xbox_controller_20_filled:before {
    content: "\f2f6";
}
.icon-ic_fluent_xbox_controller_20_regular:before {
    content: "\f2f7";
}
.icon-ic_fluent_xbox_controller_error_20_filled:before {
    content: "\f2f8";
}
.icon-ic_fluent_xbox_controller_error_20_regular:before {
    content: "\f2f9";
}
.icon-ic_fluent_xray_20_filled:before {
    content: "\f2fa";
}
.icon-ic_fluent_xray_20_regular:before {
    content: "\f2fb";
}
.icon-ic_fluent_zoom_fit_20_filled:before {
    content: "\f2fc";
}
.icon-ic_fluent_zoom_fit_20_regular:before {
    content: "\f2fd";
}
.icon-ic_fluent_zoom_in_20_filled:before {
    content: "\f2fe";
}
.icon-ic_fluent_zoom_in_20_regular:before {
    content: "\f2ff";
}
.icon-ic_fluent_zoom_out_20_filled:before {
    content: "\f300";
}
.icon-ic_fluent_zoom_out_20_regular:before {
    content: "\f301";
}
